.slider_CTA{
  border: 2px solid #000;
  color: #000!important;
  background-color: #fff; 
  height: 36px;
  font-size: 14px;
  padding: 7px 25px 5px!important;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;  
  font-family: Times New Roman;
}
.acc-slider-description {
  margin: auto;
  justify-content: center;
  text-align: center;
}
.amp-dc-slider .acc-slider-description h1, .amp-dc-slider .acc-slider-description h2, .amp-dc-slider .acc-slider-description h3, .amp-dc-slider .acc-slider-description h4, .amp-dc-slider .acc-slider-description h5, .amp-dc-slider .acc-slider-description h6{
  font-family:inherit!important;
  font-weight:500;
}
.amp-dc-slider .acc-slider-description h1{
  font-size:36px;
}
.amp-dc-slider .acc-slider-description h2{
  font-size:30px;
}
.amp-dc-slider .acc-slider-description h3{
  font-size:22px;
}
.amp-dc-slider .acc-slider-description h4{
 font-size:18px; 
}
.amp-dc-slider .acc-slider-description h5{
  font-size:16px;
}
.amp-dc-slider .acc-slider-description h6{
  font-size:14px;
}
.amp-dc-card-list .amp-dc-card-title{
  font-size:25px;
}
.amp-dc-slider {
 position: relative;
 overflow: hidden;
 white-space: nowrap;
}
.amp-dc-slider * {
 margin: 0;
 padding: 0;
}
.amp-dc-slider .amp-dc-slider-frame {
 padding-bottom: 50px;
 position: relative;
 overflow: hidden;
 white-space: nowrap;
 width: 100%;
}
.amp-dc-slider .amp-dc-slider-slides {
 font-size: 0;
}
.amp-dc-slider .amp-dc-slider-slides > li {
 display: inline-block;
 overflow: hidden;
 vertical-align: middle;
 width: 100%;
 text-align: center;
 font-size: 22px;
 position: relative;
 line-height: 1;
}
.amp-dc-slider .amp-dc-slider-next, .amp-dc-slider .amp-dc-slider-prev {
 position: absolute;
 top: 50%;
 margin-top: -25px;
 display: block;
 cursor: pointer;
 user-select: none;
}
.amp-dc-slider .amp-dc-slider-next svg, .amp-dc-slider .amp-dc-slider-prev svg {
 cursor: pointer;
 width: 40px;
 height: 40px;

 background: rgba(0, 0, 0, 0.5);
}
.amp-dc-slider .amp-dc-slider-next.ctrl-disabled, .amp-dc-slider .amp-dc-slider-prev.ctrl-disabled {
 opacity: 0.3;
 -webkit-tap-highlight-color: transparent;
 cursor: default;
}
.amp-dc-slider .amp-dc-slider-next.ctrl-disabled svg, .amp-dc-slider .amp-dc-slider-prev.ctrl-disabled svg {
 cursor: default;
}
.amp-dc-slider .amp-dc-slider-next {
 right: 0;
}
.amp-dc-slider .amp-dc-slider-prev {
 left: 0;
}
.amp-dc-slider .amp-dc-dots {
 user-select: none;
 margin: 10px 0 0 0;
 padding: 0;
 text-align: center;
 position: absolute;
 width: 100%;
 -webkit-tap-highlight-color: transparent;
}
.amp-dc-slider .amp-dc-dots > li {
 border: 1px solid #666;
 border-radius: 50%;
 box-shadow: inset 1px 1px 1px #888;
 display: inline-block;
 height: 25px;
 width: 25px;
 margin: 0 5px;
 cursor: pointer;
}
.amp-dc-slider .amp-dc-dots > li.active {
 background-color: #41abe5;
}

