
.amp-dc-banner video#vjs_video_3_html5_api {
  width: 100%;
}
video.amp-dc-video {
  width: 100%;
  height: auto;
}

.inactive-video {
  position: relative;
  z-index: 100;
  &.no-overlay {
    display: none;
  }
}

.amp-dc-video-wrap {
  position: relative;
  margin: 0;
  padding: 0;
  .amp-dc-video {
    user-select: none;
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .pause-button {
    cursor: pointer;
    position: absolute;
    z-index: 50;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    -webkit-tap-highlight-color: transparent;
    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      box-shadow: inset 0 0 500px rgba(196, 215, 182, 0.5);
      position: absolute;
      z-index: 100;
    }
    &:after {
      content: '';
      display: block;
      border-left: 43px solid #3f433a;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      position: absolute;
      border-radius: 6px;
      z-index: 200;
      margin-top: 15px;
      margin-left: 24px;
    }
    &.inactive {
      display: none;
    }
  }
}



/* special offerspage cards*/
video.amp-dc-video.special-offers-308x330{
  width:308px;
  height:auto!important;
}
/*hotel collections*/
video.amp-dc-video.hotel-collection-277x172 {
  width: 277px;
  height: auto;
}
video.amp-dc-video.journal-video-729 {
  width: 729px;
  height: auto;
}
/*hotel product video*/
video.amp-dc-video.hotel-product-120{
   width: 120px;
   border: 1px solid #d6d6d6;
  height: auto;
}
/*about us bottom video*/
video.amp-dc-video.about-us-165x166{
  width: 165px;
  height: 166px;
}
/*about us main video */
video.amp-dc-video.about-us-main-355x478{
   width: 355px;
  height: 478px;
}
/*mega menu video*/
video.amp-dc-video.mm-208x208{
   width: 208px;
  height: 208px;
}
/*Hotel aminities bottom video*/
video.amp-dc-video.hotel-bottom-220x208{
   width: 220px;
  height: 208px;
}
/*Journal home,category page video*/
video.amp-dc-video.journal-small-270x181{
   width: 270px;
  height: auto;
}
video.amp-dc-video.journal-main-574x385{
   width: 574px;
  height: auto;
}
/*hotel aminities  video*/
video.amp-dc-video.hotel-right-293x218{
   width: 293px;
  height: auto;
}
video.amp-dc-video.hotel-right-292x161{
   width: 292px;
  height: auto;
}
video.amp-dc-video.hotel-bottom-220x202{
   width: 220px;
  height: auto;
}
/*about us main video */
video.amp-dc-video.journal-product-212{
   width: 212px;
  height: 212px;
}
/*journal page product video */
video.amp-dc-video.journal-product-212{
   width: 212px;
  height: 212px;
}
/*journal listcials,editorial, listical title and numericals*/

video.amp-dc-video.journal-small-413{
   width: 413px;
  height: auto;
}
video.amp-dc-video.journal-small-414{
  width: 414px;
  height: auto;
}
video.amp-dc-video.journal-small-417{
   width: 417px;
  height: auto;
}
video.amp-dc-video.journal-small-415{
   width: 415px;
  height: auto;
}
video.amp-dc-video.journal-small-422{
   width: 422px;
  height: auto;
}
/* home page video */
video.amp-dc-video.hp-small-445x181{
   width: 100%;
  height: auto!important;
}
@media screen and (min-width: 1025px){
video.amp-dc-video.hp-small-431x291{
   width: 431px;
  height: auto!important;
} 
video.amp-dc-video.hp-small-445x181{
   width: 445px;
  height: 181px!important;
}
video.amp-dc-video.frag-hub-two-banners-620{
  width: 620px;
  height:auto;
}
}




@media screen and (max-width: 768px){
 
 /* special offers page image*/   
 video.amp-dc-video.special-offers-308x330{
   width:100%;
   height:auto!important;
 }     
   
 /*hotel collections*/
 video.amp-dc-video.hotel-collection-277x172 {
  width: 100%;
  height: auto;
}
 video.amp-dc-video.journal-video-729 {
  width: 100%;
  height: auto;
}  
/*hotel products*/
video.amp-dc-video.hotel-product-120{
   width: 120px;
  height: auto;
}
/*about us bottom banners*/
video.amp-dc-video.about-us-165x166{
  width: 165px;
  height: 166px;
}
/*about us main video */
video.amp-dc-video.about-us-main-355x478{
   width: 100%;
  height: 100%;
}
/*Hotel aminities bottom video*/
video.amp-dc-video.hotel-bottom-220x208{
   width: 100%;
  height:auto;
}
/*Journal home,category page video*/
video.amp-dc-video.journal-small-270x181{
   width: 100%;
  height: auto;
}
video.amp-dc-video.journal-main-574x385{
   width: 100%;
  height: auto;
}
/*hotel aminities  images*/
video.amp-dc-video.hotel-right-293x218{
   width: 100%;
  height: auto;
}
video.amp-dc-video.hotel-right-292x161{
   width: 292px;
  height: auto;
}
video.amp-dc-video.hotel-bottom-220x202{
   width: 100%;
  height: auto;
}
/*journal page prodcut image */
video.amp-dc-video.journal-product-212{
  width: 100%;
  height: auto;
}
/*journal listcials,editorial, listical title and numericals*/

video.amp-dc-video.journal-small-413{
   width: 100%;
  height: auto;
}
video.amp-dc-video.journal-small-414{
  width: 100%;
  height: auto;
}
video.amp-dc-video.journal-small-417{
  width: 100%;
  height: auto;
}
video.amp-dc-video.journal-small-415{
   width: 100%;
  height: auto;
}
video.amp-dc-video.journal-small-422{
  width: 100%;
  height: auto;
}
video.amp-dc-video.frag-hub-two-banners-620{
  width: 100%;
  height:auto;
}
}

@media screen and (max-width: 1024px){
   video.amp-dc-video.hp-small-431x291{
     width: 100%;
    height: auto;
  }
.amp-dc-card.hp-small-445x181 {
  width: 100%;
  height: auto;
}

}

.amp-dc-video-wrap {
 position: relative;
 margin: 0;
 padding: 0;
}
.amp-dc-video-wrap .amp-dc-video {
   height:auto;
 user-select: none;
 margin: 0 auto;
 display: block;
 width: 100%;
}
.amp-dc-video-wrap .pause-button {
 cursor: pointer;
 position: absolute;
 z-index: 50;
 top: 50%;
 left: 50%;
 width: 80px;
 height: 80px;
 margin-top: -40px;
 margin-left: -40px;
 -webkit-tap-highlight-color: transparent;
}
.amp-dc-video-wrap .pause-button:before {
 content: '';
 display: block;
 height: 100%;
 width: 100%;
 border-radius: 50%;
 box-shadow: inset 0 0 500px rgba(196, 215, 182, 0.5);
 position: absolute;
 z-index: 100;
}
.amp-dc-video-wrap .pause-button:after {
 content: '';
 display: block;
 border-left: 43px solid #3f433a;
 border-top: 25px solid transparent;
 border-bottom: 25px solid transparent;
 position: absolute;
 border-radius: 6px;
 z-index: 200;
 margin-top: 15px;
 margin-left: 24px;
}
.amp-dc-video-wrap .pause-button.inactive {
 display: none;
}

