
.numerical-block{
    margin-top: 25px;
}
    @media screen and (max-width: 1440px){
     .prodct-block{
      width: 990px;
      margin:0 auto;
      text-align:center;
      
     }
   }
   .prodct-block .shop-this-story-prdct {
     width: 24%;
     height: auto;
     position : relative;
     display:inline-block;
     vertical-align: top;
     margin:0 auto;
     
   }
   .shop-this-story-prdct-link{
       margin-top:20px;
       position:relative;
   }
   .shop-story-prodct-cta{
    font-family: 'Times New Roman',Times,serif;
    font-style: italic;
    font-size: 14px;
    padding: 7px 25px 5px;
    line-height: 20px;
    text-decoration: none;
    color:#000;
    text-transform:none;
    background-color:#fff;
    
   }
   .shop-story-prodct-cta::after {
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-bottom: 5px solid transparent;
    content: "";
    display: inline-block;
    height: auto;
    width: auto;
    margin-left: 3px;
    vertical-align: baseline;
    visibility: visible;
}

    
    
.editorial-carousel-wrapper{
  width:100%;
  padding-top: 20px;
  text-align:center;
}
.editoril-carousel{
  width:90%;
  margin:0px auto;
}
.slick-slide{
  margin:10px;
}
.slick-slide img{
  width:100%;
  border: 2px solid #fff;
}

.shop-story-prodct-description {
    font-size:12px;
    margin-top:15px;
}
.num-right-sec-desc{
    margin-top: 25px;
}
.editorial-container {
    blockquote{
        margin-top: 15px;
    }
}