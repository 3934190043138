@font-face {
  font-family: "icons";
  src: url("fonts/icomoon.woff?jqp4hl") format("woff"),
    url("fonts/icomoon.svg?jqp4hl#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag-sm:before {
  content: "\e919";
}
.icon-circle-sm:before {
  content: "\e91a";
}
.icon-circle-selected-sm:before {
  content: "\e91b";
}
.icon-close-sm:before {
  content: "\e91c";
}
.icon-downarrow-sm:before {
  content: "\e91d";
}
.icon-downarrow1-sm:before {
  content: "\e91e";
}
.icon-gift-sm:before {
  content: "\e91f";
}
.icon-leftarrow-sm:before {
  content: "\e920";
}
.icon-menu-sm:before {
  content: "\e921";
}
.icon-minus-sm:before {
  content: "\e922";
}
.icon-phone-sm:before {
  content: "\e923";
}
.icon-pin-sm:before {
  content: "\e924";
}
.icon-plus-sm:before {
  content: "\e925";
}
.icon-rightarrow-sm:before {
  content: "\e926";
}
.icon-search-sm:before {
  content: "\e927";
}
.icon-tick-sm:before {
  content: "\e928";
}
.icon-uparrow-sm:before {
  content: "\e929";
}
.icon-user-sm:before {
  content: "\e92a";
}
.icon-circle-selected-md:before {
  content: "\e92b";
}
.icon-bag-md:before {
  content: "\e92c";
}
.icon-user-md:before {
  content: "\e92d";
}
.icon-search-md:before {
  content: "\e92e";
}
.icon-pin-md:before {
  content: "\e92f";
}
.icon-menu-md:before {
  content: "\e930";
}
.icon-downarrow1-md:before {
  content: "\e931";
}
.icon-downarrow2-md:before {
  content: "\e932";
}
.icon-uparrow-md:before {
  content: "\e933";
}
.icon-rightarrow-md:before {
  content: "\e934";
}
.icon-leftarrow-md:before {
  content: "\e935";
}
.icon-plus-md:before {
  content: "\e936";
}
.icon-minus-md:before {
  content: "\e937";
}
.icon-close-md:before {
  content: "\e938";
}
.icon-tick-md:before {
  content: "\e939";
}
.icon-phone-md:before {
  content: "\e93a";
}
.icon-bin-md:before {
  content: "\e93b";
}
.icon-edit-md:before {
  content: "\e93c";
}
.icon-lock-md:before {
  content: "\e93d";
}
.icon-logout-md:before {
  content: "\e93e";
}
.icon-heart-md:before {
  content: "\e93f";
}
.icon-book-md:before {
  content: "\e940";
}
.icon-creditcard-md:before {
  content: "\e941";
}
.icon-gift-md:before {
  content: "\e942";
}
.icon-star-md:before {
  content: "\e943";
}
.icon-envelope-md:before {
  content: "\e944";
}
.icon-smile-md:before {
  content: "\e945";
}
.icon-circle-md:before {
  content: "\e946";
}
.icon-shop-md:before {
  content: "\e947";
}
.icon-date-md:before {
  content: "\e948";
}
.icon-circle-lg:before {
  content: "\e900";
}
.icon-circle-selected-lg:before {
  content: "\e901";
}
.icon-bag-lg:before {
  content: "\e902";
}
.icon-user-lg:before {
  content: "\e903";
}
.icon-search-lg:before {
  content: "\e904";
}
.icon-pin-lg:before {
  content: "\e905";
}
.icon-menu-lg:before {
  content: "\e906";
}
.icon-downarrow1-lg:before {
  content: "\e907";
}
.icon-downarrow2-lg:before {
  content: "\e908";
}
.icon-leftarrow-lg:before {
  content: "\e909";
}
.icon-rightarrow-lg:before {
  content: "\e90a";
}
.icon-plus-lg:before {
  content: "\e90b";
}
.icon-minus-lg:before {
  content: "\e90c";
}
.icon-close-lg:before {
  content: "\e90d";
}
.icon-tick-lg:before {
  content: "\e90e";
}
.icon-phone-lg:before {
  content: "\e90f";
}
.icon-lock-lg:before {
  content: "\e910";
}
.icon-logout-lg:before {
  content: "\e911";
}
.icon-heart-lg:before {
  content: "\e912";
}
.icon-book-lg:before {
  content: "\e913";
}
.icon-creditcard-lg:before {
  content: "\e914";
}
.icon-gift-lg:before {
  content: "\e915";
}
.icon-star-lg:before {
  content: "\e916";
}
.icon-envelope-lg:before {
  content: "\e917";
}
.icon-smile-lg:before {
  content: "\e918";
}
