@import '@adyen/adyen-web/dist/adyen.css';
@import "node_modules/bootstrap/scss/mixins";
@import "_variable-redesign";

* {
  @include box-sizing(border-box);
  margin: 0;
  padding: 0;
}

html {
  font-family: $baskerville-regular;
}



body {
  margin: 0;
  font-size: $mb-base-font-size;
  font-weight: $mb-font-normal;
  text-align: left;
  font-family: $gill-sans-light;
  line-height: $mb-primary-line-height;
  color: $molton-black;
  font-style: normal;
  font-stretch: normal;
  overflow-x: hidden;

  .bold {
    font-family: $gill-sans-regular;
    font-weight: $mb-font-bold;
  }

  &.overlay-scroll-hidden {
    #modal-background {
      background-color: rgba(31, 26, 24, 0.6);
      height: 100%;
      position: fixed;
      opacity: .5;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }

    @include media-breakpoint-only(sm) {
      overflow-y: hidden;
    }
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

button {
  font-size: $mb-base-font-size;
  font-family: $gill-sans-regular;
  font-weight: $mb-font-bold;
  line-height: $mb-captions-line-height;
}

label {
  font-size: $mb-alt-base-text-size;
  font-family: $gill-sans-regular;
  line-height: $mb-captions-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $baskerville-regular;
  font-weight: $mb-font-bold;
  line-height: $mb-secondary-line-height;
}

h6 {
  font-family: $gill-sans-regular;
  line-height: $mb-primary-line-height;
}

h1 {
  font-size: $mb-base-font-size-h1;

  @include media-breakpoint-only(sm) {
    font-size: $mb-base-font-size-h1-mob;
  }
}

h2 {
  font-size: $mb-base-font-size-h2;

  @include media-breakpoint-only(sm) {
    font-size: $mb-base-font-size-h2-mob;
  }
}

h3 {
  font-size: $mb-base-font-size-h3;
}

h4 {
  font-size: $mb-base-font-size-h4;
}

h5 {
  font-size: $mb-base-font-size-h5;
}

h6 {
  font-size: $mb-base-font-size-h6;
}

p,
span,
a {
  font-family: $gill-sans-light;
  font-style: normal;
  font-weight: 300;
  font-size: $mb-base-font-size-h5;
  line-height: $mb-primary-line-height;
  color: $molton-black;
  //font-kerning: none;
}

a, a:link {
  color: $mb-gray-100;
}
.description a {
  text-decoration: underline;
}

.alt-text-large {
  font-family: $baskerville-regular;
  font-weight: $mb-font-bold;
  line-height: $mb-secondary-line-height;
  font-size: $mb-alt-text-large;
}

.text-xlarge,
.text-xlarge-bold,
.text-large,
.text-large-bold {
  line-height: $mb-primary-line-height;
  font-size: $mb-alt-base-text-size;
}

.text-large,
.text-xlarge {
  font-family: $gill-sans-light;
  font-weight: $mb-font-normal;
}

.text-large-bold,
.text-xlarge-bold {
  font-family: $gill-sans-regular;
  font-weight: $mb-font-bold;
}

.overline {
  font-family: $gill-sans-regular;
  line-height: $mb-captions-line-height;
  font-size: $mb-overline-font-size;
  font-weight: $mb-font-bold;
}

/* Anchor Style*/

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Button Style */

button,
.mb-btn-link {
  height: 48px;
  border-radius: 100px;
  padding: 15px 32px 15px 32px;
  box-sizing: border-box;
  font-family: $gill-sans-regular;
  font-size: $mb-base-font-size;
  font-weight: $mb-font-bold;
  line-height: $mb-primary-line-height;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 5px;
  @include flexbox();
  align-items: center;
}

.btn-black {
  border: none;
  @include btn-theme($mb-color-white, $mb-gray-100);

  &:hover {
    background-color: $mb-gray-80;
  }

  &:disabled {
    @include btn-theme($mb-gray-80, $mb-gray-40);
  }
}

.btn-grey-outline {
  @include btn-border-theme(1.5px, $mb-gray-40);
  @include btn-theme($mb-gray-100, $mb-color-white);

  &:hover {
    @include btn-border-theme(1.5px, $mb-gray-80);
  }

  &:disabled {
    border: none;
    @include btn-theme($mb-gray-80, $mb-gray-40);
  }
}

.btn-black-outline {
  @include btn-border-theme(1.5px, $mb-gray-80);
  @include btn-theme($mb-gray-100, $mb-gray-20);

  &:hover {
    @include btn-border-theme(1.5px, $mb-gray-40);
  }

  &:disabled {
    border: none;
    @include btn-theme($mb-gray-80, $mb-gray-40);
  }
}

.btn-white-outline {
  @include btn-border-theme(1px, $mb-color-white);
  @include btn-theme($mb-color-white, $mb-gray-100);

  &:hover {
    @include btn-border-theme(1.5px, $mb-gray-40);
  }

  &.active {
    @include btn-border-theme(1.5px, $mb-color-white);
  }

  &:disabled {
    border: none;
    @include btn-theme($mb-gray-80, $mb-gray-40);
  }
}

.btn-white {
  border: none;
  @include btn-theme($mb-gray-100, $mb-color-white);

  &:hover {
    @include btn-theme($mb-gray-100, $mb-gray-30);
  }

  &:disabled {
    @include btn-theme($mb-gray-80, $mb-gray-40);
  }
}

.mb-shadow-sm {
  border-radius: $mb-primary-border-radius;
  box-shadow: $mb-shadow-small;
}

.mb-shadow-md {
  border-radius: $mb-primary-border-radius;
  box-shadow: $mb-shadow-medium;
}

.mb-shadow-lg {
  border-radius: $mb-primary-border-radius;
  box-shadow: $mb-shadow-large;
}

.badge {
  &.mb-badge {
    text-align: center;
    border-radius: $mb-primary-border-radius;
    text-transform: uppercase;
    padding: 15px 16px 9px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    font-style: normal;
    height: 36px;
    width: 127px;
    border: none;

    &.badge-light {
      @include btn-theme($mb-gray-100, $mb-color-white);
    }

    &.badge-dark {
      @include btn-theme($mb-gray-10, $mb-gray-100);
    }

    &.badge-close {
      color: $mb-gray-100;
      height: 36px;
      width: 83px;
      padding: 0 12px;
      @include flexbox();
      align-items: center;
      justify-content: space-between;
      @include btn-theme($mb-gray-100, $mb-gray-30);
    }
  }
}

.badge-pill {
  width: 71px;
  height: 44px;
  padding: 16px, 20px;
  border-radius: 100px;
  text-transform: capitalize;
  font-family: $gill-sans-regular;
  @include flexbox();
  justify-content: center;
  align-items: center;
  font-size: 16px;

  &.mb-badge-default {
    @include btn-theme($mb-color-black, $mb-gray-30);
  }

  &.mb-badge-active {
    @include btn-theme($mb-color-black, $mb-color-white);
    box-shadow: $mb-shadow-small;
  }
}

.mb-flex {
  @include flexbox();
  background-color: #000;
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon-sm {
  font-size: 16px;
}

.icon-md {
  font-size: 24px;
}

.icon-lg {
  font-size: 40px;
}

/* Forms */

.form-floating {

  .form-control,
  .form-select {
    padding-left: 20px;
    font-size: $mb-base-font-size-h6;
    height: 60px;
    width: 327px;
    font-family: $gill-sans-regular;
    font-style: normal;
    font-weight: $mb-font-bold;
    font-size: $mb-base-font-size-h6;
    line-height: $mb-alt-base-text-size;
    color: $mb-gray-100;
    border-radius: 8px;

    @include media-breakpoint-only (sm) {
      width: 100%;
    }
  }

  label {
    font-family: $gill-sans-regular;
    font-style: normal;
    font-weight: $mb-font-bold;
    font-size: $mb-base-font-size-h6;
    line-height: $mb-primary-line-height;
    @include flexbox();
    align-items: center;
    letter-spacing: 0.02em;
    color: $mb-gray-70;
    left: 20px;
    padding-top: 0px;
    border-top-width: 0px;
    padding-bottom: 24px;

    &.no-caption {
      padding-top: 30px;
    }
  }

  input {
    padding: 16px 18px;
    border-radius: 8px;

    @include media-breakpoint-only (sm) {
      width: 100%;
    }

    &:focus,
    &:hover {
      box-shadow: none !important;
      
      border: 1.5px solid $mb-gray-80 !important;
    }

    &:disabled {
      background: $mb-gray-40;
      cursor: not-allowed;
      border: none !important;
      padding-left:20px;

      &:focus,
      &:hover {
        box-shadow: none !important;
        border: none !important;
        padding-left:20px;
        cursor: not-allowed;
        //pointer-events: none;
      }
    }
  }

  .caption {
    left: 3.4%;
    margin-top: 8px;
    font-family: $gill-sans-regular;
    font-style: normal;
    font-weight: $mb-font-bold;
    font-size: $mb-base-font-size-h6;
    line-height: $mb-primary-line-height;
    @include flexbox();
    align-items: center;
    letter-spacing: 0.02em;
    color: $mb-gray-70;

    span {
      font-size: 16px;
      font-family: $gill-sans-regular;
      font-style: normal;
      font-weight: $mb-font-bold;
      font-size: $mb-base-font-size-h6;
      line-height: $mb-primary-line-height;
    }

    .error {
      visibility: visible;
      color: $mb-gray-70;

      &.not-valid,
      .invalid,
      .cred-error {
        display: none;
      }
    }

    &.not-valid,
    .invalid,
    .cred-error {
      span {
        color: $mb-alert-50 !important;
      }
    }
  }

  &.mb-custom-select {
    label {
      padding-bottom: 30px;
    }

  }

  .border_grey {
    @include btn-border-theme(1.5px, $mb-gray-40);
    @include box-sizing(border-box);
    border-radius: 8px;
  }

  .required {
    color: $mb-alert-50;
  }

  .star-required {
    font-family: $gill-sans-regular;
    color: $mb-alert-50;
    font-size: $mb-base-font-size-h6;
    margin-left: 5px;
    font-weight: 600;
  }
}


.mb-custom-checkbox {

  .align-text-child {
    @include flexbox();
    align-items: center;

    &.align-text-parent {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  label {
    color: $mb-gray-100;
    font-size: $mb-base-font-size-h6;
    line-height: $mb-primary-line-height;
    @include flexbox();
    letter-spacing: 0.02em;
    margin-left: 12px;
    margin-top: 5px;
  }

  .form-check-input {
    border: 1.5px solid $mb-gray-40;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;

    &:checked {
      background-color: $mb-gray-100;
      border-color: $mb-gray-100;
    }

    &:checked[type="radio"] {
      background-image: url(/assets/Redesign/checked-radio.svg);
      background-size: 24px 14px;
      background-color: $mb-color-white;
      border-color: $mb-gray-100;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .caption-radiobtn {
    font-family: $gill-sans-regular;
    font-style: normal;
    font-weight: $mb-font-normal;
    font-size: $mb-base-font-size-h6;
    line-height: $mb-primary-line-height;
    color: $mb-gray-70;
    ;
    margin-left: 38px;
  }

  .caption-checkbox {
    font-family: $gill-sans-regular;
    font-style: normal;
    font-weight: $mb-font-bold;
    font-size: $mb-label-font-size;
    line-height: $mb-captions-line-height;
    align-items: center;
    color: $mb-gray-70;
    margin-top: 10px;
  }
}


form {

  input.not-valid,
  button.not-valid,
  select.not-valid,
  textarea.not-valid {
    border: 1.5px solid $mb-alert-50 !important;
  }

  input[type="radio"],
  input[type="checkbox"] {

    &:focus,
    &:hover {
      @include btn-border-theme(1.5px, $mb-gray-100);
      box-shadow: none;
    }
  }
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label,
.form-floating .autocomplete .form-control:focus~label
 {
  opacity: 1;
  -webkit-transform: scale(0.95) translateY(-0.5rem) translateX(0.15rem);
  -ms-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.90) translateY(-0.5rem) translateX(0.15rem);
  padding-left: 12px;
  font-size: 16px;

  @include media-breakpoint-only(sm) {
    padding-left: 12px;
  }
}

.form-control {

  &:focus,
  &:not(:placeholder-shown) {
    padding-left: 18px;
    padding-top: 31px !important;
  }
}

.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  background-image: url(/assets/Redesign/select-arrow.svg);

  &:focus,
  &:hover {
    box-shadow: none !important;
    border: 1.5px solid $mb-gray-80 !important;
  }

  &:disabled {
    background-color: $mb-gray-40;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;

    &:focus,
    &:hover {
      cursor: not-allowed;
    }
  }

  option {
    font-family: $gill-sans-regular;
  }
}

b,
strong {
  font-weight: bold;
  font-family: $gill-sans-regular;

}

.desktop-only {
  display: block;

  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.mob-only {
  display: none;

  @include media-breakpoint-only(sm) {
    display: block;
  }
}

.banner_description {
  font-family: $gill-sans-regular;
  font-weight: $mb-font-normal;
  color: $molton-black;
  font-size: 18px;

  @include media-breakpoint-only(sm) {
    font-size: 16px;
  }

  p {
    font-size: 18px;
    color: $molton-black;

    @include media-breakpoint-only(sm) {
      font-size: 16px;
      color: $molton-black;
    }
  }

  span {
    &.read-more {
      font-size: $mb-base-font-size;
      font-family: $gill-sans-regular;
      font-weight: $mb-font-bold;
      text-decoration: underline;
      color: $molton-black;
    }
  }

  a {
    text-decoration: underline;
    color: $molton-black;
    font-size: 18px;

    @include media-breakpoint-only(sm) {
      font-size: 16px;
    }
  }
}

.modal-open .modal.show {
  background: rgba(31, 26, 24, 0.8);
}
//css for Component specific carousel(Product-sample-carousel)

.sample-product-section .slick-dots {
  position: relative !important;
  bottom: 0 !important;
  padding-top: 20px;

  li {
    margin: 0 5px;

    button {
      font-size: 10px;
      width: 10px;
      height: 10px;

      &:before {
        color: $mb-gray-40;
        line-height: normal;
        font-size: 10px;
        width: 10px;
        height: 10px;
        -webkit-text-stroke: 1.5px $mb-color-white;
        -webkit-text-stroke-width: thick;
        opacity: 1;
      }
    }

    &.slick-active {
      button {
        &:before {
          -webkit-text-stroke: 1.5px $mb-color-black;
          -webkit-text-stroke-width: thick;
          color: $mb-color-white;
          opacity: 1;
        }
      }
    }
  }
}

.sample-product-section .slick-list {
  padding-left: 0 !important;
}

.sample-product-section .gift-sample-carousel .nav-arrow {
  color: $mb-gray-100;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: none;
  font-size: 20px;
  top: 95% !important;
  margin-right: 0;
  z-index: 1;
  display: block !important;

  @include media-breakpoint-only(sm) {
    color: $mb-gray-100;
    box-shadow: none;
    z-index: 1;
    font-size: 20px;
  }

  i {
    font-weight: 900;
  }
}

.sample-product-section .slick-prev {
  left: 20px !important;
}

.sample-product-section .slick-next {
  right: 20px !important;
}

.sample-product-section .slick-next:hover {
  color: $mb-gray-100;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: none;
  font-size: 20px;
  bottom: 0;
  margin-right: 0;

  @include media-breakpoint-only(sm) {
    color: $mb-gray-100;
    box-shadow: none;
    font-size: 20px;
    z-index: 1;
  }
}

.sample-product-section .slick-next:before {
  font-size: 20px;
  content: none;

  @include media-breakpoint-only(sm) {
    font-size: 20px;
    z-index: 1;
  }
}

.sample-product-section .slick-prev:before {
  font-size: 20px;
  content: none;

  @include media-breakpoint-only(sm) {
    font-size: 20px;
    content: none;
  }
}

.sample-product-section .slick-prev:hover {
  color: $mb-gray-100;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: none;
  font-size: 20px;
  bottom: 0;
  margin-right: 0;

  @include media-breakpoint-only(sm) {
    color: $mb-gray-100;
    box-shadow: none;
    font-size: 20px;
    z-index: 1;
  }
}

.delivery-information {
  a {
    color: $mb-gray-100;
    font-family: $gill-sans-light;
    font-size: $mb-base-font-size;
    font-weight: 300;
    line-height: 25.6px;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: $mb-gray-100;
    text-decoration-thickness: 0px;

    &:focus,
    &:hover {
      color: $mb-gray-100;
    }
  }

  p {
    color: $mb-gray-100;
    font-family: $gill-sans-light;
    font-size: $mb-base-font-size;
    font-weight: 300;
    line-height: 24px;
    padding-left: 1px;
  }

  strong{
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
  }

  ul {
    li {
      padding-bottom: 20px;
      list-style-type: disc;
      @media not all and (min-resolution:.001dpcm) { 
        @supports (-webkit-appearance:none) {
          list-style-position: inside;
        }
      }
    }
  }
}

// Green Marble banner 
.rd-marble-container {
  position: relative;
  text-align: center;
  color: $mb-color-white;
  max-height: 193px;
  overflow: hidden;

  @include media-breakpoint-only(sm) {
    max-height: 127px;
  }
}

.rd-marble-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.joinmoltonbrown{
    text-align:left;
    padding-top: 0px;
    @include media-breakpoint-only(sm){
      text-align:left;
    }
    
  }
  &.orderid{
    padding-top:0px;
    padding-left:17px; 

  }
  
  @include media-breakpoint-only(sm) {
    white-space: nowrap;
    &.mob-txt-wrap{
      white-space: normal;
    }
  }
}

.btn-accent {
  border: none;
  @include btn-theme($mb-gray-10, $mb-color-black);
  &:hover {
    background-color: $mb-gray-80;
  }
}

.mentionme-text .mmWrapping {
  a {
    font-family: $gill-sans-light;
    font-weight: $mb-font-normal;
    font-size: $mb-base-font-size-h6;
    color: $mb-gray-90;
    line-height: $mb-primary-line-height;
    text-decoration: underline;
  }

  .mmLink:after {
    content: '';
  }
}

.View_Basket_Container {
  .entry-value {
    .clearfix {
      font-family: $baskerville-regular;

      .ds-price {
        color: #FF1717;
        font-size: $mb-base-font-size-h5;
        font-family: $baskerville-regular;
      }

      .price-format-discount-retail-price {
        font-size: $mb-base-font-size-h5;
        font-family: $baskerville-regular;
      }

      .promoprice {
        font-size: $mb-base-font-size-h5;
        font-family: $baskerville-regular;
        color:$mb-gray-100;
      }

      .entry-promo-message-basketpage {
        font-size: $mb-label-font-size;
        font-family: $gill-sans-light;
        font-style: normal;
        color: $mb-gray-100;
      }
      .promofreemsg{
        position: absolute !important;
        top: 75% !important;
        margin-bottom: 10.5px;
        font-size: $mb-base-font-size-h6;
        font-family: $gill-sans-light;
        font-weight:$mb-font-normal;
      }

    }
  }

  .promotionapplied {
    padding-top: 10px;

    .entry-promo-message-basketpage {
      font-size: $mb-label-font-size;
      font-family: $gill-sans-light;
      font-style: normal;
      color: $mb-gray-100;
      font-weight: $mb-font-normal;
    }

    .promoprice {
      position: absolute !important;
      top: 65px !important;
      margin-bottom: 10.5px;
      font-size: $mb-base-font-size-h5;
      font-family: $baskerville-regular;
      color: $mb-gray-100;
      @include media-breakpoint-only(sm) {
        top: 95px !important;
      }
    }
    .promofreemsg{
      position: absolute !important;
      top: 75% !important;
      margin-bottom: 10.5px;
      font-size: $mb-base-font-size-h6;
      font-family: $gill-sans-light;
      font-weight:$mb-font-normal;
      color: $mb-gray-100;
    }
  }
}

.delivery-options {
  .modal-dialog .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 389px;
  }

  .modal-dialog .modal-body p,
  .amp-dc-external-block span {
    font-size: 16px !important;
    font-family: $baskerville-regular !important;
    color: $mb-gray-100 !important;
  }

  .amp-container {

    li,
    strong,
    span,
    strong a,
    a {
      font-size: 16px !important;
      font-family: $baskerville-regular !important;
      color: $mb-gray-100 !important;
    }

  }

  .modal-dialog .modal-header {
    background: #464646;
    color: $mb-color-white;
  }

  .modal-dialog .modal-header button .icon-close-lg::before {
    color: $mb-color-white !important;
  }

  .amp-container li {
    padding-bottom: 20px;
    line-height: 25.6px !important;
  }
}


//css for Fragrance Finder SLICK-NAV-SECTION slider
.slick-nav-section {
  .slick-nav-cta {

      .slick-backward,
      .slick-forward {
          font-family: century-gothic, Verdana, sans-serif;
          line-height: 1.42857;
          color: #212529;
      }

      .slick-dots {
          width: 100%;
          position: unset !important;
          bottom: -25px;
          display: block;
          padding: 0;

          li {
              margin: 0 5px;
              button {
                font-size: 0px;
                height: 20px;
                width: 20px;
              }
          }
          li button:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "•";
            width: 20px;
            height: 20px;
            font-family: slick;
            font-size: 6px;
            line-height: 20px;
            text-align: center;
            color: #000;
            opacity: .25;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            -webkit-text-stroke: transparent;
          }
          li.slick-active button:before {
            color: #000;
            opacity: .75;
          }
          
      }
  }
}

.ff-carousel { 
  .slick-track {
    display: block;
  }
}
.order-summary-detail{
  @include btn-border-theme(1px, $mb-gray-30);
  border-style: solid none;
  [class*="col-"] {
    padding: 0;
  }
  .ord-summary-headers{
    font-family: $gill-sans-regular;
    font-weight: $mb-font-bold;
    font-size: $mb-base-font-size-h6;
    letter-spacing: 0.01em;
    color: $mb-gray-100;
  }
  .ord-summary-elm-values{
    font-family: $baskerville-regular;
    letter-spacing: 0.01em;
    color: $mb-gray-100;
  }
  .subtotal-promo-total-amt{
    font-weight: $mb-font-bold;
    line-height: $mb-secondary-line-height;
  }
  .delivery{
    font-size: $mb-base-font-size-h6;
    font-family: $gill-sans-light;
  }
  .estimated-total{
    padding: 18.5px 0 22.5px;
    @include media-breakpoint-only(sm){
      padding-bottom: 0px;
    }
    .total-amt{
      font-size: $mb-base-font-size-h4;
    }
  }
}


app-custom-select {
  .not-valid-error-select-msg {
    margin-top: 5px;
    width: 100%;
    display: none;

    span {
      font-size: $mb-label-font-size;
      color: $mb-alert-50;
      font-family: $gill-sans-regular;
      font-weight: $mb-font-bold;
      line-height: 20px;
    }
  }

  &.has-error {
    .not-valid-error-select-msg {
      display: block;
    }
    select.form-control {
      border: 1.5px solid $mb-alert-50 !important;
    }
  }
}
.pick-again-carousel {
  app-sample-carousel {
    .nav-arrow {
      box-shadow: none;
      background-color: transparent;
      top: 97%;
      @include media-breakpoint-only(sm){
        top: 97%!important;
      }
    }
    .slick-prev::before {
      border: 0 !important;
    }
    .slick-next::before {
      border: 0 !important;
    }
    i {
      color: $mb-gray-100;
      font-size: 16px;
      font-weight: bold;
  }
  .slick-dots {
    display: block !important;
    bottom: 0!important;
  }
  .icon-plus-lg:before {
    color: $mb-color-white;
  }
  }
  h1 {
    font-size: 36px;
  }
  .choose-pdt-title {
    font-family: $gill-sans-light;
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;
    color: $mb-gray-100;
    padding: 12px 0 40px 0;
    @include media-breakpoint-only(sm) {
      padding: 40px 0 12px 0;
    }
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
  -webkit-text-stroke: 1.5px #16271f;
  -webkit-text-stroke-width: thick;
  color: #ffffff;
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  color: $mb-gray-100;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  padding-bottom: 35px;
  margin-bottom: 30px;
}
.slick-slider .slick-list {
  @include media-breakpoint-only(sm) {
    min-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
}
cs-communication {
  .form-floating {
.form-control{
width: 89% !important;
    }
    label{
      left:10px !important
    }
  }
}
.my-account-form{
  margin: 7.12% 37.875% 9.5% 15.67%;
  .required-info{
    span{
      font-size: $mb-base-font-size-h6;
    }
  }
  .color{
    color: $mb-gray-100;
  }
  .form-floating {
    .form-control, .form-select, button{
      width: 387px;
    }
  }
  .form-floating label{
    left: 5px;
    padding-bottom: 14px;
    height: 40%;
  }
  .not-valid-error-msg{
    margin-top: 5px;
    width: 100%;
    span{
      font-size: $mb-base-font-size-h6;
      color: $mb-alert-50;
      font-family: $gill-sans-regular;
      font-weight: $mb-font-bold;
      line-height: 20px;
    }
  }
  .save-btn{
    margin: 60px 0 0;
  }
}
#saveSuccessForm{
  .modal-dialog{
    width: 100%;
  }
  .tick-img{
    width: 42px;
  }
}
@media screen and (max-width:874px) {
  
}
@media screen and (max-width:874px) {
  .my-account-form{
    width: 87%;
    margin: 40px auto;
    .form-floating {
      .form-control, .form-select, button {
        width: 100%;
      }
    }
    .save-btn{
      margin: 40px 0 0;
    }
  }
  #saveSuccessForm{
    .modal-dialog{
      width: 70%;
    }
  }
}
.my-account-express{
  span{
    font-size: $mb-base-font-size-h6;
  }
}
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

th {
  background: rgba(0, 0, 0, 0.05);
}
th, td {
  border: 1px solid $mb-gray-40;
  padding: 8px;
  font-family: $gill-sans-light;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.01em;
  color: $mb-gray-100;
  word-break: break-all;
}
}
.wrapperDiv{
  .slick-track{
    display:contents;
    @include media-breakpoint-only(sm) {
      display:flex;
    }
  }
}
//store-events carousel css
.storeEventsPage 
{
  .nav-arrow{
  @include flexbox();
        justify-content: center;
        align-items: center;
        color: $mb-color-white;
        width: 60px;
        height: 60px;
        background-color: $mb-color-black;
        border-radius: 50%;
        box-shadow: $mb-shadow-medium;
        font-size: 20px;
        top:40%;
        margin-right: 2%;
        z-index: 1;
        @include media-breakpoint-only(sm) {
            color: $molton-black;
            background-color: $mb-gray-20;
            box-shadow: none; 
            top:97% !important;
            -webkit-text-stroke-width: thin;   
            z-index: 1; 
            font-size: 15px;    
        }   
}
.left-nav-arrow{
  @include media-breakpoint-only(sm) {
      position:absolute;
      top: 95%;
      left:10%
  }
}
.slick-next:hover{
  @include flexbox();
  justify-content: center;
  align-items: center;
  color: $mb-color-white;
  width: 60px;
  height: 60px;
  background-color: $molton-navColor;
  border-radius: 50%;
  box-shadow: $mb-shadow-medium;
  font-size: 20px;
  top:40%;
  margin-right: 2%;
  @include media-breakpoint-only(sm) {
      color: $molton-black;
      background-color: $mb-gray-20;
      box-shadow: none; 
      top:97% !important;
      -webkit-text-stroke-width: thin; 
      font-size: 15px; 
      z-index: 1;      
  }
}
.slick-next:before {
  font-size: 20px;
  content:none;  
  @include media-breakpoint-only(sm) {
      font-size: 15px; 
      z-index: 1; 
  }  
}
.slick-prev:before {
font-size: 20px;
content:none;
@include media-breakpoint-only(sm) {
  font-size: 15px;
  content:none;       
}
}
.slick-prev:hover{
@include flexbox();
justify-content: center;
align-items: center;
color: $mb-color-white;
width: 60px;
height: 60px;
background-color: $molton-navColor;
border-radius: 50%;
box-shadow: $mb-shadow-medium;
font-size: 20px;
top:40%;
margin-right: 2%;
  @include media-breakpoint-only(sm) {
   @include flexbox();
   justify-content: center;
   align-items: center;
   color: $molton-black;
   background-color: $mb-gray-20;
   box-shadow: none; 
   top:97% !important;
   left:5%;
   -webkit-text-stroke-width: thin; 
   font-size: 15px; 
   z-index: 1;      
}
}
.slick-prev:focus, .slick-next:focus {
  background-color: $mb-color-black;
  color: #fff;
  @include media-breakpoint-only(sm) {
      background-color: transparent;
      color: #1A1A1A;
  }
}  
.slick-dots{
   padding-top: 40px;
   position: relative !important;
   bottom: 0px;    
   z-index: 0; 
   width : 70%;
   margin: 0 auto;
   overflow: hidden;
   @include flexbox();
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
}
.slick-dots li{
  margin: 0 4%;
}
.slick-list {
  padding-left: 0px!important; // half partial
}
.slick-dots li button:before{
   font-size: 10px;
   color: $mb-gray-40;
   -webkit-text-stroke: 1.5px $mb-color-white;
   -webkit-text-stroke-width: thick;
   opacity:1;
   width: 10px;
   height: 10px;
}
.slick-dots li button{
  font-size: 10px;
  width: 10px;
  height: 10px;
}

.slick-dots li.slick-active button:before{
  -webkit-text-stroke: 1.5px $mb-color-black;
  -webkit-text-stroke-width: thick;
  color: $mb-color-white;
  opacity:1;
}
.slick-track{
   @include flexbox();
  align-items: top;
  justify-content: center;
}
.slick-dotted.slick-slider {
  @include media-breakpoint-only(sm) {
  margin-bottom: 0px;
  }
}
.sec-nav-imgwrapper{
  height: auto;
  padding-right: 32px;
  margin:0%;
  &:hover {
      text-decoration: none;
  }
  @include media-breakpoint-only(sm) {
      padding-right: 20px;
  }
}
.sec-nav-image{
  cursor: pointer;
  border: none !important;
  border-radius: 50%;
  padding-left: 10px;
  padding-right: 10px;
  @include media-breakpoint-only(sm) {
      padding: 0%;
  }
}
.slider-nav{
  margin-top: 60px;
  @include media-breakpoint-only(sm) {
      margin-top: 40px;
  }
}
.sec-nav-imgcaption{
  cursor: pointer;
  margin-top: 24px;
}
.img-caption{
   font-family: $gill-sans-light;    
   font-size: $mb-alt-base-text-size;
   font-weight: $mb-font-normal;
   line-height: $mb-primary-line-height;
   letter-spacing: 0.01em;
   text-align: center;
   color: $mb-gray-100;
   @include flexbox();
   align-items: center;
   justify-content: center;
       @include media-breakpoint-only(sm) {
           font-size: 18px;
           height: 29px;
       }
}
.nav-arrow{
  @include flexbox();
  justify-content: center;
  align-items: center;
  color: $mb-color-white;
  width: 60px;
  height: 60px;
  background-color: $mb-color-black;
  border-radius: 50%;
  box-shadow: $mb-shadow-medium;
  font-size: 20px;
  top:40%;
  margin-right: 2%;
  z-index: 1;
  @include media-breakpoint-only(sm) {
      color: $molton-black;
      background-color: $mb-gray-20;
      box-shadow: none; 
      top:97% !important;
      -webkit-text-stroke-width: thin;   
      z-index: 1; 
      font-size: 15px;    
  }   
}
.sub-title{
  p{
      font-family: $gill-sans-light;
      font-weight: $mb-font-normal;
      font-size: $mb-alt-base-text-size;
      text-transform: none;
      line-height: $mb-primary-line-height;
      color: $mb-color-white !important;
  }
}
.prodDesc{
  p{
    font-family: $gill-sans-light;
    font-weight: $mb-font-normal;
    font-size: $mb-alt-base-text-size;
    margin: 0px 0 20px 0px;
    line-height: $mb-primary-line-height;
    display:block; 
    color:$mb-gray-100;
  }
}
.msgdescription{
  p{
    font-size: $mb-alt-base-text-size;
    font-family: $gill-sans-light;
    color: $mb-gray-100;
    line-height: $mb-primary-line-height;
    letter-spacing: 0.01em;
    font-weight: $mb-font-normal;
    margin-bottom: 40px;
    @include media-breakpoint-only(sm) {
    padding-right:0;
    }
  }
}
}

// Amplience Visulization classes
.hide-module {
  display: none !important;
}

.show-this-module {
  display: block !important;
}

//Information pages inside modal body
.modal-body {
  .org-content {
    width: 100%;
    @include media-breakpoint-only(sm) {
      margin:48px 0px 0px 0px
    }
  }
}
.amp-container{
  p {
    font-family: $gill-sans-light !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 160%;
    color: $mb-gray-100 !important;
  }

} 
.bv_histogram_component_container {
  margin-bottom: 1.5em;
}
.bv_button_component_container .bv_button_buttonFull, .bv-cv2-cleanslate .bv-shared button.bv-write-review.bv-submission-button {
  padding-top: 0.7em!important;
}
[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist {
  font-family:$gill-sans-light !important;
}
.bv-cv2-cleanslate .bv-core-container-337 button.bv-write-review, .bv-cv2-cleanslate .bv-core-container-337 button.bv-read-review, .bv-cv2-cleanslate .bv-core-container-337 button.bv-ask-question, .bv-cv2-cleanslate .bv-core-container-337 button.bv-first-to-review, .bv-cv2-cleanslate .bv-core-container-337 button.bv-first-to-ask-question, .bv-cv2-cleanslate .bv-core-container-337 .bv-button {
  font-family:$gill-sans-light !important;
}
.order-summary-product-price{
  .myAccOrd_disPrice{
    color: #FF1717;
    font-size: $mb-base-font-size-h5;
    font-family:$baskerville-regular;
    line-height: 130%;
    font-weight:$mb-font-bold;
  }
  .myAccOrd_orgPrice{
    color: $mb-gray-100;
    font-size: $mb-base-font-size-h5;
    font-family:$baskerville-regular;
    line-height: 130%;
    font-weight:$mb-font-bold;
    margin-right:12px;
  }
}
.product-price {
  .price{
    .entry-promo-message-basketpage {
      font-size: 16px;
    }
  }
}
#modalsizeGuidePopup{
  .modal-dialog .modal-body {
      @include media-breakpoint-only(sm) {
          padding: 24px 24px 40px 24px;
      }
    p:nth-child(even) {
      color: $mb-gray-100;
      padding:20px 0 0 0;
      @include media-breakpoint-only(sm) {
          font-size: $mb-base-font-size-h6;
          padding: 12px 0 0 0;
      }
    }
    p:nth-child(odd) {
      color: $mb-gray-100;
      padding:40px 0 0 0;
      @include media-breakpoint-only(sm) {
          font-size: $mb-base-font-size-h6;
          padding: 12px 0 0 0;
      }
    }
  }
  }
.confirm-sel-header-desc , .gift-disclaimer-text{
  p,span,a,h1,h2,h3,h4,h5,h6,ul,ol,li,em,strong{
      color: $mb-gray-100;
  }
  em{
      font-family: $gill-sans-light;
  }
  strong{
      font-family: $gill-sans-regular;
  }
  a{
      text-decoration: underline;
  }
}
.giftBuilder 
{
#filtersModal {
  z-index: 9999;
}
.amp-filters {
  margin: 0 auto;
  padding: 24px 0 !important;
 @include media-breakpoint-only(sm) {
 margin: 0;
 padding: 23px 23px 20px 10px;
 }
}
.plp-prdct-name a, .plp-prod-image a
{
    cursor: default;
    pointer-events: none;
}
}

.summarybar-container.sticky+ .plp-view_container .amp-filters {
  position: fixed;
  top: 190px;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  left: 0;
  box-shadow: 0 0px 2px #e7e5e1;
  @include media-breakpoint-only(sm) {
    background-color: $mb-gray-30;
    box-shadow: none;
    top: 235px;
  }
}
.mob-ord-sum-accordion, .ord-sum-accordion{
  &.mob-only{
    @include media-breakpoint-only(sm) {
    text-align:left;
    }
  }
}
#homepage_banner_dynamic_custom {
.swiper-slide{
p{
font-family: $gill-sans-regular !important;
}
  }
} 

//CSS for Sticky header starts
// #header-submenuwrap {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 1051;
//   // transition: transform 1s;
//   @include media-breakpoint-only(sm) {
//       background-color: $mb-color-white;
//     }
// }
// .storefront, .checkout-contentWrapper {
//   transition: all 1s;
// }
// .scroll-down #header-submenuwrap {
//   transform: translate3d(0, -100%, 0);
// }
// .scroll-up #header-submenuwrap{
//   transform: none;
// }
.scroll-down:not(.modal-open) #header-submenuwrap {
  position: relative;
}
.scroll-up:not(.modal-open) #header-submenuwrap{
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1051;
  @include media-breakpoint-only(sm) {
      background-color: $mb-color-white;
    }
}
// .scroll-up.modal-open #header-submenuwrap, .scroll-down.modal-open #header-submenuwrap, .modal-open #header-submenuwrap{
//   display: none;
// }
//CSS for Sticky header ends

/*FOR ANIMATION ON HEADER ICONS*/
.desktop-only{
  a.header-icons {
    position: relative;
    .icon-search-md, .icon-pin-md, .icon-user-md, .icon-bag-md {
      transition: all 0.4s ease;
      &:before {
        transition: all 0.4s ease;
        position: relative;
        top: 0;
      }
    }
    &:hover {
      .icon-search-md, .icon-pin-md, .icon-user-md, .icon-bag-md {
        &:before {
          top: -7px;
        }
      }
      .header-icons {
        color: $mb-gray-100;
      }
    }
  }
}

.mob-only.cart-icon {
  a.header-icons {
    position: relative;
    .badge-cart {
      right: -14px;
      top: 4px;
    }
  }
}
a.mob-add-btn:hover {
  text-decoration: none;
}
#gb-product-feed-homepage-container , #uk-product-feed-product-page-div, #uk-product-feed-basket-fr_carousel {
.nav-arrow {
  background-color: $mb-color-black;
  &:hover {
    background-color:#2A483A;
  }
}
.product-cta {
  .btn-accent {
    background-color: #16271F;
      &:hover {
          background-color: #2A483A;
      }
      &:focus {
          background-color: #16271f;
      }
  }
}
}
// adyen styles overrride
.adyen-checkout__applepay__button{
  width: 100%;
  height:47px;
  margin-top: 17px;
  @include media-breakpoint-only(sm) {
    margin-top: 19px;
  }
}
.adyen-checkout__card__form{
  margin-top: 20px;
}
.adyen-checkout__button{
  background-color: #1F1A18 !important;
  border-radius: 50px;
}
.adyen-checkout__button__text {
  font-family: $gill-sans-regular;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 160%;
  font-weight: $mb-font-bold;
}
.adyen-checkout__input {
  height: 60px;
  border: 1.5px solid #d0cbc3;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 400;
  font-family: $gill-sans-regular;
}
.adyen-checkout__label__text {
  font-family: $gill-sans-regular;
  font-size: 16px;
  color: #1F1A18;
  line-height: 160%;
  //margin-bottom: 4px;
  font-weight: 400;
}
.adyen-checkout__input--focus, .adyen-checkout__input--focus:hover, .adyen-checkout__input:active, .adyen-checkout__input:active:hover, .adyen-checkout__input:focus, .adyen-checkout__input:focus:hover {
  border: 1.5px solid #d0cbc3!important;
  box-shadow: none; //change highlighting placeholder blue color
}
.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #1F1A18; //change highlighting placeholder blue color
}
.adyen-checkout__input--error, .adyen-checkout__input--error:hover{
  color: $mb-alert-50;
  border: 1.5px solid $mb-alert-50;
}
.adyen-checkout__label__text--error, .adyen-checkout__error-text, .adyen-checkout-input__inline-validation--invalid {
  color: $mb-alert-50;
  font-size: 1rem;
  font-family: $gill-sans-regular;
}
.adyen-checkout__input--invalid{
  border: 1.5px solid $mb-alert-50;
}
.adyen-checkout-input__inline-validation {
  height: 100%;
  margin-top: 13px;
  top: 50%;
}
.adyen-checkout__card__brands__brand-wrapper{
  img{
    vertical-align: top;
  }
}
.adyen-checkout__input--valid, .adyen-checkout__input--valid:hover{
  border: 1.5px solid $mb-success-50;
}
.adyen-checkout__field.adyen-checkout__field--cardNumber.adyen-checkout__field--error{
.adyen-checkout__label--focused.adyen-checkout__label--filled{
  .adyen-checkout__label__text{
  color: $mb-alert-50;
  }
}
}
#sepadirectdebit-container {
.adyen-checkout-form-instruction {
  font-size: $mb-base-font-size-h6;
  color: $mb-gray-100;
  margin-bottom: 1rem;
 }
}
app-encrypted-card{
.encryptCard{
.adyen-checkout__field-wrapper>.adyen-checkout__field:first-child {
  display : none;
}
}
.adyen-checkout__card__form{
  margin-top: 20px;
}
.adyen-checkout__field-wrapper>.adyen-checkout__field:nth-child(2) {
  margin-left: 0px;
}
}
.adyen-checkout__paypal__button{
  margin-bottom: 0px;
}
#credit-container, .storedCardContainer {
  .adyen-checkout-form-instruction {
    font-size: $mb-base-font-size-h6;
    color: $mb-gray-100;
    margin-top: 1rem;
   }
  }
  .storedCardContainer {
    .adyen-checkout-form-instruction {
      margin-bottom: 20px;
    }
  }
.adyen-checkout__input-wrapper{
  input[type="text"]{
  &::placeholder {
    color: $mb-gray-70;
    font-family: $gill-sans-light;
  }
}
}
.adyen-checkout__paypal__status--processing{
  font-size: $mb-base-font-size-h6;
}
.adyen-comp{
  .adyen-checkout__card-input.CardInput-module_card-input__wrapper__wXSCw.adyen-checkout__card-input--credit{
    margin-top: 24px;
  }
  .adyen-checkout__threeds2__challenge{
    margin-top: 24px;
  }
}

//PLP bootstrap tooltip

.custom-tooltip{
  background-color: transparent;
  border: none;
}

//Subscription OG styles
.subscribe-blk{
.radio.active::after {
  width: 73%;
  height: 73%;
  left: 3px;
  top: 3px;
}
}
og-offer[subscribed]{
  .subscribe-blk{
    border: 1px solid $mb-gray-100;
  }
  .onetime-blk{
    border: none;
    }
}
og-offer{
  .onetime-blk{
    border: 1px solid $mb-gray-100;
  }
}
.og-tooltip-content {
  display: block;
  line-height: 1.5;
  color: black;
  padding: 20px 12px;
  background-color: $mb-color-white;
  box-shadow: 0px 4px 40px 0px rgba(31, 26, 24, 0.25);
  border-radius: 4px;
  color: $mb-gray-100;
  width: 339px;
  height: auto;
  max-width: 339px;
  overflow: scroll;
  left: -60px;
  top: -120px;
  position: absolute;
  z-index: 1;
  @include media-breakpoint-only(sm){
    width: 256px;
    max-width: 256px;
    left: -64px;
    top: -380px;
  }
  }
  
  .og-tooltip-trigger{
    width: 10px;
  }
  .subscribe-blk.basket{
    @include media-breakpoint-only(sm){
    .og-tooltip-content {
      left: -58px;
      top: -204px;
    }
    }
  }

  //PDP OG-Modal 

  .modal-subsEligible{
    width: 81.6%;
    padding: 10px;
    @include media-breakpoint-only(sm){
      width: 359px;
      padding-bottom: 16px;
    }
  }

  .add-btn-blk, .cancel-btn-blk{
    button{
      width: 77.05%;
      height: 52px;
      align-items: center;
      justify-content: center;
      margin: 0px;
      @include media-breakpoint-only(sm){
        width: 93.32%;
      }
    }
    position: relative;
    bottom: 20px;
  }
  .add-btn-blk{
    margin-bottom: 16px;
  }
  .modal-title, .modal-desc-blk{
    padding-bottom: 12px;
    @include media-breakpoint-only(sm){
      padding-bottom: 16px;
    }
  }
  .btn-white-og {
    @include btn-border-theme(1.5px, $mb-gray-80);
    @include btn-theme($mb-gray-100, $mb-color-white);
  }

.jointxtone {
  margin-bottom: 0px;
  span{
      font-size: $mb-base-font-size-h6;
      letter-spacing: 0.16px;
  }
}

#email_subscription_footer_9ohul5_frel_overlay {
  z-index: 9 !important;
}

#modalTermsPopup{
  p {
    font-size: $mb-base-font-size-h5;
    padding: 15px 0 0 0;
    color:$mb-gray-100;
    @include media-breakpoint-only (sm) {
      padding: 12px 0 0 0;
    }
  }
}