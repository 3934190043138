@use "sass:math";
/* Global Grid - Layout Grid */
@import "../node_modules/bootstrap/scss/mixins";
$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 875px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1540px
);

// $container-max-widths: (
//   xs: 0,
//   sm: 360px,
//   md: 768px,
//   lg: 1024px,
//   xl: 1280px
// );

/* Specify your own gutters for every breakpoint. */
$grid-gutter-widths: (
  xs: 16px,
  sm: 20px,
  md: 24px,
  lg: 28px,
  xl: 28px,
);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* setting up the margin and gutter for custom breakpoints */
@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: math.div($grid-gutter-width,2);
    .row:not(.no-gutters) {
      margin-right: $grid-gutter-half-width + 4;
      margin-left: $grid-gutter-half-width + 4;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*="col-"] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}
