.amp-dc-card-list .century-gothic-stack {
  font-family: century-gothic, Verdana, sans-serif!important;
}
.amp-dc-card-list .times-new-roman {
   font-family: 'Times New Roman',Times,serif;
}
.amp-dc-card-list .amp-dc-cardlist-description h1, .amp-dc-card-list .amp-dc-cardlist-description h2, .amp-dc-card-list .amp-dc-cardlist-description h3, .amp-dc-card-list .amp-dc-cardlist-description h4, .amp-dc-card-list .amp-dc-cardlist-description h5, .amp-dc-card-list .amp-dc-cardlist-description h6 {
   font-family:inherit !important;
   font-weight:500;
 }
 .amp-dc-card-list .amp-dc-cardlist-description a, .amp-dc-card-list .amp-dc-cardlist-description p{
      font-family:inherit !important;
      color:inherit;
 }
.amp-dc-card-list .amp-dc-cardlist-description h1{
   font-size:36px;
}
.amp-dc-card-list .amp-dc-cardlist-description h2{
   font-size:30px;
}
.amp-dc-card-list .amp-dc-cardlist-description h3{
   font-size:22px;
}
.amp-dc-card-list .amp-dc-cardlist-description h4{
  font-size:18px; 
}
.amp-dc-card-list .amp-dc-cardlist-description h5{
   font-size:16px;
}
.amp-dc-card-list .amp-dc-cardlist-description h6{
   font-size:14px;
}
.amp-dc-card-list .amp-dc-card-title{
   font-size:25px;
}
.amp-dc-image {
  min-width: 0;
}
/* homepage */
.homepage-six-small-banners{
   margin-bottom:20px;
}
.hotel-amenities-bottom-cards .amp-dc-card-list-wrap {
   width: auto;
}  
.amp-dc-card-list .amp-dc-card-list-wrap {
   text-align: center;
   margin: auto;
}
#featureFRQuote{

   margin-bottom:20px;
}

@media (max-width:1550px) and (min-width:1441px) {
   .homepage-six-small-banners .amp-dc-card-list-wrap {
   width: 100%;
}  
   
}

@media screen and (min-width: 1441px){ 
  
/* .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card.hp-small-431x291 {
   width: 431px;
  } */
}

@media screen and (min-width: 2560px){
 .homepage-six-small-banners .amp-dc-card-list-wrap {
   width: 919px;
} 
   
}
@media screen and (max-width: 1440px) and (min-width: 1025px){ 
  .homepage-six-small-banners .amp-dc-card-list-wrap {
   width: 990px;
  } 

}

@media screen and (min-width: 1025px){ 
  .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card{
   
   margin:14px 29px 0;
  }
} 
 
@media screen and (max-width: 1024px) and (min-width: 768px) {
 .amp-dc-card-list.homepage-six-small-banners {
     margin-top:20px;
    padding: 0px 6%;
  }
   .homepage-six-small-banners .amp-dc-card-list-wrap {
       width: 100%;
      margin:auto;
  }
  .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card{
      padding-left: 15px;
      padding-right: 15px;
  }
  .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291 {
      width:49%;
      margin: auto;
      font-size: 0;
  }
   .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291-poi {
      width:49%;
      margin: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
   width: 50%;
   height: auto;
   margin: auto;
}
}
 @media screen and (max-width: 767px) {
     .amp-dc-card-list.homepage-six-small-banners {
     margin-top:20px;
   
  }
  .homepage-six-small-banners .amp-dc-card-list-wrap {
       width: 92%;
      margin:auto;
  }
  .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291 {
   margin: auto;
  }
   .amp-dc-card-list-wrap .amp-dc-external-block {
   width: 100%;
   height: auto;
   margin: auto;
}
}

/* homepage */
/* journal home,categorypages*/ 
.journal-hp-cat-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(-n+6)) {
   display:none;   
}
.amp-dc-card-list.journal-hp-cat-small-card-list .journal-morestories-button {
   color: #fff!important;
   background-color: #000;
   font-size: 16px;
   height: 36px;
   padding: 8px;
   padding: 7px 25px 5px;
   margin-top:15px;
}

.amp-dc-card-list-wrap .amp-dc-card.show3cards {
   display: inline-block!important;
}
   @media screen and (min-width: 1024px) {
    .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap{
     width: 878px; 
     margin-top: 20px!important;
         font-size: 0;
    }
    .amp-dc-card-list.hotel-amenities-bottom-cards {
        padding:15px;
    }
   }
 
    
 @media screen and (min-width: 769px) {
   .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(3n)) {
   margin-right: 34px;
  }
 }
  @media screen and (max-width: 768px){
      #journal-homepage{
          width:100%;
      }
   .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card{
         padding-left: 10px;
         padding-right: 10px;
         padding-bottom: 10px;

    }
     .amp-dc-card-list.journal-hp-cat-small-card-list  .amp-dc-card-list-wrap{
      width:98%!important;
  
    }
  }
  .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap{
  
       margin: auto;
   }
/* journal home,categorypages*/ 

/* aboutus page */

.amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
       margin-right: 20px;
}
.amp-dc-card-list.about-us-bottom-banners .amp-dc-card-list-wrap {
   margin:auto;
}
@media screen and (min-width: 769px){
.amp-dc-card-list.about-us-bottom-banners .amp-dc-card-list-wrap {
   width:770px;
}

.amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
  
   margin-right: 20px!important;
}
}
@media screen and (max-width: 768px){
.amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
  
   margin: 10px!important;
}
}
@media screen and (max-width: 480px){
.amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
  margin-bottom: 10px!important;
   margin: auto!important;
}
}
/* about us page */

/* hotel-aminities*/



@media screen and (max-width: 768px){
 .amp-dc-card-list.hotelaminities-cardlist .amp-dc-card-list-wrap {
   width: 100%;
   margin: auto;
  
 }
  .amp-dc-card-list.hotel-amenities-bottom-cards .amp-dc-card {

   margin-bottom: 20px;
  
  }
}
/*hotel aminities*/

/*special offers */
.amp-dc-card-list.special-offers-everyday-treats .amp-dc-card-list-wrap{

   margin:auto;
}
@media screen and (min-width: 769px) {
   .amp-dc-card-list.hotelaminities-cardlist .amp-dc-card-list-wrap {

  padding:10px;
  
  }
  
  .amp-dc-card-list.special-offers-everyday-treats .amp-dc-card.special-offers-308x330:not(:nth-child(3n)) {
     margin-right:20px;
  }
  
}

/* special offers */
/* journal perfumers */
@media screen and (min-width: 1441px){
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap  {
     width:919px; 
     margin-top: 15px
  }
}

@media screen and (min-width: 1025px){
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(4n)) {
     margin-right:14px; 
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap  {
     width:919px; 
     margin:auto;
     margin-bottom: 20px;
     margin-top: 15px
  }
}
@media screen and (max-width: 1024px) {

.amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card {
     padding:10px; 
  }
.amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap{
     width:75%;
     margin-bottom: 20px;
     margin:auto;
 }

}
@media screen and (max-width: 768px){
    .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card{
        width:48%!important;
    }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap{
     width:100%;
     margin:auto;
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card {
   width: auto!important;
    height: auto;
  }
}
@media screen and (max-width: 480px){
    .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card{
        width:50%!important;
    }
   
}

/* journal perfumers */









/* cardlist homepage */


.amp-dc-card-title {
        margin: 27px;
  font-size: 2em;
  text-align: center;
}

@media screen and (min-width: 769px) {
.amp-dc-card-list .amp-dc-card-list-wrap {
 text-align: center;
  margin:auto;
}
}
.amp-dc-card-list .amp-dc-card {
  display: inline-block;
  vertical-align: top;
}
.amp-dc-card-list .amp-dc-card-wrap {
  margin: auto!important ;
}
.amp-dc-card-list .amp-dc-card-wrap .amp-dc-card-img {
  max-height: 250px;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap {
  margin-left: 0;
  overflow: hidden;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child {
  float: left;
  width: 70%;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child .amp-dc-card-wrap {
  margin-right: 20px;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-wrap {
  margin: 0 0 50px 0;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card {
  float: right;
  width: 30%;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card.amp-dc-card-1 {
  float: left;
  width: 70%;
}
.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card.amp-dc-card-1 .amp-dc-card-wrap {
  margin-right: 20px;
}
.amp-dc-prod-5-rows .amp-dc-card {
  width: 20%;
}
/*.amp-dc-prod-4-rows .amp-dc-card {
  width: 25%;
}*/

.amp-dc-prod-2-rows .amp-dc-card {
  width: 50%;
}
.amp-dc-prod-1-rows .amp-dc-card {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .amp-dc-prod-5-rows .amp-dc-card {
    width: 48%;
 }
  .amp-dc-prod-4-rows .amp-dc-card {
    width: 48%;
 }
 
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child {
    width: 100%;
 }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child .amp-dc-card-wrap {
    margin-right: 0;
 }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card {
    width: 100%;
 }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card.amp-dc-card-1 {
    width: 100%;
 }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card.amp-dc-card-1 .amp-dc-card-wrap {
    margin-right: 0;
 }
  .amp-dc-prod-2-rows .amp-dc-card {
    width: 48%;
 }
  .amp-dc-prod-1-rows .amp-dc-card {
    width: 100%;
 }
}
@media screen and (max-width: 480px) {
  .amp-dc-prod-5-rows .amp-dc-card {
    width: 100%;
 }
  .amp-dc-prod-4-rows .amp-dc-card {
    width: 100%;
 }
  .amp-dc-prod-3-rows .amp-dc-card {
    width: 100%;
 }
  .amp-dc-prod-2-rows .amp-dc-card {
    width: 100%;
 }
  .amp-dc-prod-1-rows .amp-dc-card {
    width: 100%;
 }
}

 @media (max-width: 2560px) and (min-width: 1551px){
   .homepage-six-small-banners .amp-dc-card-list-wrap {
       width: 1530px !important;
   }
}
.amp-dc-card.hp-small-431x291 .amp-dc-card-link {
       line-height:normal;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link{
   background-color: #fff !important;
   text-transform: none;
   cursor: pointer;
}
.journal-morestories-button{
   cursor: pointer;
}