@charset "UTF-8";
/* video css */
.amp-dc-banner span.font-xsmall.uppercase.block {
  font-weight: 600;
}

.century-gothic-stack {
  font-family: century-gothic, Verdana, sans-serif !important;
}

.times-new-roman {
  font-family: "Times New Roman", Times, serif !important;
}

@media screen and (max-width: 480px) {
  .desktop-video {
    display: none !important;
  }
  .desktop-video .amp-dc-video {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  #card-list-slot1 .amp-dc-card-list-wrap {
    margin: auto !important;
  }
  .desktop-video {
    display: none !important;
  }
  .desktop-video .amp-dc-video {
    display: none !important;
  }
  .amp-dc-video-wrap.mob-video {
    height: auto;
  }
}
@media screen and (min-width: 769px) {
  .landingpage_wrapper #bannerslot .amp-dc-banner .amp-dc-banner-info {
    padding: 25px;
  }
  .mob-video {
    display: none !important;
  }
  .mob-video .amp-dc-video {
    display: none !important;
  }
}
.amp-dc-banner table#uk-timer-web-thumbcontainer {
  margin: auto;
}

#pickmix-amp-content .amp-dc-banner-header, #pickmix-amp-content .amp-dc-banner-subheader, #pickmix-amp-content .amp-dc-banner-subheader-2 {
  font-style: italic;
  margin-top: 0;
}

#pickmix-amp-content .amp-dc-banner-subheader {
  width: max-content;
}

#pickmix-amp-content .amp-dc-banner-info {
  text-align: right;
}

.amp-dc-banner .scroll-container {
  color: #000;
  width: 100%;
  margin: auto;
  position: absolute !important;
  bottom: 0;
  height: 45px;
  display: block;
}

.amp-dc-banner .scroll-container {
  cursor: pointer;
}

.amp-dc-banner .scroll-container span {
  font-size: 12px;
  color: #000;
}

@media screen and (max-width: 768px) {
  .amp-dc-banner .scroll-container {
    display: none;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap {
    width: 100%;
  }
}
.amp-dc-banner .scroll-container .arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #000;
  margin: 0 auto;
}

/*video icon */
.amp-dc-banner .video-player-ft {
  display: block;
  width: 76px;
  height: 76px;
  line-height: 66px;
  border-radius: 50px;
  border-width: 7px;
  border-style: solid;
  margin: 0 auto;
  overflow: hidden;
  color: #fff;
}

.amp-dc-banner .video-player-ft .video-arrow {
  color: #fff;
  display: inline-block;
  margin: 12px 0 0 8px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left-style: solid;
  border-left-width: 33px;
  border-bottom: 20px solid transparent;
}

.amp-dc-banner .banner-video-icon {
  color: #fff;
  height: 100%;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
}

/* video icon */
/* Markdown banner */
.amp-dc-banner .amp-dc-banner-description p, .amp-dc-banner .amp-dc-banner-description a, .amp-dc-banner .amp-dc-banner-description em, .amp-dc-banner .amp-dc-banner-description strong {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description h1, .amp-dc-banner .amp-dc-banner-description h2, .amp-dc-banner .amp-dc-banner-description h3, .amp-dc-banner .amp-dc-banner-description h4, .amp-dc-banner .amp-dc-banner-description h5, .amp-dc-banner .amp-dc-banner-description h6 {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description p a {
  font-family: inherit;
  text-decoration: underline;
  color: #000;
}

.amp-dc-banner .amp-dc-banner-description p em {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description em {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description p strong {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description h2 {
  font-family: inherit;
  font-size: 30px;
  font-weight: 500;
}

.amp-dc-banner .amp-dc-banner-description h1, .amp-dc-banner .amp-dc-banner-description h2, .amp-dc-banner .amp-dc-banner-description h3, .amp-dc-banner .amp-dc-banner-description h4, .amp-dc-banner .amp-dc-banner-description h5, .amp-dc-banner .amp-dc-banner-description h6 {
  font-weight: 500;
}

.amp-dc-banner .amp-dc-banner-description h1 {
  font-size: 36px;
}

.amp-dc-banner .amp-dc-banner-description h2 {
  font-size: 30px;
}

.amp-dc-banner .amp-dc-banner-description h3 {
  font-size: 22px;
}

.amp-dc-banner .amp-dc-banner-description h4 {
  font-size: 18px;
}

.amp-dc-banner .amp-dc-banner-description h5 {
  font-size: 16px;
}

.amp-dc-banner .amp-dc-banner-description h6 {
  font-size: 14px;
}

.amp-dc-banner .amp-dc-banner-description p {
  font-size: 14px;
}

.amp-dc-banner .amp-dc-banner-description h2 em {
  font-family: inherit;
}

.amp-dc-banner .amp-dc-banner-description h3 {
  font-family: inherit;
}

/* Markdown banner */
/* Need to keep heritage banner heading as h1 for seo reasons so changing the font size */
#heritageSlot .amp-dc-banner .amp-dc-banner-description h1 {
  font-size: 24px;
}

.custom-country-select {
  position: relative;
}

.custom-country-select select.form-control {
  margin: 0;
}

/* blockquote */
/* blockquote*/
/* background image */
.amp-dc-banner.banner-background-image {
  height: calc(100vh - 95px);
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  color: #000;
  background-size: cover;
  text-align: center;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .amp-dc-banner.banner-background-image .amp-dc-banner-info .amp-dc-banner-subheader {
    padding: 0px 56px 15px;
    font-weight: 600;
  }
  .amp-dc-banner.banner-background-image .amp-dc-banner-info .amp-dc-banner-description {
    padding: 0px 80px 15px;
  }
}
/* background image */
.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-info .amp-dc-banner-description a {
  text-decoration: underline;
  color: #000;
}

.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-button {
  font-weight: normal;
  margin: 0;
  background-color: transparent !important;
  color: #000 !important;
  font-family: Times New Roman !important;
  font-style: italic;
  font-size: 18px;
}

.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-subheader {
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
}

.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-button::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-banner {
  position: relative;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.amp-dc-banner .amp-dc-banner-info .countdown-timer {
  margin: 0;
  margin: auto;
  margin-top: 12px;
}

.amp-dc-banner .amp-dc-banner-info .countdown-time table#uk-timer-web-thumbcontainer {
  margin: auto;
}

.amp-dc-banner * {
  margin: 0;
  padding: 0;
}

.amp-dc-banner .amp-dc-hide {
  display: none;
}

.amp-dc-banner .amp-dc-banner-pic {
  max-width: 100%;
  display: block;
}

.amp-dc-banner .amp-dc-banner-pic img {
  width: 100%;
}

.amp-dc-banner .amp-dc-banner-img {
  width: 100%;
  display: block;
}

.amp-dc-image img {
  width: 100%;
}

.amp-dc-banner .amp-dc-banner-info-wrap {
  display: block;
  width: 33.3%;
  height: 100%;
  position: absolute;
  top: 0;
  white-space: normal;
}

.amp-dc-banner .amp-dc-banner-info {
  font-size: 22px;
  text-align: center;
  color: #000;
  padding: 15px;
  min-width: 200px;
}

.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom {
  vertical-align: top;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: auto;
  display: inline-block;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0 15px 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amp-dc-banner-info-wrap.amp-dc-info-bottom::before {
  height: auto;
}

.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom .amp-dc-banner-info {
  vertical-align: middle;
  display: inline-block;
}

.amp-dc-banner .amp-dc-banner-info-wrap[data-align=left] {
  left: 65px;
}

.amp-dc-banner .amp-dc-banner-info-wrap[data-align=right] {
  right: 65px;
}

@media screen and (min-width: 768px) {
  .amp-dc-banner .amp-dc-banner-info-wrap:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: -1em;
    /* Adjusts for spacing */
  }
}
.amp-dc-banner .amp-dc-banner-info {
  display: inline-block;
  vertical-align: middle;
}

.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom .amp-dc-banner-info {
  display: inline-block;
  vertical-align: top;
}

.amp-dc-banner .amp-dc-banner-header {
  white-space: pre-wrap;
  font-size: 30px;
}

.amp-dc-banner .amp-dc-banner-description {
  font-size: 14px;
  margin-top: 10px;
}

.amp-dc-banner .amp-dc-banner-subheader {
  white-space: pre-wrap;
  font-size: 20pxt;
  margin-top: 10px;
}

.amp-dc-banner .amp-dc-banner-button {
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  min-width: 40%;
  padding: 7px 25px 5px;
  text-decoration: none;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amp-dc-banner .amp-dc-banner-button.black {
  background: #000;
  color: #fff;
}

.amp-dc-banner .amp-dc-banner-button.white {
  background: #fff;
  color: #000;
}

@media screen and (max-width: 768px) {
  .amp-dc-banner .amp-dc-banner-button {
    background-color: #000 !important;
    color: #fff !important;
  }
  .amp-dc-banner .amp-dc-banner-info .countdown-timer .uk-timer-web-imagecontainer {
    color: #000;
  }
  .amp-dc-banner .amp-dc-banner-header {
    line-height: 40px;
    font-size: 30px !important;
    color: #000 !important;
  }
  .amp-dc-banner .amp-dc-banner-subheader {
    font-size: 20px !important;
    color: #000 !important;
  }
  .amp-dc-banner .amp-dc-banner-description p {
    color: #000;
  }
  .amp-dc-banner .amp-dc-banner-info {
    background: transparent !important;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap {
    width: 40%;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap[data-align=left] {
    left: 35px;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap[data-align=right] {
    right: 35px;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap {
    vertical-align: top;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: auto;
    width: 100%;
    height: auto;
    text-align: center;
    padding: 0 15px 10px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom .amp-dc-banner-info {
    display: inline-block;
  }
}
@media screen and (max-width: 480px) {
  .amp-dc-banner .amp-dc-banner-info-wrap {
    width: 100% !important;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap[data-align=left] {
    left: 20px;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap[data-align=right] {
    right: 20px;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom {
    width: 100%;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom[data-align=left] {
    left: 0;
  }
  .amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom[data-align=right] {
    right: 0;
  }
}
.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-header {
  font-size: 20px;
}

.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-subheader {
  font-size: 16px;
  font-style: italic;
}

.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-description p {
  font-style: initial;
}

.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-description p {
  font-style: initial;
}

.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-header {
  font-size: 26px;
}

.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-subheader {
  font-size: 20px;
}

#heritageSlot .amp-dc-banner .amp-dc-banner-info .amp-dc-banner-description h1 {
  font-family: century-gothic, Verdana, sans-serif;
  font-size: 18px;
  font-style: normal;
}

@media only screen and (max-width: 1600px) {
  .container-column .blockquote-padding {
    padding: 35px 20px 0 62px;
  }
}
.amp-dc-banner.hp-delivery-banner .amp-dc-banner-header {
  font-weight: 700;
}

.amp-dc-banner.hp-delivery-banner .amp-dc-banner-description a {
  text-decoration: underline;
  color: inherit;
}

.amp-dc-banner.hp-delivery-banner .amp-dc-banner-button {
  border: 2px solid #000;
  font-size: 16px;
  font-family: century-gothic, Verdana, sans-serif;
  font-weight: normal;
}

.amp-dc-banner .amp-dc-banner-info-wrap .amp-dc-banner-info .amp-dc-banner-promolink {
  font-size: 10px;
  margin-top: 10px;
  display: block;
  text-decoration: none;
  color: #000;
}

@media screen and (max-width: 768px) {
  .amp-dc-banner.hp-delivery-banner .amp-dc-banner-header {
    font-size: 18px !important;
    line-height: 1.2;
  }
  #card-list-slot1 .amp-dc-banner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .amp-dc-banner.hp-delivery-banner .amp-dc-banner-button:hover {
    background-color: #000;
    color: #fff !important;
  }
}
@media screen and (min-width: 1024px) {
  #card-list-slot1 .amp-dc-banner {
    width: 880px;
    margin: auto;
  }
}
.amp-dc-blog {
  overflow: hidden;
  margin: 40px auto;
  max-width: 980px;
  padding: 0 20px;
}
.amp-dc-blog .amp-dc-block-wrap {
  margin-top: 50px;
}
.amp-dc-blog .amp-dc-blog-title {
  font-size: 2em;
  text-align: center;
}
.amp-dc-blog .amp-dc-blog-content {
  margin-top: 20px;
}

.amp-dc-snippet .amp-dc-image {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
}
.amp-dc-snippet .amp-dc-image img {
  width: 100%;
  user-select: none;
}
.amp-dc-snippet .amp-dc-title {
  text-transform: uppercase;
  font-size: 2em;
  margin: 20px;
}
.amp-dc-snippet .amp-dc-description {
  padding: 20px;
}
.amp-dc-snippet .amp-dc-link {
  margin: 20px;
  font-weight: bold;
}

.time-duartion .time-circle {
  font-size: 10px;
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  padding-top: 3px;
  margin: auto;
  font-family: Georgia;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}
.time-duartion .time-circle span {
  display: block;
}

.ImageOverlay {
  height: 24px;
  position: absolute;
  top: 0px;
  font-style: italic;
  width: 76.84px;
  font-family: "Times New Roman";
  text-align: center;
  border: 1px;
  border-style: solid;
}

.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.image-414 blockquote p {
  padding: 44px 58px 0 50px;
  line-height: 1.2;
}

.image-415 blockquote p {
  padding: 44px 58px 0 50px;
  line-height: 1.2;
}

blockquote p {
  font-size: 30px;
}

blockquote {
  position: relative;
  padding: 0;
  border: none;
  font-size: 150%;
  margin-top: 24px;
  font-style: italic;
  text-align: left;
}

.time-duartion .time-circle span {
  display: block;
}

.time-duartion .time-circle {
  font-size: 10px;
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  padding-top: 3px;
  margin: auto;
  font-family: Georgia;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}

.image-270x181 .ImageOverlay {
  font-size: 10px;
}

.ImageOverlay {
  height: 24px;
  position: absolute;
  top: 0px;
  font-style: italic;
  width: 76.84px;
  font-family: "Times New Roman";
  text-align: center;
  border: 1px;
  border-style: solid;
}

.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.amp-dc-card {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.amp-dc-card * {
  margin: auto;
  padding: 0;
}

.amp-dc-card .amp-dc-hide {
  display: none;
}

.amp-dc-card .amp-dc-card-text-wrap {
  color: #000;
  padding: 10px 0;
  text-align: center;
  background-color: #fff;
}

.amp-dc-card a.amp-dc-card-wrap {
  display: block;
  text-decoration: none;
}

.amp-dc-card a.amp-dc-card-wrap:hover .amp-dc-card-link {
  text-decoration: underline;
}

.amp-dc-card .amp-dc-card-name {
  font-size: 15px;
  line-height: 20px;
  color: #000;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}

.amp-dc-card .amp-dc-card-description {
  font-style: italic;
  font-family: Georgia;
  font-size: 14px;
  line-height: 1.2;
  display: block;
  color: #000;
}

.amp-dc-card .amp-dc-card-link {
  font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  text-transform: uppercase;
}

.amp-dc-external-block .test {
  padding: 20px;
  background: #efefef;
  border-radius: 5px;
  margin: 20px;
  min-width: 360px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amp-dc-card-img-wrap {
  position: relative;
}

.amp-dc-image {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.amp-dc-image .amp-dc-image-pic {
  display: block;
}

.amp-dc-image img {
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image-934x500 {
  width: 934px;
}

.image-964x372 {
  width: 964px;
}

.image-964x400 {
  width: 964px;
}

.image-964x400 {
  width: 964px;
}

.image-880x175 {
  width: 880px;
}

@media screen and (min-width: 769px) {
  .amp-dc-card.image-270x181 {
    width: 270px;
    height: auto;
  }
  .image-574x385 {
    width: 100% !important;
  }
  .image-574x385 .amp-dc-card-img-wrap {
    float: left;
    width: 574px;
    height: 386px;
  }
  .image-574x385 .time-duartion {
    float: right;
    width: 304px;
  }
  .image-574x385 .amp-dc-card-text-wrap {
    margin-top: 100px;
    width: 304px;
    float: right;
  }
}
.image-417 {
  width: 417px;
}

.image-415x277 {
  width: 415px;
  height: auto;
}

.image-415x532 {
  width: 415px;
  height: auto;
}

.image-430x290 {
  width: 430px;
}

.amp-dc-card.image-431x291 {
  width: 431.98px;
}

@media screen and (min-width: 1025px) {
  .amp-dc-card.image-431x291 a.amp-dc-card-wrap {
    width: 431.98px;
  }
}
.amp-dc-card.image-431x291 .amp-dc-card-name {
  font-size: 18px;
}

.image-431x291 .amp-dc-banner-description {
  margin-bottom: 10px;
  line-height: 18px;
  font-size: 14px;
}

.amp-dc-card.image-431x291 .amp-dc-card-link {
  border: 2px solid #000;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  height: 36px;
}

.image-445x181 {
  width: 445px;
}

.image-355x478 {
  width: 355px;
}

.image-292x161 {
  width: 292px;
}

.image-293x218 {
  width: 293px;
}

.image-293x218 .amp-dc-card-text-wrap {
  display: block;
  position: relative;
}

.image-293x218 .amp-dc-card-link {
  bottom: 0px;
  padding: 10px 0;
  text-align: center;
  background-color: unset;
}

.image-277x172 {
  width: 277px;
}

.image-220x220 {
  width: 220px;
}

.image-270x181 {
  width: 270px;
}

.image-270x181.amp-dc-card-name {
  font-size: 16px;
  line-height: 17px;
}

.image-270x181.amp-dc-card-description {
  line-height: 20.94px;
  font-size: 14px;
}

.image-270x181 .amp-dc-card-link {
  font-size: 16px;
  height: 36px;
}

.image-165x166 {
  width: 165px;
  height: auto;
}

.image-165x166 .amp-dc-card-name {
  display: none;
}

.image-165x166 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}

.image-165x166 .amp-dc-card-description {
  display: none;
}

.image-165x166 .amp-dc-card-link {
  background-color: unset;
  color: #8f8f8f;
  font-size: 16px;
  white-space: nowrap;
}

.image-220x208 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}

.image-220x202 .amp-dc-card-link {
  background-color: unset;
  color: #8f8f8f;
  font-size: 16px;
  white-space: nowrap;
}

.image-220x202 {
  width: 220px;
  height: 208px;
}

.image-125x125 {
  width: 125px;
  height: auto;
}

.image-208x208 {
  width: 208px;
  height: auto;
}

.image-208x208 .amp-dc-card-name {
  font-size: 14px !important;
  font-weight: normal !important;
}

.image-208x208 .amp-dc-card-link {
  background: unset;
  font-size: 14px;
  color: #000;
  text-decoration: underline;
}

.image-208x208 .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

@media screen and (max-width: 768px) {
  .image-445x181, .image-355x478, .image-292x161, .image-293x218, .image-431x291, .image-270x181, .image-220x220, .image-165x166 {
    width: 100%;
    height: auto;
  }
  .image-277x172, .image-934x500, .image-430x290, .image-964x400, .image-880x175, .image-574x385, .image-430x290 {
    width: 100%;
    height: auto;
  }
  .image-125x125 {
    width: 50%;
    height: 50%;
  }
  .image-415x532 {
    width: 100%;
    height: auto;
  }
  .image-415x277 {
    width: 100%;
    height: auto;
  }
  .image-417 {
    width: 100%;
    height: auto;
  }
  .image-430x290 {
    width: 100%;
  }
  .image-270x181 {
    width: 100%;
  }
  .image-220x208 {
    width: 100%;
    height: auto;
  }
}
.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link {
  background-color: #fff;
  color: #000;
  text-transform: none;
}

.amp-dc-card.journal-product-212 .amp-dc-card-link {
  background-color: #fff;
  color: #000;
  text-transform: none;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-description p, .amp-dc-card.journal-product-212 .amp-dc-card-description p {
  font-family: inherit !important;
  font-style: normal;
}

.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description {
  font-style: normal;
}

@media screen and (min-width: 1025px) {
  .amp-dc-card.journal-perfumers-img-291x291 {
    width: 291px !important;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .amp-dc-card.hp-small-431x291-poi {
    width: 100% !important;
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  .amp-dc-card.image-431x291 {
    width: 100%;
    height: auto;
  }
}
.amp-dc-card-list .century-gothic-stack {
  font-family: century-gothic, Verdana, sans-serif !important;
}

.amp-dc-card-list .times-new-roman {
  font-family: "Times New Roman", Times, serif;
}

.amp-dc-card-list .amp-dc-cardlist-description h1, .amp-dc-card-list .amp-dc-cardlist-description h2, .amp-dc-card-list .amp-dc-cardlist-description h3, .amp-dc-card-list .amp-dc-cardlist-description h4, .amp-dc-card-list .amp-dc-cardlist-description h5, .amp-dc-card-list .amp-dc-cardlist-description h6 {
  font-family: inherit !important;
  font-weight: 500;
}

.amp-dc-card-list .amp-dc-cardlist-description a, .amp-dc-card-list .amp-dc-cardlist-description p {
  font-family: inherit !important;
  color: inherit;
}

.amp-dc-card-list .amp-dc-cardlist-description h1 {
  font-size: 36px;
}

.amp-dc-card-list .amp-dc-cardlist-description h2 {
  font-size: 30px;
}

.amp-dc-card-list .amp-dc-cardlist-description h3 {
  font-size: 22px;
}

.amp-dc-card-list .amp-dc-cardlist-description h4 {
  font-size: 18px;
}

.amp-dc-card-list .amp-dc-cardlist-description h5 {
  font-size: 16px;
}

.amp-dc-card-list .amp-dc-cardlist-description h6 {
  font-size: 14px;
}

.amp-dc-card-list .amp-dc-card-title {
  font-size: 25px;
}

.amp-dc-image {
  min-width: 0;
}

/* homepage */
.homepage-six-small-banners {
  margin-bottom: 20px;
}

.hotel-amenities-bottom-cards .amp-dc-card-list-wrap {
  width: auto;
}

.amp-dc-card-list .amp-dc-card-list-wrap {
  text-align: center;
  margin: auto;
}

#featureFRQuote {
  margin-bottom: 20px;
}

@media (max-width: 1550px) and (min-width: 1441px) {
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 100%;
  }
}
@media screen and (min-width: 1441px) {
  /* .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card.hp-small-431x291 {
     width: 431px;
    } */
}
@media screen and (min-width: 2560px) {
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 919px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 990px;
  }
}
@media screen and (min-width: 1025px) {
  .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card {
    margin: 14px 29px 0;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .amp-dc-card-list.homepage-six-small-banners {
    margin-top: 20px;
    padding: 0px 6%;
  }
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 100%;
    margin: auto;
  }
  .homepage-six-small-banners .amp-dc-card-list-wrap .amp-dc-card {
    padding-left: 15px;
    padding-right: 15px;
  }
  .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291 {
    width: 49%;
    margin: auto;
    font-size: 0;
  }
  .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291-poi {
    width: 49%;
    margin: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 50%;
    height: auto;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .amp-dc-card-list.homepage-six-small-banners {
    margin-top: 20px;
  }
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 92%;
    margin: auto;
  }
  .amp-dc-card-list.homepage-six-small-banners .amp-dc-card.hp-small-431x291 {
    margin: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 100%;
    height: auto;
    margin: auto;
  }
}
/* homepage */
/* journal home,categorypages*/
.journal-hp-cat-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(-n+6)) {
  display: none;
}

.amp-dc-card-list.journal-hp-cat-small-card-list .journal-morestories-button {
  color: #fff !important;
  background-color: #000;
  font-size: 16px;
  height: 36px;
  padding: 8px;
  padding: 7px 25px 5px;
  margin-top: 15px;
}

.amp-dc-card-list-wrap .amp-dc-card.show3cards {
  display: inline-block !important;
}

@media screen and (min-width: 1024px) {
  .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap {
    width: 878px;
    margin-top: 20px !important;
    font-size: 0;
  }
  .amp-dc-card-list.hotel-amenities-bottom-cards {
    padding: 15px;
  }
}
@media screen and (min-width: 769px) {
  .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(3n)) {
    margin-right: 34px;
  }
}
@media screen and (max-width: 768px) {
  #journal-homepage {
    width: 100%;
  }
  .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap {
    width: 98% !important;
  }
}
.amp-dc-card-list.journal-hp-cat-small-card-list .amp-dc-card-list-wrap {
  margin: auto;
}

/* journal home,categorypages*/
/* aboutus page */
.amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
  margin-right: 20px;
}

.amp-dc-card-list.about-us-bottom-banners .amp-dc-card-list-wrap {
  margin: auto;
}

@media screen and (min-width: 769px) {
  .amp-dc-card-list.about-us-bottom-banners .amp-dc-card-list-wrap {
    width: 770px;
  }
  .amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
    margin: 10px !important;
  }
}
@media screen and (max-width: 480px) {
  .amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
    margin-bottom: 10px !important;
    margin: auto !important;
  }
}
/* about us page */
/* hotel-aminities*/
@media screen and (max-width: 768px) {
  .amp-dc-card-list.hotelaminities-cardlist .amp-dc-card-list-wrap {
    width: 100%;
    margin: auto;
  }
  .amp-dc-card-list.hotel-amenities-bottom-cards .amp-dc-card {
    margin-bottom: 20px;
  }
}
/*hotel aminities*/
/*special offers */
.amp-dc-card-list.special-offers-everyday-treats .amp-dc-card-list-wrap {
  margin: auto;
}

@media screen and (min-width: 769px) {
  .amp-dc-card-list.hotelaminities-cardlist .amp-dc-card-list-wrap {
    padding: 10px;
  }
  .amp-dc-card-list.special-offers-everyday-treats .amp-dc-card.special-offers-308x330:not(:nth-child(3n)) {
    margin-right: 20px;
  }
}
/* special offers */
/* journal perfumers */
@media screen and (min-width: 1441px) {
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap {
    width: 919px;
    margin-top: 15px;
  }
}
@media screen and (min-width: 1025px) {
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card:not(:nth-child(4n)) {
    margin-right: 14px;
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap {
    width: 919px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card {
    padding: 10px;
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap {
    width: 75%;
    margin-bottom: 20px;
    margin: auto;
  }
}
@media screen and (max-width: 768px) {
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card {
    width: 48% !important;
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap {
    width: 100%;
    margin: auto;
  }
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card {
    width: auto !important;
    height: auto;
  }
}
@media screen and (max-width: 480px) {
  .amp-dc-card-list.journal-perfumers-small-card-list .amp-dc-card-list-wrap .amp-dc-card {
    width: 50% !important;
  }
}
/* journal perfumers */
/* cardlist homepage */
.amp-dc-card-title {
  margin: 27px;
  font-size: 2em;
  text-align: center;
}

@media screen and (min-width: 769px) {
  .amp-dc-card-list .amp-dc-card-list-wrap {
    text-align: center;
    margin: auto;
  }
}
.amp-dc-card-list .amp-dc-card {
  display: inline-block;
  vertical-align: top;
}

.amp-dc-card-list .amp-dc-card-wrap {
  margin: auto !important;
}

.amp-dc-card-list .amp-dc-card-wrap .amp-dc-card-img {
  max-height: 250px;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap {
  margin-left: 0;
  overflow: hidden;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child {
  float: left;
  width: 70%;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child .amp-dc-card-wrap {
  margin-right: 20px;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card-wrap {
  margin: 0 0 50px 0;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card {
  float: right;
  width: 30%;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card.amp-dc-card-1 {
  float: left;
  width: 70%;
}

.amp-dc-card-list.amp-dc-cards-hero .amp-dc-card.amp-dc-card-1 .amp-dc-card-wrap {
  margin-right: 20px;
}

.amp-dc-prod-5-rows .amp-dc-card {
  width: 20%;
}

/*.amp-dc-prod-4-rows .amp-dc-card {
  width: 25%;
}*/
.amp-dc-prod-2-rows .amp-dc-card {
  width: 50%;
}

.amp-dc-prod-1-rows .amp-dc-card {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .amp-dc-prod-5-rows .amp-dc-card {
    width: 48%;
  }
  .amp-dc-prod-4-rows .amp-dc-card {
    width: 48%;
  }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child {
    width: 100%;
  }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap > div:nth-last-child(3):first-child .amp-dc-card-wrap {
    margin-right: 0;
  }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card {
    width: 100%;
  }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card.amp-dc-card-1 {
    width: 100%;
  }
  .amp-dc-prod-3-rows.amp-dc-cards-hero .amp-dc-card-list-wrap .amp-dc-card.amp-dc-card-1 .amp-dc-card-wrap {
    margin-right: 0;
  }
  .amp-dc-prod-2-rows .amp-dc-card {
    width: 48%;
  }
  .amp-dc-prod-1-rows .amp-dc-card {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .amp-dc-prod-5-rows .amp-dc-card {
    width: 100%;
  }
  .amp-dc-prod-4-rows .amp-dc-card {
    width: 100%;
  }
  .amp-dc-prod-3-rows .amp-dc-card {
    width: 100%;
  }
  .amp-dc-prod-2-rows .amp-dc-card {
    width: 100%;
  }
  .amp-dc-prod-1-rows .amp-dc-card {
    width: 100%;
  }
}
@media (max-width: 2560px) and (min-width: 1551px) {
  .homepage-six-small-banners .amp-dc-card-list-wrap {
    width: 1530px !important;
  }
}
.amp-dc-card.hp-small-431x291 .amp-dc-card-link {
  line-height: normal;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link {
  background-color: #fff !important;
  text-transform: none;
  cursor: pointer;
}

.journal-morestories-button {
  cursor: pointer;
}

.amp-dc-homepage {
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
}
.amp-dc-homepage .amp-dc-block-wrap {
  margin-top: 0px;
}
.amp-dc-homepage .amp-dc-card-img-wrap {
  margin-top: 20px;
}
.amp-dc-homepage .amp-dc-text {
  margin: 0 25px;
}
.amp-dc-homepage .amp-dc-splitBlock {
  overflow: hidden;
}
.amp-dc-homepage .amp-dc-splitBlock {
  overflow: hidden;
}
.amp-dc-homepage .amp-dc-promo-banner-wrap {
  margin: 20px 0;
}
.amp-dc-homepage .amp-dc-promo-banner {
  margin: -70px -5px 0 -5px;
}

.header-wrap ul {
  list-style: none;
}
.header-wrap .header-content {
  margin: 10px 50px;
  overflow: hidden;
  height: 40px;
}
.header-wrap .logo {
  float: left;
  width: 220px;
  height: 100%;
  display: inline-block;
}
.header-wrap .logo-image {
  height: 100%;
}
.header-wrap .top-menu {
  overflow: hidden;
  text-align: center;
  margin: 10px 0;
}
.header-wrap .top-menu > li {
  display: inline-block;
  vertical-align: top;
  margin-right: 23px;
}
.header-wrap .top-menu > li:last-child {
  margin-right: 0;
}
.header-wrap .top-menu > li > a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
.header-wrap .top-menu > li > a:hover {
  text-decoration: underline;
}
.header-wrap .customer-menu {
  float: right;
  height: 40px;
  margin: 0;
  padding: 0;
}
.header-wrap .customer-menu > li {
  display: inline-block;
  vertical-align: top;
  padding: 0 24px;
}
.header-wrap .customer-menu > li > a {
  text-decoration: none;
  color: #000;
  font-size: 36px;
  height: 40px;
  line-height: 40px;
}
.header-wrap .header-promotion {
  background-color: #000;
  color: #fff;
  padding: 5px 7px;
  text-align: center;
  font-size: 12.8px;
  text-transform: uppercase;
}

@media screen and (max-width: 979px) {
  .header-wrap .logo {
    width: auto;
  }
  .header-wrap .top-menu {
    display: none;
  }
}
@media screen and (max-width: 499px) {
  .header-wrap .customer-menu .first-nav, .header-wrap .customer-menu .second-nav {
    display: none;
  }
}
.amp-dc-image {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.hotel-aminities-cardlist-card-image .amp-dc-image-pic {
  width: 220px;
  height: 202px;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-right-banner-image-02 .amp-dc-image-pic {
    width: 100%;
  }
  .hotel-aminities-right-logo-image .amp-dc-image-pic {
    width: 100%;
  }
  .hotel-aminities-right-banner-image-03 .amp-dc-image-pic {
    width: 100%;
  }
  .hotel-aminities-cardlist-card-image .amp-dc-image-pic {
    width: 100%;
    height: auto;
  }
}
img.amp-dc-image-pic.image-431x291 {
  width: 431px;
  height: 291px;
}

img.amp-dc-image-pic.image-934x500 {
  width: 934px;
  height: 500px;
}

img.amp-dc-image-pic.image-417 {
  width: 417px;
  height: auto;
}

.image-964x372 {
  width: 964px;
  height: 372px;
}

.image-964x400 {
  width: 964px;
  height: 400px;
}

.image-964x400 {
  width: 964px;
  height: 400px;
}

.image-880x175 {
  width: 880px;
  height: auto;
}

.image-729 {
  width: 729px;
  height: auto;
}

.image-574x385 {
  width: 574px;
  height: auto;
}

.image-430x290 {
  width: 430px;
  height: 290px;
}

.image-widthx590px {
  width: 100%;
  height: 590px;
}

.image-445x181 {
  width: 445px;
  height: 181px;
}

.image-428x514 {
  width: 428px;
  height: auto;
}

.image-413 {
  width: 413px;
  height: auto;
}

.image-414 {
  width: 414px;
  height: auto;
}

.image-415 {
  width: 415px;
  height: auto;
}

.image-417 {
  width: 417px;
  height: auto;
}

.image-422 {
  width: 422px;
  height: auto;
}

.image-355x478 {
  width: 355px;
  height: 478px;
}

.image-200x200 {
  width: 200px;
  height: 200px;
}

.image-292x161 {
  width: 292px;
  height: 161px;
}

.image-293x218 {
  width: 293px;
  height: 218px;
}

.image-277x172 {
  width: 277px;
  height: 172px;
}

.image-270x181 {
  width: 270px;
  height: 181px;
}

.image-220x220 {
  width: 220px;
  height: 220px;
}

.image-220x202 {
  width: 220px;
  height: 220px;
}

.image-165x166 {
  width: 165px;
  height: 166px;
}

.image-415x277 {
  width: 415px;
  height: 277px;
}

.image-415x532 {
  width: 415px;
  height: 532px;
}

.image-125x125 {
  width: 125px;
  height: 125px;
}

.image-120x120 {
  width: 120px;
  height: 120px;
}

.image-208x208 {
  width: 208px;
  height: 208px;
}

@media screen and (max-width: 768px) {
  .image-445x181, .image-355x478, .image-292x161, .image-293x218, .image-270x181, .image-220x220, .image-165x166 {
    width: 100%;
    height: auto;
  }
  .image-277x172, .image-934x500, .image-430x290, .image-964x400, .image-880x175, .image-574x385, .image-430x290, .image-270x181 {
    width: 100%;
    height: auto;
  }
  .image-125x125 {
    width: 100% !important;
    height: auto !important;
  }
  .image-120x120 {
    width: 100% !important;
    height: auto !important;
  }
  .image-413, .image-414, .image-415, .image-417, .image-416, .image-729px {
    width: 100%;
    height: auto;
  }
  img.amp-dc-image-pic.image-417 {
    width: 100%;
    height: auto;
  }
  .image-415x532 {
    width: 100%;
    height: auto;
  }
  .image-415x277 {
    width: 100%;
    height: auto;
  }
  .image-200x200 {
    width: 100%;
    height: auto;
  }
  .image-widthx590px {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  img.amp-dc-image-pic.image-431x291 {
    width: 100%;
    height: auto;
  }
}
.amp-dc-promo-banner {
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
}
.amp-dc-promo-banner * {
  margin: 0;
  padding: 0;
}
.amp-dc-promo-banner .amp-dc-promo-banner-wrap {
  display: table;
  vertical-align: top;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .amp-dc-promo-banner .amp-dc-promo-banner-wrap {
    position: relative;
    display: block;
  }
}
.amp-dc-promo-banner .amp-dc-promo-block {
  display: table-cell;
  overflow: visible;
  border: 4px solid transparent;
}
.amp-dc-promo-banner .amp-dc-promo-wrap a {
  display: block;
  overflow: hidden;
  text-decoration: none;
}
.amp-dc-promo-banner .amp-dc-promo-wrap a:hover * {
  opacity: 0.8;
}
.amp-dc-promo-banner .amp-dc-promo-text-wrap {
  overflow: hidden;
}
.amp-dc-promo-banner .amp-dc-promo-ico {
  float: left;
  margin-right: 15px;
  min-height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}
.amp-dc-promo-banner .amp-dc-promo-ico img {
  display: block;
}
.amp-dc-promo-banner .amp-dc-promo-head-text {
  font-weight: bold;
  font-size: 16px;
  color: #424242;
}
.amp-dc-promo-banner .amp-dc-promo-content-text {
  font-size: 14px;
  color: #000000;
  margin-top: 3px;
}
.amp-dc-promo-banner .amp-dc-image-img {
  width: 100%;
  font-size: 14px;
  display: block;
}
.amp-dc-promo-banner .amp-dc-promo-center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .amp-dc-promo-banner {
    white-space: normal;
  }
  .amp-dc-promo-banner .amp-dc-promo-block {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    opacity: 0;
  }
  .amp-dc-promo-banner .amp-dc-promo-block.dc-fade-in {
    position: relative;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
    animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    opacity: 1;
  }
  .amp-dc-promo-banner .amp-dc-promo-head-text {
    font-size: 15px;
  }
  .amp-dc-promo-banner .amp-dc-promo-content-text {
    font-size: 13px;
  }
  .amp-dc-promo-banner .amp-dc-promo-ico {
    min-height: 35px;
  }
  ul.amp-dc-nav-wrap {
    width: 100%;
    height: auto;
  }
}
.slider_CTA {
  border: 2px solid #000;
  color: #000 !important;
  background-color: #fff;
  height: 36px;
  font-size: 14px;
  padding: 7px 25px 5px !important;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  font-family: Times New Roman;
}

.acc-slider-description {
  margin: auto;
  justify-content: center;
  text-align: center;
}

.amp-dc-slider .acc-slider-description h1, .amp-dc-slider .acc-slider-description h2, .amp-dc-slider .acc-slider-description h3, .amp-dc-slider .acc-slider-description h4, .amp-dc-slider .acc-slider-description h5, .amp-dc-slider .acc-slider-description h6 {
  font-family: inherit !important;
  font-weight: 500;
}

.amp-dc-slider .acc-slider-description h1 {
  font-size: 36px;
}

.amp-dc-slider .acc-slider-description h2 {
  font-size: 30px;
}

.amp-dc-slider .acc-slider-description h3 {
  font-size: 22px;
}

.amp-dc-slider .acc-slider-description h4 {
  font-size: 18px;
}

.amp-dc-slider .acc-slider-description h5 {
  font-size: 16px;
}

.amp-dc-slider .acc-slider-description h6 {
  font-size: 14px;
}

.amp-dc-card-list .amp-dc-card-title {
  font-size: 25px;
}

.amp-dc-slider {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.amp-dc-slider * {
  margin: 0;
  padding: 0;
}

.amp-dc-slider .amp-dc-slider-frame {
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.amp-dc-slider .amp-dc-slider-slides {
  font-size: 0;
}

.amp-dc-slider .amp-dc-slider-slides > li {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  width: 100%;
  text-align: center;
  font-size: 22px;
  position: relative;
  line-height: 1;
}

.amp-dc-slider .amp-dc-slider-next, .amp-dc-slider .amp-dc-slider-prev {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  display: block;
  cursor: pointer;
  user-select: none;
}

.amp-dc-slider .amp-dc-slider-next svg, .amp-dc-slider .amp-dc-slider-prev svg {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
}

.amp-dc-slider .amp-dc-slider-next.ctrl-disabled, .amp-dc-slider .amp-dc-slider-prev.ctrl-disabled {
  opacity: 0.3;
  -webkit-tap-highlight-color: transparent;
  cursor: default;
}

.amp-dc-slider .amp-dc-slider-next.ctrl-disabled svg, .amp-dc-slider .amp-dc-slider-prev.ctrl-disabled svg {
  cursor: default;
}

.amp-dc-slider .amp-dc-slider-next {
  right: 0;
}

.amp-dc-slider .amp-dc-slider-prev {
  left: 0;
}

.amp-dc-slider .amp-dc-dots {
  user-select: none;
  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.amp-dc-slider .amp-dc-dots > li {
  border: 1px solid #666;
  border-radius: 50%;
  box-shadow: inset 1px 1px 1px #888;
  display: inline-block;
  height: 25px;
  width: 25px;
  margin: 0 5px;
  cursor: pointer;
}

.amp-dc-slider .amp-dc-dots > li.active {
  background-color: #41abe5;
}

.amp-dc-homepage .amp-dc-text {
  margin: 0;
}

.amp-dc-splitBlock .amp-dc-size-10 .amp-dc-split-part-wrap {
  text-align: left;
}

.amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
  height: auto !important;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text h5 {
  color: #000000;
  margin: 24px 0 0 0;
}

.amp-dc-split-part.amp-dc-size-90 .amp-dc-text {
  margin-left: 20px;
}

.splitblock-heading .title {
  padding: 45px 0 40px 35px;
  font-size: 36px;
}

.amp-dc-splitBlock .amp-dc-text {
  margin: auto;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {
  content: "“"; /*Unicode for Left Double Quote*/
  font-size: 189px;
  color: rgba(255, 255, 255, 0.2);
  font-family: Didot, Georgia;
  font-style: italic;
  position: absolute;
  left: -49px;
  top: -56px;
  z-index: 1;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {
  color: rgba(0, 0, 0, 0.2);
  left: -17px;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::after {
  content: "";
}

.amp-dc-homepage {
  display: block !important;
}

/*
.amp-dc-splitBlock .amp-dc-split-part {
 width: 355px ;
}
*/
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote p {
  font-size: 22px;
  font-style: italic;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text a {
  color: #666;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text p, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text p, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text a, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size960 .amp-dc-text a {
  font-size: 13px;
}

/*
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text p{
  padding-top:8px;
  padding-bottom:8px;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text p{
  padding-bottom:8px;
}*/
@media screen and (min-width: 1024px) {
  .amp-dc-splitBlock .container-column .amp-dc-split-part.amp-dc-size-50:nth-child(2) .amp-dc-split-part-wrap {
    width: 288px;
  }
  .splitblock-background {
    justify-content: center;
    align-items: center;
    padding: 54px 0 40px;
    width: 720px;
  }
  .splitblock-heading .title {
    padding: 45px 0 40px 35px;
    font-size: 36px;
  }
  .container-column {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 890px;
    margin: 0 auto;
    text-align: center;
    padding: 0;
  }
  .container-column .amp-dc-split-part-wrap:nth-child(2) {
    width: 288px;
  }
  .amp-dc-splitBlock .amp-dc-split-part:first-child .amp-dc-split-part-wrap {
    float: right;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40:nth-child(2) .amp-dc-split-part-wrap {
    float: none;
  }
  .amp-dc-splitBlock .amp-dc-split-part:nth-child(2) .amp-dc-split-part-wrap {
    float: left;
  }
  .amp-dc-splitBlock .amp-dc-split-part:nth-child(1) .amp-dc-split-part-wrap {
    float: right;
  }
  .amp-dc-splitBlock .amp-dc-split-part:first-child .amp-dc-split-part-wrap {
    padding-left: 0;
    padding-right: 20px;
  }
  .amp-dc-splitBlock .amp-dc-split-part .amp-dc-split-part-wrap {
    padding-right: 0;
    padding-left: 20px;
  }
  .width-770 {
    width: 770px;
    height: auto;
  }
  .width-964 {
    width: 964px;
    height: auto;
  }
  .width-878 {
    width: 878px;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text {
    text-align: left;
    padding: 0;
  }
  .amp-dc-splitBlock {
    border: none !important;
  }
  #background {
    padding: 15px;
  }
  .amp-dc-splitBlock .container-column .splitblock-background {
    padding: 20px;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text {
    margin: 0 25px;
  }
  .amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {
    left: -37px;
    top: -78px;
  }
  .width-770 {
    width: 100%;
    height: auto;
  }
  .width-964 {
    width: 100%;
    height: auto;
  }
  .width-878 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .width-770, .width-964 {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
    height: auto;
    width: 80%;
    margin: auto;
  }
}
.amp-dc-splitBlock {
  text-align: center;
  margin: 0;
  padding: 0;
}

.amp-dc-splitBlock:after {
  content: "";
  display: table;
  clear: both;
}

.amp-dc-splitBlock .amp-dc-split-part {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text {
  min-width: 100px;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-video {
  min-width: 300px;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-image {
  min-width: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
  width: 50%;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 {
  width: 60%;
  height: auto;
  text-align: left;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
  width: 70%;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-80 {
  width: 80%;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
  width: 80%;
  height: auto;
  text-align: left;
}

@media screen and (min-width: 768px) {
  #featureFRQuote .amp-dc-splitBlock {
    display: flex;
    align-items: center;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
    width: 80%;
    height: auto;
  }
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 {
  width: 17%;
  height: auto;
  border: none;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 .amp-dc-split-part-wrap {
  padding-left: unset !important;
  padding-right: unset !important;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-split-part-wrap {
  padding-right: unset !important;
  padding-left: unset !important;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 {
  width: 20%;
  padding: 10px;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote::before {
  left: -72px;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-30 {
  width: 30%;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
  width: 40%;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
  width: 50%;
  height: auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
  width: 40%;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .container-column {
    flex-direction: column;
  }
  .amp-dc-splitBlock {
    height: auto;
  }
  #background {
    height: 658px;
    align-items: center;
    position: relative;
    z-index: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 .amp-dc-split-part-wrap {
    padding-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .hotel-collections .amp-dc-splitBlock {
    border: none;
  }
  .hotelproducts-page .amp-dc-splitBlock {
    border: none;
  }
  .amp-dc-splitBlock .amp-dc-split-part {
    float: none;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-80 {
    width: 100%;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap .amp-dc-image {
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-30 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 {
    width: 100%;
    height: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 {
    width: 100%;
    height: auto;
    border: none;
  }
  .hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
    border: none;
    height: auto;
    width: 80%;
    margin: auto;
  }
  .amp-dc-splitBlock .amp-dc-split-part:first-child {
    padding: 0 0 30px 0;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
    margin-bottom: 15px;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote::before {
    left: -25px;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text {
    text-align: center;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60:first-child .amp-dc-split-part-wrap {
    padding-left: 0;
    padding-right: 0;
    float: left;
    padding: 15px;
  }
}
.amp-dc-text {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}
.amp-dc-text li {
  list-style-position: outside;
}

.Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.static_para {
  color: #260E03 !important;
  margin-top: 15px;
  font-size: 13px !important;
}

.static_para h1 {
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .static_para {
    padding: 0 15px;
  }
}
.onlyImage {
  margin: 0 !important;
}

.onlyImage .staticHeader h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
  margin: 10px 0 0;
}

@media screen and (max-width: 768px) {
  .onlyText .staticHeader h1 {
    padding: 15px !important;
  }
}
.staticAside {
  width: 194px;
  height: 441px;
}

.staticAside ul .list-group-item {
  border: none !important;
  padding: 0px;
}

.leftColumn .medium-aside-menu li ul.search-menu-sub-cntnt li {
  line-height: 16px;
}

@media (max-width: 768px) {
  .leftColumn {
    margin-top: 0px !important;
  }
  .staticHeader.list-group-item {
    border: none !important;
    background-color: #f4f4f4 !important;
    margin-bottom: 10px;
  }
  aside {
    padding: 0 !important;
  }
  .browser-info-cntnt {
    margin-bottom: 10px;
  }
  .browser-info-cntnt h1 {
    font-size: 12px !important;
  }
  .medium-aside-menu li {
    padding: 10px 20px;
  }
}
.onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
  color: #000;
  font-size: 28px;
  font-weight: 100;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px;
    font-size: 15px !important;
    font-weight: bold !important;
    color: #fff !important;
    background: #90d2c5;
    margin: 0;
  }
}
/* About us*/
.amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap {
  position: relative;
}

@media screen and (max-width: 768px) {
  .aboutus-text {
    margin: 0px !important;
  }
}
.aboutus-text {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .aboutus-text {
    width: 300px;
    margin: 10px !important;
  }
}
.aboutus-text .rightContent ul li {
  list-style: none;
  line-height: 15px;
  font: 12px/15px Arial, Helvetica, sans-serif;
  margin-left: 15px;
}

@media screen and (max-width: 768px) {
  .aboutus-text ul li {
    margin-left: 0px !important;
  }
}
.aboutus-text .rightContent ul li h4 {
  font-weight: bold;
  margin: 10px 0;
  font-size: 18px;
}

.aboutus-text .rightContent h3 {
  margin: 3.5em 0 2em;
  color: #4b2d16 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-transform: uppercase;
}

.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link {
  text-align: center !important;
  margin: 3.5em 25px 2em !important;
}

.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link .amp-dc-link-value {
  color: #4b2d16 !important;
  font-weight: bold;
  text-decoration: underline;
}

.aboutus-text .amp-dc-link .amp-dc-link-value:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap {
    margin-left: 0px !important;
  }
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  width: 165px;
  height: 166px;
  position: relative !important;
}

@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
    margin: auto;
  }
}
.staticHeader {
  font-family: Trebuchet MS, Arial, sans-serif;
}

/* gift terms and conditions */
.giftcardterms-conditions .staticHeader .mb_contentblock_landing span a {
  color: #666 !important;
}

.giftcardterms-conditions .staticHeader .static_para p {
  margin-bottom: 15px;
}

/*Faqs*/
.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
  font-family: Trebuchet MS, Arial, sans-serif;
  background: #fafafa;
  border: 1px solid #d6d6d6;
  padding: 20px;
  width: auto;
  float: left;
  margin: 23px 0 0 0;
}

@media screen and (max-width: 768px) {
  .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
    font-family: Trebuchet MS, Arial, sans-serif;
    border: 0px;
    background-color: #fff;
    padding: 20px 0;
    width: 100%;
  }
}
.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
  background: #fff;
  border: 1px solid #d6d6d6;
  display: block !important;
  margin-top: 20px;
  padding: 10px;
  width: 710px;
}

.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a {
  color: #666;
}

@media screen and (max-width: 768px) {
  .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
    border: none;
    padding: 10px 15px;
    width: auto;
  }
}
.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul {
  margin-left: 20px;
}

.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li {
  list-style: circle;
  line-height: 15px;
  font-size: 12px;
  color: #000;
}

.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p {
  padding-bottom: 7px;
}

.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 900;
  color: #666;
}

@media screen and (max-width: 768px) {
  .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
    font-weight: normal;
    line-height: 30px;
    color: #333;
  }
}
.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title {
  font-weight: 900;
  font-size: 12px;
  color: #000;
}

.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
  background-color: #d6d6d6;
  clear: both;
  display: block;
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
    display: none;
  }
}
.deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1, .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
}

.faqsandhelp h1 {
  margin-top: 0;
}

.placingorderfaqs h1 {
  margin-top: 0;
}

.deliveryfaqs .staticHeader h1 {
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .deliveryfaqs .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px !important;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background: #90d2c5;
    margin: 0;
  }
}
.mb_back_to_top {
  float: right;
  color: #5d5d5d;
  font-size: 12px;
  text-decoration: underline;
}

.mb_back_to_top:before {
  border-left: 5px solid transparent;
  border-bottom: 8px solid;
  border-right: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: 1px;
  margin-right: 5px;
  margin-top: -8px;
  vertical-align: baseline;
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .mb_back_to_top:before {
    border-top: 4px solid transparent;
    border-left: 8px solid;
    border-bottom: 4px solid transparent;
  }
}
.mb_back_to_top:hover {
  color: #000;
}

#FAQ_links span.title {
  font-weight: 900;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  #FAQ_links span.title {
    font-size: 12px;
    color: #333;
    font-weight: normal;
    line-height: 25px;
    padding-bottom: 10px;
  }
}
/*terms& conditions */
.terms-conditions .staticHeader .static_para {
  margin-top: 0px;
}

.terms-conditions .staticHeader .static_para p {
  padding: 10px 0px;
}

/* Promotional Terms & Conditions */
.promotionalterms-conditions .staticHeader .mb_contentblock_landing div p {
  padding-bottom: 17px;
}

/* Placing an Order trade enquire */
.tradeenquiry .staticHeader .static_para p a {
  color: #666;
}

/* delivery and returns */
span p a {
  color: #666;
}

.staticsubheader {
  padding-bottom: 13px;
}

.delivery-returns .staticHeader .static_para ul li {
  list-style: circle;
  margin-left: 15px;
  color: #000;
}

a:link, a {
  color: #666;
}

/*--affiliate -- */
.affiliate-enquiry-page .amp-dc-block-wrap:after {
  clear: both;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
  width: 964px;
  height: 372px;
  margin-bottom: 30px;
  position: relative;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap {
  height: 210px;
  width: 427px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 20%;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap .amp-dc-banner-info {
  display: inline-block;
  /* vertical-align: middle;
   */
  width: 75%;
  margin: 24px 55px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
    width: 100%;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap {
  position: relative;
  height: auto;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin-top: 0px;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  width: 964px;
  height: 372px;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
    width: 100%;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  top: 25%;
  width: 427px;
  height: 210px;
  left: 3%;
  right: 3%;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
    width: 94%;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
  font-size: 28px;
  line-height: 35px;
  padding: 15px 65px;
  font-style: italic;
  font-family: Georgia, "Times New Roman", Times, serif !important;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
    padding: 15px 10px;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
  color: #1c1c1c;
  font-size: 20px;
  line-height: 25px;
  margin: 0px 0 25px 0;
  padding: 0px 65px;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
    padding: 0px 10px;
    font-size: 14px;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
  width: 365px;
  height: 237px;
  border: 4px solid #4fb9c9;
  padding: 28px 10px 28px 60px;
  float: right;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
    width: 100%;
    margin: 0;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 h2 i {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}

.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}

.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li:first-child {
  font-weight: bold;
  margin-top: 15px;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
  background: #f9f9f9;
  width: 365px;
  height: 237px;
  padding: 32px 10px 28px 60px;
  float: left;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
    width: 100%;
    margin: 15px 0 0;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 h2 i {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}

.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li:first-child {
  margin-top: 15px;
}

.affiliate-enquiry-page .amp-dc-block-wrap .note {
  margin-top: 30px !important;
}

.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
  margin-top: 30px !important;
}

.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p {
  color: #1c1c1c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 17px;
}

.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .mail-link, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .mail-link {
  color: #1c1c1c !important;
  font-size: 14px;
  font-weight: bold;
}

.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
  background-color: #c74782;
  color: #fff !important;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
    width: 100%;
    text-align: center;
  }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button:after, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2 i, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 i {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
}

.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part {
  padding: 0px;
}

.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with {
  margin-top: 15px !important;
}

.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with h2 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
  font-style: italic;
}

.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
  margin-top: 15px !important;
  width: 346.8px;
  height: 46.53px;
}

@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
    width: 100%;
    height: auto;
  }
}
/* affiliate --End */
/* bespoke-hotel-amenitiespage */
.bespoke-hotel-amenitiespage {
  padding-left: 18px;
}

@media screen and (max-width: 768px) {
  .bespoke-hotel-amenitiespage {
    padding-left: 0px;
  }
}
.WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}

.HotelS_heading, .bespoke-hotel-amenitiespage .WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-01 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: 10px auto;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap {
  position: relative;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 218px;
  margin: auto;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  height: inherit;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  width: 292px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #000 8a !important;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff;
}

.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.mb_address {
  margin-top: 10px;
  padding-top: 7px;
}

.contactdetails {
  margin: 0px !important;
}

.contactdetails .HotelS_text-orange {
  color: #eb6909;
  margin-top: 32px;
  font-size: 13px !important;
}

.hotelproducts-page {
  width: 752px;
}

.hotelproducts-page .amp-dc-splitBlock {
  border: 1px solid #d6d6d6;
  margin-top: 20px;
  padding: 10px;
  width: 752px;
}

.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 {
  width: 448px;
}

.hotel-collections-description {
  width: 448px;
  margin: 0;
  padding: 0 0 0 12px;
}

.hotel-collections-description .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}

.hotel-collections-description .HotelS_text {
  margin: 10px 0 20px;
}

.hotel-collections-description a {
  font-size: 13px;
}

.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 {
  width: 277px;
}

.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
  height: 172px;
}

.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap .amp-dc-image {
  height: inherit;
}

.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap img {
  width: 100%;
  height: inherit;
}

@media screen and (max-width: 768px) {
  .hotelproducts-page {
    width: 100%;
  }
  .hotel-collections-description, .hotelproducts-page .amp-dc-size-60 {
    width: 100% !important;
  }
  .hotel-collections-description .amp-dc-size-40, .hotelproducts-page .amp-dc-size-60 .amp-dc-size-40 {
    margin: auto;
  }
}
/* hand care*/
.eledescription {
  margin-top: 20px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child {
  position: relative;
}

.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap {
  padding: 0px;
}

.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
  background-color: #941e7e;
  padding-top: 10px;
  color: #b5c437 !important;
  height: 161px;
  width: 410px;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
  position: absolute;
  bottom: 35px;
  right: 50px;
  color: #b5c437 !important;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
    bottom: 10px;
  }
}
.hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
  margin: 0px;
  width: 408px;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}

.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  margin: auto;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 {
  position: relative;
}

.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
    height: auto;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}

.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}

.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
    flex-direction: column;
  }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
  width: 220px;
  height: 202px;
}

@media screen and (max-width: 480px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
    margin: 10px auto;
    width: 100%;
    height: auto;
  }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  position: relative;
  margin: 0;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin: 0px;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
  width: 220px;
  height: 202px;
}

@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
  }
}
.hotel-collections {
  width: 770px;
  padding-left: 18px;
}

@media screen and (max-width: 768px) {
  .hotel-collections {
    width: 100%;
    padding-left: 0px;
  }
}
.hotel-collections .amp-dc-splitBlock {
  padding: 10px;
  border: 1px solid #d6d6d6;
  margin-top: 20px;
}

.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 {
  width: 125px;
  height: 125px;
  padding: 2px;
  border: 1px solid #d6d6d6;
  margin: auto;
}

.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
  height: 120px;
  width: 120px;
}

.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description {
  margin-top: 10px;
  padding-bottom: 7px;
}

.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p.title {
  margin-top: 10px;
  font-size: 14.1px;
  padding-bottom: 7px;
}

.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p {
  margin-top: 10px;
}

.hotel-collections.collections1973 .hotelproducts .staticHeader p {
  margin-top: 20px;
}

.whymoltonbrown {
  padding-left: 18px;
}

@media screen and (max-width: 768px) {
  .whymoltonbrown {
    padding-left: 0px;
  }
}
.WhyMoltonBrown-main-text {
  margin: 0px !important;
}

.WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}

.WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}

.WhyMoltonBrown-main-text .leftContent img {
  width: 130px;
  height: 78px;
}

aside {
  width: 194px;
  border: 0;
  text-align: left;
  padding: 4px 6px 20px 15px;
}

aside h3.facet_header {
  font-size: 14px;
  line-height: 30px;
  color: #666;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  aside h3.facet_header {
    display: none !important;
  }
}
.whymoltonbrown-right-section .amp-dc-block-wrap {
  width: 292px;
  margin: auto;
}

.whymoltonbrown-right-section .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}

.whymoltonbrown-right-section .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: auto;
}

.whymoltonbrown-right-section .bespoke-right-card-02 {
  position: relative;
}

.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
  }
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}

.careers h1 {
  padding-left: 18px;
}

.careers .spaHotelsCareers3900026 {
  margin-top: 40px;
}

.careers .spaHotelsCareers3900026 p {
  margin-top: 20px;
  padding-bottom: 7px;
  padding-left: 18px;
}

.careers .spaHotelsCareers3900026 .one {
  margin-top: 20px;
}

.careers .spapage {
  display: flex;
  padding: 42px 18px 18px 18px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .careers .spapage {
    flex-direction: column;
  }
  .careers .spapage div {
    width: 100% !important;
    height: auto !important;
  }
}
.careers .spapage p {
  padding-top: 20px;
}

.careers .spapage a {
  text-decoration: underline;
}

.careers .spapage div {
  width: 220px;
  height: 202px;
  padding: 5px;
  border: #d6d6d6 1px solid;
  margin-top: 10px;
}

.ourheritagepage .ourheritageheaderblock {
  margin: 0;
}

.ourheritagepage .ourheritageheaderblock .head-section {
  font-family: Georgia, Garamond, serif;
  color: #000;
  margin: auto;
  text-align: center;
  width: 425px !important;
}

@media screen and (max-width: 768px) {
  .ourheritagepage .ourheritageheaderblock .head-section {
    width: 100% !important;
  }
}
.ourheritagepage .ourheritageheaderblock .head-section .main-title {
  font-size: 25px;
  margin: 30px 0 3px;
  padding: 0;
  font-weight: 100;
  font-family: Georgia;
  text-transform: uppercase;
}

.ourheritagepage .ourheritageheaderblock .head-section .sub-title {
  font-size: 22px;
  font-style: italic;
  margin: 0 0 15px;
  font-weight: 100;
}

.ourheritagepage .ourheritageheaderblock .head-section .time-duartion {
  margin-bottom: 15px;
}

.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle {
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  padding-top: 3px;
  margin: auto;
  font-family: Georgia;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}

.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle span {
  display: block;
  font-size: 14px;
}

.ourheritagepage .ourheritageheaderblock .head-section .font14 {
  width: 425px;
  font-family: Georgia !important;
  font-size: 14px;
  margin: auto;
}

@media screen and (max-width: 425px) {
  .ourheritagepage .ourheritageheaderblock .head-section .font14 {
    width: 100% !important;
  }
}
.ourheritagepage .amp-dc-splitBlock .Ourheritage-text {
  color: #000;
  padding-bottom: 30px;
}

.Ourheritage-text h3 {
  font-style: italic;
  font-size: 22px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.Ourheritage-text p {
  margin-top: 16px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing {
  counter-reset: h2;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list {
  margin: 0 0 13px;
  padding: 0 0 0 34px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list li {
  line-height: 15px;
  font-size: 12px;
  margin-left: 15px;
  padding-bottom: 5px;
  color: #000;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .para-left-padding {
  padding: 0 0 13px 34px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing h2 {
  font-weight: 700;
  counter-reset: h3;
  margin: 0;
  padding: 5px 0 13px 0;
  font-size: 13px;
  color: #000 !important;
}

.privacyPolicy .staticHeader .mb_contentblock_landing h2::before {
  counter-increment: h2;
  content: counter(h2) ". ";
  padding-right: 18px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing h3 {
  padding: 5px 0 13px;
  font-size: 13px;
  color: #000;
  font-weight: 700;
}

.privacyPolicy .staticHeader .mb_contentblock_landing h3::before {
  counter-increment: h3;
  content: counter(h2) "." counter(h3) ". ";
  padding-right: 8px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead {
  vertical-align: top;
  height: 30px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead th {
  color: #000 !important;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .staticsubheader {
  font-weight: 700;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc p {
  font-family: Arial, Helvetica, sans-serif;
}

.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc {
  border: 0 transparent;
  margin: 5px 0 13px 34px;
  width: 100%;
  border-collapse: collapse;
}

.brochureblock {
  padding: 0 !important;
}

.mb_contentblock_landing.singleBlock td {
  border-top: 0 solid #d6d6d6;
  padding: 5px 5px 5px 0;
  vertical-align: top;
}

.corporate-gifting p {
  padding-top: 20px;
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
  line-height: 18px;
}

.corporate-gifting a {
  color: #000;
  font-weight: bold;
}

.corporate-gifting a.download-brochure-link {
  background: #4d3938;
  font-style: italic;
  font-family: georgia;
  color: #fff;
  padding: 10px 30px 10px 30px;
  display: inline-block;
  margin: 30px 0 33px;
  font-size: 16px;
  cursor: pointer;
  font-weight: normal;
}

.amp-dc-banner video#vjs_video_3_html5_api {
  width: 100%;
}

video.amp-dc-video {
  width: 100%;
  height: auto;
}

.inactive-video {
  position: relative;
  z-index: 100;
}
.inactive-video.no-overlay {
  display: none;
}

.amp-dc-video-wrap {
  position: relative;
  margin: 0;
  padding: 0;
}
.amp-dc-video-wrap .amp-dc-video {
  user-select: none;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.amp-dc-video-wrap .pause-button {
  cursor: pointer;
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  -webkit-tap-highlight-color: transparent;
}
.amp-dc-video-wrap .pause-button:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 500px rgba(196, 215, 182, 0.5);
  position: absolute;
  z-index: 100;
}
.amp-dc-video-wrap .pause-button:after {
  content: "";
  display: block;
  border-left: 43px solid #3f433a;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  position: absolute;
  border-radius: 6px;
  z-index: 200;
  margin-top: 15px;
  margin-left: 24px;
}
.amp-dc-video-wrap .pause-button.inactive {
  display: none;
}

/* special offerspage cards*/
video.amp-dc-video.special-offers-308x330 {
  width: 308px;
  height: auto !important;
}

/*hotel collections*/
video.amp-dc-video.hotel-collection-277x172 {
  width: 277px;
  height: auto;
}

video.amp-dc-video.journal-video-729 {
  width: 729px;
  height: auto;
}

/*hotel product video*/
video.amp-dc-video.hotel-product-120 {
  width: 120px;
  border: 1px solid #d6d6d6;
  height: auto;
}

/*about us bottom video*/
video.amp-dc-video.about-us-165x166 {
  width: 165px;
  height: 166px;
}

/*about us main video */
video.amp-dc-video.about-us-main-355x478 {
  width: 355px;
  height: 478px;
}

/*mega menu video*/
video.amp-dc-video.mm-208x208 {
  width: 208px;
  height: 208px;
}

/*Hotel aminities bottom video*/
video.amp-dc-video.hotel-bottom-220x208 {
  width: 220px;
  height: 208px;
}

/*Journal home,category page video*/
video.amp-dc-video.journal-small-270x181 {
  width: 270px;
  height: auto;
}

video.amp-dc-video.journal-main-574x385 {
  width: 574px;
  height: auto;
}

/*hotel aminities  video*/
video.amp-dc-video.hotel-right-293x218 {
  width: 293px;
  height: auto;
}

video.amp-dc-video.hotel-right-292x161 {
  width: 292px;
  height: auto;
}

video.amp-dc-video.hotel-bottom-220x202 {
  width: 220px;
  height: auto;
}

/*about us main video */
video.amp-dc-video.journal-product-212 {
  width: 212px;
  height: 212px;
}

/*journal page product video */
video.amp-dc-video.journal-product-212 {
  width: 212px;
  height: 212px;
}

/*journal listcials,editorial, listical title and numericals*/
video.amp-dc-video.journal-small-413 {
  width: 413px;
  height: auto;
}

video.amp-dc-video.journal-small-414 {
  width: 414px;
  height: auto;
}

video.amp-dc-video.journal-small-417 {
  width: 417px;
  height: auto;
}

video.amp-dc-video.journal-small-415 {
  width: 415px;
  height: auto;
}

video.amp-dc-video.journal-small-422 {
  width: 422px;
  height: auto;
}

/* home page video */
video.amp-dc-video.hp-small-445x181 {
  width: 100%;
  height: auto !important;
}

@media screen and (min-width: 1025px) {
  video.amp-dc-video.hp-small-431x291 {
    width: 431px;
    height: auto !important;
  }
  video.amp-dc-video.hp-small-445x181 {
    width: 445px;
    height: 181px !important;
  }
  video.amp-dc-video.frag-hub-two-banners-620 {
    width: 620px;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  /* special offers page image*/
  video.amp-dc-video.special-offers-308x330 {
    width: 100%;
    height: auto !important;
  }
  /*hotel collections*/
  video.amp-dc-video.hotel-collection-277x172 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-video-729 {
    width: 100%;
    height: auto;
  }
  /*hotel products*/
  video.amp-dc-video.hotel-product-120 {
    width: 120px;
    height: auto;
  }
  /*about us bottom banners*/
  video.amp-dc-video.about-us-165x166 {
    width: 165px;
    height: 166px;
  }
  /*about us main video */
  video.amp-dc-video.about-us-main-355x478 {
    width: 100%;
    height: 100%;
  }
  /*Hotel aminities bottom video*/
  video.amp-dc-video.hotel-bottom-220x208 {
    width: 100%;
    height: auto;
  }
  /*Journal home,category page video*/
  video.amp-dc-video.journal-small-270x181 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-main-574x385 {
    width: 100%;
    height: auto;
  }
  /*hotel aminities  images*/
  video.amp-dc-video.hotel-right-293x218 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.hotel-right-292x161 {
    width: 292px;
    height: auto;
  }
  video.amp-dc-video.hotel-bottom-220x202 {
    width: 100%;
    height: auto;
  }
  /*journal page prodcut image */
  video.amp-dc-video.journal-product-212 {
    width: 100%;
    height: auto;
  }
  /*journal listcials,editorial, listical title and numericals*/
  video.amp-dc-video.journal-small-413 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-small-414 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-small-417 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-small-415 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.journal-small-422 {
    width: 100%;
    height: auto;
  }
  video.amp-dc-video.frag-hub-two-banners-620 {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  video.amp-dc-video.hp-small-431x291 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.hp-small-445x181 {
    width: 100%;
    height: auto;
  }
}
.amp-dc-video-wrap {
  position: relative;
  margin: 0;
  padding: 0;
}

.amp-dc-video-wrap .amp-dc-video {
  height: auto;
  user-select: none;
  margin: 0 auto;
  display: block;
  width: 100%;
}

.amp-dc-video-wrap .pause-button {
  cursor: pointer;
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  -webkit-tap-highlight-color: transparent;
}

.amp-dc-video-wrap .pause-button:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 500px rgba(196, 215, 182, 0.5);
  position: absolute;
  z-index: 100;
}

.amp-dc-video-wrap .pause-button:after {
  content: "";
  display: block;
  border-left: 43px solid #3f433a;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  position: absolute;
  border-radius: 6px;
  z-index: 200;
  margin-top: 15px;
  margin-left: 24px;
}

.amp-dc-video-wrap .pause-button.inactive {
  display: none;
}

.static_para {
  margin-top: 15px;
  font-size: 13px !important;
}
.static_para h1 {
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .static_para {
    padding: 0 15px;
  }
}

.rightColumn .onlyImage {
  margin: 0 !important;
}
.rightColumn .onlyImage .staticHeader h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
  margin: 10px 0 0;
}

@media screen and (max-width: 768px) {
  .rightColumn .onlyText .staticHeader h1 {
    padding: 15px !important;
  }
}

.staticAside {
  width: 194px;
  height: 441px;
}

.staticAside ul .list-group-item {
  border: none !important;
  padding: 0px;
}

.leftColumn .medium-aside-menu li ul.search-menu-sub-cntnt li {
  line-height: 16px;
}

@media (max-width: 768px) {
  .leftColumn {
    margin-top: 0px !important;
  }
  .staticHeader.list-group-item {
    border: none !important;
    background-color: #f4f4f4 !important;
    margin-bottom: 10px;
  }
  aside {
    padding: 0 !important;
  }
  .browser-info-cntnt {
    margin-bottom: 10px;
  }
  .browser-info-cntnt h1 {
    font-size: 12px !important;
  }
  .medium-aside-menu li {
    padding: 10px 20px;
  }
}
.onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
  color: #000;
  font-size: 28px;
  font-weight: 100;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px;
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #000 !important;
    margin: 0;
  }
}

.staticContainer .container {
  padding: 0 !important;
}

/* About us*/
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock {
  display: flex;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock {
    flex-flow: wrap;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part {
  width: 355px !important;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part {
    width: 100% !important;
    height: auto;
    padding: 0 !important;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap {
  position: relative;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  width: 268px !important;
  background-color: #2c2d2d;
  height: 370px;
  position: absolute;
  opacity: 0.7;
  text-align: left !important;
  top: 34px;
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap .amp-dc-card-text-wrap {
    background-color: #2c2d2d;
    position: absolute;
    opacity: 0.7;
    text-align: center !important;
    padding: 10px;
    height: auto;
    margin: auto;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
  color: #fff;
  font-size: 13px;
  font-family: arial;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
  line-height: 1em;
  color: #fff !important;
  font-size: 23px !important;
  font-family: Molengo;
  margin-bottom: 0px;
  padding-bottom: 14px;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
    font-size: 18px !important;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection {
  width: 335px !important;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap .aboutus-text {
    margin: 0px !important;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent {
    width: 300px;
    margin: 10px !important;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent ul li {
  list-style: none;
  line-height: 15px;
  font: 12px/15px Arial, Helvetica, sans-serif;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent ul li {
    margin-left: 0px !important;
  }
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent ul li h4 {
  font-weight: 700;
  margin: 10px 0;
  font-size: 18px;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .rightContent h3 {
  margin: 3.5em 0 2em;
  color: #4b2d16 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link {
  text-align: center !important;
  margin: 3.5em 25px 2em !important;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link .amp-dc-link-value {
  color: #4b2d16 !important;
  font-weight: 700;
  text-decoration: underline;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link .amp-dc-link-value:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap {
    margin-left: 0px !important;
  }
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  width: 165px;
  height: 166px;
  position: relative !important;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
    margin: auto;
  }
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute !important;
  bottom: 0px !important;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link {
  font-size: 13px !important;
  color: #fff;
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

/* gift terms and conditions */
.giftcardterms-conditions .staticHeader .mb_contentblock_landing span a {
  color: #666 !important;
}
.giftcardterms-conditions .staticHeader .static_para p {
  margin-bottom: 15px;
}

/*Faqs*/
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
  background: #fafafa;
  border: 1px solid #d6d6d6;
  padding: 20px;
  width: auto;
  float: left;
  margin: 23px 0 0 0;
}
@media screen and (max-width: 768px) {
  .rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
  .rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
    border: 0px;
    background-color: #fff;
    padding: 20px 0;
    width: 100%;
  }
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
  background: #fff;
  border: 1px solid #d6d6d6;
  display: block !important;
  margin-top: 20px;
  padding: 10px;
  width: 710px;
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a {
  color: #666;
}
@media screen and (max-width: 768px) {
  .rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,
  .rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
    border: none;
    padding: 10px 15px;
    width: auto;
  }
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul {
  margin-left: 20px;
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li {
  list-style: circle;
  line-height: 15px;
  font-size: 12px;
  color: #000;
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p {
  padding-bottom: 7px;
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 900;
  color: #666;
}
@media screen and (max-width: 768px) {
  .rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,
  .rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
    font-weight: normal;
    line-height: 30px;
    color: #333;
  }
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title {
  font-weight: 900;
  font-size: 12px;
  color: #000;
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
  background-color: #d6d6d6;
  clear: both;
  display: block;
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,
  .rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
    display: none;
  }
}
.rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,
.rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
}

.rightColumn .faqsandhelp h1 {
  margin-top: 0;
}

.rightColumn .placingorderfaqs h1 {
  margin-top: 0;
}

.rightColumn .deliveryfaqs .staticHeader h1 {
  padding: 0 !important;
}
@media screen and (max-width: 768px) {
  .rightColumn .deliveryfaqs .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px !important;
    font-size: 15px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }
}

.mb_back_to_top {
  float: right;
  color: #5d5d5d;
  font-size: 12px;
  text-decoration: underline;
}
.mb_back_to_top:before {
  border-left: 5px solid transparent;
  border-bottom: 8px solid;
  border-right: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: 1px;
  margin-right: 5px;
  margin-top: -8px;
  vertical-align: baseline;
  visibility: visible;
}
@media screen and (max-width: 768px) {
  .mb_back_to_top:before {
    border-top: 4px solid transparent;
    border-left: 8px solid;
    border-bottom: 4px solid transparent;
  }
}
.mb_back_to_top:hover {
  color: #000;
}

#FAQ_links span.title {
  font-weight: 900;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  #FAQ_links span.title {
    font-size: 12px;
    color: #333;
    font-weight: normal;
    line-height: 25px;
    padding-bottom: 10px;
  }
}

/*terms& conditions */
.terms-conditions .staticHeader .static_para {
  margin-top: 0px;
}
.terms-conditions .staticHeader .static_para p {
  padding: 10px 0px;
}

/* Promotional Terms & Conditions */
.promotionalterms-conditions .staticHeader .mb_contentblock_landing div p {
  padding-bottom: 17px;
}

/* Placing an Order trade enquire */
.tradeenquiry .staticHeader .static_para p a {
  color: #666666;
}

/* delivery and returns */
span p a {
  color: #666;
}

.staticsubheader {
  padding-bottom: 13px;
}

.rightColumn .delivery-returns .staticHeader .static_para ul li {
  list-style: circle;
  margin-left: 15px;
  color: #000000;
}

a:link, a {
  color: #666;
}

/*--affiliate -- */
.affiliate-enquiry-page .amp-dc-block-wrap:after {
  clear: both;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
  width: 964px;
  height: 372px;
  margin-bottom: 30px;
  position: relative;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap {
  height: 210px;
  width: 427px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 20%;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap .amp-dc-banner-info {
  display: inline-block;
  /* vertical-align: middle; */
  width: 75%;
  margin: 24px 55px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
    width: 100%;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap {
  position: relative;
  height: auto;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin-top: 0px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  width: 964px;
  height: 372px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
    width: 100%;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  top: 25%;
  width: 427px;
  height: 210px;
  left: 3%;
  right: 3%;
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
    width: 94%;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
  font-size: 28px;
  line-height: 35px;
  padding: 15px 65px;
  font-style: italic;
  font-family: "Times New Roman", Times, serif !important;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
    padding: 15px 10px;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
  color: #1c1c1c;
  font-size: 20px;
  line-height: 25px;
  margin: 0px 0 25px 0;
  padding: 0px 65px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
    padding: 0px 10px;
    font-size: 14px;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
  width: 365px;
  height: 237px;
  border: 4px solid #4fb9c9;
  padding: 28px 10px 28px 60px;
  float: right;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
    width: 100%;
    margin: 0;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 h2 i {
  font-family: "Times New Roman", Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li:first-child {
  font-weight: 700;
  margin-top: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
  background: #f9f9f9;
  width: 365px;
  height: 237px;
  padding: 32px 10px 28px 60px;
  float: left;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
    width: 100%;
    margin: 15px 0 0;
    height: auto;
  }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 h2 i {
  font-family: "Times New Roman", Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li:first-child {
  margin-top: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .note {
  margin-top: 30px !important;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
  margin-top: 30px !important;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 {
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p {
  color: #1c1c1c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 17px;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .mail-link, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .mail-link {
  color: #1c1c1c !important;
  font-size: 14px;
  font-weight: 700;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
  background-color: #c74782;
  color: #ffffff !important;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  padding: 10px 20px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
    width: 100%;
    text-align: center;
  }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button:after, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2 i, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 i {
  font-family: "Times New Roman", Times, serif !important;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part {
  padding: 0px;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with {
  margin-top: 15px !important;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with h2 {
  font-family: "Times New Roman", Times, serif !important;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
  font-style: italic;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
  margin-top: 15px !important;
  width: 346.8px;
  height: 46.53px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
    width: 100%;
    height: auto;
  }
}

/* affiliate --End */
/* bespoke-hotel-amenitiespage */
.bespoke-hotel-amenitiespage {
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .bespoke-hotel-amenitiespage {
    padding-left: 0px;
  }
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-page-left-section, .bespoke-hotel-amenitiespage .WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-page-left-section .HotelS_heading, .bespoke-hotel-amenitiespage .WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: 700;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-01 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: 10px auto;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap {
  position: relative;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 218px;
  margin: auto;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  height: inherit;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  width: 292px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.mb_address {
  margin-top: 10px;
  padding-top: 7px;
}

.contactdetails {
  margin: 0px !important;
}
.contactdetails .HotelS_text-orange {
  color: #eb6909;
  margin-top: 32px;
  font-size: 13px !important;
}

.hotelproducts-page {
  width: 752px;
}
.hotelproducts-page .amp-dc-splitBlock {
  border: 1px solid #d6d6d6;
  margin-top: 20px;
  padding: 10px;
  width: 752px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 {
  width: 448px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 .hotel-collections-description {
  width: 448px;
  margin: 0;
  padding: 0 0 0 12px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 .hotel-collections-description .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: 700;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 .hotel-collections-description .HotelS_text {
  margin: 10px 0 20px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 .hotel-collections-description a {
  font-size: 13px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 {
  width: 277px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
  height: 172px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap .amp-dc-image {
  height: inherit;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap img {
  width: 100%;
  height: inherit;
}
@media screen and (max-width: 768px) {
  .hotelproducts-page {
    width: 100%;
  }
  .hotelproducts-page .amp-dc-splitBlock, .hotelproducts-page .hotel-collections-description, .hotelproducts-page .amp-dc-size-60 {
    width: 100% !important;
  }
  .hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40, .hotelproducts-page .hotel-collections-description .amp-dc-size-40, .hotelproducts-page .amp-dc-size-60 .amp-dc-size-40 {
    margin: auto;
  }
}

/* hand care*/
.hotelproducts .eledescription, .hotelproducts-page .eledescription {
  margin-top: 20px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child {
  position: relative;
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap {
  padding: 0px;
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
  background-color: #941e7e;
  padding-top: 10px;
  color: #b5c437 !important;
  height: 161px;
  width: 410px;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
  position: absolute;
  bottom: 35px;
  right: 50px;
  color: #b5c437 !important;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
    bottom: 10px;
  }
}
.hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
  margin: 0px;
  width: 408px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}
.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  margin: auto;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
    width: 100%;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 {
  position: relative;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
    height: auto;
  }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: rgba(119, 118, 116, 0.831372549);
  color: #fff !important;
  width: 100%;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
    flex-direction: column;
  }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
  width: 220px;
  height: 202px;
}
@media screen and (max-width: 480px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
    margin: 10px auto;
    width: 100%;
    height: auto;
  }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  position: relative;
  margin: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin: 0px;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: rgba(119, 118, 116, 0.831372549);
  color: #fff !important;
  width: 100%;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
  width: 220px;
  height: 202px;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
  }
}

.hotel-collections {
  width: 770px;
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .hotel-collections {
    width: 100%;
    padding-left: 0px;
  }
}
.hotel-collections .amp-dc-splitBlock {
  padding: 10px;
  border: 1px solid #d6d6d6;
  margin-top: 20px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 {
  width: 125px;
  height: 125px;
  padding: 2px;
  border: 1px solid #d6d6d6;
  margin: auto;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
  height: 120px;
  width: 120px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description {
  margin-top: 10px;
  padding-bottom: 7px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p.title {
  margin-top: 10px;
  font-size: 14.1px;
  padding-bottom: 7px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p {
  margin-top: 10px;
}

.hotel-collections.collections1973 .hotelproducts .staticHeader p {
  margin-top: 20px;
}

.whymoltonbrown {
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .whymoltonbrown {
    padding-left: 0px;
  }
}

.WhyMoltonBrown-main-text {
  margin: 0px !important;
}
.WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}
.WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: 700;
}
.WhyMoltonBrown-main-text .leftContent img {
  width: 130px;
  height: 78px;
}

aside {
  width: 194px;
  border: 0;
  text-align: left;
  padding: 4px 6px 20px 15px;
}
aside h3.facet_header {
  font-size: 14px;
  line-height: 30px;
  color: #666;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left !important;
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  aside h3.facet_header {
    display: none !important;
  }
}

.whymoltonbrown-right-section .amp-dc-block-wrap {
  width: 292px;
  margin: auto;
}
.whymoltonbrown-right-section .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}
.whymoltonbrown-right-section .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: auto;
}
.whymoltonbrown-right-section .bespoke-right-card-02 {
  position: relative;
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
  }
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: rgba(119, 118, 116, 0.831372549);
  color: #fff !important;
  width: 100%;
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff;
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.sale-banner {
  width: 762px;
  height: 380px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .sale-banner {
    width: 100%;
    height: auto;
  }
}
.sale-banner .amp-dc-banner-info-wrap {
  width: 100%;
}
.sale-banner .amp-dc-banner-info-wrap .amp-dc-banner-info {
  margin: auto;
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .sale-banner .amp-dc-banner-info-wrap .amp-dc-banner-info {
    margin-left: 20px;
    position: absolute;
  }
}
.sale-banner .amp-dc-banner-info-wrap .amp-dc-banner-info .amp-dc-banner-header {
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 85px;
  line-height: 90px;
  font-weight: normal;
}
.sale-banner .amp-dc-banner-info-wrap .amp-dc-banner-info .amp-dc-banner-subheader {
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
}
.sale-banner .amp-dc-banner-info-wrap .amp-dc-banner-info .amp-dc-banner-description {
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}

.careers h1 {
  padding-left: 18px;
}
.careers .spaHotelsCareers3900026 {
  margin-top: 40px;
}
.careers .spaHotelsCareers3900026 p {
  margin-top: 20px;
  padding-bottom: 7px;
  padding-left: 18px;
}
.careers .spaHotelsCareers3900026 .one {
  margin-top: 20px;
}
.careers .spapage {
  display: flex;
  padding: 42px 18px 18px 18px;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .careers .spapage {
    flex-direction: column;
  }
  .careers .spapage div {
    width: 100% !important;
    height: auto !important;
  }
}
.careers .spapage p {
  padding-top: 20px;
}
.careers .spapage a {
  text-decoration: underline;
}
.careers .spapage div {
  width: 220px;
  height: 202px;
  padding: 5px;
  border: #d6d6d6 1px solid;
  margin-top: 10px;
}

.ourheritagepage .ourheritageheaderblock {
  margin: 0;
}
.ourheritagepage .ourheritageheaderblock .head-section {
  font-family: "Times New Roman", Times, serif;
  color: #000;
  margin: auto;
  text-align: center;
  width: 425px !important;
}
@media screen and (max-width: 768px) {
  .ourheritagepage .ourheritageheaderblock .head-section {
    width: 100% !important;
  }
}
.ourheritagepage .ourheritageheaderblock .head-section .main-title {
  font-size: 25px;
  margin: 30px 0 3px;
  padding: 0;
  font-weight: 100;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
}
.ourheritagepage .ourheritageheaderblock .head-section .sub-title {
  font-size: 22px;
  font-style: italic;
  margin: 0 0 15px;
  font-weight: 100;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion {
  margin-bottom: 15px;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle {
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000000;
  padding-top: 3px;
  margin: auto;
  font-family: "Times New Roman", Times, serif;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle span {
  display: block;
  font-size: 14px;
}
.ourheritagepage .ourheritageheaderblock .head-section .font14 {
  width: 425px;
  font-family: "Times New Roman", Times, serif !important;
  font-size: 14px;
  margin: auto;
}
@media screen and (max-width: 425px) {
  .ourheritagepage .ourheritageheaderblock .head-section .font14 {
    width: 100% !important;
  }
}
.ourheritagepage .amp-dc-splitBlock {
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .ourheritagepage .amp-dc-splitBlock {
    flex-direction: column;
    margin-top: 0px;
  }
}
.ourheritagepage .amp-dc-splitBlock .heritagepage-image-01 {
  width: 422px;
  height: 285px;
}
@media screen and (max-width: 768px) {
  .ourheritagepage .amp-dc-splitBlock .heritagepage-image-01 {
    width: 100%;
    height: auto;
  }
}
.ourheritagepage .amp-dc-splitBlock .Ourheritage-text {
  color: #000;
  padding-bottom: 30px;
}
.ourheritagepage .amp-dc-splitBlock .Ourheritage-text h3 {
  font-style: italic;
  font-size: 22px;
  font-family: "Times New Roman", Times, serif;
}
.ourheritagepage .amp-dc-splitBlock .Ourheritage-text p {
  margin-top: 16px;
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-cards {
  width: 422px;
}
@media screen and (max-width: 768px) {
  .ourheritagepage .amp-dc-splitBlock .ourheritage-cards {
    width: 100%;
  }
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-cards .amp-dc-card-img-wrap {
  margin-top: 0px;
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-cards .amp-dc-card-text-wrap {
  padding: 0 17px;
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-cards .amp-dc-card-text-wrap .amp-dc-card-name {
  margin-top: 24px;
  font-size: 22px;
  font-style: italic;
  text-align: left;
  font-family: "Times New Roman", Times, serif;
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-cards .amp-dc-card-text-wrap .amp-dc-card-description {
  margin-top: 16px;
  font-size: 14px;
  text-align: left;
  line-height: 1.2;
  color: #000;
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-card-3 .amp-dc-card-text-wrap {
  display: block;
  padding: 0 75px 0 75px;
  position: relative;
  border: none;
  line-height: 100%;
  font-style: italic;
  text-align: left;
  margin-top: 40px;
  line-height: 1.2;
}
@media screen and (max-width: 425px) {
  .ourheritagepage .amp-dc-splitBlock .ourheritage-card-3 .amp-dc-card-text-wrap {
    padding: 0 15px;
  }
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-card-3 .amp-dc-card-text-wrap .amp-dc-card-name {
  font-size: 40px;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  padding-bottom: 12px;
}
@media screen and (max-width: 425px) {
  .ourheritagepage .amp-dc-splitBlock .ourheritage-card-3 .amp-dc-card-text-wrap .amp-dc-card-name {
    font-size: 23px !important;
  }
}
.ourheritagepage .amp-dc-splitBlock .ourheritage-card-3 .amp-dc-card-text-wrap .amp-dc-card-description {
  width: 185px;
  color: #000;
  font-family: "Times New Roman", Times, serif;
}

.specialofferspage .special-offers-title h1 {
  text-align: center;
  font-size: 28px;
  padding: 0;
  margin: 20px 0;
  font-family: "Times New Roman", Times, serif;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-pic-wrap .amp-dc-image {
  width: 964px;
  height: 287px;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-pic-wrap .amp-dc-image img {
  width: inherit;
  height: inherit;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-info-wrap {
  width: 100%;
  height: 185px;
  position: relative;
  /* left: 0; */
  transform: translateX(-50%);
  left: 50%;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-info-wrap .amp-dc-banner-info {
  width: 100%;
  margin-left: 9px;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-info-wrap .amp-dc-banner-header {
  font-size: 20px;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-info-wrap .amp-dc-banner-subheader {
  font-size: 14px;
  font-weight: 700;
  padding-top: 8px;
  line-height: 1.2;
  text-transform: uppercase;
  font-style: normal;
}
.specialofferspage .special-offers-extrabanner-01 .amp-dc-banner-info-wrap .amp-dc-banner-description {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  padding-top: 8px;
}
.specialofferspage .special-offers-banner-01 {
  margin-bottom: 18px;
}
.specialofferspage .special-offers-banner-01 .amp-dc-banner-info-wrap {
  width: 368px;
  height: 178px;
  position: absolute;
  left: 15%;
  top: 5%;
}
.specialofferspage .special-offers-banner-01 .amp-dc-banner-info-wrap .amp-dc-banner-header {
  font-size: 20px;
}
.specialofferspage .special-offers-banner-01 .amp-dc-banner-info-wrap .amp-dc-banner-subheader {
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  padding-top: 10px;
}
.specialofferspage .special-offers-banner-01 .amp-dc-banner-info-wrap .amp-dc-banner-description {
  font-size: 14px;
  font-style: normal;
}
.specialofferspage .special-offers-banner-01 .amp-dc-banner-info-wrap .amp-dc-banner-button {
  margin-top: 20px;
}
.specialofferspage .specialoffersbanner .amp-dc-banner-info-wrap {
  position: absolute;
  right: 8% !important;
  left: auto;
}
.specialofferspage .specialoffersbanner .amp-dc-banner-info-wrap .amp-dc-banner-header {
  font-size: 30px;
}
.specialofferspage .specialoffersbanner .amp-dc-banner-info-wrap .amp-dc-banner-subheader {
  font-size: 30px;
  font-style: normal;
}
.specialofferspage .specialoffersbanner .amp-dc-banner-info-wrap .amp-dc-banner-description {
  font-size: 18px;
  font-style: normal;
}
.specialofferspage .specialoffersbanner .amp-dc-banner-button {
  margin-top: 20px;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 28px;
  padding: 0;
  margin: 20px 0;
  font-style: italic;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-img-wrap {
  margin: 0px;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
  position: relative;
  width: 308px;
  height: 338px;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  margin: 0;
  height: inherit;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-img-wrap {
  height: inherit;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap {
  position: absolute;
  top: 0px;
  color: #000;
  background-color: transparent;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-description {
  color: #000;
}
.specialofferspage .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link {
  position: inherit;
  bottom: -185px;
  left: 40%;
}
.specialofferspage .specialoffers-text {
  position: relative;
  top: -75px;
}
.specialofferspage .specialoffers-text .xxsmall-font {
  font-size: 12px;
  color: #000;
  list-style: none;
}
.specialofferspage .specialoffers-text .pos-absolute-terms-lower {
  position: absolute;
  right: 0;
  font-size: 12px;
  bottom: 0px;
}

.privacyPolicy .staticHeader .mb_contentblock_landing {
  counter-reset: h2;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list {
  margin: 0 0 13px;
  padding: 0 0 0 34px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list li {
  line-height: 15px;
  font-size: 12px;
  margin-left: 15px;
  padding-bottom: 5px;
  color: #000;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .para-left-padding {
  padding: 0 0 13px 34px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h2 {
  font-weight: 700;
  counter-reset: h3;
  margin: 0;
  padding: 5px 0 13px 0;
  font-size: 13px;
  color: #000 !important;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h2::before {
  counter-increment: h2;
  content: counter(h2) ". ";
  padding-right: 18px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h3 {
  padding: 5px 0 13px;
  font-size: 13px;
  color: #000;
  font-weight: 700;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h3::before {
  counter-increment: h3;
  content: counter(h2) "." counter(h3) ". ";
  padding-right: 8px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead {
  vertical-align: top;
  height: 30px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead th {
  color: #000 !important;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .staticsubheader {
  font-weight: 700;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc p {
  font-family: Arial, Helvetica, sans-serif;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc {
  border: 0 transparent;
  margin: 5px 0 13px 34px;
  width: 100%;
  border-collapse: collapse;
}

.download-brochure {
  padding-left: 18px;
}
.download-brochure .staticHeader {
  padding-bottom: 10px;
}
.download-brochure .brochure-blocks {
  margin: 24px 0 0 0;
  padding: 0;
}
.download-brochure .brochure-blocks .amp-dc-blog-title {
  text-align: left !important;
  font-size: 16px;
  color: #da007a;
  font-weight: 700;
}
.download-brochure .brochure-blocks .bg-pic-wrap .amp-dc-blog-content {
  margin-top: 10px;
}
.download-brochure .brochure-blocks .bg-pic-wrap .amp-dc-blog-content .amp-dc-splitBlock {
  display: block;
  padding: 13px 13px 0 0px;
  background-color: #D9D9D9;
  margin: 0 0 48px 0;
  width: 410px;
  height: 115px;
}
.download-brochure .brochure-blocks .amp-dc-split-part-wrap .amp-dc-image {
  width: 129px;
  height: 92px;
  float: right;
}
.download-brochure .brochure-blocks .amp-dc-split-part-wrap .amp-dc-image img {
  height: 100%;
}
.download-brochure .brochure-link strong {
  color: #464646;
  display: block;
}

.brochureblock {
  padding: 0 !important;
}

.mb_contentblock_landing.singleBlock td {
  border-top: 0 solid #d6d6d6;
  padding: 5px 5px 5px 0;
  vertical-align: top;
}

.impressum h1 {
  font-size: 28px;
}
.impressum .staticHeader {
  margin: auto;
}

.corporate-gifting {
  text-align: center;
  padding-top: 40px;
}
.corporate-gifting p {
  padding-top: 20px;
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
  line-height: 18px;
}
.corporate-gifting a {
  color: #000 !important;
}
.corporate-gifting a.download-brochure-link {
  background: #4d3938;
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
  color: #fff !important;
  padding: 10px 30px 10px 30px;
  display: inline-block;
  margin: 30px 0 33px;
  font-size: 16px;
  cursor: pointer;
  font-weight: normal;
}
.corporate-gifting .logo-image {
  width: 34%;
  margin: 22px 0 8px;
  display: inline-block;
}
.corporate-gifting .ascot-logo {
  width: 46%;
}
.corporate-gifting .rangers-logo {
  width: 100%;
  margin: 22px 0 0;
}
.corporate-gifting .en-mb-logo {
  width: 84%;
}
.corporate-gifting .gifting-para {
  width: 100%;
  padding-top: 0;
  font-size: 13px;
}
.corporate-gifting .partner-para {
  padding-bottom: 25px;
  width: 67%;
}

@media screen and (max-width: 768px) {
  .corporate-gifting .logo-image {
    width: 100%;
  }
  .corporate-gifting .th-mb-logo {
    width: 78%;
    padding-bottom: 25px;
  }
  .corporate-gifting .th-mb-logo, .corporate-gifting .rangers-logo, .corporate-gifting .en-mb-logo {
    width: 78%;
  }
  .corporate-gifting .en-mb-logo {
    padding-top: 25px;
  }
  .corporate-gifting .rangers-logo {
    margin: 0;
  }
  .corporate-gifting .gifting-para {
    width: 90%;
  }
}
.trebuchet span {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}

.termsconditions {
  color: #000 !important;
  font-size: 16px;
  font-weight: 700;
}

.onlyText {
  margin: 0 !important;
}

.delivery-returns .static_para p {
  padding-bottom: 7px;
  margin: 0 0 10px;
}

.delivery-returns .static_para p a {
  font-weight: 700 !important;
  color: #000 !important;
}

.delivery-returns .static_para strong a {
  font-weight: 700 !important;
  color: #464646 !important;
}

.delivery-returns .static_para p b, .delivery-returns .static_para p strong {
  font-weight: 700 !important;
  color: #260e03 !important;
}

.delivery-returns .static_para b {
  font-weight: 700 !important;
  color: #260e03 !important;
}

.delivery-returns .static_para p strong a {
  font-weight: 700 !important;
  color: #000000 !important;
  text-decoration: underline;
}

.delivery-returns .static_para strong a:link {
  color: #464646 !important;
  font-weight: normal;
}

.delivery-returns .static_para p {
  font-weight: 700 !important;
}

.delivery-returns .static_para p a:link {
  font-weight: 700 !important;
  color: #666666 !important;
}

.mb_contentblock_landing.singleBlock span strong {
  font-weight: 700 !important;
  color: #000 !important;
}

.mb_contentblock_landing.singleBlock span {
  color: #000 !important;
}

.staticsubheader p strong a {
  color: #000 !important;
}

.mb_contentblock_landing.singleBlock static_para {
  color: #260e03 !important;
}

div.sub-menu .amp-dc-card .amp-dc-card-link, .amp-dc-card.mm-208x208 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
}

.amp-dc-card .century-gothic-stack {
  font-family: century-gothic, Verdana, sans-serif !important;
}

.amp-dc-card .times-new-roman {
  font-family: "Times New Roman", Times, serif;
}

.amp-dc-card .amp-dc-card-subheader {
  font-size: 20px;
  color: #000;
  position: absolute;
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
}

.sub-menu .amp-dc-card .amp-dc-card-description {
  font-family: "Times New Roman", Times, serif !important;
}

.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap .amp-dc-card-description p, .amp-dc-card.journal-small-415 .amp-dc-card-text-wrap .amp-dc-card-description p, .amp-dc-card.journal-small-416 .amp-dc-card-text-wrap .amp-dc-card-description p, .amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description p {
  margin-bottom: 15px;
}

.amp-dc-card .amp-dc-card-description a, .amp-dc-card .amp-dc-card-description p, .amp-dc-card .amp-dc-card-description p a, .amp-dc-card .amp-dc-card-description p em, .amp-dc-card .amp-dc-card-description em, .amp-dc-card .amp-dc-card-description p strong {
  font-family: inherit;
  color: inherit;
}

.amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description a {
  text-decoration: underline;
}

.amp-dc-card .amp-dc-card-description blockquote {
  font-family: "Times New Roman", Times, serif !important;
  color: inherit;
}

.amp-dc-card .amp-dc-card-description blockquote h1, .amp-dc-card .amp-dc-card-description blockquote h2, .amp-dc-card .amp-dc-card-description blockquote h3, .amp-dc-card .amp-dc-card-description blockquote h4, .amp-dc-card .amp-dc-card-description blockquote h5, .amp-dc-card .amp-dc-card-description blockquote h6, .amp-dc-card .amp-dc-card-description blockquote p {
  font-family: "Times New Roman", Times, serif !important;
  color: inherit;
}

.amp-dc-card .amp-dc-card-description strong, .amp-dc-card .amp-dc-card-description strong a, .amp-dc-card .amp-dc-card-description strong p {
  text-transform: none;
  font-weight: bold;
}

.amp-dc-card .amp-dc-card-description h1, .amp-dc-card .amp-dc-card-description h2, .amp-dc-card .amp-dc-card-description h3, .amp-dc-card .amp-dc-card-description h4, .amp-dc-card .amp-dc-card-description h5 {
  font-family: inherit;
}

.amp-dc-card .amp-dc-card-description h2 {
  font-family: inherit;
  font-size: 30px;
  font-weight: 500;
}

.amp-dc-card .amp-dc-card-description h1, .amp-dc-card .amp-dc-card-description h2, .amp-dc-card .amp-dc-card-description h3, .amp-dc-card .amp-dc-card-description h4, .amp-dc-card .amp-dc-card-description h5, .amp-dc-card .amp-dc-card-description h6 {
  font-weight: 500;
}

.amp-dc-card .amp-dc-card-description h1 {
  font-size: 36px;
}

.amp-dc-card .amp-dc-card-description h2 {
  font-size: 30px;
}

.amp-dc-card .amp-dc-card-description h3 {
  font-size: 22px;
}

.amp-dc-card .amp-dc-card-description h4 {
  font-size: 18px;
}

.amp-dc-card .amp-dc-card-description h5 {
  font-size: 16px;
}

.amp-dc-card .amp-dc-card-description h6 {
  font-size: 14px;
}

.amp-dc-card .amp-dc-card-description em {
  font-family: inherit;
  font-size: inherit;
}

.amp-dc-card .amp-dc-card-description p {
  font-size: 14px;
}

.amp-dc-card .amp-dc-card-description h2 em {
  font-family: inherit;
}

.amp-dc-card .amp-dc-card-description h3 {
  font-family: inherit;
}

.amp-dc-card .video-player-ft {
  display: block;
  position: absolute !important;
  margin: 0 auto 0;
  top: 50%;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  z-index: 10;
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 35px;
  border: 7px solid rgba(255, 255, 255, 0.8);
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.amp-dc-card .video-player-ft .animated.infinite {
  /* -webkit-animation-iteration-count: infinite; */
  animation-iteration-count: infinite;
}

.amp-dc-card .video-player-ft .zoomIn {
  /* -webkit-animation-iteration-count: infinite; */
  webkit-animation-name: zoomIn;
}

.amp-dc-card .video-player-ft .animated {
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes zoomIn {
  from {
    opacity: 1;
    transform: scale3d(0.7, 0.7, 0.7);
  }
  50% {
    opacity: 1;
    transform: scale3d(0.9, 0.9, 0.9);
  }
  100% {
    opacity: 1;
    transform: scale3d(0.7, 0.7, 0.7);
  }
}
.amp-dc-card .video-player-ft .zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.amp-dc-card .video-player-ft .video-arrow {
  display: inline-block;
  margin: 2px 0 0 6px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-left: 34px solid rgba(255, 255, 255, 0.8);
  border-bottom: 18px solid transparent;
}

.time-duartion .time-circle {
  font-size: 10px;
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  margin: auto;
  font-family: "Times New Roman", Times, serif;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}
.time-duartion .time-circle span {
  display: block;
}

.time-circle span {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
}

.ImageOverlay {
  height: 24px;
  position: absolute !important;
  top: 0px;
  font-style: italic;
  width: 76.84px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.journal-main-574x385 .ImageOverlay {
  width: 106px;
  height: 35px;
  font-size: 14px;
}

.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.amp-dc-card .amp-dc-card-description blockquote p {
  font-style: italic;
}

.amp-dc-card .amp-dc-card-description blockquote::before {
  content: "“"; /*Unicode for Left Double Quote*/
  font-size: 189px;
  color: rgba(255, 255, 255, 0.2);
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  /*Positioning*/
  position: absolute;
  left: -49px;
  top: -38px;
  z-index: 1;
}

.amp-dc-card .amp-dc-card-description blockquote::before {
  color: rgba(0, 0, 0, 0.2);
  left: -10px;
  top: -26px;
}

.amp-dc-card .amp-dc-card-description blockquote::after {
  content: "";
}

blockquote {
  position: relative;
  padding: 0;
  border: none;
  font-size: 150%;
  margin-top: 24px;
  font-style: italic;
  text-align: left;
}

.amp-dc-card .time-duartion {
  padding-top: 6px;
}

.time-duartion .time-circle span {
  display: block;
}

.time-duartion .time-circle {
  padding-top: 4px;
  font-size: 10px;
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  margin: auto;
  font-family: "Times New Roman", Times, serif;
  line-height: 13px;
  font-style: italic;
  font-size: 14px;
}

.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.amp-dc-card {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.amp-dc-card * {
  margin: auto;
  padding: 0;
}

.amp-dc-card .amp-dc-hide {
  display: none;
}

.amp-dc-card .amp-dc-card-text-wrap {
  color: #000;
  text-align: center;
  background-color: #fff;
}

.amp-dc-card a.amp-dc-card-wrap {
  display: block;
  text-decoration: none;
}

.amp-dc-card a.amp-dc-card-wrap:hover .amp-dc-card-link {
  text-decoration: underline;
}

.amp-dc-card .amp-dc-card-name {
  white-space: pre-wrap;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}

.amp-dc-card .amp-dc-card-description {
  font-size: 14px;
  line-height: 1.2;
  display: block;
  color: #000;
}

.amp-dc-card .amp-dc-card-link {
  font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  color: #000;
  font-family: Times New Roman;
}

.amp-dc-external-block .test {
  padding: 20px;
  background: #efefef;
  border-radius: 5px;
  margin: 20px;
  min-width: 360px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.amp-dc-image {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.amp-dc-image .amp-dc-image-pic {
  display: block;
}

.amp-dc-image img {
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.amp-dc-card .amp-dc-card-description p {
  font-family: inherit;
}

.amp-dc-card.hotel-right-292x161 {
  margin-bottom: 15px;
}

/*special offers small banner */
.amp-dc-card.special-offers-308x330 {
  margin: auto;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-text-wrap {
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  height: 100%;
  top: 0;
  width: 100%;
  background: transparent;
  position: absolute !important;
  color: #000;
  margin: auto;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-link {
  left: 28%;
  right: 28%;
  bottom: 20px !important;
  margin: 0;
  color: #fff !important;
  background-color: #000;
  position: absolute;
  left: unset !important;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-name {
  position: absolute !important;
  left: 0px;
  right: 0px;
  margin-top: 25px;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-subheader {
  left: 0px;
  right: 0px;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-description {
  margin-top: 65px;
  left: 0px;
  right: 0px;
  position: absolute;
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-description p {
  font-size: 16px;
}

/* homepage small banners */
.amp-dc-card.hp-small-431x291 .amp-dc-card-name, .amp-dc-card.hp-small-431x291-poi .amp-dc-card-name {
  font-size: 18px;
  font-weight: 700;
}

.amp-dc-card.hp-small-431x291 .amp-dc-card-description {
  font-style: italic;
  font-size: 14px;
  padding-bottom: 12px;
  line-height: 18px;
}

.amp-dc-card.hp-small-431x291-poi .amp-dc-card-description {
  font-style: italic;
  font-size: 14px;
  padding-bottom: 12px;
  line-height: 18px;
}

.amp-dc-card.hp-small-431x291-poi .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
  border: 2px solid #000;
  color: #000 !important;
  background-color: #fff;
  font-size: 16px;
  height: 36px;
}

.amp-dc-card.hp-small-431x291 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
  border: 2px solid #000;
  color: #000 !important;
  background-color: #fff;
  font-size: 16px;
  height: 36px;
}

.amp-dc-card.hp-small-431x291 .amp-dc-card-link:hover {
  background-color: #000 !important;
}

.amp-dc-card.hp-small-431x291-poi .amp-dc-card-link:hover {
  background-color: #000 !important;
}

.journal-small-270x181 .ImageOverlay {
  font-size: 10px;
}

.amp-dc-card.journal-small-270x181 {
  padding-top: 0;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  text-transform: uppercase;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-description {
  font-style: italic;
  line-height: 20.94px;
  font-size: 14px;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-description h3 {
  line-height: 1.2;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-link {
  font-size: 16px;
  height: 36px;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-description p::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: " ";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 7px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-hp-product-img .amp-dc-card-link {
  white-space: initial;
  padding: 0;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-description a {
  text-decoration: none;
}

.amp-dc-card.journal-hp-product-img .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 7px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-description p::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: " ";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 6px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-img-wrap {
  position: initial !important;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-description h3 {
  line-height: 1.2;
  padding: 7px 0 13px;
}

.amp-dc-card.journal-main-574x385 {
  padding-top: 0;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img {
  width: 291px !important;
  height: auto;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-perfumers-img-291x291 {
  width: 291px !important;
  height: auto;
}

.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description h4::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description p em {
  line-height: 2.1;
}

.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description p a {
  text-decoration: underline;
}

/* homepage small banners */
/* about us page small banner*/
.amp-dc-card.about-us-165x166 .amp-dc-card-name {
  display: none;
}

.about-us-165x166 {
  width: 165px;
  height: auto;
}

.amp-dc-card.about-us-165x166 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.amp-dc-card.about-us-165x166 .amp-dc-card-description {
  display: none;
}

.amp-dc-card.about-us-165x166 .amp-dc-card-link {
  padding: 0;
  line-height: 15px;
  background-color: unset;
  color: #fff !important;
  font-size: 12px;
  white-space: nowrap;
}

.amp-dc-card.about-us-165x166 .amp-dc-card-link::after {
  color: #fff;
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

/* megamenu cards */
.amp-dc-card.mm-208x208 .amp-dc-card-description, .amp-dc-card.mm-208x208 .amp-dc-card-description p, .amp-dc-card.mm-208x208 .amp-dc-card-description p em, .amp-dc-card.mm-208x208 .amp-dc-card-description em p {
  font-family: Times New Roman !important;
}

.image-208x208 {
  width: 208px;
  height: auto;
}

.amp-dc-card.mm-208x208 .amp-dc-card-name {
  font-size: 14px !important;
  font-weight: normal !important;
}

.amp-dc-card.mm-208x208 .amp-dc-card-link {
  background: unset;
  font-size: 14px;
  color: #000;
  text-decoration: underline;
}

/* hotel aminities cards */
.amp-dc-card.hotel-bottom-220x202 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  padding: 10px;
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  left: 0;
  padding: 8px !important;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}

.amp-dc-card.hotel-bottom-220x202 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif;
  padding: 0;
  background-color: unset;
  color: #8f8f8f;
  font-size: 12px;
  white-space: nowrap;
}

.amp-dc-card.hotel-right-293x218 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute !important;
  bottom: 0 !important;
  right: 0;
  left: 0;
  padding: 8px !important;
  background-color: rgba(0, 0, 0, 0.5411764706) !important;
}

.amp-dc-card.hotel-right-293x218 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
  padding: 0;
  background-color: unset;
  color: #fff !important;
  font-size: 16px;
  white-space: nowrap;
}

/* journal category and homepage cards */
.amp-dc-card.journal-small-270x181 .amp-dc-card-wrap {
  display: block;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-img-wrap {
  position: initial !important;
}

.journal-small-270x181 .ImageOverlay {
  font-size: 10px;
}

.amp-dc-card-img-wrap {
  position: relative;
}

/* about us main banner */
.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
  font-size: 23px;
  color: #fff !important;
  padding: 0 0 14px 0;
}

.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description p {
  font-size: 13px;
  color: #fff !important;
}

.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  width: 268px !important;
  background-color: #2c2d2d;
  height: 370px;
  position: absolute;
  opacity: 0.7;
  text-align: left !important;
  top: 34px;
  padding: 10px;
  left: 20px;
}

/*journal page product image */
.amp-dc-card.journal-product-212 {
  width: 212px;
  height: auto;
}

.amp-dc-card.journal-product-212 .amp-dc-card-link {
  background: transparent !important;
  color: #000 !important;
}

.amp-dc-card.journal-product-212 .amp-dc-card-link {
  font-style: italic;
}

.amp-dc-card.journal-product-212 .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-product-212 .amp-dc-card-description p a {
  color: #000;
  text-decoration: none;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-name {
  font-size: 25px;
  line-height: 1.2;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-description a {
  color: #000;
  text-decoration: none;
}

/* frag-hub */
.amp-dc-card.frag-hub-two-banners-620 {
  width: 620px;
  height: auto;
}

.amp-dc-card.frag-hub-two-banners-620 .amp-dc-card-name {
  padding-top: 10px;
  font-size: 20px;
}

.amp-dc-card.frag-hub-two-banners-620 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
  border: 2px solid #000;
  color: #000 !important;
  background-color: #fff;
  font-size: 16px;
}

.amp-dc-card.frag-hub-product-icons-img .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif !important;
  border: 2px solid #000;
  color: #000 !important;
  background-color: #fff;
  font-size: 16px;
}

.amp-dc-card.frag-hub-product-icons-img {
  width: 291px;
  height: auto;
}

/* more stories*/
.amp-dc-card.journal-more-stories-img {
  width: 316px;
  height: auto;
}

.amp-dc-card.journal-more-stories-img .amp-dc-card-name {
  margin-bottom: 0;
}

.amp-dc-card.journal-more-stories-img .amp-dc-card-description strong a::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}

.amp-dc-card.journal-more-stories-img .amp-dc-card-description p {
  line-height: 1.2;
}

.amp-dc-card.journal-more-stories-img .amp-dc-card-description em {
  line-height: 1.2;
}

/* journal cards */
@media screen and (min-width: 769px) {
  .amp-dc-card.journal-small-415 blockquote p {
    padding: 44px 58px 0 50px;
    line-height: 1.2;
  }
  .amp-dc-card.journal-small-415 blockquote p {
    padding: 44px 58px 0 50px;
    line-height: 1.2;
  }
}
.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap .amp-dc-card-description, .amp-dc-card.journal-small-415 .amp-dc-card-text-wrap .amp-dc-card-description, .amp-dc-card.journal-small-416 .amp-dc-card-text-wrap .amp-dc-card-description, .amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description {
  line-height: 1.3 !important;
}

.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-card.journal-small-415 .amp-dc-card-text-wrap {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-card.journal-small-416 .amp-dc-card-text-wrap {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-card.journal-small-416 .amp-dc-card-text-wrap {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-card.journal-small-417 .amp-dc-card-text-wrap {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-card.journal-small-414 .amp-dc-card-description {
  text-align: left;
}

.amp-dc-card.journal-small-415 .amp-dc-card-description {
  text-align: left;
}

.amp-dc-card.journal-small-416 .amp-dc-card-description {
  text-align: left;
}

.amp-dc-card.journal-small-417 .amp-dc-card-description {
  text-align: left;
}

.amp-dc-card.journal-small-422 .amp-dc-card-description {
  text-align: left;
}

/*journal cards*/
@media screen and (min-width: 769px) {
  .amp-dc-card.journal-main-574x385 .amp-dc-card-img-wrap {
    float: left;
    width: 574px;
    height: 386px;
  }
  .amp-dc-card.journal-main-574x385 .time-duartion {
    float: right;
    width: 304px;
  }
  .amp-dc-card.journal-main-574x385 .amp-dc-card-text-wrap {
    padding: 10px;
    margin-top: 100px;
    width: 304px;
    float: right;
  }
}
/* card sizes */
.amp-dc-card.special-offers-308x330 {
  width: 308px;
  height: 338px !important;
}

.amp-dc-card.hotel-collection-277x172 {
  width: 277px;
  height: auto;
}

.amp-dc-card.journal-img-729 {
  width: 729px;
  height: auto;
}

/*hotel product images*/
.amp-dc-card.hotel-product-120 {
  width: 120px;
  border: 1px solid #d6d6d6;
  height: auto;
}

/*about us bottom images*/
.amp-dc-card.about-us-165x166 {
  width: 165px;
  height: 166px;
}

/*about us main image */
.amp-dc-card.about-us-main-355x478 {
  width: 355px;
  height: 478px;
}

/*mega menu images*/
.amp-dc-card.mm-208x208 {
  width: 208px;
  height: 208px;
}

/*Hotel aminities bottom images*/
.amp-dc-card.hotel-bottom-220x202 {
  width: 220px;
  height: auto;
}

/*Journal home,category page images*/
.amp-dc-card.journal-small-270x181 {
  width: 270px;
  margin-bottom: 10px;
  height: auto;
}

.amp-dc-card.journal-hp-product-img {
  width: 138px;
  height: auto;
}

.amp-dc-card.journal-main-574x385 {
  width: 100%;
  height: auto;
}

/*hotel aminities  images*/
.amp-dc-card.hotel-right-293x218 {
  width: 293px;
  height: auto;
}

.amp-dc-card.hotel-right-292x161 {
  width: 292px;
  height: auto;
}

/*about us main image */
.amp-dc-card.journal-product-212 {
  width: 212px;
  height: auto;
}

/*journal listcials,editorial, listical title and numericals*/
.amp-dc-card.journal-small-413 {
  width: 413px;
  height: auto;
}

.amp-dc-card.journal-small-414 {
  width: 414px;
  height: auto;
}

.amp-dc-card.journal-small-417 {
  width: 417px;
  height: auto;
}

.amp-dc-card.journal-small-415 {
  width: 415px;
  height: auto;
}

.amp-dc-card.journal-small-422 {
  width: 422px;
  height: auto;
}

/* home page images */
.amp-dc-card.hp-small-445x181 {
  width: 445px;
  height: auto;
}

.amp-dc-card.hp-small-431x291 {
  width: 431px;
  height: auto;
}

.amp-dc-card.hp-small-431x291-poi {
  width: 431px;
  height: auto;
}

.amp-dc-card.hp-small-431x291-poi {
  width: 431px;
  height: auto;
}

.amp-dc-card-list-wrap .amp-dc-external-block {
  width: 431px;
  display: inline-block;
  height: auto;
  position: relative;
  margin: 14px 29px 0;
}

.amp-dc-card-list-wrap .amp-dc-external-block video, .amp-dc-card-list-wrap .amp-dc-external-block img {
  width: 431px;
  height: auto;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .amp-dc-card.hp-small-431x291 {
    width: 50%;
    height: auto;
  }
  .amp-dc-card.hp-small-431x291-poi {
    width: 50%;
    height: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 50%;
    height: auto;
    margin: auto;
  }
}
@media screen and (max-width: 767px) {
  .amp-dc-card.hp-small-431x291 .amp-dc-card-text-wrap {
    width: 100%;
  }
  .amp-dc-card.hp-small-431x291-poi .amp-dc-card-text-wrap {
    width: 100%;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .amp-dc-card.hp-small-431x291 {
    width: 100% !important;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .amp-dc-card-list-wrap .amp-dc-external-block {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .amp-dc-card-list-wrap .amp-dc-external-block video, .amp-dc-card-list-wrap .amp-dc-external-block img {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .amp-dc-card.hp-small-431x291 .amp-dc-card-link {
    border: 2px solid #000;
    color: #fff !important;
    background-color: #000;
    font-size: 16px;
    height: 36px;
  }
  .amp-dc-card.hp-small-431x291-poi .amp-dc-card-link {
    border: 2px solid #000;
    color: #fff !important;
    background-color: #000;
    font-size: 16px;
    height: 36px;
  }
  .amp-dc-card .amp-dc-card-description blockquote {
    top: 10px;
  }
  .journal-small-270x181 .ImageOverlay, .journal-main-574x385 .ImageOverlay {
    font-size: 16px;
  }
  .amp-dc-card .amp-dc-card-description blockquote::before {
    left: -37px;
    top: -78px;
  }
  /*special offers */
  .amp-dc-card.special-offers-308x330 {
    width: 100%;
    height: auto !important;
  }
  /*hotel collections*/
  .amp-dc-card.hotel-collection-277x172 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-hp-product-img {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-img-729 {
    width: 100%;
    height: auto;
  }
  /*hotel products*/
  .amp-dc-card.hotel-product-120 {
    width: 120px;
    height: auto;
  }
  /*about us bottom banners*/
  .amp-dc-card.about-us-165x166 {
    width: 47% !important;
    height: auto;
  }
  /*about us main banner */
  .amp-dc-card.about-us-main-355x478 {
    width: 50%;
    height: auto;
    float: left;
  }
  /*Hotel aminities bottom banner*/
  .amp-dc-card.hotel-bottom-220x202 {
    width: 100% !important;
    height: auto !important;
  }
  /*Journal home,category page banner*/
  .amp-dc-card.journal-small-270x181 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-small-270x181 .amp-dc-card-description p {
    padding-left: 10%;
    padding-right: 10%;
  }
  .amp-dc-card.journal-main-574x385 .amp-dc-card-description p {
    padding-left: 12%;
    padding-right: 12%;
  }
  .amp-dc-card.journal-perfumers-img-291x291 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-main-574x385 {
    width: 100%;
    height: auto;
    padding-bottom: 15px;
  }
  /*hotel aminities  banners*/
  .amp-dc-card.hotel-right-293x218 {
    width: 96%;
    height: auto;
  }
  .amp-dc-card.hotel-right-292x161 {
    width: 96%;
    height: auto;
  }
  /*journal page prodcut banners */
  .amp-dc-card.journal-product-212 {
    width: 49%;
    height: 100%;
  }
  /*journal listcials,editorial, listical title and numericals*/
  .amp-dc-card.journal-small-413 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-small-414 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-small-417 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-small-415 {
    width: 100%;
    height: auto;
  }
  .amp-dc-card.journal-small-422 {
    width: 100%;
    height: auto;
  }
  /* More Stories*/
  .amp-dc-card.journal-more-stories-img {
    width: 92%;
    height: auto;
  }
  .amp-dc-card.frag-hub-two-banners-620 {
    width: 92%;
    height: auto;
  }
  .amp-dc-card.frag-hub-product-icons-img {
    width: auto;
    height: auto;
  }
  .amp-dc-card.perfumer-profile-shop-fragrances-img {
    width: 49% !important;
    height: auto;
  }
}
@media screen and (min-width: 1024px) {
  .amp-dc-card.hp-small-445x181 {
    width: 431px;
    height: auto;
  }
}
@media screen and (max-width: 1023px) {
  .amp-dc-card.hp-small-445x181 {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 480px) {
  .amp-dc-card.about-us-main-355x478 {
    width: 96% !important;
    height: auto;
    float: none;
  }
  .amp-dc-card.about-us-165x166 {
    width: 96% !important;
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  #cardListslot .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
    width: 50%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
  }
  #cardListslot .amp-dc-splitBlock {
    padding: 0px 6%;
    text-align: center;
    margin: 0;
    display: flex;
    padding: 0;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.journal-nav-bar ul.amp-dc-nav-wrap a:active {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .journal-nav-bar ul.amp-dc-nav-wrap {
    display: flex !important;
  }
  .journal-nav-bar ul.amp-dc-nav-wrap .navigation-link {
    font-size: 16px !important;
  }
  .journal-nav-bar ul.amp-dc-nav-wrap li {
    margin: auto;
  }
}
.journal-nav-bar ul.amp-dc-nav-wrap li {
  font-family: inherit;
}

.journal-nav-bar ul.amp-dc-nav-wrap .navigation-link {
  font-size: 18px;
  color: #000 !important;
  font-family: inherit;
}

@media screen and (min-width: 1024px) {
  .top-navigation-header.journal-nav-bar {
    width: 581.59px;
    margin: auto;
  }
}
.top-navigation-header.journal-nav-bar .amp-dc-nav-wrap {
  width: auto;
}

.top-navigation-header.journal-nav-bar .amp-dc-nav-wrap li {
  font-style: italic;
}

.amp-dc-navigation {
  text-align: center;
}

.amp-dc-navigation .Title {
  height: auto;
  line-height: 42px;
  width: 320px;
  margin: 25px auto;
  border-bottom: 1px solid #000;
  font-size: 62px;
}

ul.amp-dc-nav-wrap {
  margin: auto;
  display: flex;
  justify-content: space-around;
  height: 26px;
  align-items: center;
}

ul.amp-dc-nav-wrap li {
  font-size: 18px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
}

ul.amp-dc-nav-wrap li a {
  color: black;
  text-decoration: none;
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.dropdown-err {
  position: absolute;
  bottom: 100%;
  background-color: #b63432;
  color: #ffffff;
  padding: 10px;
  left: 6%;
  text-align: center;
  width: 88%;
  display: none;
  z-index: 9;
}
.dropdown-err::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: 0;
  left: 5%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #b63432;
  bottom: -7px;
}

.has-slct-error .select-selected {
  border: 1px solid #b63432 !important;
}

.select-selected {
  background-color: #fff;
  height: 48px;
  border: 1px solid #c2c2c2;
  border-radius: 0;
  background-image: url(/assets/imgs/select-box-right-bg.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #666;
  text-transform: none;
  font-size: 14px;
  top: 0px;
  min-width: 245px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .select-selected {
    min-width: 100% !important;
  }
}
.select-selected:after {
  position: absolute;
  content: "";
  border-color: #707070;
  border-top: 13px solid !important;
  border-right: 11px solid transparent !important;
  border-left: 11px solid transparent !important;
  border-bottom: 11px solid transparent !important;
  right: 10px;
  top: 40%;
}
.select-selected.select-arrow-active:after {
  border-color: #707070;
  border-top: 13px solid;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
.select-selected.has-error {
  border: 1px solid #b63432 !important;
}
.select-selected.has-error:focus ~ .dropdown-err {
  display: block !important;
}

.custom-country-select {
  width: 100%;
  display: block;
}

.select-items div, .select-selected {
  color: #000000;
  padding: 3px 0.75rem;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  min-width: 100%;
  z-index: 1035;
}
.select-items::-webkit-scrollbar {
  width: 2px;
}
.select-items::-webkit-scrollbar-thumb {
  background: #D9D9D9;
}
.select-items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-country-select {
  position: relative;
}
.custom-country-select select.form-control {
  margin: 0;
}

.custom-country-select .dropdown-menu {
  background-color: #ffffff !important;
  box-sizing: content-box;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  width: 100%;
}
@media (max-width: 450px) {
  .custom-country-select .dropdown-menu {
    min-width: 300px;
  }
}
.custom-country-select .dropdown-menu ul.inner {
  padding: 5px 7px 5px 10px;
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow-y: scroll;
}
.custom-country-select .dropdown-menu ul.inner::-webkit-scrollbar {
  width: 5px;
}
.custom-country-select .dropdown-menu ul.inner::-webkit-scrollbar-thumb {
  background: #D9D9D9;
}
.custom-country-select .dropdown-menu ul.inner::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom-country-select .dropdown-menu ul.inner li {
  position: relative;
}
.custom-country-select .dropdown-menu ul.inner li a {
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #262626;
  font-size: 14px;
  white-space: nowrap;
}
.custom-country-select .dropdown-menu ul.inner li a:hover {
  text-decoration: none;
  color: #262626;
  background-color: whitesmoke;
}
.custom-country-select .dropdown-menu.show {
  display: block;
}

.dropdown-container {
  width: 100%;
}

.custom-country-select select {
  visibility: hidden;
  height: 0px !important;
  width: 0px !important;
  padding: 0;
}

.dropdown, .dropleft, .dropright, .dropup {
  float: left;
}
@media (max-width: 768px) {
  .dropdown, .dropleft, .dropright, .dropup {
    float: none;
  }
}

.amp-list-num {
  display: block;
  position: relative;
  width: 100%;
}

.amp-list-num .amp-dc-image-pic {
  display: block;
  position: relative;
  width: 100%;
}

.amp-list-num .journal-intro-section {
  margin: auto;
  text-align: center;
  height: auto;
}

.amp-list-num .journal-intro-section .intro-title {
  font-family: "Times New Roman", Times, serif;
  font-size: 25px;
  font-weight: 100;
  color: #000;
  margin: 30px 0 0px;
}

.amp-list-num .journal-intro-section .intro-sub-title {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 22px;
  color: #000;
  font-weight: 100;
  margin: 0px 0 15px;
}

.amp-list-num .journal-intro-section .time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.amp-list-num .journal-intro-section .time-duartion .time-circle {
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  margin: auto;
  font-family: "Times New Roman", Times, serif;
  line-height: 13px;
  font-style: italic;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
}

.amp-list-num .journal-intro-section .time-duartion .time-circle span {
  display: block;
}

.amp-list-num .numerical-block .num-block-header {
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
  font-weight: 100;
  font-size: 22px;
}

.amp-list-num .numerical-block .num-desc {
  font-family: "Times New Roman", Times, serif;
}
.amp-list-num .numerical-block .num-desc p {
  line-height: 1.3;
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: initial;
  font-size: 14px;
  font-family: inherit;
}
.amp-list-num .numerical-block .num-right-sec-desc {
  font-family: "Times New Roman", Times, serif;
}
.amp-list-num .numerical-block .num-right-sec-desc p {
  line-height: 1.3;
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: initial;
  font-size: 14px;
  font-family: inherit;
}

.amp-list-num .journal-intro-section .intro-page-desc p {
  padding: 0 7%;
  font-family: "Times New Roman", Times, serif;
  color: #000;
}

.num-content-wrapper {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  margin: 0 auto;
  padding: 0;
}

.amp-list-num .list-num-main-block {
  margin-bottom: -180px;
  margin: 0 auto;
  text-align: center;
}

.amp-list-num .numerical-block .num-left-content, .amp-list-num .numerical-block .num-right-content {
  width: 50%;
  height: auto;
}

.amp-list-num .numerical-block {
  width: 100%;
  display: block;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .amp-list-num .numerical-block .num-left-content {
    padding-right: 15px;
  }
  .amp-list-num .numerical-block .num-right-content {
    padding-left: 15px;
  }
  .amp-list-num .num-content-wrapper {
    width: 878px;
  }
  .amp-list-num .numerical-block {
    width: 878px;
    margin-top: 25px;
    justify-content: center;
    display: flex;
    height: auto;
    padding: 0;
  }
  .amp-list-num .list-num-main-block {
    margin-bottom: -180px;
  }
}
@media screen and (max-width: 768px) {
  .amp-list-num .numerical-block .num-left-content, .amp-list-num .numerical-block .num-right-content {
    width: 100%;
  }
  .amp-list-num .numerical-block {
    padding: 6px;
  }
}
@media screen and (max-width: 1440px) {
  .prodct-block {
    width: 990px;
    margin: 0 auto;
    text-align: center;
  }
  .amp-list-num .list-num-main-block {
    margin-bottom: -100px !important;
  }
}
.prodct-block .shop-this-story-prdct {
  width: 24%;
  height: auto;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
}

.shop-this-story-prdct-link {
  margin-top: 20px;
  position: relative;
}

.shop-story-prodct-cta {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  text-transform: none;
  background-color: #fff;
}

.shop-story-prodct-cta::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.fab {
  display: inline-block;
  font: 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-instagram:before {
  font-family: "Font Awesome 5 Brands";
  content: "\f16d";
}

.fa-instagram {
  font-size: 40px;
  vertical-align: middle;
  margin-right: 4px;
}

.fa-instagram::before {
  content: "\f16d";
}

.sharingIcons {
  display: inline-block;
  height: 24px;
  width: 24px;
  text-indent: -9990px;
  vertical-align: middle;
  margin: 10px 10px 0 10px;
  border: none;
}

.social-section {
  padding-top: 30px;
}

.share-heading {
  font-size: 17px;
  margin: 0;
}

.social-container {
  padding-top: 16px;
}

.social-container.flex-align {
  height: inherit;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.fb-color {
  background: #3B5998;
}

.tw-color {
  background: #1da1f2;
}

.em-color {
  background: #f28d4f;
}

.social-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  text-decoration: none;
  color: #fff;
  height: 37px;
  position: relative;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  margin: 0 12px;
}
@media screen and (max-width: 768px) {
  .social-btn {
    min-width: 90px;
  }
}

.social-icons {
  display: inline-block;
  background: url("https://media.moltonbrown.co.uk/i/moltonbrown/socialIcon") no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  height: 25px;
}

.instagramlogo-mbgallery {
  width: 40px;
  padding-top: 5px;
}

div.bcc-footer {
  text-align: center;
  width: 100%;
}

div.bcc-footer.flex-align {
  height: inherit;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

div.bcc-footer.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.social-icons.icon-fb {
  background-size: 44px;
  width: 15px;
  background-position: -8px -51px;
}

.social-icons.icon-tw {
  background-size: 46px;
  width: 28px;
  background-position: -9px -23px;
}

.social-icons.icon-em {
  background-size: 46px;
  width: 33px;
  background-position: -7px 5px;
}

.editorial-carousel-wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}

.editoril-carousel {
  width: 90%;
  margin: 0px auto;
}

.slick-slide {
  margin: 10px;
}

.slick-slide img {
  width: 100%;
  border: 2px solid #fff;
}

.shop-story-prodct-description {
  font-size: 12px;
}

.shop-story-content {
  margin-top: 24px;
  padding: 0 16px;
}

.amp-dc-text blockquote {
  font-family: Didot, Georgia !important;
  position: relative;
  font-size: 150%;
  font-style: italic;
  text-align: left;
}
.amp-dc-text blockquote h3 {
  font-family: Didot, Georgia !important;
  padding: 0 87px 0 60px;
  text-align: left;
  font-size: 40px;
}
.amp-dc-text blockquote::before {
  content: "“";
  font-size: 189px;
  font-family: Didot, Georgia;
  font-style: italic;
  position: absolute;
  top: -56px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.2);
  left: -17px;
}
.amp-dc-text blockquote em {
  font-family: inherit;
  line-height: 1.2;
}

.times-new-roman {
  font-family: "Times New Roman", Times, serif !important;
}

.amp-dc-card .amp-dc-card-description {
  font-style: italic;
  font-family: Georgia;
}

.amp-list-num .amp-dc-card-img-wrap {
  margin-top: 20px;
}

.sop-sec-heading h2 {
  font-size: 25px;
}

.numerical-block {
  margin-top: 25px;
}

@media screen and (max-width: 1440px) {
  .prodct-block {
    width: 990px;
    margin: 0 auto;
    text-align: center;
  }
}
.prodct-block .shop-this-story-prdct {
  width: 24%;
  height: auto;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
}

.shop-this-story-prdct-link {
  margin-top: 20px;
  position: relative;
}

.shop-story-prodct-cta {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  text-transform: none;
  background-color: #fff;
}

.shop-story-prodct-cta::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.editorial-carousel-wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}

.editoril-carousel {
  width: 90%;
  margin: 0px auto;
}

.slick-slide {
  margin: 10px;
}

.slick-slide img {
  width: 100%;
  border: 2px solid #fff;
}

.shop-story-prodct-description {
  font-size: 12px;
  margin-top: 15px;
}

.num-right-sec-desc {
  margin-top: 25px;
}

.editorial-container blockquote {
  margin-top: 15px;
}

.shop-this-story-prdct-link {
  margin-top: 20px;
  position: relative;
}

.shop-story-prodct-cta {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  text-transform: none;
  background-color: #fff;
}

.shop-story-prodct-cta::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.editorial-carousel-wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}

.editoril-carousel {
  width: 90%;
  margin: 0px auto;
}

.slick-slide {
  margin: 10px;
}

.slick-slide img {
  width: 100%;
  border: 2px solid #fff;
}

.shop-story-prodct-description {
  font-size: 12px;
}

.amp-dc-card {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.amp-dc-card.journal-img-729 {
  width: 729px;
  height: auto;
}

.amp-dc-card .video-player-ft {
  display: block;
  position: absolute !important;
  margin: -36px auto 0 -36px;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 50%;
  border: 7px solid rgba(255, 255, 255, 0.8);
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.amp-dc-card .video-player-ft .animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.amp-dc-card .video-player-ft .animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.amp-dc-card .video-player-ft .video-arrow {
  display: inline-block;
  margin: 2px 0 0 6px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-left: 34px solid rgba(255, 255, 255, 0.8);
  border-bottom: 18px solid transparent;
}

.amp-dc-card .video-player-ft .animated {
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.amp-dc-card .video-player-ft .zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation: 0.5s bounce;
  animation: 0.5s bounce;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-repeat: no-repeat;
  background-position: left top;
}

.quote blockquote {
  font-family: Didot, Georgia !important;
  position: relative;
}

.quote blockquote::before {
  content: "“";
  font-size: 189px;
  font-family: Didot, Georgia;
  font-style: italic;
  position: absolute;
  top: -56px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.2);
  left: -17px;
}

.quote blockquote h2 {
  padding: 25px 58px 0 50px;
  font-size: 30px;
}

.quote blockquote em {
  line-height: 1.2;
  font-family: Didot, Georgia !important;
}

.journal-image-729 {
  width: 729px;
  margin: 0 auto;
}

.shop-story-prodct-name {
  height: 35px;
}

.shop-story-prodct-cta a {
  color: #000 !important;
}

.sop-sec-heading {
  text-align: center;
}

@media screen and (max-width: 874px) {
  .Editorial-heading-section .title {
    margin: 25px 0 0px;
  }
}
.shop-this-story {
  width: 874px;
  margin: 0 auto;
  margin-top: 25px;
}
.shop-this-story .perfumer-prodct-block {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.shop-this-story .perfumer-prodct-block .shop-this-story-prdct {
  width: 24.5%;
  height: auto;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
}

.font1 {
  font-size: 1px;
}

.padding-top-1 {
  padding-top: 1px;
}

.pt-1 {
  padding-top: 1px;
}

.margin-top-1 {
  margin-top: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mt-1 {
  margin-top: 1px;
}

.margin-bottom-1 {
  margin-top: 1px;
}

.font2 {
  font-size: 2px;
}

.padding-top-2 {
  padding-top: 2px;
}

.pt-2 {
  padding-top: 2px;
}

.margin-top-2 {
  margin-top: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mt-2 {
  margin-top: 2px;
}

.margin-bottom-2 {
  margin-top: 2px;
}

.font3 {
  font-size: 3px;
}

.padding-top-3 {
  padding-top: 3px;
}

.pt-3 {
  padding-top: 3px;
}

.margin-top-3 {
  margin-top: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mt-3 {
  margin-top: 3px;
}

.margin-bottom-3 {
  margin-top: 3px;
}

.font4 {
  font-size: 4px;
}

.padding-top-4 {
  padding-top: 4px;
}

.pt-4 {
  padding-top: 4px;
}

.margin-top-4 {
  margin-top: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-4 {
  margin-top: 4px;
}

.margin-bottom-4 {
  margin-top: 4px;
}

.font5 {
  font-size: 5px;
}

.padding-top-5 {
  padding-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-top: 5px;
}

.font6 {
  font-size: 6px;
}

.padding-top-6 {
  padding-top: 6px;
}

.pt-6 {
  padding-top: 6px;
}

.margin-top-6 {
  margin-top: 6px;
}

.ml-6 {
  margin-left: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.margin-bottom-6 {
  margin-top: 6px;
}

.font7 {
  font-size: 7px;
}

.padding-top-7 {
  padding-top: 7px;
}

.pt-7 {
  padding-top: 7px;
}

.margin-top-7 {
  margin-top: 7px;
}

.ml-7 {
  margin-left: 7px;
}

.mt-7 {
  margin-top: 7px;
}

.margin-bottom-7 {
  margin-top: 7px;
}

.font8 {
  font-size: 8px;
}

.padding-top-8 {
  padding-top: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.margin-top-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.margin-bottom-8 {
  margin-top: 8px;
}

.font9 {
  font-size: 9px;
}

.padding-top-9 {
  padding-top: 9px;
}

.pt-9 {
  padding-top: 9px;
}

.margin-top-9 {
  margin-top: 9px;
}

.ml-9 {
  margin-left: 9px;
}

.mt-9 {
  margin-top: 9px;
}

.margin-bottom-9 {
  margin-top: 9px;
}

.font10 {
  font-size: 10px;
}

.padding-top-10 {
  padding-top: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-top: 10px;
}

.font11 {
  font-size: 11px;
}

.padding-top-11 {
  padding-top: 11px;
}

.pt-11 {
  padding-top: 11px;
}

.margin-top-11 {
  margin-top: 11px;
}

.ml-11 {
  margin-left: 11px;
}

.mt-11 {
  margin-top: 11px;
}

.margin-bottom-11 {
  margin-top: 11px;
}

.font12 {
  font-size: 12px;
}

.padding-top-12 {
  padding-top: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.margin-top-12 {
  margin-top: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.margin-bottom-12 {
  margin-top: 12px;
}

.font13 {
  font-size: 13px;
}

.padding-top-13 {
  padding-top: 13px;
}

.pt-13 {
  padding-top: 13px;
}

.margin-top-13 {
  margin-top: 13px;
}

.ml-13 {
  margin-left: 13px;
}

.mt-13 {
  margin-top: 13px;
}

.margin-bottom-13 {
  margin-top: 13px;
}

.font14 {
  font-size: 14px;
}

.padding-top-14 {
  padding-top: 14px;
}

.pt-14 {
  padding-top: 14px;
}

.margin-top-14 {
  margin-top: 14px;
}

.ml-14 {
  margin-left: 14px;
}

.mt-14 {
  margin-top: 14px;
}

.margin-bottom-14 {
  margin-top: 14px;
}

.font15 {
  font-size: 15px;
}

.padding-top-15 {
  padding-top: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.margin-top-15 {
  margin-top: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.margin-bottom-15 {
  margin-top: 15px;
}

.font16 {
  font-size: 16px;
}

.padding-top-16 {
  padding-top: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.margin-top-16 {
  margin-top: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.margin-bottom-16 {
  margin-top: 16px;
}

.font17 {
  font-size: 17px;
}

.padding-top-17 {
  padding-top: 17px;
}

.pt-17 {
  padding-top: 17px;
}

.margin-top-17 {
  margin-top: 17px;
}

.ml-17 {
  margin-left: 17px;
}

.mt-17 {
  margin-top: 17px;
}

.margin-bottom-17 {
  margin-top: 17px;
}

.font18 {
  font-size: 18px;
}

.padding-top-18 {
  padding-top: 18px;
}

.pt-18 {
  padding-top: 18px;
}

.margin-top-18 {
  margin-top: 18px;
}

.ml-18 {
  margin-left: 18px;
}

.mt-18 {
  margin-top: 18px;
}

.margin-bottom-18 {
  margin-top: 18px;
}

.font19 {
  font-size: 19px;
}

.padding-top-19 {
  padding-top: 19px;
}

.pt-19 {
  padding-top: 19px;
}

.margin-top-19 {
  margin-top: 19px;
}

.ml-19 {
  margin-left: 19px;
}

.mt-19 {
  margin-top: 19px;
}

.margin-bottom-19 {
  margin-top: 19px;
}

.font20 {
  font-size: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-top: 20px;
}

.font21 {
  font-size: 21px;
}

.padding-top-21 {
  padding-top: 21px;
}

.pt-21 {
  padding-top: 21px;
}

.margin-top-21 {
  margin-top: 21px;
}

.ml-21 {
  margin-left: 21px;
}

.mt-21 {
  margin-top: 21px;
}

.margin-bottom-21 {
  margin-top: 21px;
}

.font22 {
  font-size: 22px;
}

.padding-top-22 {
  padding-top: 22px;
}

.pt-22 {
  padding-top: 22px;
}

.margin-top-22 {
  margin-top: 22px;
}

.ml-22 {
  margin-left: 22px;
}

.mt-22 {
  margin-top: 22px;
}

.margin-bottom-22 {
  margin-top: 22px;
}

.font23 {
  font-size: 23px;
}

.padding-top-23 {
  padding-top: 23px;
}

.pt-23 {
  padding-top: 23px;
}

.margin-top-23 {
  margin-top: 23px;
}

.ml-23 {
  margin-left: 23px;
}

.mt-23 {
  margin-top: 23px;
}

.margin-bottom-23 {
  margin-top: 23px;
}

.font24 {
  font-size: 24px;
}

.padding-top-24 {
  padding-top: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.margin-top-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.margin-bottom-24 {
  margin-top: 24px;
}

.font25 {
  font-size: 25px;
}

.padding-top-25 {
  padding-top: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.margin-top-25 {
  margin-top: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.margin-bottom-25 {
  margin-top: 25px;
}

.font26 {
  font-size: 26px;
}

.padding-top-26 {
  padding-top: 26px;
}

.pt-26 {
  padding-top: 26px;
}

.margin-top-26 {
  margin-top: 26px;
}

.ml-26 {
  margin-left: 26px;
}

.mt-26 {
  margin-top: 26px;
}

.margin-bottom-26 {
  margin-top: 26px;
}

.font27 {
  font-size: 27px;
}

.padding-top-27 {
  padding-top: 27px;
}

.pt-27 {
  padding-top: 27px;
}

.margin-top-27 {
  margin-top: 27px;
}

.ml-27 {
  margin-left: 27px;
}

.mt-27 {
  margin-top: 27px;
}

.margin-bottom-27 {
  margin-top: 27px;
}

.font28 {
  font-size: 28px;
}

.padding-top-28 {
  padding-top: 28px;
}

.pt-28 {
  padding-top: 28px;
}

.margin-top-28 {
  margin-top: 28px;
}

.ml-28 {
  margin-left: 28px;
}

.mt-28 {
  margin-top: 28px;
}

.margin-bottom-28 {
  margin-top: 28px;
}

.font29 {
  font-size: 29px;
}

.padding-top-29 {
  padding-top: 29px;
}

.pt-29 {
  padding-top: 29px;
}

.margin-top-29 {
  margin-top: 29px;
}

.ml-29 {
  margin-left: 29px;
}

.mt-29 {
  margin-top: 29px;
}

.margin-bottom-29 {
  margin-top: 29px;
}

.font30 {
  font-size: 30px;
}

.padding-top-30 {
  padding-top: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-top: 30px;
}

.font31 {
  font-size: 31px;
}

.padding-top-31 {
  padding-top: 31px;
}

.pt-31 {
  padding-top: 31px;
}

.margin-top-31 {
  margin-top: 31px;
}

.ml-31 {
  margin-left: 31px;
}

.mt-31 {
  margin-top: 31px;
}

.margin-bottom-31 {
  margin-top: 31px;
}

.font32 {
  font-size: 32px;
}

.padding-top-32 {
  padding-top: 32px;
}

.pt-32 {
  padding-top: 32px;
}

.margin-top-32 {
  margin-top: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.margin-bottom-32 {
  margin-top: 32px;
}

.font33 {
  font-size: 33px;
}

.padding-top-33 {
  padding-top: 33px;
}

.pt-33 {
  padding-top: 33px;
}

.margin-top-33 {
  margin-top: 33px;
}

.ml-33 {
  margin-left: 33px;
}

.mt-33 {
  margin-top: 33px;
}

.margin-bottom-33 {
  margin-top: 33px;
}

.font34 {
  font-size: 34px;
}

.padding-top-34 {
  padding-top: 34px;
}

.pt-34 {
  padding-top: 34px;
}

.margin-top-34 {
  margin-top: 34px;
}

.ml-34 {
  margin-left: 34px;
}

.mt-34 {
  margin-top: 34px;
}

.margin-bottom-34 {
  margin-top: 34px;
}

.font35 {
  font-size: 35px;
}

.padding-top-35 {
  padding-top: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.margin-top-35 {
  margin-top: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.margin-bottom-35 {
  margin-top: 35px;
}

.font36 {
  font-size: 36px;
}

.padding-top-36 {
  padding-top: 36px;
}

.pt-36 {
  padding-top: 36px;
}

.margin-top-36 {
  margin-top: 36px;
}

.ml-36 {
  margin-left: 36px;
}

.mt-36 {
  margin-top: 36px;
}

.margin-bottom-36 {
  margin-top: 36px;
}

.font37 {
  font-size: 37px;
}

.padding-top-37 {
  padding-top: 37px;
}

.pt-37 {
  padding-top: 37px;
}

.margin-top-37 {
  margin-top: 37px;
}

.ml-37 {
  margin-left: 37px;
}

.mt-37 {
  margin-top: 37px;
}

.margin-bottom-37 {
  margin-top: 37px;
}

.font38 {
  font-size: 38px;
}

.padding-top-38 {
  padding-top: 38px;
}

.pt-38 {
  padding-top: 38px;
}

.margin-top-38 {
  margin-top: 38px;
}

.ml-38 {
  margin-left: 38px;
}

.mt-38 {
  margin-top: 38px;
}

.margin-bottom-38 {
  margin-top: 38px;
}

.font39 {
  font-size: 39px;
}

.padding-top-39 {
  padding-top: 39px;
}

.pt-39 {
  padding-top: 39px;
}

.margin-top-39 {
  margin-top: 39px;
}

.ml-39 {
  margin-left: 39px;
}

.mt-39 {
  margin-top: 39px;
}

.margin-bottom-39 {
  margin-top: 39px;
}

.font40 {
  font-size: 40px;
}

.padding-top-40 {
  padding-top: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.margin-top-40 {
  margin-top: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.margin-bottom-40 {
  margin-top: 40px;
}

.font41 {
  font-size: 41px;
}

.padding-top-41 {
  padding-top: 41px;
}

.pt-41 {
  padding-top: 41px;
}

.margin-top-41 {
  margin-top: 41px;
}

.ml-41 {
  margin-left: 41px;
}

.mt-41 {
  margin-top: 41px;
}

.margin-bottom-41 {
  margin-top: 41px;
}

.font42 {
  font-size: 42px;
}

.padding-top-42 {
  padding-top: 42px;
}

.pt-42 {
  padding-top: 42px;
}

.margin-top-42 {
  margin-top: 42px;
}

.ml-42 {
  margin-left: 42px;
}

.mt-42 {
  margin-top: 42px;
}

.margin-bottom-42 {
  margin-top: 42px;
}

.font43 {
  font-size: 43px;
}

.padding-top-43 {
  padding-top: 43px;
}

.pt-43 {
  padding-top: 43px;
}

.margin-top-43 {
  margin-top: 43px;
}

.ml-43 {
  margin-left: 43px;
}

.mt-43 {
  margin-top: 43px;
}

.margin-bottom-43 {
  margin-top: 43px;
}

.font44 {
  font-size: 44px;
}

.padding-top-44 {
  padding-top: 44px;
}

.pt-44 {
  padding-top: 44px;
}

.margin-top-44 {
  margin-top: 44px;
}

.ml-44 {
  margin-left: 44px;
}

.mt-44 {
  margin-top: 44px;
}

.margin-bottom-44 {
  margin-top: 44px;
}

.font45 {
  font-size: 45px;
}

.padding-top-45 {
  padding-top: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.margin-top-45 {
  margin-top: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.margin-bottom-45 {
  margin-top: 45px;
}

.font46 {
  font-size: 46px;
}

.padding-top-46 {
  padding-top: 46px;
}

.pt-46 {
  padding-top: 46px;
}

.margin-top-46 {
  margin-top: 46px;
}

.ml-46 {
  margin-left: 46px;
}

.mt-46 {
  margin-top: 46px;
}

.margin-bottom-46 {
  margin-top: 46px;
}

.font47 {
  font-size: 47px;
}

.padding-top-47 {
  padding-top: 47px;
}

.pt-47 {
  padding-top: 47px;
}

.margin-top-47 {
  margin-top: 47px;
}

.ml-47 {
  margin-left: 47px;
}

.mt-47 {
  margin-top: 47px;
}

.margin-bottom-47 {
  margin-top: 47px;
}

.font48 {
  font-size: 48px;
}

.padding-top-48 {
  padding-top: 48px;
}

.pt-48 {
  padding-top: 48px;
}

.margin-top-48 {
  margin-top: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.margin-bottom-48 {
  margin-top: 48px;
}

.font49 {
  font-size: 49px;
}

.padding-top-49 {
  padding-top: 49px;
}

.pt-49 {
  padding-top: 49px;
}

.margin-top-49 {
  margin-top: 49px;
}

.ml-49 {
  margin-left: 49px;
}

.mt-49 {
  margin-top: 49px;
}

.margin-bottom-49 {
  margin-top: 49px;
}

.font50 {
  font-size: 50px;
}

.padding-top-50 {
  padding-top: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.margin-top-50 {
  margin-top: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-top: 50px;
}

.font51 {
  font-size: 51px;
}

.padding-top-51 {
  padding-top: 51px;
}

.pt-51 {
  padding-top: 51px;
}

.margin-top-51 {
  margin-top: 51px;
}

.ml-51 {
  margin-left: 51px;
}

.mt-51 {
  margin-top: 51px;
}

.margin-bottom-51 {
  margin-top: 51px;
}

.font52 {
  font-size: 52px;
}

.padding-top-52 {
  padding-top: 52px;
}

.pt-52 {
  padding-top: 52px;
}

.margin-top-52 {
  margin-top: 52px;
}

.ml-52 {
  margin-left: 52px;
}

.mt-52 {
  margin-top: 52px;
}

.margin-bottom-52 {
  margin-top: 52px;
}

.font53 {
  font-size: 53px;
}

.padding-top-53 {
  padding-top: 53px;
}

.pt-53 {
  padding-top: 53px;
}

.margin-top-53 {
  margin-top: 53px;
}

.ml-53 {
  margin-left: 53px;
}

.mt-53 {
  margin-top: 53px;
}

.margin-bottom-53 {
  margin-top: 53px;
}

.font54 {
  font-size: 54px;
}

.padding-top-54 {
  padding-top: 54px;
}

.pt-54 {
  padding-top: 54px;
}

.margin-top-54 {
  margin-top: 54px;
}

.ml-54 {
  margin-left: 54px;
}

.mt-54 {
  margin-top: 54px;
}

.margin-bottom-54 {
  margin-top: 54px;
}

.font55 {
  font-size: 55px;
}

.padding-top-55 {
  padding-top: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.margin-top-55 {
  margin-top: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.margin-bottom-55 {
  margin-top: 55px;
}

.font56 {
  font-size: 56px;
}

.padding-top-56 {
  padding-top: 56px;
}

.pt-56 {
  padding-top: 56px;
}

.margin-top-56 {
  margin-top: 56px;
}

.ml-56 {
  margin-left: 56px;
}

.mt-56 {
  margin-top: 56px;
}

.margin-bottom-56 {
  margin-top: 56px;
}

.font57 {
  font-size: 57px;
}

.padding-top-57 {
  padding-top: 57px;
}

.pt-57 {
  padding-top: 57px;
}

.margin-top-57 {
  margin-top: 57px;
}

.ml-57 {
  margin-left: 57px;
}

.mt-57 {
  margin-top: 57px;
}

.margin-bottom-57 {
  margin-top: 57px;
}

.font58 {
  font-size: 58px;
}

.padding-top-58 {
  padding-top: 58px;
}

.pt-58 {
  padding-top: 58px;
}

.margin-top-58 {
  margin-top: 58px;
}

.ml-58 {
  margin-left: 58px;
}

.mt-58 {
  margin-top: 58px;
}

.margin-bottom-58 {
  margin-top: 58px;
}

.font59 {
  font-size: 59px;
}

.padding-top-59 {
  padding-top: 59px;
}

.pt-59 {
  padding-top: 59px;
}

.margin-top-59 {
  margin-top: 59px;
}

.ml-59 {
  margin-left: 59px;
}

.mt-59 {
  margin-top: 59px;
}

.margin-bottom-59 {
  margin-top: 59px;
}

.font60 {
  font-size: 60px;
}

.padding-top-60 {
  padding-top: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.margin-top-60 {
  margin-top: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.margin-bottom-60 {
  margin-top: 60px;
}

.font61 {
  font-size: 61px;
}

.padding-top-61 {
  padding-top: 61px;
}

.pt-61 {
  padding-top: 61px;
}

.margin-top-61 {
  margin-top: 61px;
}

.ml-61 {
  margin-left: 61px;
}

.mt-61 {
  margin-top: 61px;
}

.margin-bottom-61 {
  margin-top: 61px;
}

.font62 {
  font-size: 62px;
}

.padding-top-62 {
  padding-top: 62px;
}

.pt-62 {
  padding-top: 62px;
}

.margin-top-62 {
  margin-top: 62px;
}

.ml-62 {
  margin-left: 62px;
}

.mt-62 {
  margin-top: 62px;
}

.margin-bottom-62 {
  margin-top: 62px;
}

.font63 {
  font-size: 63px;
}

.padding-top-63 {
  padding-top: 63px;
}

.pt-63 {
  padding-top: 63px;
}

.margin-top-63 {
  margin-top: 63px;
}

.ml-63 {
  margin-left: 63px;
}

.mt-63 {
  margin-top: 63px;
}

.margin-bottom-63 {
  margin-top: 63px;
}

.font64 {
  font-size: 64px;
}

.padding-top-64 {
  padding-top: 64px;
}

.pt-64 {
  padding-top: 64px;
}

.margin-top-64 {
  margin-top: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.mt-64 {
  margin-top: 64px;
}

.margin-bottom-64 {
  margin-top: 64px;
}

.font65 {
  font-size: 65px;
}

.padding-top-65 {
  padding-top: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.margin-top-65 {
  margin-top: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.margin-bottom-65 {
  margin-top: 65px;
}

.font66 {
  font-size: 66px;
}

.padding-top-66 {
  padding-top: 66px;
}

.pt-66 {
  padding-top: 66px;
}

.margin-top-66 {
  margin-top: 66px;
}

.ml-66 {
  margin-left: 66px;
}

.mt-66 {
  margin-top: 66px;
}

.margin-bottom-66 {
  margin-top: 66px;
}

.font67 {
  font-size: 67px;
}

.padding-top-67 {
  padding-top: 67px;
}

.pt-67 {
  padding-top: 67px;
}

.margin-top-67 {
  margin-top: 67px;
}

.ml-67 {
  margin-left: 67px;
}

.mt-67 {
  margin-top: 67px;
}

.margin-bottom-67 {
  margin-top: 67px;
}

.font68 {
  font-size: 68px;
}

.padding-top-68 {
  padding-top: 68px;
}

.pt-68 {
  padding-top: 68px;
}

.margin-top-68 {
  margin-top: 68px;
}

.ml-68 {
  margin-left: 68px;
}

.mt-68 {
  margin-top: 68px;
}

.margin-bottom-68 {
  margin-top: 68px;
}

.font69 {
  font-size: 69px;
}

.padding-top-69 {
  padding-top: 69px;
}

.pt-69 {
  padding-top: 69px;
}

.margin-top-69 {
  margin-top: 69px;
}

.ml-69 {
  margin-left: 69px;
}

.mt-69 {
  margin-top: 69px;
}

.margin-bottom-69 {
  margin-top: 69px;
}

.font70 {
  font-size: 70px;
}

.padding-top-70 {
  padding-top: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.margin-top-70 {
  margin-top: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.margin-bottom-70 {
  margin-top: 70px;
}

.font71 {
  font-size: 71px;
}

.padding-top-71 {
  padding-top: 71px;
}

.pt-71 {
  padding-top: 71px;
}

.margin-top-71 {
  margin-top: 71px;
}

.ml-71 {
  margin-left: 71px;
}

.mt-71 {
  margin-top: 71px;
}

.margin-bottom-71 {
  margin-top: 71px;
}

.font72 {
  font-size: 72px;
}

.padding-top-72 {
  padding-top: 72px;
}

.pt-72 {
  padding-top: 72px;
}

.margin-top-72 {
  margin-top: 72px;
}

.ml-72 {
  margin-left: 72px;
}

.mt-72 {
  margin-top: 72px;
}

.margin-bottom-72 {
  margin-top: 72px;
}

.font73 {
  font-size: 73px;
}

.padding-top-73 {
  padding-top: 73px;
}

.pt-73 {
  padding-top: 73px;
}

.margin-top-73 {
  margin-top: 73px;
}

.ml-73 {
  margin-left: 73px;
}

.mt-73 {
  margin-top: 73px;
}

.margin-bottom-73 {
  margin-top: 73px;
}

.font74 {
  font-size: 74px;
}

.padding-top-74 {
  padding-top: 74px;
}

.pt-74 {
  padding-top: 74px;
}

.margin-top-74 {
  margin-top: 74px;
}

.ml-74 {
  margin-left: 74px;
}

.mt-74 {
  margin-top: 74px;
}

.margin-bottom-74 {
  margin-top: 74px;
}

.font75 {
  font-size: 75px;
}

.padding-top-75 {
  padding-top: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.margin-top-75 {
  margin-top: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.margin-bottom-75 {
  margin-top: 75px;
}

.font76 {
  font-size: 76px;
}

.padding-top-76 {
  padding-top: 76px;
}

.pt-76 {
  padding-top: 76px;
}

.margin-top-76 {
  margin-top: 76px;
}

.ml-76 {
  margin-left: 76px;
}

.mt-76 {
  margin-top: 76px;
}

.margin-bottom-76 {
  margin-top: 76px;
}

.font77 {
  font-size: 77px;
}

.padding-top-77 {
  padding-top: 77px;
}

.pt-77 {
  padding-top: 77px;
}

.margin-top-77 {
  margin-top: 77px;
}

.ml-77 {
  margin-left: 77px;
}

.mt-77 {
  margin-top: 77px;
}

.margin-bottom-77 {
  margin-top: 77px;
}

.font78 {
  font-size: 78px;
}

.padding-top-78 {
  padding-top: 78px;
}

.pt-78 {
  padding-top: 78px;
}

.margin-top-78 {
  margin-top: 78px;
}

.ml-78 {
  margin-left: 78px;
}

.mt-78 {
  margin-top: 78px;
}

.margin-bottom-78 {
  margin-top: 78px;
}

.font79 {
  font-size: 79px;
}

.padding-top-79 {
  padding-top: 79px;
}

.pt-79 {
  padding-top: 79px;
}

.margin-top-79 {
  margin-top: 79px;
}

.ml-79 {
  margin-left: 79px;
}

.mt-79 {
  margin-top: 79px;
}

.margin-bottom-79 {
  margin-top: 79px;
}

.font80 {
  font-size: 80px;
}

.padding-top-80 {
  padding-top: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.margin-top-80 {
  margin-top: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.margin-bottom-80 {
  margin-top: 80px;
}

.font81 {
  font-size: 81px;
}

.padding-top-81 {
  padding-top: 81px;
}

.pt-81 {
  padding-top: 81px;
}

.margin-top-81 {
  margin-top: 81px;
}

.ml-81 {
  margin-left: 81px;
}

.mt-81 {
  margin-top: 81px;
}

.margin-bottom-81 {
  margin-top: 81px;
}

.font82 {
  font-size: 82px;
}

.padding-top-82 {
  padding-top: 82px;
}

.pt-82 {
  padding-top: 82px;
}

.margin-top-82 {
  margin-top: 82px;
}

.ml-82 {
  margin-left: 82px;
}

.mt-82 {
  margin-top: 82px;
}

.margin-bottom-82 {
  margin-top: 82px;
}

.font83 {
  font-size: 83px;
}

.padding-top-83 {
  padding-top: 83px;
}

.pt-83 {
  padding-top: 83px;
}

.margin-top-83 {
  margin-top: 83px;
}

.ml-83 {
  margin-left: 83px;
}

.mt-83 {
  margin-top: 83px;
}

.margin-bottom-83 {
  margin-top: 83px;
}

.font84 {
  font-size: 84px;
}

.padding-top-84 {
  padding-top: 84px;
}

.pt-84 {
  padding-top: 84px;
}

.margin-top-84 {
  margin-top: 84px;
}

.ml-84 {
  margin-left: 84px;
}

.mt-84 {
  margin-top: 84px;
}

.margin-bottom-84 {
  margin-top: 84px;
}

.font85 {
  font-size: 85px;
}

.padding-top-85 {
  padding-top: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.margin-top-85 {
  margin-top: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.margin-bottom-85 {
  margin-top: 85px;
}

.font86 {
  font-size: 86px;
}

.padding-top-86 {
  padding-top: 86px;
}

.pt-86 {
  padding-top: 86px;
}

.margin-top-86 {
  margin-top: 86px;
}

.ml-86 {
  margin-left: 86px;
}

.mt-86 {
  margin-top: 86px;
}

.margin-bottom-86 {
  margin-top: 86px;
}

.font87 {
  font-size: 87px;
}

.padding-top-87 {
  padding-top: 87px;
}

.pt-87 {
  padding-top: 87px;
}

.margin-top-87 {
  margin-top: 87px;
}

.ml-87 {
  margin-left: 87px;
}

.mt-87 {
  margin-top: 87px;
}

.margin-bottom-87 {
  margin-top: 87px;
}

.font88 {
  font-size: 88px;
}

.padding-top-88 {
  padding-top: 88px;
}

.pt-88 {
  padding-top: 88px;
}

.margin-top-88 {
  margin-top: 88px;
}

.ml-88 {
  margin-left: 88px;
}

.mt-88 {
  margin-top: 88px;
}

.margin-bottom-88 {
  margin-top: 88px;
}

.font89 {
  font-size: 89px;
}

.padding-top-89 {
  padding-top: 89px;
}

.pt-89 {
  padding-top: 89px;
}

.margin-top-89 {
  margin-top: 89px;
}

.ml-89 {
  margin-left: 89px;
}

.mt-89 {
  margin-top: 89px;
}

.margin-bottom-89 {
  margin-top: 89px;
}

.font90 {
  font-size: 90px;
}

.padding-top-90 {
  padding-top: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.margin-top-90 {
  margin-top: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.margin-bottom-90 {
  margin-top: 90px;
}

.font91 {
  font-size: 91px;
}

.padding-top-91 {
  padding-top: 91px;
}

.pt-91 {
  padding-top: 91px;
}

.margin-top-91 {
  margin-top: 91px;
}

.ml-91 {
  margin-left: 91px;
}

.mt-91 {
  margin-top: 91px;
}

.margin-bottom-91 {
  margin-top: 91px;
}

.font92 {
  font-size: 92px;
}

.padding-top-92 {
  padding-top: 92px;
}

.pt-92 {
  padding-top: 92px;
}

.margin-top-92 {
  margin-top: 92px;
}

.ml-92 {
  margin-left: 92px;
}

.mt-92 {
  margin-top: 92px;
}

.margin-bottom-92 {
  margin-top: 92px;
}

.font93 {
  font-size: 93px;
}

.padding-top-93 {
  padding-top: 93px;
}

.pt-93 {
  padding-top: 93px;
}

.margin-top-93 {
  margin-top: 93px;
}

.ml-93 {
  margin-left: 93px;
}

.mt-93 {
  margin-top: 93px;
}

.margin-bottom-93 {
  margin-top: 93px;
}

.font94 {
  font-size: 94px;
}

.padding-top-94 {
  padding-top: 94px;
}

.pt-94 {
  padding-top: 94px;
}

.margin-top-94 {
  margin-top: 94px;
}

.ml-94 {
  margin-left: 94px;
}

.mt-94 {
  margin-top: 94px;
}

.margin-bottom-94 {
  margin-top: 94px;
}

.font95 {
  font-size: 95px;
}

.padding-top-95 {
  padding-top: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.margin-top-95 {
  margin-top: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.margin-bottom-95 {
  margin-top: 95px;
}

.font96 {
  font-size: 96px;
}

.padding-top-96 {
  padding-top: 96px;
}

.pt-96 {
  padding-top: 96px;
}

.margin-top-96 {
  margin-top: 96px;
}

.ml-96 {
  margin-left: 96px;
}

.mt-96 {
  margin-top: 96px;
}

.margin-bottom-96 {
  margin-top: 96px;
}

.font97 {
  font-size: 97px;
}

.padding-top-97 {
  padding-top: 97px;
}

.pt-97 {
  padding-top: 97px;
}

.margin-top-97 {
  margin-top: 97px;
}

.ml-97 {
  margin-left: 97px;
}

.mt-97 {
  margin-top: 97px;
}

.margin-bottom-97 {
  margin-top: 97px;
}

.font98 {
  font-size: 98px;
}

.padding-top-98 {
  padding-top: 98px;
}

.pt-98 {
  padding-top: 98px;
}

.margin-top-98 {
  margin-top: 98px;
}

.ml-98 {
  margin-left: 98px;
}

.mt-98 {
  margin-top: 98px;
}

.margin-bottom-98 {
  margin-top: 98px;
}

.font99 {
  font-size: 99px;
}

.padding-top-99 {
  padding-top: 99px;
}

.pt-99 {
  padding-top: 99px;
}

.margin-top-99 {
  margin-top: 99px;
}

.ml-99 {
  margin-left: 99px;
}

.mt-99 {
  margin-top: 99px;
}

.margin-bottom-99 {
  margin-top: 99px;
}

.font100 {
  font-size: 100px;
}

.padding-top-100 {
  padding-top: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.margin-top-100 {
  margin-top: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.margin-bottom-100 {
  margin-top: 100px;
}

/*for targetting small screens */
.bg-results, form.vetiverGrapefruit, form.tobaccoAbsolute, form.suedeOrris, form.russianLeather, form.rosaAbsolute, form.pinkPepper, form.oudh, form.orangeBergamot, form.jasmineSunRose, form.gingerlily, form.geranium, form.floraLuminaire, form.coastalCypress, form.bushukan, form.blackPepper {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
}

.ds-ff-video {
  display: block;
}
@media (max-width: 874px) {
  .ds-ff-video {
    display: none !important;
  }
}

.mb-ff-img {
  display: none;
}
@media (max-width: 874px) {
  .mb-ff-img {
    display: block !important;
  }
}

.btn-ff-cta {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.restart-arrow-container.btn-ff-cta {
  margin: 0;
  border-radius: 0;
  border: 1px solid;
  background-color: #000;
  color: #fff;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 667px) and (min-device-width: 375px) {
  .restart-arrow-container.btn-ff-cta {
    width: 80px !important;
  }
}

.left-arrow-container.btn-ff-cta {
  margin: 0;
  border-radius: 0;
  border: 1px solid;
  background-color: #000;
  color: #fff;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 667px) and (min-device-width: 375px) {
  .left-arrow-container.btn-ff-cta {
    width: 80px;
  }
}

form.initialProfile {
  height: 610px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  form.initialProfile {
    height: 568px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  form.initialProfile {
    height: 640px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  form.initialProfile {
    height: 667px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  form.initialProfile {
    height: 736px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  form.initialProfile {
    height: 812px;
  }
}
form.finalProfile {
  height: 1350px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  form.finalProfile {
    height: 2200px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  form.finalProfile {
    height: 2200px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  form.finalProfile {
    height: 2237px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  form.finalProfile {
    height: 2237px;
  }
}
form.resultsPage {
  padding: 80px;
  min-height: inherit;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  form.resultsPage {
    padding: 45px 0 70px;
    height: 568px;
    height: auto;
    min-height: inherit;
    background-position: top left;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  form.resultsPage {
    padding: 45px 0 70px;
    height: 667px;
    height: auto;
    min-height: inherit;
    background-position: top left;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
  form.resultsPage {
    padding: 70px 0px 70px 0px;
    height: auto;
    min-height: inherit;
    background-position: top left;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  form.resultsPage {
    padding: 70px 0px 70px 0px;
    height: auto;
    min-height: inherit;
    background-position: top left;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  form.resultsPage {
    padding: 70px;
    height: auto;
    min-height: inherit;
    background-position: top left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
form.active {
  background-image: linear-gradient(#f3f3f3, #fff, #fff, #fff, #fff, #f3f3f3);
}
form.blackPepper {
  background-image: url(/assets/resource/img/uk/results/black-pepper.jpg);
}
form.bushukan {
  background-image: url(/assets/resource/img/uk/results/bushukan.jpg);
}
form.coastalCypress {
  background-image: url(/assets/resource/img/uk/results/coastal-cypress.jpg);
}
form.floraLuminaire {
  background-image: url(/assets/resource/img/uk/results/flora-luminaire.jpg);
}
form.geranium {
  background-image: url(/assets/resource/img/uk/results/geranium.jpg);
}
form.gingerlily {
  background-image: url(/assets/resource/img/uk/results/gingerlily.jpg);
}
form.jasmineSunRose {
  background-image: url(/assets/resource/img/uk/results/jasmine-sun-rose.jpg);
}
form.orangeBergamot {
  background-image: url(/assets/resource/img/uk/results/orange-bergamot.jpg);
}
form.oudh {
  background-image: url(/assets/resource/img/uk/results/oudh.jpg);
}
form.pinkPepper {
  background-image: url(/assets/resource/img/uk/results/pink-pepper.jpg);
}
form.rosaAbsolute {
  background-image: url(/assets/resource/img/uk/results/rosa-absolute.jpg);
}
form.russianLeather {
  background-image: url(/assets/resource/img/uk/results/russian-leather.jpg);
}
form.suedeOrris {
  background-image: url(/assets/resource/img/uk/results/suede-orris.jpg);
}
form.tobaccoAbsolute {
  background-image: url(/assets/resource/img/uk/results/tobacco-absolute.jpg);
}
form.vetiverGrapefruit {
  background-image: url(/assets/resource/img/uk/results/vetiver-grapefruit.jpg);
}

.container {
  position: relative;
}
@media (min-width: 1200px) {
  .container {
    max-width: 911px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .container {
    width: 100% !important;
  }
}

.form-check-inline {
  margin: 0;
  padding: 0;
  max-width: 97%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 3) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .form-check-inline {
    max-width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .form-check-inline {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 3) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .app-holder {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .app-holder {
    max-width: none !important;
    padding: 0px;
  }
}

.welcome-copy, .begin-copy {
  position: relative;
  background-image: url(/assets/resource/img/main-bg.jpg);
  height: 610px;
  background-position: center top;
  background-repeat: no-repeat;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 568px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 640px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 667px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 731px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 736px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy, .begin-copy {
    background-image: url(/assets/resource/img/mobile-main-bg.jpg);
    height: 812px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
  }
}
.welcome-copy .welcome-holder, .begin-copy .welcome-holder {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 69%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy .welcome-holder, .begin-copy .welcome-holder {
    width: 100%;
    margin-top: -8%;
  }
}
.welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 5px;
  font-family: "Times New Roman", Times, serif;
  padding: 0 10% 0 10%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .welcome-copy .welcome-holder h1, .begin-copy .welcome-holder h1 {
    padding: 0 20%;
  }
}
.welcome-copy .welcome-holder h3, .begin-copy .welcome-holder h3 {
  font-size: 20px;
  font-style: italic;
  font-weight: 100;
  margin-bottom: 20px;
  font-family: "Times New Roman", Times, serif;
}
.welcome-copy .welcome-holder h6, .begin-copy .welcome-holder h6 {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
}
.welcome-copy .welcome-holder i, .begin-copy .welcome-holder i {
  display: block;
}
.welcome-copy .welcome-holder img, .begin-copy .welcome-holder img {
  display: block;
  margin: 0 auto;
  width: 40px;
}
.welcome-copy .welcome-holder h4, .begin-copy .welcome-holder h4 {
  font-size: 18px;
  margin: 15px 0px;
}
.welcome-copy .welcome-holder .btn, .begin-copy .welcome-holder .btn {
  margin-top: 24px;
  height: 32px;
  width: 218px;
  border-radius: 0px;
  color: #fff;
  border-color: #000;
  background-color: #000;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.welcome-copy .welcome-holder .btn:hover, .begin-copy .welcome-holder .btn:hover {
  background-color: #fff;
  color: #000;
}

.results-container .container {
  background-color: #fff;
  text-align: center;
  padding-top: 20px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .results-container .container {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .results-container .container {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container {
    max-width: 100%;
    padding: 0px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container {
    max-width: 100%;
    padding: 0px;
  }
}
.results-container .container.primary {
  height: 425px;
  padding: 60px;
  width: auto;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.primary {
    padding: 35px;
    height: auto;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.primary {
    padding: 35px;
    height: auto;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.primary {
    padding: 35px;
    height: auto;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary {
    height: auto;
    padding-bottom: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary {
    height: auto;
    padding-bottom: 20px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary {
    height: auto;
    padding-bottom: 20px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary {
    height: auto;
    padding-bottom: 20px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary {
    height: auto;
    padding-bottom: 20px;
  }
}
.results-container .container.secondary .primary-product {
  height: 400px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product {
    margin: 0px;
    height: auto;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product {
    margin: 0px;
    height: auto;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product {
    margin: 0px;
    height: auto;
  }
}
.results-container .container.secondary .primary-product img {
  width: 100%;
  position: relative;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product img {
    top: -45px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product img {
    top: -45px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product img {
    top: -45px;
    padding-bottom: 40px;
  }
}
.results-container .container.secondary .primary-product .product-checkbox {
  position: absolute;
  bottom: -12px;
  width: 100%;
  left: 0px;
  font-size: 16px;
  text-align: center;
  padding-left: 20px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .product-checkbox {
    bottom: 50px;
    margin: 20px 0;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .product-checkbox {
    bottom: 50px;
    margin: 20px 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .product-checkbox {
    bottom: 50px;
    margin: 20px 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .product-checkbox {
    bottom: 50px;
    margin: 20px 0;
    text-align: center;
  }
}
.results-container .container.secondary .primary-product .product-checkbox label {
  margin-bottom: 0px;
  font-family: century-gothic, Verdana, sans-serif;
}
.results-container .container.secondary .primary-product .product-checkbox #one-prod {
  display: none;
}
.results-container .container.secondary .primary-product .product-checkbox .checker {
  display: inline-block;
  padding: 0;
  background: url(/assets/resource/img/unchecked-sprite.png) no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100%;
}
.results-container .container.secondary .primary-product .product-checkbox #one-prod:checked + .checker {
  background: url(/assets/resource/img/checked-sprite.png) no-repeat;
  padding: 0;
  height: 28px;
  width: 28px;
  display: inline-block;
  background-size: 100%;
}
.results-container .container.secondary .primary-product .product-checkbox span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  vertical-align: 8px;
  font-family: century-gothic, Verdana, sans-serif;
}
.results-container .container.secondary .primary-product .product-checkbox span.not-available {
  color: #9c1006;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}
.results-container .container.secondary .primary-product .product-checkbox span.selected {
  color: #959595;
}
.results-container .container.secondary .primary-product .img-fragrance {
  padding-top: 20px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .img-fragrance {
    order: 2;
    padding-top: 0px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .img-fragrance {
    order: 2;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .img-fragrance {
    order: 2;
    padding-top: 30px;
    padding-bottom: 30px;
    flex: none !important;
    max-width: 100% !important;
  }
}
.results-container .container.secondary .primary-product .img-fragrance p {
  display: none;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .img-fragrance p {
    font-size: 13px;
    margin-bottom: 0px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: block;
  }
  .results-container .container.secondary .primary-product .img-fragrance p a {
    color: #000;
    text-decoration: underline;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .img-fragrance p {
    font-size: 13px;
    margin-bottom: 0px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: block;
  }
  .results-container .container.secondary .primary-product .img-fragrance p a {
    color: #000;
    text-decoration: underline;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .img-fragrance p {
    font-size: 13px;
    margin-bottom: 0px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: block;
  }
  .results-container .container.secondary .primary-product .img-fragrance p a {
    color: #000;
    text-decoration: underline;
  }
}
.results-container .container.secondary .primary-product .copy-fragrance {
  padding-right: 40px;
  text-align: left;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 0;
  display: flex;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .copy-fragrance {
    order: 1;
    text-align: center;
    padding: 40px 30px 0px 30px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .copy-fragrance {
    order: 1;
    text-align: center;
    padding: 40px 30px 0px 30px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .copy-fragrance {
    order: 1;
    text-align: center;
    padding: 40px 30px 0px 30px;
    flex: none !important;
    max-width: 100% !important;
  }
}
.results-container .container.secondary .primary-product .copy-fragrance p {
  font-size: 11px;
  margin-bottom: 0px;
  margin-top: 32px;
}
.results-container .container.secondary .primary-product .copy-fragrance p a {
  color: #000;
  text-decoration: underline;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .copy-fragrance p {
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: none;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .primary-product .copy-fragrance p {
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .primary-product .copy-fragrance p {
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: none;
  }
}
.results-container .container.secondary .secondary-product {
  margin-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product {
    width: 100%;
    margin: 20px 0px 0px 0px;
  }
}
.results-container .container.secondary .secondary-product .col-md-6 {
  padding: 0 15px;
  /*	height: 355px;*/
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .col-md-6 {
    padding: 0 15px 0 15px;
    height: auto;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .col-md-6 {
    padding: 0 15px 0 15px;
    height: auto;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .col-md-6 {
    padding: 0 15px 0 15px;
    height: auto;
    flex: none !important;
    max-width: 100% !important;
  }
}
.results-container .container.secondary .secondary-product .product-checkbox {
  /*position: absolute;*/
  bottom: 0px;
  width: 100%;
  left: 0px;
  text-align: center;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .product-checkbox {
    bottom: 50px;
    margin: 20px 0;
    text-align: center;
  }
}
.results-container .container.secondary .secondary-product .product-checkbox label {
  margin-bottom: 0px;
  font-family: century-gothic, Verdana, sans-serif;
}
.results-container .container.secondary .secondary-product .product-checkbox #two-prod-0, .results-container .container.secondary .secondary-product .product-checkbox #two-prod-1 {
  display: none;
}
.results-container .container.secondary .secondary-product .product-checkbox .checker {
  display: inline-block;
  padding: 0;
  background: url(/assets/resource/img/unchecked-sprite.png) no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100%;
}
.results-container .container.secondary .secondary-product .product-checkbox #two-prod-0:checked + .checker, .results-container .container.secondary .secondary-product .product-checkbox #two-prod-1:checked + .checker {
  background: url(/assets/resource/img/checked-sprite.png) no-repeat;
  padding: 0;
  height: 28px;
  width: 28px;
  display: inline-block;
  background-size: 100%;
}
.results-container .container.secondary .secondary-product .product-checkbox span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  vertical-align: 8px;
  font-family: century-gothic, Verdana, sans-serif;
  padding-left: 3px;
}
.results-container .container.secondary .secondary-product .product-checkbox span.not-available {
  color: #9c1006;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
  vertical-align: -7px;
}
.results-container .container.secondary .secondary-product .product-checkbox span.selected {
  color: #959595;
}
.results-container .container.secondary .secondary-product img {
  width: 40%;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product img {
    width: 55%;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product img {
    width: 55%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product img {
    width: 55%;
  }
}
.results-container .container.secondary .secondary-product h4 {
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  margin: 5px 0px 10px 0px;
}
.results-container .container.secondary .secondary-product h6 {
  margin-bottom: 15px;
  height: auto;
  min-height: 55px;
  font-size: 13px;
  line-height: 18px;
}
.results-container .container.secondary .secondary-product .checkout.btn {
  margin-top: 23px;
  height: 32px;
  width: 160px;
  border-radius: 0px;
  color: #fff;
  border-color: #959595;
  background-color: #959595;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.results-container .container.secondary .secondary-product .checkout.btn:disabled {
  opacity: 1;
}
.results-container .container.secondary .secondary-product .checkout.btn:disabled:hover {
  cursor: progress;
}
.results-container .container.secondary .secondary-product .checkout.btn:not(:disabled) {
  background-color: #000;
  border-color: #000;
}
.results-container .container.secondary .secondary-product .checkout.btn:not(:disabled):hover {
  cursor: pointer;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .checkout.btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .checkout.btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .checkout.btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.results-container .container.secondary .secondary-product .checkout.active {
  border-color: #000;
  background-color: #000;
}
.results-container .container.secondary .secondary-product .map img {
  width: auto;
  display: block;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 2px;
}
.results-container .container.secondary .secondary-product .map .fa-map-marker-alt {
  display: block;
  margin: 20px 0px 10px 0px;
}
.results-container .container.secondary .secondary-product .map span {
  font-size: 13px;
}
.results-container .container.secondary .secondary-product .map span a {
  color: #000;
  text-decoration: underline;
}
.results-container .container.secondary .secondary-product .contact-us {
  margin-top: 18px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us {
    width: 100%;
    margin-top: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us {
    width: 100%;
    margin-top: 20px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us {
    width: 100%;
    margin-top: 20px;
  }
}
.results-container .container.secondary .secondary-product .contact-us .input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group {
    width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group {
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .input-group {
    width: 100%;
  }
}
.results-container .container.secondary .secondary-product .contact-us .input-group .bold-upper-text {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0px;
}
.results-container .container.secondary .secondary-product .contact-us .input-group span {
  display: flex;
  text-transform: uppercase;
  padding-top: 8px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span {
    font-size: 17px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span {
    font-size: 17px;
  }
}
.results-container .container.secondary .secondary-product .contact-us .input-group span.restart-link {
  text-decoration: underline;
}
.results-container .container.secondary .secondary-product .contact-us .input-group span.restart-link:hover {
  cursor: pointer;
}
.results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
  /*margin:0 30px;*/
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
    margin: 0 15px 0 0px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
    margin: 0 15px 0 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
    margin: 0 15px 0 10px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
    margin: 0 25px 0 30px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .input-group span:first-of-type {
    margin: 0 25px 0 30px;
  }
}
.results-container .container.secondary .secondary-product .contact-us .input-group .form-control {
  height: 32px;
  width: 245px;
  border: 2px solid black;
  border-radius: 0px;
  font-size: 13px;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group .form-control {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .input-group .form-control {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .input-group .form-control {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 0px;
  }
}
.results-container .container.secondary .secondary-product .contact-us .btn {
  margin-top: 0px;
  height: 32px;
  width: 110px;
  border-radius: 0;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.results-container .container.secondary .secondary-product .contact-us .btn:disabled {
  opacity: 1;
  border: 2px solid #959595;
  color: #959595;
}
.results-container .container.secondary .secondary-product .contact-us .btn:disabled:hover {
  cursor: progress;
}
.results-container .container.secondary .secondary-product .contact-us .btn:not(:disabled) {
  border: 2px solid #000;
  color: #000;
}
.results-container .container.secondary .secondary-product .contact-us .btn:not(:disabled):hover {
  cursor: pointer;
}
.results-container .container.secondary .secondary-product .contact-us .btn span {
  display: block;
  padding: 0px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .btn {
    width: 40%;
    margin-top: 20px;
    margin: 0 auto;
    border-left: 2px solid;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .btn {
    width: 40%;
    margin-top: 20px;
    margin: 0 auto;
    border-left: 2px solid;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .btn {
    width: 40%;
    margin-top: 20px;
    margin: 0 auto;
    border-left: 2px solid;
  }
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes {
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes {
    text-align: left;
    width: 100%;
    display: inline-block;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes {
    text-align: left;
    width: 100%;
    display: inline-block;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes {
    text-align: left;
    width: 100%;
    display: inline-block;
  }
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox {
  width: 100%;
  left: 0px;
  font-size: 13px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox {
    bottom: 50px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox {
    bottom: 50px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox {
    bottom: 50px;
  }
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox a {
  color: #000;
  text-decoration: underline;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox a:hover {
  color: #000;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox label {
  margin-bottom: 0px;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox input {
  display: none;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox .checker {
  display: inline-block;
  padding: 0;
  background: url(/assets/resource/img/unchecked-sprite.png) no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100%;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox input:checked + .checker {
  background: url(/assets/resource/img/checked-sprite.png) no-repeat;
  padding: 0;
  height: 40px;
  width: 40px;
  display: inline-block;
  background-size: 100%;
}
.results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span, .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span:first-of-type {
  display: inline-block;
  text-transform: none;
  vertical-align: top;
  margin: 0;
  width: 70%;
  text-align: left;
  font-size: 12px;
  line-height: 15px;
  min-width: 226px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span, .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span:first-of-type {
    width: 80%;
    font-size: 13px;
    line-height: 16px;
    vertical-align: 6px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span, .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span:first-of-type {
    width: 80%;
    font-size: 13px;
    line-height: 16px;
    vertical-align: 6px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span, .results-container .container.secondary .secondary-product .contact-us .email-checkboxes .email-checkbox span:first-of-type {
    width: 80%;
    font-size: 13px;
    line-height: 16px;
    vertical-align: 12px;
  }
}
.results-container .container.secondary .secondary-product p {
  margin-top: 15px;
  font-size: 12px;
  line-height: 14px;
  width: 60%;
  min-width: 432px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product p {
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    min-width: 0;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container.secondary .secondary-product p {
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    min-width: 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container.secondary .secondary-product p {
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    min-width: 0;
  }
}
.results-container .container.secondary .secondary-product .restart-xp {
  position: absolute;
  bottom: -58px;
  color: white;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  left: 0;
}
.results-container .container h1 {
  font-family: "Times New Roman", Times, serif;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.results-container .container h3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-style: italic;
  margin-bottom: 1%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container h3 {
    font-size: 24px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container h3 {
    font-size: 24px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .results-container .container h3 {
    font-size: 24px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .results-container .container h3 {
    font-size: 24px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container h3 {
    font-size: 24px;
  }
}
.results-container .container .youmight {
  margin-bottom: 10px;
}
.results-container .container textarea {
  font-size: 14px;
  border: none;
  resize: none;
  position: absolute;
  bottom: 60px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container textarea {
    position: initial;
    bottom: initial;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container textarea {
    position: initial;
    bottom: initial;
    width: 100%;
    text-align: center;
  }
}
.results-container .container textarea:focus {
  outline: 0;
}
.results-container .container h6 {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 0px;
  line-height: 18px;
}
.results-container .container h6.identity-copy {
  font-size: 13px;
  margin-top: 15px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .results-container .container h6.identity-copy {
    margin-bottom: 0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .results-container .container h6.identity-copy {
    margin-bottom: 0px;
  }
}
.results-container .container .btn {
  margin-top: 50px;
  height: 32px;
  width: 218px;
  border-radius: 0px;
  color: #fff;
  border-color: #000;
  background-color: #000;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.results-container .container .btn:hover {
  background-color: #fff;
  color: #000;
}

.load-container {
  padding-top: 100px;
}
.load-container h3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  font-style: italic;
}
.load-container img {
  width: 16%;
  margin-right: 0;
  margin-left: 0;
  margin-top: 50px;
  margin-bottom: 100px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .load-container img {
    width: 35%;
    margin: 50px 0 85px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .load-container img {
    width: 35%;
    margin: 50px 0 85px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .load-container img {
    width: 35%;
    margin: 50px 0 85px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .load-container img {
    width: 35%;
    margin: 50px 0 85px;
  }
}
.load-container h6 {
  font-size: 14px;
  padding: 0 29%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .load-container h6 {
    padding: 0px 55px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .load-container h6 {
    padding: 0px 55px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .load-container h6 {
    padding: 0px 55px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .load-container h6 {
    padding: 0px 55px;
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .load-container h6 {
    padding: 0px 65px;
    font-size: 16px;
    line-height: 20px;
  }
}

.type-container h2 {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
  font-style: italic;
  font-family: "Times New Roman", Times, serif;
  font-weight: lighter;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .type-container .container {
    max-width: 100%;
    padding: 0px;
  }
  .type-container .container .form-check {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .type-container .container {
    max-width: 100%;
    padding: 0px;
  }
  .type-container .container .form-check {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .type-container .container {
    max-width: 100%;
    padding: 0px;
  }
  .type-container .container .form-check {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .type-container .container {
    max-width: 100%;
    padding: 0px;
  }
  .type-container .container .form-check {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

/*.animate-div.ng-hide-add,
.animate-div.ng-hide-remove {
    transition: 1s ease-in-out 0.5s;
	top:-600px;
	position: relative;
	opacity: 1;
}

.animate-div.ng-hide {
   top:0px;
   position:relative;
   opacity: 0.5;
}*/
.pref-holder {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.pref-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none;
  visibility: hidden;
}
.pref-selector video {
  object-fit: cover;
  width: 256px;
  height: 250px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector video {
    width: 147px;
    height: 147px;
    display: flex;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector video {
    width: 175px;
    height: 175px;
    display: flex;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector video {
    width: 190px;
    height: 190px;
    display: flex;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector video {
    width: 147px;
    height: 147px;
    display: flex;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector video {
    width: 190px;
    height: 180px;
    display: flex;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .pref-selector video {
    width: 190px;
    height: 180px;
    display: flex;
  }
}
.pref-selector label {
  max-width: 256px;
  height: auto;
  margin-bottom: 0px;
}
.pref-selector label.four-only {
  width: 188px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector label {
    width: 145px;
    height: 153px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector label {
    width: 172px;
    height: 186px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector label {
    width: 183px;
    height: 183px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector label {
    width: 190px;
    height: 200px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector label {
    width: 190px;
    height: 205px;
  }
}
.pref-selector img {
  width: 100%;
}
.pref-selector:first-child {
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:first-child {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:first-child {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:first-child {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:first-child {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(2) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(2) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(2) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(2) {
    margin: 0px 10px 0px 5px;
  }
}
.pref-selector:nth-child(3) {
  margin-left: 15px;
  margin-right: 15px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(3) {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(3) {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(3) {
    margin: 0px 5px 0px 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(3) {
    margin: 0px 5px 0px 10px;
  }
}
.pref-selector:nth-child(4) {
  margin-right: 15px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(4) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .pref-selector:nth-child(4) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(4) {
    margin: 0px 10px 0px 5px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .pref-selector:nth-child(4) {
    margin: 0px 10px 0px 5px;
  }
}

.pref-selector input:active + .fragrance-select {
  opacity: 1;
}

.pref-selector input:checked + .fragrance-select {
  border: 1px solid red;
}

.pref-card {
  cursor: pointer;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  opacity: 1;
}

.show-steps {
  padding-top: 25px;
  margin-bottom: 50px;
}
.show-steps .dot-container {
  position: relative;
}
.show-steps .dot-container .dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border-color: #bbb;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  top: -3px;
}
.show-steps .dot-container .dot.active {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #bbb;
  position: relative;
  z-index: 99;
  top: 0px;
}
.show-steps .dot-container .dot-line {
  position: absolute;
  width: 100%;
  margin: 0px;
  display: inline-block;
  top: 11px;
  border-top: 2px solid #bbb;
  left: 0;
}
.show-steps .dot-container .dot-line.first {
  left: 41.6%;
  width: 36px;
}
.show-steps .dot-container .dot-line.second {
  left: 46.1%;
}
.show-steps .dot-container .dot-line.third {
  left: 50.6%;
}
.show-steps .dot-container .dot-line.fourth {
  left: 55.1%;
}

.step-container {
  padding: 0;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 55px;
  list-style: none;
  width: 200px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.step-container .step-item {
  background: #EFF4F7;
  width: 38px;
  height: 10px;
  font-weight: bold;
  font-size: 0.1px;
  text-align: center;
  border: 1px solid #ABBFCA;
}
.step-container .step-item.even {
  border-left: none;
  border-right: none;
}
.step-container .step-item.odd {
  border-right: none;
}
.step-container .step-item.active {
  background: #2A507B;
}
.step-container .step-item:first-child {
  border-radius: 10px 0px 0px 10px;
  /*width: 76px;*/
  border-right: none;
}
.step-container .step-item:last-child {
  border-radius: 0px 10px 10px 0px;
  /*width: 76px;*/
  border-left: none;
}

.left-arrow-container {
  position: absolute !important;
  width: 120px;
  left: 75px;
  bottom: -152px;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .left-arrow-container {
    bottom: -55px;
    left: 10px;
    width: 80px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .left-arrow-container {
    bottom: -55px;
    left: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container {
    bottom: -55px;
    left: 10px;
    padding: 3px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container {
    bottom: -55px;
    left: 10px;
  }
}
.left-arrow-container.intro {
  width: 220px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container.intro {
    width: 170px;
    padding: 3px 5px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container.intro img {
    vertical-align: -1px;
  }
}
.left-arrow-container:hover {
  cursor: pointer;
}
.left-arrow-container.btn {
  margin: 0px;
  border-radius: 0px;
  border: 1px solid;
  background-color: black;
  color: white;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container.btn {
    padding: 3px 5px;
  }
}
.left-arrow-container i {
  font-size: 1.3em;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container i {
    font-size: 0.9em;
  }
}
.left-arrow-container img {
  vertical-align: -1px;
}
.left-arrow-container .left-arrow {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 25px solid #ABBFCA;
  bottom: -70px;
  left: -15px;
}
.left-arrow-container span {
  width: 165px;
  font-size: 14px;
  line-height: 16px;
  vertical-align: 0;
  margin-left: 5px;
  text-transform: uppercase;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-arrow-container span {
    font-size: 10px;
    vertical-align: 2px;
    margin-left: 2px;
  }
}

.restart-arrow-container {
  position: absolute !important;
  width: 120px !important;
  right: 42px;
  bottom: -152px;
  background-color: #000;
  color: #fff;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .restart-arrow-container {
    bottom: -55px;
    right: 10px;
    width: 80px;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .restart-arrow-container {
    bottom: -55px;
    right: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .restart-arrow-container {
    width: 80px;
    padding: 3px 5px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .restart-arrow-container {
    bottom: -55px;
    right: 10px;
  }
}
.restart-arrow-container.btn {
  margin: 0;
  border-radius: 0;
  border: 1px solid;
  background-color: #000;
  color: #fff;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .restart-arrow-container.btn {
    padding: 3px 5px;
  }
}
.restart-arrow-container img {
  vertical-align: -3px;
}
.restart-arrow-container i {
  font-size: 1.3em;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .restart-arrow-container i {
    font-size: 0.9em;
  }
}
.restart-arrow-container:hover {
  cursor: pointer;
}
.restart-arrow-container .fa-redo-alt {
  transform: rotate(180deg);
}
.restart-arrow-container .left-arrow {
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 25px solid #ABBFCA;
  bottom: -70px;
  right: -15px;
}
.restart-arrow-container span {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  text-transform: uppercase;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .restart-arrow-container span {
    font-size: 10px;
    vertical-align: 1px;
    margin-left: 2px;
  }
}