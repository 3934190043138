/* You can add global styles to this file, and also import other style files */
@import "variable";
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.42857;
}

body {
  width: 100%;
  font-size: 14px;
  line-height: 1.42857;
  &.overlay-scroll-hidden{
    @media(max-width: $screen-sm){
      overflow-y: hidden;
   }
 }
}
:root {
  -o-animation-delay: 0ms;
  animation-delay: 0ms;
  duration: 800ms;
  --iteration: 1;
}
.haz-dl-info-content {
  font-size: 12px;
  padding-top: 12px;
  ~{
    ul{
      list-style-type: disc;
    }
  }
}
.row{
  display: -webkit-box; 
  display: -webkit-flex; 
  display: -ms-flexbox; 
  display: flex; 
}
.scroll-up .storefront-checkout-header {
  position: sticky;
  z-index: 1056;
  width: 100%;
  top: 0;
  @media(max-width: $screen-sm){
   z-index: 554;
  }
}
.menu-bar{
  display: block;
  @media(max-width: $screen-sm){
      display: none;
      app-menu{
          display: none;
      }
  }
}
.pdp-dl-instructions{
  @media (max-width:$screen-sm){
    app-delivery-instructions{
      display: none;
    }
  }
 
}
.picker-modal-content{
  .Terms-condtions-picker{
   .amp-container{
     br{
       &:first-child{
         display: none;
       }
       +{
        .amp-page-title{
           display: none;
        }
       }
     }
   }
   height: 55vh !important;
   overflow-y: scroll !important;
  }
}
.italic {
  font-style: italic;
}
 .arrow-right{
   &:after {
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-bottom: 5px solid transparent;
    content: "";
    display: inline-block;
    height: auto;
    width: auto;
    margin-left: 3px;
    vertical-align: baseline;
    visibility: visible;
 }
}
.checkbox {
  label{
    cursor: pointer;
  }
  &.has-error{
    ~{
        label{
            &::before {
             background-color: #f2dcdb;
             border:1px solid $mb-error-bg;
             opacity: 0.85;
         }
        }
    }
  }
  
} 
.disable-active,select{
    cursor: pointer;
}   
.cntry-select{
  width:240px !important;
}
@media (min-width: $screen-sm){
  .container {
      max-width: 100%;
  }
}
.store-service-point{
  .mb-stores-locator{
   app-store-locator{
    agm-map{
        height:600px;
    }
   }
  }
}
app-profile-form{
 .container {
   &.reg_form{
     @media screen and (max-width:$screen-sm){
       padding:0;
     }
  }
}
}
button {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}
textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}
h6.header-mandatory {
  display: none;
  @media screen and (max-width:$screen-sm) {
    display: block;
    float: right;
    font-weight: normal;
    margin-top: 5px;
    font-size: 0.9285714286em !important;
  }
}

.mb-breadcrumb {
  a {
    &:last-child {
      cursor: text !important;
      color: red;
    }
  }
}
.card-form-section {
  .not-valid-error-msg {
    left: 3% !important;
  }
}
:host {
  display: block;
}
.contextual-help {
  position: absolute;
  top: 0px;
  width: 42px;
  cursor: pointer;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #c2c2c2;
  background: #fff url(/assets/imgs/help-black-icons.png) no-repeat scroll center center;
  text-indent: -9999px;
  right: 0;
}
.mb-overlay-block {
  display: block;
}
.mb-overlay-none {
  display: none;
}


.show-menu-block {
  @media (max-width: 768px) {
    display: block;
  }
}
.hide-menu-block {
  @media (max-width: 768px) {
    display: none;
  }
}
.pac-container:after {
  background-image: none !important;
  height: 0px;
}
/* Start Error Messages */
.postcode-error-msg {
  position: absolute;
  bottom: 100%;
  background-color: $mb-error-bg;
  color: $mb-white;
  padding: 10px;
  left: 6%;
  text-align: center;
  span {
    font-size: 14px !important;
  }
  width: 235px;
  z-index: 9;
  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border: 0;
    left: 5%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $mb-error-bg;
    bottom: -7px;
  }
}
/* End Error Messages */
.mb-content {
  overflow: hidden;
  min-height: 100%;
  flex: 1 0 auto;
  -webkit-box-flex: 1 0 auto;
}
.loading-backdrop,.FR-loading-backdrop {
  display: block;
  height: 85vh;
  position: relative;
  .b2c-title {
    position: absolute;
    margin-top: 10px;
    top: 10%;
    left: 45%;
    font-size: 14px !important;
	@media(max-width: $screen-sm){
      left: 38.2%;
    }
    &::before {
      content: "";
      background: url("../src/assets/imgs/loading-compass.svg") no-repeat center center;
      background-size: 20px 20px;
      display: inline-block;
      width: 20px;
      height: 20px;
      transform: translateZ(0) scale(1, 1);
      -webkit-transform: translateZ(0) scale(1, 1);
      -moz-transform: translateZ(0) scale(1, 1);
      -ms-transform: translateZ(0) scale(1, 1);
      transform: translateZ(0) scale(1, 1);
      -webkit-animation: spin 2.5s linear infinite;
      animation: spin 2.5s linear infinite;
      outline: 1px solid transparent;
    }
    .loading-txt{
      float: right !important;
      padding-left: 5px !important;
      font-size: 14px !important;
      padding-top: 2px !important;
    }    
  }
}
.payer-loading{
  .b2c-title {
    &::before {
      width:35px;
      height:35px;
    }
  }
}
.FR-loading-backdrop{
  background-color: $mb-white;
  min-height: 250px;
  transition: all 1s ease-out;
}
.loading-backdrop{
  background-color:$overlay-bg;
  transition: all 1s ease-out;
}

ngx-slick-carousel {
  .slick-arrow {
    z-index: 666;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes color-change {
  0% {
    color: $primary-color;
  }
  50% {
    color: #727272;
  }
  100% {
    color: #e6e6e6;
  }
}
@-moz-keyframes color-change {
  0% {
    color: $primary-color;
  }
  50% {
    color: #727272;
  }
  100% {
    color: #e6e6e6;
  }
}
@-ms-keyframes color-change {
  0% {
    color: $primary-color;
  }
  50% {
    color: #727272;
  }
  100% {
    color: #e6e6e6;
  }
}
@-o-keyframes color-change {
  0% {
    color: $primary-color;
  }
  50% {
    color: #727272;
  }
  100% {
    color: #e6e6e6;
  }
}
@keyframes color-change {
  0% {
    color: $primary-color;
  }
  50% {
    color: #727272;
  }
  100% {
    color: #e6e6e6;
  }
}
a,
input {
  outline: none;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  height: 45px;
  top: 0;
}
.radio input[type="radio"],
.form-inline .radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  display: block;
  z-index: 999;
  height: 24px;
}
.bannerDescription {
  a {
    color: #232323 !important;
    text-decoration: underline;
  }
}
span {
  font-size: 12px;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  background-repeat: no-repeat;
  background-position: left top;
}
p {
  overflow: hidden;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  outline: none;
}  

.rowComponents {
  display: flex;
  flex-wrap: wrap;
}

.page-link {
  border: 0px !important;
}
.check-error-msg-bottom {
  color: $err-color;
  margin: 10px 0;
  overflow: hidden;
}
.redstar {
  color: $err-color;
  padding-right: 5px;
  font-weight: 700;
  font-size: 15px;
}
.page-link {
  border: 0px !important;
  color: #999 !important;
}

.h4 {
  font-size: 1.5rem;
}
.btn {
  border-radius: 0 !important;
  width: 165px;
  height: 48px;
  text-transform: uppercase;
  font-size: 1.0714285714em;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  position: relative;
  line-height: 1 !important;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.0714285714em;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}
.btn:hover {
  color: #fff;
  opacity: 0.9;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  color: #000000 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.checkbox {
  height: 32px;
  padding-top: 8px;
}
.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox {
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 555;
    padding: 24px;
    opacity: 0;
    cursor: pointer;
  }
  label {
    padding-left: 38px;
    position: relative;
    font-size: 14px;
    color: #464646;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 32px;
      height: 32px;
      left: 0;
      top: 0px;
      border: 1px solid #a1a1a1;
      border-radius: 0;
      background-color: #ccc;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
    }
    &:after {
      display: inline-block;
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      left: 4px;
      top: 0px;
      border-radius: 0;
      background: url(/assets/imgs/sample-tick.png) 0 -2px no-repeat;
      -webkit-transform: scale(0, 0);
      -ms-transform: scale(0, 0);
      transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      @media screen and (max-width: 768px) {
        top: 8px;
      }
    }
  }
  input[type="checkbox"] {
    &:checked {
      ~ {
        label::after {
          -webkit-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          transform: scale(1, 1);
        }
      }
    }
  }
}
app-footer {
  bottom: 0 !important;
}
.treat-section > .pdp-realtedprduct-container > app-related-product > .item {
  h4 {
    text-align: center;
    font-size: 22px;
    padding: 15px 0px;
    font-weight: 400;
    line-height: 24px;
    color: $primary-color;
  }
  .slick-initialized .slick-next {
    right: -1px !important;
  }
  .slick-initialized .slick-prev {
    left: 0px !important;
  }
  ngx-slick-carousel .slick-list .slick-track .slick-slide {
    margin-left: 21px !important;
    margin-right: 25px !important;
  }
}
ngx-slick-carousel .slick-slide {
  margin: 0 13px;
  
  @media screen and (max-width: 768px) {
    margin:0;
  }

  img {
    margin: 0 auto;
  }
}

.slick-prev {
  left: 3px !important;
  &::before {
    font-family: "slick";
    font-size: 0px !important;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 0;
    height: 0;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-right: 12px solid #c8c8c8 !important;
  }
}
.slick-next {
  right: 3px !important;
  @media screen and (max-width:874px){
    right: 0px !important;
}
  &::before {
    font-family: "slick";
    font-size: 0px !important;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 0;
    height: 0;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid #c8c8c8 !important;
  }
}

app-basketpage .clearfix .treat-align {
  .delivery-option-block{
    width: 49.5%;
    @media(max-width:$screen-sm){
      width: 100%;
    }
  }
  .treat-section {
    margin-top: 15px !important;
    width: 49.5%;
    &.basketpageTreat {
      margin-top: 0px !important;
    }
    @media screen and (max-width: $screen-sm) {
      width: 100% !important;
    }
    .pdp-realtedprduct-container app-related-product div h4 {
      font-size: 1.57143em;
      text-transform: uppercase;
      padding: 15px 0px;
      text-align: center;
    }
    app-related-product {
      padding: 10px;
    }
  }
}

.payment-gateway-icons {
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 320px) {
    padding: 0px !important;
  }
  span.norton-secure {
    background-position: 0 10px;
    @media screen and (max-width: 768px) {
      width: 30px;
      background-position: 2px 10px;
    }
  }
  span {
    background: url(/assets/imgs/livedata/sprite-credit-cards-new.png) no-repeat;
    height: 43px;
    width: 58px;

    @media screen and (max-width: 768px) {
      // background: url(/assets/imgs/livedata/sprite-credit-norton-2x.png) no-repeat;
      margin: 0;
      padding: 0;
      float: left;
      text-indent: -9999px;
      overflow: hidden;
      height: 32px;
      background-size: 300px auto;
      margin-right: 5px;
    }

    &.visa-secure {
      background-position: -64px 10px;
      width: 56px;
      @media screen and (max-width: 768px) {
        width: 31px;
        background-position: -29px 10px;
      }
    }
    &.master-card {
      background-position: -126px 10px;
      @media screen and (max-width: 768px) {
        width: 31px;
        background-position: -62px 10px;
      }
    }
    &.american-express {
      background-position: -190px 10px;
      width: 56px;
      @media screen and (max-width: 768px) {
        width: 33px;
        background-position: -91px 10px;
      }
    }
    &.vpay-secure {
      background-position: -252px 10px;
      width: 35px;
      @media screen and (max-width: 768px) {
        background-position: -123px 10px;
        width: 19px;
      }
    }
    &.paypal-secure {
      background-position: -292px 10px;
      width: 56px;
      @media screen and (max-width: 768px) {
        width: 30px;
        background-position: -143px 10px;
      }
    }
    &.mb-secure {
      background-position: -353px 10px;
      width: 55px;
      @media screen and (max-width: 768px) {
        background-position: -173px 10px;
        width: 33px;
      }
    }
    &.apple-pay {
      background-position: -548px 10px;
      width: 56px;
      @media screen and (max-width: 768px) {
        background-position: -271px 10px;
        width: 29px;
      }
    }
    &.de-kalrna {
      background-position: -413px 10px;
      width: 69px;
      @media screen and (max-width: 768px) {
        background-position: -204px 10px;
        width: 35px;
      }
    }
    &.de-sepa {
      background-position: -487px 10px;
      width: 55px;
      @media screen and (max-width: 768px) {
        background-position: -239px 10px;
        width: 35px;
      }
    }
  }
}
app-product-samples .clearfix {
  .basket-footer-container .order-subtotal {
    margin-bottom: 10px;
    font-size: 14px;
    height: 47px;
  }
}
.star-3:before {
  content: "\2605\2605\2605\2606\2606";
  font-size: 20px;
}

.orders-container app-related-product .item h4 {
  font-size: 1.2857142857em;
  color: $primary-color;
  border: 0;
  margin: 0 0 12px 0;
}

@media screen and (max-width:768px) {
  .slick-prev,
  .slick-next {
    top: 19% !important;
    @media screen and (max-width:425px) {
      top: 15% !important;
    }
  }
  app-basketpage .clearfix .hideBlock {
    display: none;
  }
  .treat-align .delivery-option-block {
    width: 100%;
    padding: 0px;
    app-delivery-options .deliveryOption {
      width: 100% !important;
      height: auto;
    }
  }
  ngx-slick-carousel{ 

    .slick-slide {
      .product-info {
        .product-title {
          min-height: 52px !important;
          margin-bottom: 15px !important;
          font-size: 1em !important;
          color: #464646;
          width: 100% !important;
        }
        .product-price {
          font-size: 1em !important;
          color: #464646;
        }
        .addToBasket {
          font-size: 1.0714285714em !important;
          transition: background 0.3s ease-in-out;
          width: 160px !important;
          height: 36px !important;
        }
      }
  }
}
  .payment-gateway-icons {
    justify-content: center;
  }
  .security-label {
    text-align: center;
  }
}

.staticHeader .mb_contentblock_landing span {
  font-size: 13px;
  a:link {
    color: #666;
  }
  b {
    font-weight: 700;
  }
}
p.BannerDescription{
  color: $banner-color;
  font-size: 12px;
  a{
    color: $banner-color !important;
  }
}
.FR-QV-product-info-block{
  p {
    color: $primary-color;
  }

}
p.fullDetails{
  a{
    color: $primary-color !important;
  }
}
.radio input[type="radio"],
.form-inline .radio input[type="radio"] {
  opacity: 0;
  position: absolute;
}
.radio {
  label {
    min-height: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    padding-left: 28px;
    position: relative;
    font-size: 14px;
    line-height: 22px;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 22px;
      height: 22px;
      left: 0;
      top:0px;
      border: 1px solid #727272;
      border-radius: 50%;
      background-color: #727272;
      -webkit-transition: border 0.15s ease-in-out;
      transition: border 0.15s ease-in-out;
      background-color: #e1e1e1;
    }
  }
  input[type="radio"] {
    &:checked {
      ~ {
        label {
          &:before {
            border: 1px solid #e1e1e1;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
          }
          &:after {
            position: absolute;
            content: "";
            width: 14px;
            height: 14px;
            left: 4px;
            top: 4px;
            border-radius: 50%;
            background-color: $mb-radio-bg;
            transform: scale(1, 1);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
          }
          &.radio-existingGuest {
            &:after {
              top: 3px;
            }
          }
        }
      }
    }
  }
}
ngx-stars {
  position: relative;
  z-index: 111;
}
.pos-relative {
  position: relative;
}
.backdrop {
  background-color: #d9d9d9;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 555;
  width: 100%;
  min-height: 250px;
  height:100% !important;
  margin: 0 auto;
  display: none;
  @media (max-width:$screen-sm) {
    background-color:transparent;
  }
}
.overlay-bg-show {
  display: block;
}
.overlay-bg-hide {
  display: none;
}
.custom-dropdown {
  select {
    height: 48px;
    min-width: 240px;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    @media screen and (max-width:546px) {
      width: 100%;
    }
  }
}

@media screen and (max-width:768px) {
  .slick-prev,
  .slick-next {
    top: 19% !important;
    @media screen and (max-width:425px) {
      top: 50% !important;
    }
  }
  .treat-align .delivery-option-block {
    width: 100%;
    app-delivery-options .deliveryOption {
      width: 100% !important;
      height: auto;
    }
  }
  ngx-slick-carousel .slick-slide {

    .product-info {
      .product-title {
        min-height: 52px !important;
        margin-bottom: 15px !important;
        font-size: 1em !important;
        color: #464646;
        width: 100% !important;
      }
      .product-price {
        font-size: 1em !important;
        color: #464646;
      }
      .addToBasket {
        font-size: 1.0714285714em !important;
        transition: background 0.3s ease-in-out;
        width: 160px !important;
        height: 36px !important;
      }
    }
  }
  app-basketpage .clearfix .rowComponents {
    margin-left: 3% !important;
    margin-right: 3% !important;
    padding-bottom: 15px;
    width: 94%;
  }
  app-basketpage .basket-heading .rowComponents {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
  .payment-gateway-icons {
    justify-content: center;
  }
  .security-label {
    text-align: center;
  }
}
app-checkoutpage ol {
  justify-content: space-between !important;
}
.toolTipFont {
  font-size: 12px;
}
.user-delivery-bg {
  padding: 10px 0;
  border-bottom: 1px solid $primary-color;
  p {
    font-size: 14px;
  }
}
.gift-sample-carousel {
  .slick-track {
    display: flex !important;
  }
  ngx-slick-carousel {
  .slick-prev{      
        @media (max-width: 768px) {
            top:50% !important;
            left:0px !important;
          }
    }
    .slick-next {
    @media (max-width: 768px) {
        top: 50% !important;
        right:0px !important;
      }
    }
    }
  }

.slick-active {
  outline: none !important;
}
// @media screen and (max-width:425px) {
//   .mandatory {
//     width: 100% !important;
//   }
// }
.buttonStyle {
  background: $primary-color;
  padding: 5px 10px;
  color: #fff;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
}
button.buttonStyle:disabled,
button.buttonStyle[disabled] {
  cursor: progress;
}
.login_fields {
  width: 250px;
  position: relative;
  padding: 0 !important;
  display: inline-block;
  input,
  select {
    width: inherit;
    width: 250px !important;
    height: 25px !important;
    padding: 0 5px !important;
  }
}
ol > li {
  .active {
    height: 27px;
    position: relative;
    padding: 0px;
    text-align: center;
    font-size: 1.2857142857em;
    line-height: 28px;
  }
}
// ol > li {
//   height: 27px;
//   position: relative;
//   padding: 0px;
//   margin: 0px;
//   text-align: center;
// }
ol > li:last-child {
  &.step::after {
    display: none !important;
  }
}
ol > li:first-child {
  &.step:before {
    display: none !important;
  }
}
ol > li {
  &.step {
    width: 320px;
    position: relative;
    float: left;
    font-size: 1.2857142857em;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    background-color: #fff;
    font-weight: 700;
    height: 27px;
  }
}
.step::after {
  right: -13px !important;
}
.step.current {
  background-color: #464646 !important;
  color: #fff;
}
.step.current::after {
  border-left: 14px solid #464646;
}
.step::before {
  left: 0;
  border-left: 14px solid #acacac;
  z-index: 1;
}
.step::after,
.step::before {
  position: absolute;
  top: 0;
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
}
.step::before {
  left: -1px !important;
}
.step::after {
  right: -14px;
  border-left: 14px solid #fff;
  z-index: 2;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  ol > li {
    &.step {
      width: 15%;
      &.current {
        width: 70% !important;
      }
    }
  }
}

.text-right {
  text-align: right;
}
.paddingTop10px {
  padding-top: 10px;
}
html {
  scroll-behavior: unset;
}
.gm-style {
  button[aria-label="Close"]{
    top: 0px;
    right: 2px;
    visibility: hidden;
    span{
      visibility: visible;
      font-size: 16px;
      color:$mb-white;
    }
  }
  .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0px !important;
    overflow: visible;
    height: auto !important;
    max-height: none !important;
  }
}
.gm-style-iw-d {
  overflow: hidden !important;
  max-height: unset !important;
  height: auto !important;
}
.cc-store-address {
  p {
    margin-bottom: 5px;
    font-size: 12px;
    color: #464646;
    b {
      font-weight: 900 !important;
    }
  }
}
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0 auto !important;
    min-height: 200px;
    .modal-content {
      min-height: 200px;
      overflow-y: scroll;
    }
  }
}

.adp {
  height: 350px;
  overflow-y: scroll;
  border: 1px solid $mb-border-color;
  .adp-placemark {
    margin-top: 0px;
  }
  .adp-directions {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 425px) {
  app-mb-terms .form-group .checkbox label:before {
    top: 4px !important;
  }
}
app-delivery-service {
  section {
    section {
      &.card-body1 {
        padding: 0 !important;
      }
    }
  }
}

.login_fields {
  position: relative;
  .contextual-help {
    position: absolute;
    width: 25px !important ;
    height: 25px !important ;
    right: -12px !important ;
    background-size: 7px;
  }
}
.cc-stores-list {
  .checkbox {
    label {
      &::before {
        top: 0px !important;
      }
    }
  }
}

.chck-modal-dialog {
  width: 900px;

  box-shadow: none !important;
  .modal-content {
    border: 0;
    max-height: 421px;
    position: relative;
  }
  .close {
    margin-top: -1px;
    color: #fff;
    padding-top: 0;
  }
  .chck-modal-header {
    padding: 10px 15px;
    border-bottom: 0;
    background: #464646;
    display: block;
    color: #fff;
    .modal-title {
      font-size: 17px;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      line-height: 1.42;
    }
  }
  .chck-modal-body {
    border: 0;
    border-radius: 0;
    background-color: #fff;
    max-height: 421px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto !important;
    position: relative;
  }
}
.chck-modal-dialog .modal-content {
  .staticHeader {
    p {
      color: #464646;
    }
    h1 {
      display: none;
    }
    ul li {
      list-style: none;
    }
  }

  .paddingTop10px {
    padding: 0;
  }
  .chck-modal-body {
    padding: 0 15px;
  }
  .static_para {
    &:first-child {
      margin-top: 0px;
    }
  }
}
app-card-form {
  .payer-saved-address .saved-address p span {
    display: block;
    font-size: 14px;
  }
}
.mb-personal-details {
  fieldset legend {
    font-size: 16px;
  }
}
app-profile-form {
  .contextual-help{
    background-size: 7px !important;
  }
  .registration_form {
    h2 {
      padding-top: 0px !important;
    }
    margin: 12px 0 15px 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .my-account {
    padding: 6px 0 5px 0px !important;
  }
}
app-myaccount {
  .contextual-help {
    position: absolute;
    top: 0px;
    left: 235px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #c2c2c2;
    background: #fff url(/assets/imgs/help-black-icons.png) no-repeat scroll center center;
    text-indent: -9999px;
    background-size: 7px !important;
  }
  .regPhone {
    .contextual-help {
      top: 0px;
    }
  }
}


app-categorylanding-page {
  app-productview {
    .product_detail {
      height: unset;
    }
    .pdp-productDtls-container {
      padding: 5px;
    }
  }
  ~ {
    app-footer {
      bottom: 0;
      footer {
        margin-top: 15px;
      }
    }
  }
  .plp-view_container{
     .plp-container-grid {
       .plp-products-container .plpPage .mb-child .wrap .wrap-content div {
        ngx-stars{ 
          .stars-line{ 
            .fa {
           font-size: 18px !important;
          }
        } 
       }
        div {
          b {
            font-size: 14px !important;
            line-height: 16px !important;
          }
        }
   }
   app-pager{ 
     .rowCompts {
      top: 15px;
    }
  }
  }
 }
}

app-search-landingpage {
  .container{
    @media screen and (max-width: 768px) {
      padding:0;
    }
  }
  .plp-view_container {
    .plp-container-grid {
      .viewAllTop {
        .freeTextShow {
          height: 18px;
          @media  (max-width: 480px) {
            padding-left: 10px;
          }
        }
      }
      .facets{
        &.col-md-3 {
        margin-top: 18px !important;
      }
    }
      .plp-products-container {
        .heading {
          margin-top: 0px;
        }
      }
    }
  }
}

.login_fields.start-date {
  display: flex;
  width: 250px;
  justify-content: space-between;

  select.smallselect {
    width: 119px !important;
  }
}
app-customer-detail {
  .my-account {
    width: 764px;
    @media screen and (max-width:$screen-md) {
      width: 100%!important;
    }
  }
}
.price-format-discount {
  &-retail-price {
    font-size: 18px;
    line-height: 16px;
  }
}
.ds-price {
  color: #FF1717;
  font-size: 18px;
}

.not-valid-postcode {
  &:focus {
    + {
      .postcode-msg-block {
        display: block;
      }
    }
  }
}
.postcode-msg-block {
  display: none;
  position: absolute;
  bottom: 100%;
  background-color: $mb-error-bg;
  color: $mb-white;
  padding: 10px;
  left: 6%;
  text-align: center;
  @media(max-width:$screen-sm){
    left: auto;
    right: 0;
  }
  span {
    font-size: 14px !important;
  }
  width: 305px;
  z-index: 9;
  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border: 0;
    left: 5%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: $mb-error-bg;
    bottom: -7px;
    @media(max-width:$screen-sm){
      left: auto;
      right: 10px;
    }
  }
}
.modal {
  .container {
    &:before {
      display: flex !important;
    }
    &:after {
      display: flex !important;
    }
  }
}
.icon-valid-white.icon-bg-block {
  display: inline-block;
  @media screen and (max-width:768px) {
    display: none;
  }
}
.monthYearAlign {
  display: flex;
  @media screen and (max-width:768px) {
    justify-content: space-between;
  }
}
select.form-control {
  color: #000000 !important;
}
.small-dropdown {
  margin-right: 10px;
  @media screen and (max-width:768px) {
    width: 49% !important;
    margin: 0;
  }
  select.form-control {
    width: 135px !important;
    min-width: 135px !important;
    @media screen and (max-width:768px) {
      width: 100% !important;
    }
  }
}
input[type="text"],
input[type="email"],
input[type="password"] {
  &::-ms-clear {
    display: none;
  }
  &::placeholder {
    color: #a2a2a2;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a2a2a2;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a2a2a2;
  }
}
// .help-block {
//   display: block;
//   margin: 5px 0 10px 0;
//   color: #464646 !important;

//   font-size: 14px;
//   a {
//     cursor: pointer;
//     text-decoration: underline !important;
//     color: #464646 !important;
//   }
//   @media screen and (max-width: 768px) {
//     margin: 15px 0 10px 0;
//   }
// }
.mb-container-left {
  position: inherit;
  @media screen and (max-width:768px) {
    margin-left: 80%;
    
  transition: all 1s ease-in-out;
    position: fixed;
    .container {
      width: max-content !important;
      .tab-justify-center {
        justify-content: flex-start !important;
      }
    }
  }
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-25 {
  margin-bottom: 25px;
}
footer .copyrights {
  color: #1f1d1e;
  font-size: 11px;
  margin-bottom: 0px !important;
  padding: 0px 0 20px 0;
  float: left;
  width: 100%;
  display: block;
  background-color: #eee;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
  background-color: #606060;
  color: #fff;
}
.delivery-collapse-block {
  //margin-top: 20px;
  .clickcollect-wrap .cc-stores-view-nav a {
    float: left;
    text-decoration: none;
    font-size: 18px;
    color: $primary-color !important;
    padding: 11px 30px 10px;
    margin-right: 5px;
    background-color: #e5e5e5;
    border-radius: 0 25px 0 0;
    cursor: pointer;
    width: 94px;
    height: 40px;
    line-height: 1.42;
  }
}

app-billing-address section .billing_form {
  @media screen and (max-width:768px) {
    .form-inline.manual_field {
      justify-content: flex-start;
      .form-group {
        width: 49% !important;
        margin-right: 0px;
        &:first-child {
          margin: 0 1% 10px 0 !important;
        }
        &:last-child {
          margin: 0 0 10px 1% !important;
        }
      }
    }
    .contextual-help {
      right: 0 !important;
    }
    .form-control {
      width: 100%;
    }
    .card_field {
      width: 100%;
      .telInput.form-control {
        width: calc(100% - 20px) !important;
      }
    }
    .shippingButtons_btn-full-width {
      .btn {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:768px) {
  .mobile-position {
    width: 100%;
  }
  .express-check-out {
    width: 100% !important;
  }
  .basket-heading .rowComponents h1 {
    margin-top: 0px;
  }
}
.continue-shopping-div {
  padding: 0 !important;
}
app-delivery-options {
  .amp-dc-external-block {
    @media screen and (max-width:768px) {
      section {
        width: 100% !important;
        height: auto !important;
      }
    }

    h1 {
      font-weight: 700;
    }
  }
}

.modal-content, .modal-header{
  border-radius:0 !important;
}

.tooltip {
  opacity: 1;
  font-size: 12px;
  margin-left: 3px;
}

ngb-tooltip-window.show{
  background-color: transparent !important;
}

.form-control{
  &:focus {
    border-color:  $mb-skyblue !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
  }
} 
.radio input[type="radio"]:checked ~ label:before {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}

.postalcode-block{
  width:65% !important;
.pcode {
    width:55% !important;
    background-position: 90% 9px !important;
    @media screen and (max-width:768px) {
      width: 100% !important;
    }
  ~{    
    .contextual-help{
      right:39% !important;
    @media screen and (max-width:768px) {
      right:35% !important;
    }
  }
}
}
}
.payer-saved-address .bl_checkbox .terms-lbl{
 
    &:after { top: 4px !important;

  }

}
.bv_modal_component_container{
  display: none!important;
}
div.bv_modal_component_container[data-bv-modal] .bv_modal_outer_content{
  background-color: $mb-white;
}
.new-saved-card-form .registration_form {
.addressSize .radio, .chooseradio{
  margin-top:12px !important;
}

}

.not-valid-postcode{
  background-image:none !important;
}
@media(max-width:768px){
.form-group,.loginx-fileds{

 ngb-tooltip-window {
  transform: none !important;
  background-color: transparent !important;
  left: auto!important;
  right: 0;
  top:-55px !important;
  .arrow{
    left: auto!important;
    right: 12px !important;
  }
}

}
}
  @media screen and (max-width:768px){
       
    .login_fields ngb-tooltip-window {
-webkit-transform: none !important;
-ms-transform: none !important;
transform: none !important;
background-color: #dc282800 !important;
left: auto !important;
right: -15px;
top: -57px !important;
&.bs-tooltip-top .arrow::before {
    top: 0;
    border-width: .4rem 0.4rem 0px;
    border-top-color: #000;
    right: -85px;
}
}
.form-group.security-code.savedcard1 ngb-tooltip-window {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  background-color: #dc282800 !important;
  left: auto !important;
  right: -20px;
  top: -100px !important;
  &.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem .4rem .4rem 6px;
    border-right-color: #0000;
    border-top-color: black;
    top: 53px;
}
}
}
.gm-style .gm-style-iw-t::after{
  display:none;
}

.tooltip-map {
  font-size: 12px;
}
.infoBox {
  img {
  position: absolute!important;
  right: 12px;
  top: 10px;
  z-index: 99999;
}
.showmore_one a, .map-links a {
  position: relative;
  text-align: center;
  margin-right: 2px;
  padding: 5px 15px 5px 8px;
  background: url(assets/imgs/livedata/white-arw.png) no-repeat scroll 90% center #7d6f64;
  color: #fff !important;
  text-decoration: none;
  font-size:12px;
}
.map-links a{
  &:last-child {
  margin-right: 0!important;
}
}

}

.tooltip-map {
  overflow: visible;
  z-index: 9999;
  font-size: 12px;
  width: auto;
  padding: 0 10px 10px;
  display: block;
  background: #fff;
  border: 4px solid #ccc;
  .arrow-tooltip {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    left: -8px;
    top: 12px;
    z-index: 99999;
    position: absolute;
    display: inline-block;
    border-top: 12px solid transparent;
    border-left: 12px solid #878787;
    border-bottom: 12px solid transparent;
    content: '';
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside none;
}
.title-map {
  color: #fff;
  background: #878787;
  padding: 10px;
  margin: 0 -10px 10px;
  overflow: hidden;
  .tmap-inner {
    float: left;
    width: 190px;
 }
}
 h3 {
  font-size: 16px;
  padding: 0 0 7px 0;
  color: #fff;
  font-weight: 700;
}
p {
  padding: 0;
  margin: 0;
  color: #fff;
}
dl {
  color: #585858;
  padding: 0;
  margin: 0 0 15px 0;
  display: block;
  overflow: hidden;
}
dt {
  padding: 5px 0;
  margin: 0;
  float: left;
  width: 80px;
  clear: left;
  font-weight: normal;
}
 dd {
  padding: 5px 0;
  margin: 0;
  float: left;
  width: 100px;
  clear: right;
}
}
.tooltip-map h3 {
  font-size: 16px;
  padding: 0 0 7px 0;
  color: #fff;
  font-weight: 700;
}
.tooltip-map h4 {
  margin: 15px 0 0;
  font-size: 12px!important;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
  overflow: hidden;
}
.showmore_one dl, .tooltip-map dl {
  color: #585858;
  padding: 0;
  margin: 0 0 15px 0;
  display: block;
  overflow: hidden;
}
.tooltip-map{
  dl{
    visibility: collapse;
    dt,dd{
      visibility: visible;
      text-transform: capitalize;
    }
  }
}


.store-timings{
  .store-timing-list{   
    span{
      &.store-day{
        visibility: visible;
        text-transform: capitalize;
      }
      &.store-hours{
        span{
          visibility: collapse;
          display: none;
        }
        text-transform: capitalize;
      }
    }
  }
}


.stores-map-view-details {
  font-family: trebuchet ms;
  right: 10px;
  top: 10px;
  width: 260px;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #c2c2c2;
  font-size: 12px;
  @media screen and (max-width:$screen-sm) {
    width: 238px;
  }
  .btn.btnChooseStore {
    cursor: pointer;
    height: auto;
    width: 100%;
}
  h4 {
    background-color: #464646;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    margin: -15px -15px 15px;
    padding: 10px 10px 6px;
    text-align: center;
    font-weight: 700;
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 0;
      margin-left: -10px;
      border: 9px solid transparent;
      border-right-color: #464646;
      position: absolute;
      left: -8px;
      top: 6px;
  }
}
  h5 {
    font-size: 12px;
  margin: 0 0 5px;
  font-weight: 700;
}
h6 {
  margin: 0 0 5px;
  font-size: 12px;
}
  .btn-close {
    width: 30px;
    height: 24px;
    border: 0;
    position: absolute;
    top: 3px;
    right: 15px;
    opacity: 1;
    text-transform: lowercase;
    color: white;
    z-index: 99;
}
.store-timings {
  margin-bottom: 5px;
   span {
    display: inline-block;
    width: 100px;
    vertical-align: top;
    &.rightTime {
      width: 128px!important;
  }
}
}
ul {
  margin: 0;
  padding: 0;
  list-style: outside none;
}
button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}
.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: black;
    text-shadow: 0 1px 0 white;
    opacity: .2;
    filter: alpha(opacity=20);
}
}

@media screen and (max-width:874px){
  .amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-subheader {
    font-size: 15px!important;
  }
  .tab-responsive-plp-grid {
    -webkit-transition: width 1s ease-out;
    transition: width 1s ease-out;
  }
  .ml-8.find-address{
    margin-left:0 !important;
  }
  #uk-timer-web-imagecontainer {
    color: #000!important;
  }
  .amp-dc-block-wrap .onlyImage h1 {
    display: block;
    overflow: hidden;
    padding: 15px;
    font-size: 15px;
    font-weight: 700;
    color: #000;
    margin: 0;
    margin-top: 15px;
}
.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text {
  text-align: left;
  padding: 0 15px;
  }
  .aboutus-text p {
    margin-bottom: 15px;
  }
  .aboutus-text h4 {
    font-size: 14px!important;
    font-weight: 600!important;
    margin-bottom: 10px;
  }
  .amp-dc-card-list.about-us-bottom-banners .amp-dc-card {
    margin: 0 15px;
  }
  .amp-dc-prod-4-rows .amp-dc-card,
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
    width: 100%;
  }
  picture.amp-dc-image.about-us-165x166 {
    width: 100%!important;
  }
  .amp-dc-homepage .amp-dc-text {
    margin: 0!important;
  }
  .amp-dc-text h1 {
    font-size: 33px;
  }
  .corporate-gifting p {
    width: 90%;
  }
  .amp-dc-text h3 {
    font-size: 22px;
    padding: 20px 0;
    font-weight: 600!important;
  }
  .corporate-gifting {
    padding-top: 0;
  }
  .amp-dc-block-wrap {
    margin-bottom: 20px;
  }
  .amp-dc-text .aboutus-text {
    padding: 0px 15px;
  }
  #hotel-aminities-page {
    padding: 0 15px!important;
  }
  .hotelaminities h3 {
    padding: 0;
  }
  .amp-dc-block-wrap .hotel-aminities-main-text p {
    text-align: center;
    margin-bottom: 10px;
  }
  .amp-dc-block-wrap .hotel-aminities-main-text ul {
    margin-bottom: 10px;
    text-align: center;
  }
  .amp-container p {
    margin-bottom: 10px;
  }
  .amp-container {
    padding: 0 15px;
  }
  #uk-hybris-prod-feed-store-finder-fr_carousel .product .FR-btn-block button {
      width: 128px!important;
      font-size: 12px!important;
  }
  #eu-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-prev {
    color: #fff!important;
  }
  #uk-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-prev, #uk-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-next {
    color: #fff!important;
  }
  #de-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-prev, #de-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-next,
  #us-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-prev, #us-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-next,
  #eu-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-prev, #eu-hybris-prod-feed-product-page-you-may-also-like-fr_carousel .slick-next {
    color: #fff!important;
  }
  .gm-style-iw .gm-style-iw-d {
    width: 238px!important;
  }
}

.picker-sample-carousel{
  ngx-slick-carousel{
      .slick-slide {
          img{
            width:160px;
         }
         margin-left:10px;
         margin-right:10px;
        }
  } 
}
.stores-map-view-details{
  p{
    font-size:12px;
    margin:0 0 5px;
  }
  h5{
    &.dl-content{
      font-weight: 700;
      font-size: 14px;
      margin-top: 5px;
  }
}
}
app-mb-terms{
  .order-totals{
      background-color: #fff;
     // padding: 10px;
     // margin-bottom: 10px;
      min-height: 40px;
      display:none;
      .btn-view-order-summary{
        text-align: center;
        padding-bottom: 10px!important;
        text-decoration: underline;
      }
      @media(max-width:$screen-sm){
          display:block;
          margin-bottom: 0px;
          app-order-summary{
              .os-summary-list{
                  .order-summary-container{
                    display: block;
                    position: initial;
                    width: 100% !important;
                    background-color: #f6f6f6 !important;
                    padding: 12px;
                    margin-top: 8px;
                    h3,h5{
                      font-size: 14px;
                    }
                    .return-checkout{
                        display: none;
                    }
                    .order-summary-product-row .order-summary-product-price {
                      float: right;
                      width: 40%;
                    }
                    .order-summary-head{
                      height:48px;
                      h5{
                        float: left;
                      }
                      .link-edit-basket-de{
                        float:right;
                      }
                    }
                    .order-summary-subtotal,.order-summary-delivery,.order-summary-total,.order-summary-head{
                      clear: both;
                      .order-summary-subtotal-label,.order-summary-delivery-label,.order-summary-total-label{
                        float:left;
                      }
                      .order-summary-subtotal-price,.order-summary-delivery-con,.order-summary-total-price{
                        float:right;
                      }
                    }
                  }
              }
          }
      }
  }
}
#journal-homepage{
  text-align: center;
  height:auto;
  @media(max-width: $screen-sm){
    width:100%;
  }
}
.slick-initialized {
  overflow: hidden;
  }
  .slick, .slick-wrapper {
    width: 100%;
  }
@media screen and (max-width:768px){
    #accordion .discard-toggle .sidemenu-title:after {
      display: none;
      content: '';
    }
  }

  #pickCarouselModal{
    .slick-slide{
      img{
        width: 100px;
        margin: auto;
        @media screen and (max-width: 768px){
          width: 160px;
          height: 160px;
        } 
      }
    }
}
.facets {
  @media screen and (max-width: $screen-sm) {
    display: none !important;
  }  
}
.search-facets{
  @media screen and (max-width: $screen-sm  ) {
    display: none !important;
  }
}
.sample-slick-carousel-container{
  img{
    @media only screen and (min-width : 1224px) {
     width: 120px;
    }
  }
}
app-product-detail-page{
  .product_detail {
      min-height: 750px;   
      margin-bottom: 40px;
      @media screen and (max-width:768px){
          margin-bottom: 0px;
      flex-direction: column;
        }
  
  }
  }
  .store-amenities{
    a{
        color:$primary-color;
        &:hover{
         color:$primary-color;
        }
    }
}
.hazardous-entry-block{
  .redstar{
      a{
       color:inherit !important;
     }
 }
}
.out-of-stock-cntnt{
  a{
    color: $err-color !important;
  }          
  ul{
    margin-left:15px;
    list-style: disc !important;
  }
}
.slick-nav-section {
  h3{
    font-size: 16px;
    font-style: italic;
  }
  .slick-nav-cta{
  display:flex;
  justify-content: center;
  .slick-Navigation{
    margin: 0 8px;
    h3{
      text-align: center;
      font-size: 22px;
      font-weight: normal;
      font-style: italic;
      font-family: "Times New Roman", Arial, sans-serif;
      color: rgba(0, 0, 0, 0.867);
      position: absolute;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      overflow: hidden;
      height: 26px;
      transform-origin: 0px 0px 0px;
      transform: matrix(1, 0, 0, 1, 75, 199);
    }
    .slick-dots{
      position: unset;
      cursor: pointer;
      li button:before{
        font-size:14px;
      }
    }
  }
  .slick-backward{
    font-size: 18px;
    cursor: pointer;
   }
   .slick-forward{
    font-size: 18px;
    cursor: pointer;
   }
}
}

.entry-cta-disabled{
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  pointer-events: none;
}

.slick-nav-section h3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 1%;
  font-weight: bold;
  &.slick-nav-title{
    cursor: pointer;
  }
  .slick-backward{
    i{
      font-size: 18px;
    }
  }
}
.editorial-carousel-wrapper {
  position: relative;
  .slick-slider{
    position: unset;
  }
  .more-stories-carousel {
    width: 95%;
  }
}

#renderAmpStaticContent 
{
  
  .amp-container {
    color: #000;
  }
  p{
    color: #000;
  }
  a{
    color: #000;
  }
  b{
    color: #000;
  }
  strong{
    color: #000;
  }
  span{
    color: #000;
  }
  .mb_FAQ_link_title{
    color: #666;
  }
  .amp-container p a{
    color: #000;
  }
  li{
    color: #000;
    line-height: 1.42857;
  }
}
.login_fields select, .login-fields select{
  appearance: auto;
}
//address finder (loqate-container) changes for production issue
.loqate-container{
  #searchResultContainer {
    position: relative;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    background-color: #e5e5e5;
    overflow-y: auto;
    overflow: auto;
    width: 335px;
    @media (max-width: $screen-sm) {
      width: 100%;
    }
    &.child-added {
      width: 100%;
      height: 300px;
      margin-top: -24px;
      @media (max-width: $screen-sm) {
        width: 100%;
        height: 300px;
      }
    }
    &.hide {
      display: none;
    }
  
    .search-result-placeholder {
      color: #000000;
    }
    li {
      list-style-type: none;
      font-size: 14px;
      &.result-header {
        padding: 8px 6px;
        span {
          font-size: 16px;
        }
      }
    }
  }
  #searchResultContainer div {
    padding: 19px 0px 22px 26px;
    cursor: pointer;
  }
  
  #searchResultContainer li:nth-child(odd) {
    background-color: #e5e5e5;
  }
  #searchResultContainer li:nth-child(even) {
    background-color: #ffffff;
  }
  #address-result-container {
    .address-container {
      padding-bottom: 30px;
      border-bottom: 1px solid #000;
      p {
        @media screen and (max-width: 874px) {
        padding-top: 0;
      }
      }
    }
  }

}
//For stories pages only
.articleCardsDiv {
  .article-card-section {
    .img-video-holder {
      img {
        width: 100%;
      }
    }
  }
}

div#modalSubscriptionCheckoutPopup {
    z-index: 1057;
  .modal-dialog {
    a{
      text-decoration: underline;
      text-underline-offset: 2px;
      color: inherit;
    }
    a:hover{
      text-decoration: none;
    }
    @media (min-width: 360px) and (max-width: 874.98px) {
      height: 80vh;
      top: 0;
      justify-content: start;
      overflow-y: hidden;
      
       div, p, a, span,b {
          font-size: 16px !important;
      }
    }
  }
}
div#ogModalForSubscriptionLink{
   z-index: 1057;
    .modal-dialog {
      a{
        text-decoration: underline;
        text-underline-offset: 2px;
        color: inherit;
      }
      a:hover{
        text-decoration: none;
      }
    @media (min-width: 360px) and (max-width: 874.98px) {
      height: 80vh;
      top: 0;
      justify-content: start;
      
       div, p, a, span,b {
          font-size: 16px !important;
      }
    }
  }
}