

   .shop-this-story-prdct-link{
       margin-top:20px;
       position:relative;
   }
   .shop-story-prodct-cta{
    font-family: 'Times New Roman',Times,serif;
    font-style: italic;
    font-size: 14px;
    padding: 7px 25px 5px;
    line-height: 20px;
    text-decoration: none;
    color:#000;
    text-transform:none;
    background-color:#fff;
    
   }
   .shop-story-prodct-cta::after {
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-bottom: 5px solid transparent;
    content: "";
    display: inline-block;
    height: auto;
    width: auto;
    margin-left: 3px;
    vertical-align: baseline;
    visibility: visible;
}
    
.editorial-carousel-wrapper{
  width:100%;
  padding-top: 20px;
  text-align:center;
}
.editoril-carousel{
  width:90%;
  margin:0px auto;
}
.slick-slide{
  margin:10px;
}
.slick-slide img{
  width:100%;
  border: 2px solid #fff;
}
.shop-story-prodct-description {
font-size:12px;
}
.amp-dc-card {
width: 100%;
display: inline-block;
vertical-align: top;
position: relative;
}
.amp-dc-card.journal-img-729 {
width: 729px;
height: auto;
}
.amp-dc-card .video-player-ft {
display: block;
position: absolute!important;
margin: -36px auto 0 -36px;
top: 50%;
left: 50%;
z-index: 10;
width: 70px;
height: 70px;
line-height: 80px;
border-radius: 50%;
border: 7px solid rgba(255,255,255,.8);
overflow: hidden;
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: center;
justify-content: center;
}
.amp-dc-card .video-player-ft .animated.infinite {
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
}

.amp-dc-card .video-player-ft .animated.infinite {
-webkit-animation-iteration-count: infinite;
animation-iteration-count: infinite;
}
.amp-dc-card .video-player-ft .video-arrow {
display: inline-block;
margin: 2px 0 0 6px;
width: 0;
height: 0;
border-top: 18px solid transparent;
border-left: 34px solid rgba(255,255,255,.8);
border-bottom: 18px solid transparent;
}
.amp-dc-card .video-player-ft .animated {
-webkit-animation-duration: 1.9s;
animation-duration: 1.9s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.amp-dc-card .video-player-ft .zoomIn {
-webkit-animation-name: zoomIn;
animation-name: zoomIn;
}
.animated {
background-repeat: no-repeat;
background-position: left top;
-webkit-animation: .5s bounce;
animation: .5s bounce;
-webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
background-repeat: no-repeat;
background-position: left top;
}
.quote blockquote {
font-family: Didot, Georgia!important;
position: relative;
}
.quote blockquote::before {
content: "\201C";
font-size: 189px;
font-family: Didot,Georgia;
font-style: italic;
position: absolute;
top: -56px;
z-index: 1;
color: rgba(0,0,0,.2);
left: -17px;
}
.quote blockquote h2 {
padding: 25px 58px 0 50px;
font-size: 30px;
}
.quote blockquote em {
line-height: 1.2;
font-family: Didot, Georgia!important;
}
.journal-image-729{
width:729px;
margin:0 auto;
}
.shop-story-prodct-name {
height:35px;
}
.shop-story-prodct-cta a { 
color:#000 !important;
}
.sop-sec-heading{
text-align:center;
}
@media screen and (max-width: 874px){
.Editorial-heading-section .title {
margin: 25px 0 0px;
}
}
.shop-this-story{
    width: 874px;
    margin: 0 auto;
    margin-top: 25px;
    .perfumer-prodct-block{
         width: 100%;
         margin:0 auto;
         text-align:center;
      .shop-this-story-prdct {
        width: 24.5%;
        height: auto;
        position : relative;
        display:inline-block;
        vertical-align: top;
        margin:0 auto;
      }
    }
}
