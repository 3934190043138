.amp-dc-homepage {
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
  .amp-dc-block-wrap {
    margin-top: 0px;
  }
  .amp-dc-card-img-wrap{
    margin-top:20px;
  }

  .amp-dc-text {
    margin: 0 25px;
  }

  .amp-dc-splitBlock {
    overflow: hidden;
  }

  .amp-dc-splitBlock {
    overflow: hidden;
  }

  .amp-dc-promo-banner-wrap {
    margin: 20px 0;
  }

  .amp-dc-promo-banner {
    margin: -70px -5px 0 -5px;
  }
}

.header-wrap {
  ul {
    list-style: none;
  }

  .header-content {
    margin: 10px 50px;
    overflow: hidden;
    height: 40px;
  }

  .logo {
    float: left;
    width: 220px;
    height: 100%;
    display: inline-block;
  }

  .logo-image {
    height: 100%;
  }

  .top-menu {
    overflow: hidden;
    text-align: center;
    margin: 10px 0;

    & > li {
      display: inline-block;
      vertical-align: top;
      margin-right: 23px;

      &:last-child {
        margin-right: 0;
      }

      & > a {
        color: #000;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .customer-menu {
    float: right;
    height: 40px;
    margin: 0;
    padding: 0;
    & > li {
      display: inline-block;
      vertical-align: top;
      padding: 0 24px;

      & > a {
        text-decoration: none;
        color: #000;
        font-size: 36px;
        height: 40px;
        line-height: 40px
      }
    }
  }

  .header-promotion {
    background-color: #000;
    color: #fff;
    padding: 5px 7px;
    text-align: center;
    font-size: 12.8px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 979px) {
  .header-wrap {
    .logo {
      width: auto;
    }
    .top-menu {
      display: none;
    }
  }
}

@media screen and (max-width: 499px) {
  .header-wrap {
    .customer-menu {
      .first-nav, .second-nav {
        display: none;
      }
    }
  }
}
  
