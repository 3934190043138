@import"../variable";
.bg-results {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	background-repeat: repeat;
    background-position: center;
}
.ds-ff-video{
  display: block;
  @media (max-width:$screen-sm){
   display:none !important;
  }
}
.mb-ff-img{
	display: none;
	@media (max-width:$screen-sm){
	 display:block !important;
	}
}
.btn-ff-cta {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.restart-arrow-container.btn-ff-cta  {
    margin: 0;
    border-radius: 0;
    border: 1px solid;
    background-color: #000;
	color: #fff;
	@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 667px) and (min-device-width: 375px){
		width:80px !important;
	}
}
.left-arrow-container.btn-ff-cta {
    margin: 0;
    border-radius: 0;
    border: 1px solid;
    background-color: #000;
	color: #fff;
	@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 667px) and (min-device-width: 375px){
		width:80px;
	}
}

form {

	&.initialProfile {
		height:610px;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			height:568px;
		}

		@media only screen 
		and (min-device-width: 360px) 
		and (max-device-width: 640px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			height:640px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			height:667px;
		}
		

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 731px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			height:736px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			height:812px;
		}

		
	}

	&.finalProfile {
		height: 1350px;


		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			height:2200px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			height:2200px
		}

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 731px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			height:2237px;
		}

		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px) 
		and (orientation : portrait) {
			height:2237px;
		}
	}

	&.resultsPage {
		padding:80px;
		min-height: inherit;
		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			padding: 45px 0 70px;
			height:568px;
			height: auto;
			min-height: inherit;
    		background-position: top left;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			padding: 45px 0 70px;
			height:667px;
			height: auto;
			min-height: inherit;
			background-position: top left;
			-webkit-background-size: contain;
			-moz-background-size: contain;
    		background-size: contain;
			
		}

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			padding: 70px 0px 70px 0px;
			height: auto;
			min-height: inherit;
			background-position: top left;
			-webkit-background-size: contain;
			-moz-background-size: contain;
    		background-size: contain;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			padding: 70px 0px 70px 0px;
			height: auto;
			min-height: inherit;
			background-position: top left;
			-webkit-background-size: contain;
			-moz-background-size: contain;
    		background-size: contain;
		}

		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px) 
		and (orientation : portrait) {
			padding: 70px;
			height: auto;
			min-height: inherit;
			background-position: top left;
			-webkit-background-size: cover;
			-moz-background-size: cover;
    		background-size: cover;
		}
	}

	&.active {
		background-image: linear-gradient(#f3f3f3, #fff,#fff,#fff,#fff,#f3f3f3); //#f3f3f3 f2f2f2
	}

	&.blackPepper {
		background-image:url(/assets/resource/img/uk/results/black-pepper.jpg);
		@extend .bg-results;
	}
	&.bushukan {
		background-image:url(/assets/resource/img/uk/results/bushukan.jpg);
		@extend .bg-results;
	}
	&.coastalCypress {
		background-image:url(/assets/resource/img/uk/results/coastal-cypress.jpg);
		@extend .bg-results;
	}
	&.floraLuminaire {
		background-image:url(/assets/resource/img/uk/results/flora-luminaire.jpg);
		@extend .bg-results;
	}
	&.geranium {
		background-image:url(/assets/resource/img/uk/results/geranium.jpg);
		@extend .bg-results;
	}
	&.gingerlily {
		background-image:url(/assets/resource/img/uk/results/gingerlily.jpg);
		@extend .bg-results;
	}
	&.jasmineSunRose {
		background-image:url(/assets/resource/img/uk/results/jasmine-sun-rose.jpg);
		@extend .bg-results;
	}
	&.orangeBergamot {
		background-image:url(/assets/resource/img/uk/results/orange-bergamot.jpg);
		@extend .bg-results;
	}
	&.oudh {
		background-image:url(/assets/resource/img/uk/results/oudh.jpg);
		@extend .bg-results;
	}
	&.pinkPepper {
		background-image:url(/assets/resource/img/uk/results/pink-pepper.jpg);
		@extend .bg-results;
	}
	&.rosaAbsolute {
		background-image:url(/assets/resource/img/uk/results/rosa-absolute.jpg);
		@extend .bg-results;
	}
	&.russianLeather {
		background-image:url(/assets/resource/img/uk/results/russian-leather.jpg);
		@extend .bg-results;
	}
	&.suedeOrris {
		background-image:url(/assets/resource/img/uk/results/suede-orris.jpg);
		@extend .bg-results;
	}
	&.tobaccoAbsolute {
		background-image:url(/assets/resource/img/uk/results/tobacco-absolute.jpg);
		@extend .bg-results;
	}
	&.vetiverGrapefruit {
		background-image:url(/assets/resource/img/uk/results/vetiver-grapefruit.jpg);
		@extend .bg-results;
	}
}

.container {
	position: relative;

	@media (min-width: 1200px) {
    	max-width: 911px;
	}

	@media only screen 
	and (min-device-width : 768px) 
	and (max-device-width : 1024px) 
	and (orientation : portrait) {
		width:100% !important;
	}
}

.form-check-inline {
	margin:0;
	padding:0;
	max-width: 97%;

	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		max-width:100%;
	}

	@media only screen 
	and (min-device-width: 360px) 
	and (max-device-width: 640px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
	}

	@media only screen 
	and (min-device-width: 411px) 
	and (max-device-width: 731px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
	}

	@media only screen 
	and (min-device-width: 411px) 
	and (max-device-width: 731px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		max-width:100%;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		max-width:100%;
	}

	@media only screen 
	and (min-device-width : 768px) 
	and (max-device-width : 1024px) 
	and (orientation : portrait) {
		max-width:100%;
	}
}

.app-holder {
	
	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width: 360px) 
	and (max-device-width: 640px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width: 411px) 
	and (max-device-width: 731px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width: 411px) 
	and (max-device-width: 731px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		max-width:100%;
		padding:0px;
	}

	@media only screen 
	and (min-device-width : 768px) 
	and (max-device-width : 1024px) 
	and (orientation : portrait) {
		max-width: none !important;
		padding:0px;
	}
	
}

.welcome-copy, .begin-copy {

	position: relative;
	background-image: url(/assets/resource/img/main-bg.jpg);
    height: 610px;
    background-position: center top;
    background-repeat: no-repeat;

	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:568px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	@media only screen 
	and (min-device-width: 360px) 
	and (max-device-width: 640px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:640px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:667px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	@media only screen 
	and (min-device-width: 411px) 
	and (max-device-width: 731px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:731px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	@media only screen 
	and (min-device-width: 414px) 
	and (max-device-width: 736px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:736px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		background-image: url(/assets/resource/img/mobile-main-bg.jpg);
		height:812px;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		background-size: cover;
	}

	
	.welcome-holder {
		position: absolute;
		transform:translate(-50%, -50%);
		top:50%;
		left:50%;
		width:69%;
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			width:100%;
			margin-top: -8%;
		}

		@media only screen 
		and (min-device-width: 360px) 
		and (max-device-width: 640px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:100%;
			margin-top: -8%;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:100%;
			margin-top: -8%;
		}

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 731px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:100%;
			margin-top: -8%;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:100%;
			margin-top: -8%;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:100%;
			margin-top: -8%;
		}

		h1 {
			font-size:28px;
			line-height:33px;
			margin-bottom:5px;
			font-family: "Times New Roman", Times, serif;
			padding: 0 10% 0 10%;

			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				padding: 0 20%;
			}

			@media only screen 
			and (min-device-width: 360px) 
			and (max-device-width: 640px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				padding: 0 20%;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 667px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				padding: 0 20%;
			}

			@media only screen 
			and (min-device-width: 411px) 
			and (max-device-width: 731px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				padding: 0 20%;
			}

			@media only screen 
			and (min-device-width: 414px) 
			and (max-device-width: 736px) 
			and (-webkit-min-device-pixel-ratio: 3) { 
				padding: 0 20%;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 812px) 
			and (-webkit-min-device-pixel-ratio: 3) { 
				padding: 0 20%;
			}
		}

		h3 {
			font-size:20px;
			font-style: italic;
			font-weight: 100;
			margin-bottom:20px;
			font-family: "Times New Roman", Times, serif;
		}
	
		h6 {
			font-size:14px;
			line-height: 20px;
    		margin-bottom: 18px;
		}
		
		i {
			display: block;
			
		}

		img {
			display: block;
			margin: 0 auto;
			width:40px;
		}

		h4 {
			font-size:18px;
			margin:15px 0px;
		}

		.btn {
			margin-top:24px;
			height:32px;
			width:218px;
			border-radius: 0px;
			color:#fff;
			border-color: #000;
			background-color:#000;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;

			&:hover {
				background-color: #fff;
				color:#000;
			}
		}
	}
	
}

.results-container {
	.container {
		
		background-color: #fff; // for the initial profile
		text-align:center;
		padding-top: 20px;

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			max-width:100%;
			padding:0px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			max-width:100%;
			padding:0px;
		}

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			max-width:100%;
			padding:0px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			max-width:100%;
			padding:0px;
		}


		&.primary {
			height:425px;
			padding:60px;
            width : auto;
			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				padding: 35px;
				height: auto;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 667px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				padding: 35px;
				height: auto;
			}

			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : portrait) {
				padding: 35px;
				height: auto;
			}


		}

		&.secondary {
			// height: 1100px;

			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				height: auto;
				padding-bottom: 20px;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 667px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				height: auto;
				padding-bottom: 20px;
			}

			@media only screen 
			and (min-device-width: 411px) 
			and (max-device-width: 731px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				height: auto;
				padding-bottom: 20px;
			}

			@media only screen 
			and (min-device-width: 414px) 
			and (max-device-width: 736px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				height: auto;
				padding-bottom: 20px;
			}

			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : portrait) {
				height: auto;
				padding-bottom: 20px;
			}

			

			.primary-product {

				height:400px;
			
				@media only screen 
				and (min-device-width: 375px) 
				and (max-device-width: 667px) 
				and (-webkit-min-device-pixel-ratio: 2) { 
					margin:0px;
					height:auto;
				}

				@media only screen 
				and (min-device-width: 320px) 
				and (max-device-width: 568px)
				and (-webkit-min-device-pixel-ratio: 2) {
					margin:0px;
					height:auto;
				}

				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px) 
				and (orientation : portrait) {
					margin:0px;
					height:auto;
				}

				img {
					width:100%;
					position: relative;

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						top:-45px;
						padding-bottom: 40px;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						top:-45px;
						padding-bottom: 40px;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						top:-45px;
						padding-bottom: 40px;
					}
				}

				.product-checkbox {
					position: absolute;
					bottom: -12px;
					width: 100%;
					left: 0px;
					font-size:16px;
					text-align: center;
					padding-left: 20px;

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						bottom:50px;
						margin: 20px 0;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						bottom:50px;
						margin: 20px 0;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						bottom:50px;
						margin: 20px 0;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						bottom:50px;
						margin: 20px 0;
						text-align: center;
					}

					label {
						margin-bottom: 0px;
						font-family:$font-century-regular;
					}

					#one-prod {
						display: none;
					}

					.checker {
						display:inline-block;
						padding:0;
						background:url(/assets/resource/img/unchecked-sprite.png) no-repeat;
						height: 28px;
						width: 28px;
						background-size: 100%;
					}
					
					#one-prod:checked + .checker{
						background:url(/assets/resource/img/checked-sprite.png) no-repeat;
						padding: 0;
						height: 28px;
						width: 28px;
						display:inline-block;
						background-size: 100%;
					}

					span {
						text-transform: uppercase;
						font-weight: 700;
						font-size:14px;
						vertical-align: 8px;
						font-family:$font-century-regular;
						&.not-available {
							color:#9c1006;
							font-size:12px;
							font-weight: normal;
							text-transform: none;
						}

						&.selected {
							color:#959595; 
						}
					}
				}

				.img-fragrance {
					padding-top:20px;
					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						order:2;
						padding-top:0px;
						padding-bottom:30px;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						order:2;
						padding-top:30px;
						padding-bottom:30px;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						order:2;
						padding-top:30px;
						padding-bottom:30px;
						flex: none !important;
						max-width: 100% !important;
					}

					p {
						
						display:none;

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 

							font-size:13px;
							margin-bottom:0px;
							position: absolute;
							bottom: -10px;
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:block;

							a {
								color:#000;
								text-decoration: underline;
							}
						}

						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {

							font-size:13px;
							margin-bottom:0px;
							position: absolute;
							bottom: -10px;
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:block;

							a {
								color:#000;
								text-decoration: underline;
							}
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							font-size:13px;
							margin-bottom:0px;
							position: absolute;
							bottom: -10px;
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:block;

							a {
								color:#000;
								text-decoration: underline;
							}
						}
					}
				}

				.copy-fragrance {

					//padding-top:55px;
					padding-right:40px;
					text-align:left;
					//ADDED
					flex-direction: column;
					justify-content: flex-end;
					padding-left: 0;
					display: flex;

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						order:1;
						text-align:center;
						padding: 40px 30px 0px 30px;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						order:1;
						text-align:center;
						padding: 40px 30px 0px 30px;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						order:1;
						text-align:center;
						padding: 40px 30px 0px 30px;
						flex: none !important;
						max-width: 100% !important;
					}

					p {
						font-size:11px;
						margin-bottom:0px;
						margin-top: 32px;

						a {
							color:#000;
							text-decoration: underline;
						}

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:none;
						}

						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:none;
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							left:50%;
							width:100%;
							transform:translate(-50%, -50%);
							display:none;
						}
					}
				}

			}

			.secondary-product {

				margin-top:40px;
				padding-left: 25px;
    			padding-right: 25px;

				@media only screen 
				and (min-device-width: 375px) 
				and (max-device-width: 667px) 
				and (-webkit-min-device-pixel-ratio: 2) { 
					width:100%;
					margin:20px 0px 0px 0px;
				}

				@media only screen 
				and (min-device-width: 320px) 
				and (max-device-width: 568px)
				and (-webkit-min-device-pixel-ratio: 2) {
					width:100%;
					margin:20px 0px 0px 0px;
				}

				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px) 
				and (orientation : portrait) {
					width:100%;
					margin:20px 0px 0px 0px;
				}

				.col-md-6 {

					padding: 0 15px;
				/*	height: 355px;*/
					
					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						padding: 0 15px 0 15px;
						height:auto;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						padding: 0 15px 0 15px;
						height:auto;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						padding: 0 15px 0 15px;
						height:auto;
						flex: none !important;
						max-width: 100% !important;
					}
				}

				.product-checkbox {

					/*position: absolute;*/
					bottom: 0px;
					width: 100%;
					left: 0px;
					text-align: center;

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						bottom:50px;
						margin: 20px 0;
						text-align: center;
					}
					
					label {
						margin-bottom: 0px;
						font-family:$font-century-regular;
					}

					#two-prod-0, #two-prod-1 {
						display: none;
					}

					.checker {
						display:inline-block;
						padding:0;
						background:url(/assets/resource/img/unchecked-sprite.png) no-repeat;
						height: 28px;
						width: 28px;
						background-size: 100%;
					}
					
					#two-prod-0:checked + .checker, #two-prod-1:checked + .checker {
						background:url(/assets/resource/img/checked-sprite.png) no-repeat;
						padding: 0;
						height: 28px;
						width: 28px;
						display:inline-block;
						background-size: 100%;
					}
					span {
						text-transform: uppercase;
						font-size: 14px;
						font-weight: 700;
						vertical-align: 8px;
						font-family:$font-century-regular;
						padding-left: 3px;
						&.not-available {
							color:#9c1006;
							font-size:12px;
							font-weight: normal;
							text-transform: none;
							vertical-align: -7px;
						}

						&.selected {
							color:#959595; 
						}
					}
				}

				img {
					width:40%;

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						width:55%;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						width:55%;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						width:55%;
					}
				}

				h4 {
					font-size:16px;
					font-family: 'Times New Roman', Times, serif;
					margin:5px 0px 10px 0px;
				}

				h6 {
					margin-bottom: 15px;
					height: auto;
					min-height: 55px;
					font-size: 13px;
					line-height: 18px;
				}

				.checkout {
					&.btn {
						margin-top:23px;
						height:32px;
						width:160px;
						border-radius: 0px;
						color:#fff;
						border-color: #959595; 
						background-color:#959595;
						text-transform: uppercase;
						font-size: 14px;
						line-height: 16px;
						
						&:disabled {
							opacity: 1;
							&:hover {
								cursor:progress;
							}
						}

						&:not(:disabled) {
							background-color:#000;
							border-color:#000;

							&:hover {
								cursor:pointer;
							}
						}

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 
							margin-top: 20px;
    						margin-bottom: 10px;
						}

						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {
							margin-top: 20px;
    						margin-bottom: 10px;
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							margin-top: 20px;
    						margin-bottom: 10px;
						}

					}

					&.active {
						border-color: #000;
						background-color:#000;
					}
				}

				.map {

					img {
						width: auto;
						display: block;
						margin: 0 auto;
						margin-top: 25px;
						margin-bottom: 2px;
					}

					.fa-map-marker-alt {
						display:block;
						margin: 20px 0px 10px 0px;
					}
					span {
						font-size:13px;

						a {
							color:#000;
							text-decoration: underline;
						}
					}
				}
				

				.contact-us {
					
					margin-top: 18px;

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						width: 100%;
						margin-top: 20px;
					}

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						width: 100%;
						margin-top: 20px;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						width: 100%;
						margin-top: 20px;
					}

					.input-group {

						display: flex;
						flex-direction: column;
						align-items: center;

						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {
							width:100%;
						}

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 
							width:100%;
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							width:100%;
						}

						.bold-upper-text {
							text-transform: uppercase;
							font-weight: 700;
							font-size: 14px;
							margin-bottom: 0px;
						}


						span {
							display:flex;
							text-transform: uppercase;
							padding-top:8px;


							@media only screen 
							and (min-device-width: 375px) 
							and (max-device-width: 667px) 
							and (-webkit-min-device-pixel-ratio: 2) { 
								font-size:17px;
							}

							@media only screen 
							and (min-device-width: 320px) 
							and (max-device-width: 568px)
							and (-webkit-min-device-pixel-ratio: 2) {
								font-size:17px;
							}

							@media only screen 
							and (min-device-width : 768px) 
							and (max-device-width : 1024px) 
							and (orientation : portrait) {
								font-size:17px;
							}

							&.restart-link {
								text-decoration: underline;

								&:hover {
									cursor: pointer;
								}
							}

							&:first-of-type {
								/*margin:0 30px;*/

								@media only screen 
								and (min-device-width: 320px) 
								and (max-device-width: 568px)
								and (-webkit-min-device-pixel-ratio: 2) {
									margin:0 15px 0 0px;
								}

								@media only screen 
								and (min-device-width: 360px) 
								and (max-device-width: 640px) 
								and (-webkit-min-device-pixel-ratio: 2) { 
									margin:0 15px 0 20px;
								}

								@media only screen 
								and (min-device-width: 375px) 
								and (max-device-width: 667px) 
								and (-webkit-min-device-pixel-ratio: 2) { 
									margin:0 15px 0 10px;
								}

								@media only screen 
								and (min-device-width: 411px) 
								and (max-device-width: 731px) 
								and (-webkit-min-device-pixel-ratio: 2) { 
									margin:0 25px 0 30px;
								}

								@media only screen 
								and (min-device-width : 768px) 
								and (max-device-width : 1024px) 
								and (orientation : portrait) {
									margin:0 25px 0 30px;
								}
							}
						}

						.form-control {
							height: 32px;
							width: 245px;
							border: 2px solid black;
							border-radius: 0px;
							font-size: 13px;
							margin-top: 15px;
							margin-bottom: 15px;

							@media only screen 
							and (min-device-width: 320px) 
							and (max-device-width: 568px)
							and (-webkit-min-device-pixel-ratio: 2) {
								width:100%;
								margin-bottom:20px;
								border-radius: 0px;
							}

							@media only screen 
							and (min-device-width: 375px) 
							and (max-device-width: 667px) 
							and (-webkit-min-device-pixel-ratio: 2) { 
								width:100%;
								margin-bottom:20px;
								border-radius: 0px;
							}

							@media only screen 
							and (min-device-width : 768px) 
							and (max-device-width : 1024px) 
							and (orientation : portrait) {
								width:100%;
								margin-bottom:20px;
								border-radius: 0px;
							}
						}
					}
					.btn {
						margin-top:0px;
						height: 32px;
						width: 110px;
						border-radius: 0;
						background-color:transparent;
						text-transform: uppercase;
						font-size: 14px;
						font-weight: bold;
						line-height: 16px;
						//border: 2px solid black;

						&:disabled {
							opacity: 1;
							border: 2px solid #959595; 
							color:#959595; 
							&:hover {
								cursor:progress;
							}
						}

						&:not(:disabled) {
							border: 2px solid #000;
							color:#000;
							&:hover {
								cursor:pointer;
							}
						}

						span {
							display: block;
							padding: 0px;
						}

						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {
							width: 40%;
							margin-top:20px;
							margin:0 auto;
							border-left:2px solid;
						}

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 
							width: 40%;
							margin-top:20px;
							margin:0 auto;
							border-left:2px solid;
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							width: 40%;
							margin-top:20px;
							margin:0 auto;
							border-left:2px solid;
						}
					}

					.email-checkboxes{
						display: flex;
						width: 90%;
						flex-direction: row;
						justify-content: center;
						align-content: center;
						align-items: center;
						margin-bottom: 15px;

						@media only screen 
						and (min-device-width: 375px) 
						and (max-device-width: 667px) 
						and (-webkit-min-device-pixel-ratio: 2) { 
							text-align: left;
							width: 100%;
							display: inline-block;
						}
	
						@media only screen 
						and (min-device-width: 320px) 
						and (max-device-width: 568px)
						and (-webkit-min-device-pixel-ratio: 2) {
							text-align: left;
							width: 100%;
							display: inline-block;
						}

						@media only screen 
						and (min-device-width : 768px) 
						and (max-device-width : 1024px) 
						and (orientation : portrait) {
							text-align: left;
							width: 100%;
							display: inline-block;
						}

						.email-checkbox {
							width: 100%;
							left: 0px;
							font-size:13px;
							// text-align: left;
		
							@media only screen 
							and (min-device-width: 375px) 
							and (max-device-width: 667px) 
							and (-webkit-min-device-pixel-ratio: 2) { 
								bottom:50px;
							}
		
							@media only screen 
							and (min-device-width: 320px) 
							and (max-device-width: 568px)
							and (-webkit-min-device-pixel-ratio: 2) {
								bottom:50px;
							}

							@media only screen 
							and (min-device-width : 768px) 
							and (max-device-width : 1024px) 
							and (orientation : portrait) {
								bottom:50px;
							}
							
							a {
								color:#000;
								text-decoration: underline;

								&:hover {
									color:#000;
								}
							}

							label {
								margin-bottom: 0px;
							}
		
							input {
								display: none;
							}
		
							.checker {
								display:inline-block;
								padding:0;
								background:url(/assets/resource/img/unchecked-sprite.png) no-repeat;
								height: 40px;
								width: 40px;
								background-size: 100%;
							}
							
							input:checked + .checker{
								background:url(/assets/resource/img/checked-sprite.png) no-repeat;
								padding: 0;
								height: 40px;
								width: 40px;
								display:inline-block;
								background-size: 100%;
							}
		
							span, span:first-of-type {
								display: inline-block;
								text-transform: none;
								vertical-align: top;
								margin: 0;
								width: 70%;
								text-align: left;
								font-size: 12px;
								line-height: 15px;
								min-width: 226px;

								@media only screen 
								and (min-device-width: 375px) 
								and (max-device-width: 667px) 
								and (-webkit-min-device-pixel-ratio: 2) { 
									width:80%;
									font-size: 13px;
    								line-height: 16px;
									vertical-align: 6px;
								}
			
								@media only screen 
								and (min-device-width: 320px) 
								and (max-device-width: 568px)
								and (-webkit-min-device-pixel-ratio: 2) {
									width:80%;
									font-size: 13px;
    								line-height: 16px;
									vertical-align: 6px;
								}

								@media only screen 
								and (min-device-width : 768px) 
								and (max-device-width : 1024px) 
								and (orientation : portrait) {
									width:80%;
									font-size: 13px;
    								line-height: 16px;
									vertical-align: 12px;
								}
							}
						}
					}
				}

				p {
					margin-top: 15px;
					font-size: 12px;
					line-height: 14px;
					width: 60%;
					min-width: 432px;

					@media only screen 
					and (min-device-width: 375px) 
					and (max-device-width: 667px) 
					and (-webkit-min-device-pixel-ratio: 2) { 
						width: 100%;
						font-size: 13px;
						line-height: 17px;
						min-width: 0;
					}

					@media only screen 
					and (min-device-width: 320px) 
					and (max-device-width: 568px)
					and (-webkit-min-device-pixel-ratio: 2) {
						width: 100%;
						font-size: 13px;
    					line-height: 17px;
						min-width: 0;
					}

					@media only screen 
					and (min-device-width : 768px) 
					and (max-device-width : 1024px) 
					and (orientation : portrait) {
						width: 100%;
						font-size: 13px;
    					line-height: 17px;
						min-width: 0;
					}
				}

				.restart-xp {
					position: absolute;
					bottom: -58px;
					color: white;
					text-decoration: underline;
					text-transform: uppercase;
					cursor: pointer;
					font-size: 12px;
					padding: 0;
					left: 0;
				}
			}

			
		}

		h1 {
			font-family: "Times New Roman", Times, serif;
			font-size:28px;
			text-transform: uppercase;
			margin-bottom: 5px;
		}

		h3 {
			font-family: "Times New Roman", Times, serif;
			font-size:20px;
			font-style:italic;
			margin-bottom: 1%;

			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				font-size: 24px;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 667px) 
			and (-webkit-min-device-pixel-ratio: 2) { 
				font-size: 24px;
			}

			@media only screen 
			and (min-device-width: 414px) 
			and (max-device-width: 736px) 
			and (-webkit-min-device-pixel-ratio: 3) { 
				font-size: 24px;
			}

			@media only screen 
			and (min-device-width: 375px) 
			and (max-device-width: 812px) 
			and (-webkit-min-device-pixel-ratio: 3) { 
				font-size: 24px;
			}

			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : portrait) {
				font-size: 24px;
			}
		}

		.youmight {
			margin-bottom: 10px;
		}

		textarea {
			font-size:14px;
			border:none;
			resize: none;
			position: absolute;
			bottom: 60px;

			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				position: initial;
				bottom:initial;
				width:100%;
				text-align:center;
			}

			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : portrait) {
				position: initial;
				bottom:initial;
				width:100%;
				text-align:center;
			}

			&:focus {
				outline: 0;
			}
		}

		h6 {
			font-size:13px;
			margin-top: 20px;
			margin-bottom: 0px;
			line-height: 18px;
			
			&.identity-copy {

				font-size: 13px;
				//height: 105px;
				margin-top:15px;

				@media only screen 
				and (min-device-width: 320px) 
				and (max-device-width: 568px)
				and (-webkit-min-device-pixel-ratio: 2) {
					margin-bottom:0px;
				}

				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px) 
				and (orientation : portrait) {
					margin-bottom:0px;
				}
			}
			
		}

		.btn {
			margin-top:50px;
			height:32px;
			width:218px;
			border-radius: 0px;
			color:#fff;
			border-color: #000;
			background-color:#000;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;
			
			&:hover {
				background-color: #fff;
				color:#000;
			}
		}
	}
}


.load-container {

	padding-top:100px;
	h3 {
		font-family: "Times New Roman", Times, serif;
		font-size:20px;
		font-style:italic;
		
	}

	img {
		width:16%;
		margin-right: 0;
		margin-left: 0;
		margin-top: 50px;
		margin-bottom: 100px;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			width:35%;
			margin: 50px 0 85px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:35%;
			margin: 50px 0 85px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:35%;
			margin: 50px 0 85px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:35%;
			margin: 50px 0 85px;
		}
	}

	h6 {
		font-size:14px;
		padding: 0 29%;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			padding: 0px 55px;
			font-size: 16px;
			line-height: 20px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			padding: 0px 55px;
			font-size: 16px;
			line-height: 20px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			padding: 0px 55px;
			font-size: 16px;
			line-height: 20px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			padding: 0px 55px;
			font-size: 16px;
			line-height: 20px;
		}

		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px) 
		and (orientation : portrait) {
			padding: 0px 65px;
			font-size: 16px;
			line-height: 20px;
		}
	}

}

.type-container {
	h2 {
		font-size:22px;
		line-height: 26px;
		margin-bottom:15px;
		font-style:italic;
		font-family: "Times New Roman", Times, serif;
		font-weight: lighter;
	}
	
	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		.container {
			max-width:100%;
			padding:0px;
	
			.form-check {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
				width: 100%;
				margin: 0 auto;
				padding: 0;
			}
		}
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		.container {
			max-width:100%;
			padding:0px;
	
			.form-check {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
				width: 100%;
				margin: 0 auto;
				padding: 0;
			}
		}
	}

	@media only screen 
	and (min-device-width: 414px) 
	and (max-device-width: 736px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		.container {
			max-width:100%;
			padding:0px;
	
			.form-check {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
				width: 100%;
				margin: 0 auto;
				padding: 0;
			}
		}
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		.container {
			max-width:100%;
			padding:0px;
	
			.form-check {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-around;
				width: 100%;
				margin: 0 auto;
				padding: 0;
			}
		}
	}
}


/*.animate-div.ng-hide-add,
.animate-div.ng-hide-remove {
    transition: 1s ease-in-out 0.5s;
	top:-600px;
	position: relative;
	opacity: 1;
}

.animate-div.ng-hide {
   top:0px;
   position:relative;
   opacity: 0.5;
}*/


.pref-holder {
	display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.pref-selector {

	input{
		margin:0;
		padding:0;
		-webkit-appearance:none !important;
		-moz-appearance:none;
		appearance:none;
		visibility: hidden;
		
	}

	video {
		object-fit: cover;
		width: 256px;
		height: 250px;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			width:147px;
			height: 147px;
			display:flex;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:175px;
			height: 175px;
			display:flex;
		}

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 731px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:190px;
			height: 190px;
			display: flex;

		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:147px;
			height: 147px;
			display: flex;

		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:190px;
			height: 180px;
			display: flex;
		}

		// iPad Portrait
		@media only screen 
		and (min-device-width : 768px) 
		and (max-device-width : 1024px) 
		and (orientation : portrait) {
			width:190px;
			height: 180px;
			display: flex;
		}
	}

	label {
		max-width:256px; 
		height: auto;
		margin-bottom:0px;

		&.four-only {
			width:188px;
		}

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			width:145px;
			height: 153px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:172px;
			height: 186px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:183px;
			height:	183px;
		}

		@media only screen 
		and (min-device-width: 411px) 
		and (max-device-width: 731px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			width:190px;
			height:200px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			width:190px;
			height:205px;	
		}
	}

	img {
		width:100%;
	}

	&:first-child {
		margin-right:15px;
		margin-left:15px;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 5px 0px 10px;
		}

	}

	&:nth-child(2) {
		
		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 10px 0px 5px;
		}
	} 

	&:nth-child(3) {
		margin-left:15px;
		margin-right:15px;

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			margin: 0px 5px 0px 10px;
		}

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			margin: 0px 5px 0px 10px;
		}
	}

	&:nth-child(4) {
		margin-right:15px;

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 812px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 414px) 
		and (max-device-width: 736px) 
		and (-webkit-min-device-pixel-ratio: 3) { 
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 375px) 
		and (max-device-width: 667px) 
		and (-webkit-min-device-pixel-ratio: 2) { 
			margin: 0px 10px 0px 5px;
		}

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			margin: 0px 10px 0px 5px;
		}
	} 

}


.pref-selector input:active +.fragrance-select{opacity: 1;}
.pref-selector input:checked +.fragrance-select{
   border:1px solid red;
}
.pref-card{
	cursor:pointer;
	-webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
   	opacity: 1;
}



.show-steps {

	padding-top:25px;
	margin-bottom:50px;

	.dot-container {
		position: relative;
		.dot {
			height: 15px;
			width: 15px;
			background-color: #bbb;
			border-radius: 50%;
			display: inline-block;
			border-color:#bbb;
			margin-left:15px;
			margin-right:15px;
			position: relative;
			top:-3px;

			&.active {
				height:22px;
				width:22px;
				background-color: #fff;
				border:2px solid #bbb;
				position: relative;
				z-index: 99;
				top:0px;
			}
		}

		.dot-line {
			position: absolute;
			width:100%;
			margin: 0px;
			display: inline-block;
			top:11px;
			border-top:2px solid #bbb;
			left:0;

			&.first{
				left:41.6%;
				width: 36px;
			}
			
			&.second {
				left:46.1%;
			}

			&.third {
				left:50.6%;
			}

			&.fourth {
				left:55.1%;
			}
			
		}
	}
}
.step-container {
	padding: 0;
	margin: 0 auto;
	margin-top:20px;
	margin-bottom:55px;
	list-style: none;
	width:200px;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	.step-item {
		background: #EFF4F7;
		width: 38px;
		height: 10px;
		font-weight: bold;
		font-size: 0.1px;
		text-align: center;
		border:1px solid #ABBFCA;
	
		&.even {
			border-left: none;
			border-right:none;
		}
		
		&.odd {
			border-right:none;
		}
	
		&.active {
			background: #2A507B;
		}
	
		&:first-child {
			border-radius: 10px 0px 0px 10px;
			/*width: 76px;*/
			border-right:none;
		}
	
		&:last-child {
			border-radius: 0px 10px 10px 0px;
			/*width: 76px;*/
			border-left:none;
		}
	}
}
.left-arrow-container {
	position: absolute !important;
    width: 120px;
    left: 75px;
    bottom: -152px;
	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		bottom: -55px;
		left:10px;
		width:80px;
	}

	@media only screen 
	and (min-device-width: 414px) 
	and (max-device-width: 736px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		bottom: -55px;
		left:10px;
	}

	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		bottom: -55px;
		left:10px;
		padding: 3px 5px;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		bottom: -55px;
		left:10px;
	}

	&.intro {
		width: 220px;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			width:170px;
			padding: 3px 5px;
		}

		img {
			@media only screen 
			and (min-device-width: 320px) 
			and (max-device-width: 568px)
			and (-webkit-min-device-pixel-ratio: 2) {
				vertical-align: -1px;
			}
		}
	}

	&:hover {
		cursor: pointer;
	}

	

	&.btn {
		margin: 0px;
		border-radius: 0px;
		border: 1px solid;
		background-color: black;
		color: white;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			padding: 3px 5px;
		}
	}

	i {
		font-size:1.3em;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			font-size:0.9em;
		}
	}

	img {
		vertical-align: -1px;
	}

	.left-arrow {
		width: 0px;
		height: 0px;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-right: 25px solid #ABBFCA;
		bottom: -70px;
		left: -15px;	
	}

	span {
		width: 165px;
		font-size: 14px;
		line-height: 16px;
		vertical-align: 0;
		margin-left: 5px;
		text-transform: uppercase;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			font-size:10px;
			vertical-align: 2px;
			margin-left:2px;
		}
	}
}

.restart-arrow-container {
	position: absolute !important;
    width: 120px !important;
    right: 42px;
	bottom: -152px;
    background-color: #000;
    color: #fff;
	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 812px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		bottom: -55px;
		right:10px;
		width:80px;
	}

	@media only screen 
	and (min-device-width: 414px) 
	and (max-device-width: 736px) 
	and (-webkit-min-device-pixel-ratio: 3) { 
		bottom: -55px;
		right:10px;
	}
	
	@media only screen 
	and (min-device-width: 320px) 
	and (max-device-width: 568px)
	and (-webkit-min-device-pixel-ratio: 2) {
		width:80px;
	    padding: 3px 5px;
	}

	@media only screen 
	and (min-device-width: 375px) 
	and (max-device-width: 667px) 
	and (-webkit-min-device-pixel-ratio: 2) { 
		bottom: -55px;
		right:10px;
	}

	&.btn {
		margin: 0;
		border-radius: 0;
		border: 1px solid;
		background-color: #000;
		color: #fff;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			padding: 3px 5px;
		}
	}

	img {
		vertical-align: -3px;
	}

	i {
		font-size:1.3em;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			font-size:0.9em;
		}
	}

	&:hover {
		cursor: pointer;
	}

	.fa-redo-alt {
		transform: rotate(180deg);
	}

	.left-arrow {
		width: 0px;
		height: 0px;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-right: 25px solid #ABBFCA;
		bottom: -70px;
		right: -15px;	
	}

	span {
		display: inline-block;
		font-size: 14px;
		line-height: 16px;
		margin-left: 5px;
		text-transform: uppercase;

		@media only screen 
		and (min-device-width: 320px) 
		and (max-device-width: 568px)
		and (-webkit-min-device-pixel-ratio: 2) {
			font-size:10px;
			vertical-align: 1px;
			margin-left:2px;
		}
	}
}
