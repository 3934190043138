/* Global Typography / Fonts */

@font-face {
  font-family: "Times New Roman";
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/TimesNewRoman/timesNewRoman.woff2') format('woff2');
}
@font-face {
  font-family: "Lato-Bold";
    src: url("/assets/fonts/Lato/lato-bold.woff2") format('woff2'), url("/assets/fonts/Lato/lato-bold.woff") format('woff');
    font-weight: 700;
    font-style:normal;
    font-display: swap;
}
@font-face {
  font-family: "Lato-Black";
  src: url("/assets/fonts/Lato/lato-black.woff2") format('woff2'), url("/assets/fonts/Lato/lato-black.woff") format('woff');
  font-weight: 900;
  font-style:normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Regular";
    src: url("/assets/fonts/Lato/lato-regular.woff2") format('woff2'), ("/assets/fonts/Lato/lato-regular.woff") format('woff');
    font-weight: 400;
    font-style:normal;
    font-display: swap;
}
@font-face {
  font-family: "Lato-Light";
    src: url("/assets/fonts/Lato/lato-light.woff2") format('woff2'), ("/assets/fonts/Lato/lato-light.woff") format('woff');
    font-weight: 300;
    font-style:normal;
    font-display: swap;
}


$baskerville-regular: "Times New Roman", Times, serif;
$gill-sans-book: Lato-Bold, Arial, Helvetica, sans-serif;
$gill-sans-regular: Lato-Regular, Arial, Helvetica, sans-serif;
$gill-sans-light: Lato-Light, Arial, Helvetica, sans-serif;
$gill-sans-black: Lato-Black, Arial, Helvetica, sans-serif;
$mb-base-font-size: 1rem;
$mb-font-bold: 400;
$mb-font-normal: 300;
$mb-primary-line-height: 160%;
$mb-secondary-line-height: 130%;
$mb-captions-line-height: 150%;
$mb-base-font-size-h1: 56px;
$mb-base-font-size-h2: 40px;
$mb-base-font-size-h3: 28px;
$mb-base-font-size-h4: 24px;
$mb-base-font-size-h5: 18px;
$mb-base-font-size-h6: 16px;
$mb-base-font-size-h1-mob: 36px;
$mb-base-font-size-h2-mob: 32px;
$mb-alt-text-large: 28px;
$mb-alt-base-text-size: 20px;
$mb-label-font-size: 14px;
$mb-greybackground-font-size: 13px;
$mb-overline-font-size: 12px;

/* Global Layer Styles - Grayscale Colors*/

$mb-gray-100: #1f1a18;
$mb-gray-90: #3c3734;
$mb-gray-80: #5a5551;
$mb-gray-70: #78736f;
$mb-gray-60: #95908b;
$mb-gray-50: #b3aea8;
$mb-gray-40: #d0cbc3;
$mb-gray-30: #e7e5e1;
$mb-gray-20: #f6f5f3;
$mb-gray-10: #fdfcfc;

/* Global Layer Styles - Support Colors */

$mb-success-50: #008556;
$mb-success-05: #d6f3e2;
$mb-warning-50: #e86825;
$mb-warning-05: #ffe1be;
$mb-alert-50: #de1c22;
$mb-alert-05: #f9d3d4;

$mb-color-black: #16271f;
$mb-color-white: #ffffff;
$mb-color-gray: #e5e5e5;
$molton-black: #1A1A1A;
$molton-navColor: #2A483A;

/* Global Layer Styles / Corners */

$mb-primary-border-radius: 4px;
$mb-secondary-border-radius: 10px;

/* Global Layer Styles / Effects */

$mb-shadow-small: 0px 0px 20px rgba(31, 26, 24, 0.08);
$mb-shadow-medium: 0px 0px 28px rgba(31, 26, 24, 0.18);
$mb-shadow-large: 0px 4px 40px rgba(31, 26, 24, 0.25);

@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

/* Global spacing units */

$space-base-unit: 4px; // 4px
$space-unit-xxs: $space-base-unit * 2; // 8px
$space-unit-xs: $space-base-unit * 3; // 12px
$space-unit-s: $space-base-unit * 4; // 16px
$space-unit-m: $space-base-unit * 5; // 20px
$space-unit-l: $space-base-unit * 6; // 24px
$space-unit-xl: $space-base-unit * 7; // 28px
$space-unit-xxl: $space-base-unit * 8; // 32px
$space-unit-3xl: $space-base-unit * 10; // 40px
$space-unit-4xl: $space-base-unit * 11; // 44px
$space-unit-5xl: $space-base-unit * 12; // 48px
$space-unit-6xl: $space-base-unit * 15; // 60px

/* Global Elements / Buttons */

@mixin btn-theme($color, $bg) {
  color: $color;
  background-color: $bg;
}

@mixin btn-border-theme($px, $border-color) {
  border: $px solid $border-color;
}

/* Re-usable mixins */

@mixin box-sizing($type) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}

@mixin flexbox {
  display: -webkit-box; // old
  display: -moz-box; // old
  display: -ms-flexbox; // ie
  display: -webkit-flex; // new
  display: flex; // new
}

@mixin theme($darken-theme: true) {
  @if $darken-theme{
  background: $mb-gray-100;
  color: $mb-color-white;
  }
  // @include theme ($darken-theme: true);
 }

 $grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 875px,
  lg: 1024px,
  xl: 1280px,
);