.amp-list-num { display:block; position:relative; width:100%;}
.amp-list-num .amp-dc-image-pic { display:block; position:relative; width:100%;}
.amp-list-num .journal-intro-section{ margin: auto; text-align: center;height: auto;}
.amp-list-num .journal-intro-section .intro-title {font-family: 'Times New Roman', Times, serif;font-size: 25px;font-weight: 100;color: #000;margin: 30px 0 0px;}
.amp-list-num .journal-intro-section .intro-sub-title {font-family: 'Times New Roman', Times, serif;font-style: italic;font-size: 22px;color: #000;font-weight: 100; margin: 0px 0 15px;}
.amp-list-num .journal-intro-section .time-duartion { margin-top: 15px; margin-bottom: 10px;}
.amp-list-num .journal-intro-section .time-duartion .time-circle {  text-align: center; width: 42px; height: 42px; border-radius: 40px; border: 1px solid #000;margin: auto;font-family: 'Times New Roman',Times,serif;line-height:13px;font-style: italic;font-size: 14px;display: flex; flex-direction: column; vertical-align: middle;justify-content: center;}
.amp-list-num .journal-intro-section .time-duartion .time-circle span {display:block;}

.amp-list-num .numerical-block .num-block-header {
    font-style:italic;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 22px;
}

.amp-list-num .numerical-block{ 
    .num-desc {
    font-family: 'Times New Roman', Times, serif;
    p {
        line-height: 1.3;
        margin-top: 16px;
        margin-bottom: 16px;
        font-style: initial;
        font-size: 14px;
        font-family: inherit;
    }
 }
 .num-right-sec-desc{
    font-family: 'Times New Roman', Times, serif;
    p {
        line-height: 1.3;
        margin-top: 16px;
        margin-bottom: 16px;
        font-style: initial;
        font-size: 14px;
        font-family: inherit;
    }
 }
}


.amp-list-num .journal-intro-section .intro-page-desc p {padding: 0 7%;font-family: 'Times New Roman', Times, serif;color: #000;}

.num-content-wrapper {position: relative;overflow: hidden;background-color: #ffffff;margin: 0 auto;padding: 0;}

.amp-list-num .list-num-main-block { margin-bottom: -180px;margin: 0 auto;text-align: center;}



.amp-list-num .numerical-block .num-left-content , .amp-list-num .numerical-block .num-right-content{width: 50%; height: auto;}
.amp-list-num .numerical-block {width:100%;display:block;position:relative;}

@media screen and (min-width: 1024px){
.amp-list-num .numerical-block .num-left-content {padding-right:15px;}
.amp-list-num .numerical-block .num-right-content {padding-left:15px;}
.amp-list-num .num-content-wrapper {width: 878px;}
.amp-list-num .numerical-block { width: 878px;margin-top: 25px;justify-content: center; display: flex;height: auto;padding:0;}
.amp-list-num .list-num-main-block { margin-bottom: -180px;}
}


@media screen and (max-width: 768px){
.amp-list-num .numerical-block .num-left-content , .amp-list-num .numerical-block .num-right-content{width:100%;}
.amp-list-num .numerical-block {padding:6px;}
}


   
    @media screen and (max-width: 1440px){
     .prodct-block{
      width: 990px;
      margin:0 auto;
      text-align:center;
      
     }
     .amp-list-num .list-num-main-block { margin-bottom: -100px!important;}
   }
   .prodct-block .shop-this-story-prdct {
     width: 24%;
     height: auto;
     position : relative;
     display:inline-block;
     vertical-align: top;
     margin:0 auto;
     
   }
   .shop-this-story-prdct-link{
       margin-top:20px;
       position:relative;
   }
   .shop-story-prodct-cta{
    font-family: 'Times New Roman',Times,serif;
    font-style: italic;
    font-size: 14px;
    padding: 7px 25px 5px;
    line-height: 20px;
    text-decoration: none;
    color:#000;
    text-transform:none;
    background-color:#fff;
    
   }
   .shop-story-prodct-cta::after {
    border-top: 5px solid transparent;
    border-left: 5px solid;
    border-bottom: 5px solid transparent;
    content: "";
    display: inline-block;
    height: auto;
    width: auto;
    margin-left: 3px;
    vertical-align: baseline;
    visibility: visible;
}

.fab {
    display: inline-block;
    font: 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-instagram:before {
    font-family: "Font Awesome 5 Brands";
    content: "\f16d";
}
.fa-instagram {
        font-size: 40px;
        vertical-align: middle;
        margin-right: 4px;
    }
    .fa-instagram::before {
            content: "\f16d";
        }
    .sharingIcons {
        display: inline-block;
        height: 24px;
        width: 24px;
        text-indent: -9990px;
        vertical-align: middle;
        margin: 10px 10px 0 10px;
        border: none;
    }
    .social-section {
        padding-top: 30px;
    }
  .share-heading {
        font-size: 17px;
        margin: 0;
    }
    .social-container {
        padding-top: 16px;  
    }
    .social-container.flex-align {
        height: inherit;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
    
    }



    
.fb-color {
    background: #3B5998;
}
.tw-color {
    background: #1da1f2;
}
 .em-color {
    background: #f28d4f;
}
.social-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    text-decoration: none;
    color: #fff;
    height: 37px;
    position: relative;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    margin: 0 12px;
    @media screen and (max-width:768px) {
        min-width: 90px;
       }
}


  .social-icons {
    display: inline-block;
    background: url('https://media.moltonbrown.co.uk/i/moltonbrown/socialIcon') no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left;
    height: 25px;
}
.instagramlogo-mbgallery{
    width: 40px;
    padding-top: 5px;
}
 div.bcc-footer {
    text-align: center;
    width: 100%;
}
div.bcc-footer.flex-align {
        height: inherit;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;        
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    div.bcc-footer.justify-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .social-icons.icon-fb {
        background-size: 44px;
        width: 15px;
        background-position: -8px -51px;
    }
    .social-icons.icon-tw {
        background-size: 46px;
        width: 28px;
        background-position: -9px -23px;
    }
    .social-icons.icon-em {
        background-size: 46px;
        width: 33px;
        background-position: -7px 5px;
    }
    
    
.editorial-carousel-wrapper{
  width:100%;
  padding-top: 20px;
  text-align:center;
}
.editoril-carousel{
  width:90%;
  margin:0px auto;
}
.slick-slide{
  margin:10px;
}
.slick-slide img{
  width:100%;
  border: 2px solid #fff;
}

.shop-story-prodct-description {
    font-size:12px;
}
.shop-story-content{
    margin-top: 24px;
    padding: 0 16px;
}
.amp-dc-text {
    blockquote{
        font-family: Didot, Georgia!important;
        position: relative;
        font-size: 150%;
        font-style: italic;
        text-align: left;
        h3{
            font-family: Didot, Georgia!important;
            padding: 0 87px 0 60px;
            text-align: left;
            font-size: 40px;
        }
        &::before {
            content: "\201C";
            font-size: 189px;
            font-family: Didot,Georgia;
            font-style: italic;
            position: absolute;
            top: -56px;
            z-index: 1;
            color: rgba(0,0,0,.2);
            left: -17px;
   }
   em {
    font-family: inherit;
    line-height: 1.2;

   }
}
}
.times-new-roman {
    font-family: 'Times New Roman',Times,serif!important;
}
.amp-dc-card .amp-dc-card-description {
    font-style: italic;
    font-family: Georgia;
}
.amp-list-num  {
    .amp-dc-card-img-wrap {
        margin-top: 20px;
    }
}
.sop-sec-heading{
    h2{
        font-size:25px;
    }
}