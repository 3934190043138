.static_para{
    margin-top:15px;
    font-size:13px !important;
    h1{
        padding: 0 15px;
    }
    
        @media screen and (max-width:768px) {
            padding: 0 15px;
        }
}
.rightColumn .onlyImage{
     margin: 0 !important;
     .staticHeader h1{
         font-size: 28px;color:#000; font-weight: 100; margin: 10px 0 0;
     }
    }
    .rightColumn .onlyText .staticHeader h1{
        @media screen and (max-width:768px) {
            padding:15px !important;
        }
        }
.staticAside{
    width:194px;
    height:441px;
}
.staticAside ul .list-group-item{
    border:none  !important;
padding: 0px;
}
.leftColumn {
    .medium-aside-menu{
        li ul.search-menu-sub-cntnt li{
            line-height: 16px;
        }
    }
}
@media(max-width:768px){
    .leftColumn {
        margin-top: 0px !important;
    }
.staticHeader.list-group-item{
border:none !important;
background-color:#f4f4f4!important;
margin-bottom: 10px ;
}
aside{
    padding: 0 !important;
}
.browser-info-cntnt{
    margin-bottom: 10px;
    h1{
        font-size:12px !important;
    }
}
.medium-aside-menu li{
    padding: 10px 20px; 
}
}
.onlyText .staticHeader h1,.onlyImage .staticHeader h1 {
    color: #000 ;
font-size: 28px;
font-weight: 100;   
margin-top: 10px;
@media screen and (max-width:768px) {
    display: block;
    overflow: hidden;
    padding: 15px;
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #000 !important;
    margin: 0;
}
}  
.staticContainer .container{
    padding:0 !important;
}
/* About us*/
    .aboutus .amp-dc-block-wrap .amp-dc-splitBlock {
        display: flex;
           @media screen and (max-width:768px){
               flex-flow: wrap;
            }
        .amp-dc-split-part {
            width:355px !important;
            @media screen and (max-width:768px){
                width: 100% !important;
                height: auto; padding: 0 !important;
            }
       }
      .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap{
   position: relative;
   .amp-dc-card-text-wrap
   {    width: 268px!important;background-color: #2c2d2d;
        height:370px;
       position: absolute;
       opacity: 0.7;text-align: left !important;
       top:34px;padding:10px;
       @media screen and (max-width:768px){
        background-color: #2c2d2d;
        position: absolute;
        opacity: 0.7;
        text-align: center!important;
        padding: 10px;
        height: auto;
        margin: auto;
       }
       .amp-dc-card-description{
           color:#fff;
           font-size: 13px;    font-family: arial;
       } .amp-dc-card-name{line-height: 1em;
           color:#fff !important;font-size: 23px !important;
           font-family: Molengo;    margin-bottom: 0px;
           padding-bottom: 14px;
           @media screen and (max-width:768px){
              font-size: 18px !important; 
           }
       }
   }
      }
               #aboutusrightsection{
                   width:335px !important;
                   .amp-dc-block-wrap .aboutus-text {          
                    @media screen and (max-width:768px) {
                        margin: 0px !important;
                    }
                   }.rightContent{
                    @media screen and (max-width:768px) {
                        width:300px;
                        margin: 10px !important;
                       }                                        
                    text-align: center;
                       ul li{
                           list-style: none;
                           line-height: 15px;
                           font: 12px/15px Arial, Helvetica, sans-serif;margin-left:15px;
                           @media screen and (max-width:768px) {
                               margin-left:0px !important;
                           }
                           h4{
                               font-weight: 700;
       margin: 10px 0;
       font-size: 18px;
                           }
                       }
         h3{
                       margin: 3.5em 0 2em;color: #4b2d16!important;
                  
       font-weight: 700!important;font-size: 14px!important;text-transform: uppercase;
                   }
               }
               .amp-dc-block-wrap:last-child{
                   .amp-dc-link{text-align: center !important;    margin: 3.5em 25px 2em !important;
                       .amp-dc-link-value {
                       
       color: #4b2d16!important;font-weight:700;text-decoration: underline;
       &:after{
        border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
       }
                   }
                   } 
               }
               }
    }
    .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap{
        @media screen and (max-width:768px) {
margin-left:0px !important;
}
 .amp-dc-card .amp-dc-card-wrap {
        width:165px;
        height:166px;
        position: relative !important;
@media screen and (max-width:768px) {
    margin: auto;
}
        .amp-dc-card-text-wrap{
            position: absolute !important ;bottom: 0px !important;    right: 0;
            left: 0;background-color: #0000008a !important;
            .amp-dc-card-link{
                font-size: 13px !important;color:#fff;
                &::after{
                    border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
                }
            }           
        }        
    }
    }
    /* gift terms and conditions */
    .giftcardterms-conditions .staticHeader{
        .mb_contentblock_landing span a{
            color: #666 !important;
        }
    .static_para p{
        margin-bottom:15px;
    }
    }
    /*Faqs*/
    .rightColumn .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,
    .rightColumn .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent
    {
        background: #fafafa;
        border: 1px solid #d6d6d6;
        padding: 20px;
        width: auto;
        float: left;
        margin: 23px 0 0 0;
        @media screen and (max-width:768px) {
            border:0px;background-color:#fff;
            padding: 20px 0;
    width: 100%;
        }
        .mb_contentblock_FAQs{
            background: #fff;
            border: 1px solid #d6d6d6;
            display: block!important;
            margin-top: 20px;
            padding: 10px;width:710px;
            a{
                color: #666;
            }
        @media screen and (max-width:768px) {
            border: none;
            padding:10px 15px;
            width:auto;

        }
            ul{    margin-left: 20px; li{
                list-style: circle;    line-height: 15px;
                font-size: 12px;
                color: #000;
            }
        }
            p{
.mb_FAQ_link_title{
                font-size: 12px;
                line-height: 20px;
                font-weight: 900;
                color: #666;
                @media screen and (max-width:768px) {
                    font-weight: normal;
                    line-height: 30px;color:#333
                }
            }
padding-bottom: 7px;
            }
            .title{
                font-weight: 900;
    font-size: 12px;color: #000;
            }
            .hr{
                background-color: #d6d6d6;
    clear: both;
    display: block;
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    @media screen and (max-width:768px) {
        display: none;
    }
            }
        }

 h1{
        font-size: 28px;color:#000; font-weight: 100;
    }
    }
    .rightColumn .faqsandhelp h1{
        margin-top:0;
    }
    .rightColumn .placingorderfaqs h1{
        margin-top:0;
    }
    .rightColumn .deliveryfaqs  .staticHeader h1 {
        padding: 0 !important;
        @media screen and (max-width:768px) {
            display: block;
            overflow: hidden;
            padding: 15px !important;
            font-size: 15px;
            font-weight: 700;
            color: #000;  
            margin: 0;            
        }
    }
       .mb_back_to_top{
        float: right;
    color: #5d5d5d;
    font-size: 12px;
    text-decoration: underline;
    &:before{
        border-left: 5px solid transparent;
        border-bottom: 8px solid;
        border-right: 5px solid transparent;
        content: "";
        display: inline-block;
        height: auto;
        width: 1px;
        margin-right: 5px;
        margin-top: -8px;
        vertical-align: baseline;
        visibility: visible;
        @media screen and (max-width:768px) {
            border-top: 4px solid transparent;
            border-left: 8px solid;
            border-bottom: 4px solid transparent;
        }
    } 
    &:hover{
        color: #000;
    }
    }    
    #FAQ_links{
        span.title{
            font-weight: 900;
            font-size: 15px ;
            @media screen and (max-width:768px) {
                font-size: 12px;
                color: #333;
                font-weight: normal;
                line-height: 25px;padding-bottom:10px;            
            }
        }
    }
    /*terms& conditions */
    .terms-conditions .staticHeader .static_para{
        margin-top: 0px;
        p{
        padding: 10px 0px;
    }
    } 
    /* Promotional Terms & Conditions */
    .promotionalterms-conditions .staticHeader .mb_contentblock_landing div p{
    padding-bottom: 17px;     
    }
/* Placing an Order trade enquire */ 
.tradeenquiry .staticHeader .static_para p a{color: #666666;}
/* delivery and returns */
span p a{
    color:#666;
}
.staticsubheader {
    padding-bottom: 13px;
}
.rightColumn .delivery-returns .staticHeader .static_para{
    ul li{
        list-style: circle;    margin-left: 15px; color: #000000;
    }
}
a:link, a{
    color: #666 ;
}
/*--affiliate -- */
.affiliate-enquiry-page{
    .amp-dc-block-wrap:after{
        clear: both;
    }
    .amp-dc-block-wrap{
        .affiliate-banner{
            width:964px;
            
          height:372px;margin-bottom: 30px;
          position: relative;
          .amp-dc-banner-info-wrap{
            height: 210px;
            width: 427px;
            background-color: rgba(255,255,255,0.8);
            top: 20%;
            .amp-dc-banner-info{
                display: inline-block;
    /* vertical-align: middle; */
    width: 75%;
    margin: 24px 55px;
    text-align: center;
            }
          }
    

@media screen and (max-width:768px){
    width:100%;
}

          .amp-dc-card-wrap{
              .amp-dc-card-img-wrap{
                  margin-top:0px;
                  .amp-dc-image { width:964px;
            
          height:372px;
          @media screen and (max-width:768px){
            width:100%;
        }
          img{
              height: inherit;
          }

                  }
              }
              position: relative;
               height: auto; 
               .amp-dc-card-text-wrap{
                position: absolute;
                 top:25%;width:427px;height: 210px;left:3%;right:3%;opacity: 0.8;
                 @media screen and (max-width:768px){
                    width:94%;
                    height:auto;
                }
                 .amp-dc-card-name{
    font-size: 28px;
    line-height: 35px;
    padding:15px 65px;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif !important ;
    @media screen and (max-width:768px){
 
    padding:15px 10px;
    }

}
.amp-dc-card-description{
    color: #1c1c1c;
    font-size: 20px;
    line-height: 25px;
    margin: 0px 0 25px 0;
    padding: 0px 65px;
@media screen and (max-width:768px) {
    padding: 0px 10px;
    font-size: 14px;
}

}
               }
          }
        }  

        .afiliate-enquiry-rectangle-1{
                width:365px;
                height: 237px;    border: 4px solid #4fb9c9;
                padding: 28px 10px 28px 60px;
                float:right;
                
            @media screen and (max-width:768px) {
                width:100%;margin:0;
                height: auto;
            }
                h2 i{
                    font-family: 'Times New Roman', Times, serif !important;
                    font-size: 28px;color: #1c1c1c;
                }
                ul {
                    li{
                        font-size: 12px;
                        color: #000;margin-left:15px;
                    }
                    li:first-child{
                    font-weight: 700;  
                    margin-top: 15px;
                }
                }
            }
            .affiliate-enquiry-rectangle-02 {
                    background: #f9f9f9;  
                    @media screen and (max-width:768px) {
                        width:100%;margin: 15px 0 0;height: auto;
                    }
                
                    width:365px;
                    height: 237px;   
                    padding: 32px 10px 28px 60px; float: left;
                    h2 i{
                        font-family: 'Times New Roman', Times, serif !important;
                        font-size: 28px;color: #1c1c1c;
                    }
                    ul {
                        li{
                            font-size: 12px;
                            color: #000;
                            margin-left:15px;
                            &:first-child{
                                margin-top:15px;
                            }
                        }
                
                    }
                }
                .note{
                    margin-top:30px !important;
                }
                }

  
 
.affiliate-enquiry-applynow-section-1,.affiliate-enquiry-applynow-section-2 {
    margin-top: 30px !important;
   h2{
       margin-bottom: 15px;}
    @media screen and (max-width:768px){
        margin-left:0 !important;
        margin-right:0 !important;
    }
    p{
        color: #1c1c1c;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 17px;
    .mail-link{
        color: #1c1c1c !important;
        font-size: 14px;
        font-weight: 700;
    }
    .generic-button{
        background-color: #c74782;
        color: #ffffff !important;
        display: inline-block;
        font-size: 15px;
        line-height: 1;
        padding: 10px 20px;
        
        @media screen and (max-width:768px) {
            width:100%;text-align:center;
        }
        &:after{
        border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
    }
    }

    }
h2 i {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 28px;
    line-height: 26px;
    margin-bottom: 15px; color: #000;
}
}
.amp-dc-splitBlock{
    .amp-dc-split-part{
        padding: 0px;
        .we-work-with{
            h2{
                font-family: 'Times New Roman', Times, serif !important;
            font-size: 28px;
            line-height: 26px;
            margin-bottom: 15px; color: #000;
            font-style: italic;
        } 
        margin-top: 15px !important;
        }
        .we-work-with-image{
            
        margin-top: 15px !important;
        width:346.8px;
        height: 46.53px;
        @media screen and (max-width:768px) {
            width:100%;
            height:auto;
        }
        } 
    }
}


}
/* affiliate --End */

/* bespoke-hotel-amenitiespage */
.bespoke-hotel-amenitiespage{
    padding-left:18px;
    @media screen and (max-width:768px){
        padding-left:0px;
    }

.bespoke-hotel-aminities-page-left-section, .WhyMoltonBrown-main-text .leftContent {
    margin: 20px 24px 0 0; 
    .HotelS_heading {
        font-size: 16px;
        color: #da007a;
        margin: 24px 0 0 0;font-weight:700;
    }
   

}
.bespoke-hotel-aminities-right-section{
    .amp-dc-block-wrap{
         .bespoke-right-card-01 .amp-dc-card-wrap .amp-dc-card-img-wrap{
       width: 292px;
       height:161px;
       margin: 10px auto;
    }
.bespoke-right-card-02 .amp-dc-card-wrap {
    position: relative;

.amp-dc-card-img-wrap{
       width: 292px;
       height:218px;
margin: auto;
       .amp-dc-image{
           height: inherit;
           img{
           height: inherit;
               
           }
       }
    }
    .amp-dc-card-text-wrap
        {
            position: absolute;
            bottom:0px;
            width:292px;
            right: 0;
            left: 0;margin: auto;
            background-color: #0000008a !important;
            .amp-dc-card-link{
                color: #fff;
                &::after{
                    border-top: 5px solid transparent;
                    border-left: 5px solid;
                    border-bottom: 5px solid transparent;
                    content: "";
                    display: inline-block;
                    height: 0;
                    margin-left: 5px;
                    visibility: visible;
                    width: 0;
                }
            }
        }
}
    }
}

}



.mb_address{
    margin-top: 10px;
    padding-top: 7px;
}
.contactdetails {
    margin: 0px !important;

.HotelS_text-orange {
    color: #eb6909;
    margin-top:32px;font-size: 13px!important;
}
}
.hotelproducts-page{
    width:752px;
    .amp-dc-splitBlock{
        border: 1px solid #d6d6d6;
        margin-top: 20px;
        padding: 10px;
        width: 752px;
        .amp-dc-size-60{
            width:448px;
            .hotel-collections-description{
               width:448px; margin:0;
                padding: 0 0 0 12px;
                .HotelS_heading{
                    font-size: 16px;
                    color: #da007a;
                    margin: 24px 0 0 0;
                    font-weight: 700;
                }
                .HotelS_text{
                    margin:10px 0 20px;
                  
                }  a{
                        font-size: 13px;
                    }
            }
        }
        .amp-dc-size-40{ width:277px;
            
            .amp-dc-split-part-wrap{ height: 172px;
              .amp-dc-image{
                  height: inherit;
              }
   img{
                width: 100%;
                height: inherit;
            }
        }   
           
         
            
            
        }
    }
    @media screen and (max-width:768px) {
        width: 100%;
       .amp-dc-splitBlock,.hotel-collections-description,.amp-dc-size-60{
            width: 100% !important;
            .amp-dc-size-40 {
                margin: auto;
            }

        }
    }
}
/* hand care*/
.hotelproducts .eledescription, .hotelproducts-page .eledescription{
margin-top:20px;
padding-bottom: 7px;
margin-bottom: 10px;
}

.hotel-aminities-page{

    .Hotel-aminities-leftsection{
        .amp-dc-block-wrap:first-child{ position: relative;
            .amp-dc-card-text-wrap{
        padding:0px;
                        .amp-dc-card-name
    {
        background-color: #941e7e;padding-top: 10px;
        color: #b5c437 !important;
        height:161px;width:410px;
        @media screen and (max-width:768px) {
            width:100%;
        }
        }
        .amp-dc-card-description{
            position:absolute;
            bottom: 35px;
            right: 50px;
        color: #b5c437 !important;
        @media screen and (max-width:768px) {
            
            bottom: 10px;
        }
    }
            }
    
        }
    
    
    .hotel-aminities-main-text{
    margin: 0px;width:408px;
    text-align: justify;
    @media screen and (max-width:768px) {
        width:100%
    }
    }
    }
    .Hotel-aminities-rightsection{

        .amp-dc-block-wrap:first-child{
            margin-top: 20px;
            .amp-dc-card-img-wrap{ 
                 margin:auto;
                 @media screen and (max-width:768px) {
                    width:100%
                }
            }
    }
    .bespoke-right-card-02{
        position: relative;
        .amp-dc-card-img-wrap{
            .amp-dc-image img{
                 width:292px;
        height: 218px;   margin:auto;
        @media screen and (max-width:768px){
            width:100%;
            height:auto;
        }
            }
      
        }
        .amp-dc-card-text-wrap  {
    position:absolute;
    bottom:0px;background-color: #777674d4;color:#fff !important;
    width:100%;
        .amp-dc-card-link{
    color: #fff !important;
    &:after{
        border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
    }
        }
            }
          
    }
    }
    .amp-dc-block-wrap{
        .amp-dc-prod-3-rows .amp-dc-card-list-wrap{

    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
    @media screen and (max-width:768px) {
        flex-direction:column;
    }
    .amp-dc-card {
width:220px;
height:202px;

@media screen and (max-width:480px) {
margin: 10px auto;
width: 100%;
height: auto;
}

.amp-dc-card-wrap{
    position: relative;
    margin: 0;
    .amp-dc-card-img-wrap{
        margin: 0px;
    }
    }
    

    .amp-dc-card-text-wrap{
        position: absolute;    bottom:0px;background-color: #777674d4;color:#fff !important;width:100%;
        .amp-dc-card-link{
    color: #fff !important;
    &:after{
        border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
    }
        }
    }
        
    .amp-dc-card-img-wrap .amp-dc-image img  {
        width:220px;
        height:202px;
        @media screen and (max-width:768px){
            width:100%
        }
        }
}
}

    }



}

.hotel-collections {
width:770px;
padding-left:18px;
@media screen and (max-width:768px){
    width:100%;
    padding-left:0px;
}
    .amp-dc-splitBlock{
        padding: 10px;
        border: 1px solid #d6d6d6;margin-top: 20px;
        .amp-dc-size-10{
            width:125px;
            height: 125px;padding:2px;border:1px solid #d6d6d6;margin:auto;
            .amp-dc-image  {
        height: 120px;width: 120px;
            }
        }
         .amp-dc-size-90{
            .description{
                    
                margin-top: 10px;
                padding-bottom: 7px;
              p.title {
                    margin-top:10px;
                    font-size: 14.1px;padding-bottom: 7px;
                }
                p{
                    margin-top:10px;
                }
                }
            }
         }
}
.hotel-collections.collections1973{
    .hotelproducts .staticHeader{
        p{
            margin-top:20px;
        }
    }
}
.whymoltonbrown {
        padding-left:18px;   
        @media screen and (max-width:768px) {
            
        padding-left:0px; 
        }
}
.WhyMoltonBrown-main-text {
margin: 0px !important;
.leftContent {
    margin: 20px 24px 0 0; 
    .HotelS_heading {
        font-size: 16px;
        color: #da007a;
        margin: 24px 0 0 0;font-weight:700;
    }
    img{
        width:130px;
        height:78px;
    }
   
}
}
aside {
    width: 194px;
    border: 0;
    text-align: left;
    padding:4px 6px 20px 15px;
     h3.facet_header {
        font-size: 14px;
        line-height: 30px;
        color: #666;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        text-align: left!important;
        margin-bottom: 0!important;

        @media(max-width: 768px){
            display: none !important;
        }
    }
 
} 
 .whymoltonbrown-right-section{
        .amp-dc-block-wrap{
            width: 292px;
            margin: auto;
        }
        .amp-dc-block-wrap:first-child{
            margin-top: 20px;
            .amp-dc-card-img-wrap{
                width:292px;
                height:161px;  margin:auto;
            }
    }
    .bespoke-right-card-02{
        position: relative;
        .amp-dc-card-img-wrap{
            .amp-dc-image img{
                 width:292px;
        height: 218px;   margin:auto;
        @media screen and (max-width:768px){
            width:100%
        }
            }
      
        }
        .amp-dc-card-text-wrap  {
    position:absolute;
    bottom:0px;background-color: #777674d4;color:#fff !important;
    width:100%;
        .amp-dc-card-link{
    color: #fff;
    &:after{
        border-top: 5px solid transparent;
        border-left: 5px solid;
        border-bottom: 5px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 5px;
        visibility: visible;
        width: 0;
    }
        }
            }
          
    }
    }
    .sale-banner{
        width: 762px;
        height: 380px;
        margin: auto;

        @media screen and (max-width:768px) {
            width: 100%;height: auto;
        }


        .amp-dc-banner-info-wrap{
    width:100%;
       
.amp-dc-banner-info{
    margin: auto;
        margin-left: 20px;
        @media screen and (max-width:768px) {
            margin-left: 20px;
    position: absolute;
        }
        .amp-dc-banner-header{
            color: #ffffff;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 85px;
            line-height: 90px;
            font-weight: normal;
        }
        .amp-dc-banner-subheader{
            font-size: 20px;
            line-height: 30px;
            font-style: normal;
        }
        .amp-dc-banner-description{
            font-size: 14px;
    line-height: 18px;font-style: normal;
        }
} }
    }
    .careers{
        h1{
            padding-left: 18px;
        }
        .spaHotelsCareers3900026{
            margin-top:40px;
            p{
                margin-top:20px;
                padding-bottom:7px;
                padding-left: 18px;
            }
            .one{                
            margin-top:20px;
            }
        }
        .spapage{
            display: flex;  
            padding:42px 18px 18px 18px;
            justify-content: space-between;
            @media screen and (max-width:768px) {
                flex-direction:column;
                
            div{
                width: 100% !important;
                height: auto !important;
            }
            }
            p {
                padding-top: 20px;
            }
            a{
                text-decoration: underline;
            }
            div{
                width: 220px;
                height:202px;
                padding: 5px;
                border: #d6d6d6 1px solid;margin-top: 10px;
            }
        }
    }
    .ourheritagepage{

    .ourheritageheaderblock{
        margin: 0;
            .head-section{
                font-family: 'Times New Roman', Times, serif ;
                color: #000;margin:auto;
                text-align: center;width:425px !important;
                @media screen and (max-width:768px) {
                    width:100% !important;
                }
                .main-title{
                    font-size: 25px;
                    margin: 30px 0 3px;
                    padding: 0;
                    font-weight: 100;
                    font-family: 'Times New Roman', Times, serif ;
                    text-transform: uppercase;
                }
                .sub-title{
                    font-size: 22px;
                    font-style: italic;
                    margin: 0 0 15px;
                    font-weight: 100;
                }
                .time-duartion{
                    margin-bottom: 15px;
                .time-circle{
                    width: 42px;
                    height: 42px;
                    border-radius: 40px;
                    border: 1px solid #000000;
                    padding-top: 3px;
                    margin: auto;
                    font-family: 'Times New Roman', Times, serif ;
                    line-height: 15px;
                        font-style: italic;
                        font-size: 14px;
                    span{
                        display: block;
                        font-size: 14px;
                    }
                }
           
                }
                .font14{
                    width:425px ;
                    font-family: 'Times New Roman', Times, serif !important;
                    font-size: 14px;    margin: auto;
                    @media screen and (max-width:425px) {
                        width:100% !important;
                    }
                }
            }
        }
        .amp-dc-splitBlock {
            margin-top:33px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width:768px) {
                flex-direction:column;
                margin-top:0px;
            }
            .heritagepage-image-01{
                width:422px;
                height:285px;
                @media screen and (max-width:768px) {
                    width: 100%;
                    height: auto;
                }
            }
            .Ourheritage-text{
                color:#000;
                padding-bottom: 30px;
                h3{
                    font-style: italic; font-size: 22px;
                    font-family: 'Times New Roman', Times, serif ;
                }
                p{
                    margin-top: 16px;
                }
            }
            .ourheritage-cards{
                width:422px;
                @media screen and (max-width:768px) {
                    width: 100%;
                }
                .amp-dc-card-img-wrap{
                    margin-top:0px;
                }
                .amp-dc-card-text-wrap
{padding: 0 17px;
    .amp-dc-card-name{
        margin-top:24px;
        font-size: 22px;
        font-style: italic;
        text-align: left;
        font-family: 'Times New Roman', Times, serif ;

    }
    .amp-dc-card-description{
        margin-top: 16px;
        font-size: 14px;
        text-align: left;
        line-height: 1.2; color: #000;
    }
}
            }
            .ourheritage-card-3{
         
    .amp-dc-card-text-wrap
{display: block;
    padding: 0 75px 0 75px;
    position: relative;
    border: none;
    line-height: 100%;
    font-style: italic;
    text-align: left;
    margin-top: 40px;line-height:1.2;
    
    @media screen and (max-width:425px){
        padding: 0 15px;
    }
  
    .amp-dc-card-name{
        font-size: 40px;
        font-family: 'Times New Roman', Times, serif ;
        font-style: italic;
    padding-bottom: 12px; 
     @media screen and (max-width:425px){
        font-size: 23px !important;
    }
       
            } .amp-dc-card-description{
                width: 185px;color: #000;
                font-family: 'Times New Roman', Times, serif ;
        }
        }
    }
    
}
    }
    
.specialofferspage{
  .special-offers-title h1{
    text-align: center;
    font-size: 28px;
    padding: 0;
    margin: 20px 0;
    font-family: 'Times New Roman', Times, serif ;
  }
  .special-offers-extrabanner-01{
      .amp-dc-banner-pic-wrap .amp-dc-image{
          width:964px;
          height:287px;
          img{
              width: inherit;
              height:inherit;
          }
      }
      .amp-dc-banner-info-wrap{
        width: 100%;
        height: 185px;
        position: relative;
        /* left: 0; */
        transform: translateX(-50%);
        left: 50%;
        .amp-dc-banner-info{
            width: 100%;    margin-left: 9px;
        }
          .amp-dc-banner-header{
              font-size: 20px;
          }
          .amp-dc-banner-subheader{
              font-size: 14px;
              font-weight: 700;
              padding-top: 8px;
              line-height: 1.2;
              text-transform: uppercase;
              font-style: normal;
          }
          .amp-dc-banner-description{
            font-family: 'Times New Roman', Times, serif ;
              font-size: 14px;padding-top: 8px;
          }
      }
    

  }
  .special-offers-banner-01{
      margin-bottom: 18px;
      .amp-dc-banner-info-wrap{
          width:368px;
          height: 178px;
          position: absolute;
    left: 15%;
    top: 5%;
    .amp-dc-banner-header{
        font-size: 20px;
        
    }      
    .amp-dc-banner-subheader{
        font-size: 16px;  
        font-family: 'Times New Roman', Times, serif ;   
        padding-top: 10px;  
    }
    .amp-dc-banner-description{
        font-size: 14px;
        font-style: normal;
          }
  .amp-dc-banner-button{
            margin-top: 20px;
        }
    
}
    

  }
  .specialoffersbanner {
      .amp-dc-banner-info-wrap{
        position: absolute;
        right: 8% !important;
        left: auto;
      .amp-dc-banner-header{
            font-size:30px;
        }
        .amp-dc-banner-subheader{
            font-size:30px;
            font-style: normal;
        }
        .amp-dc-banner-description{
            font-size: 18px;           
            font-style: normal;
        }
    }
    .amp-dc-banner-button{
        margin-top:20px;
    }
  }
  .amp-dc-prod-3-rows{
      .amp-dc-card-title{
        font-family: 'Times New Roman', Times, serif ;
          font-size: 28px;
    padding: 0;
    margin: 20px 0;font-style: italic;
      }
      .amp-dc-card-img-wrap{
          margin: 0px;
      }
      .amp-dc-card-list-wrap{
          .amp-dc-card {
              position: relative;
              width:308px;
              height:338px;
              .amp-dc-card-wrap{
                margin: 0;  
                  height: inherit;
              .amp-dc-card-img-wrap{
                  
                  height: inherit;
              }
              }
              .amp-dc-card-text-wrap{
                  position: absolute;
                  top:0px;color: #000;
                  background-color: transparent;
                  .amp-dc-card-description{
                      color: #000;
                  }
                  .amp-dc-card-link{
                      position: inherit;
                      bottom: -185px;
                      left: 40%;
                  }
              }
          }
      }
  }
  .specialoffers-text{
      position: relative;
      top:-75px;
      .xxsmall-font{
          font-size: 12px;color: #000;
          list-style: none;
      }
      .pos-absolute-terms-lower{
          position: absolute;
          right:0;
          font-size: 12px;bottom: 0px;
      }
  }
}
.privacyPolicy .staticHeader .mb_contentblock_landing{
    counter-reset: h2;
    .bullet-list{
        margin: 0 0 13px;
        padding: 0 0 0 34px;
        li{
            line-height: 15px;
            font-size: 12px;
            margin-left: 15px;    padding-bottom: 5px;
            color: #000;
        }
    }
    .para-left-padding{
        padding: 0 0 13px 34px;
    }
h2{
    font-weight: 700;
    counter-reset: h3;
    margin: 0;
    padding: 5px 0 13px 0;font-size:13px;
    color:#000 !important;
    &::before{
        counter-increment: h2;
    content: counter(h2) ". ";
    padding-right: 18px;
    }
}
h3{
    padding: 5px 0 13px;
    font-size: 13px;
    color: #000;   
    font-weight: 700;
   &::before{
       counter-increment: h3;
    content: counter(h2) "." counter(h3) ". ";
    padding-right: 8px;
   } 
}
.container-bcc{   .table-bcc thead{
        vertical-align: top;height: 30px;
     th{
        color: #000 !important;
        
    }   
    } 
  .staticsubheader{
    font-weight:700;
 

}  
p{
    
    font-family: Arial, Helvetica, sans-serif;
  
}
.table-bcc {
    border: 0 transparent;
    margin: 5px 0 13px 34px;
    width: 100%;
    border-collapse: collapse;
}
} 
}

.download-brochure{
padding-left: 18px;
.staticHeader{
    padding-bottom: 10px;

}
.brochure-blocks{
    margin:24px 0 0 0;    padding: 0;

    .amp-dc-blog-title{
        text-align: left !important;    font-size: 16px;
        color: #da007a;font-weight: 700;
    }
    .bg-pic-wrap .amp-dc-blog-content{
        margin-top:10px;
        .amp-dc-splitBlock {
            display: block;
    padding: 13px 13px 0 0px;
    background-color: #D9D9D9;
    margin: 0 0 48px 0;
    width: 410px;
    height: 115px;
        }
    }
    .amp-dc-split-part-wrap{
        .amp-dc-image{
            width:129px;height:92px;float: right;
            img{
height:100%;
            }
        }
    }
}
.brochure-link{
    strong{
        color:#464646;
        display: block;
    }
}
}
.brochureblock{
    padding: 0 !important;
}
.mb_contentblock_landing.singleBlock td {
    border-top: 0 solid #d6d6d6;
    padding: 5px 5px 5px 0;
    vertical-align: top;

}
.impressum {
h1{
    font-size: 28px;
}
    .staticHeader{
    margin:auto;
}
}
 .corporate-gifting {
    text-align: center;
    padding-top: 40px;
     p {
        padding-top: 20px;
        font-size: 16px;
        width: 80%;
        margin: 0 auto;
        line-height: 18px;
     }
     a {
        color: #000 !important ;
        &.download-brochure-link{
            background: #4d3938;
            font-style: italic;
            font-family: 'Times New Roman', Times, serif ;
            color: #fff !important;
            padding: 10px 30px 10px 30px;
            display: inline-block;
            margin: 30px 0 33px;
            font-size: 16px;
            cursor: pointer;
            font-weight: normal;
        }
        
     }
     .logo-image {
        width: 34%;
        margin: 22px 0 8px;
        display: inline-block
     }
     .ascot-logo {
        width: 46%;
    }
    .rangers-logo {
        width: 100%;
        margin: 22px 0 0;
    }
    .en-mb-logo {
        width: 84%;
    }
    .gifting-para {
        width: 100%;
        padding-top: 0;
        font-size: 13px;
    }
    .partner-para {
        padding-bottom: 25px;
        width: 67%;
    }
 }

 @media screen and (max-width: 768px) {
    .corporate-gifting  {
        .logo-image {
            width: 100%;
        }
        .th-mb-logo {
            width: 78%;
            padding-bottom: 25px;
        }
        .th-mb-logo, .rangers-logo, .en-mb-logo {
            width: 78%;
        } 
        
        .en-mb-logo {
            padding-top: 25px;
        }
        .rangers-logo {
            margin: 0;
        }
        
        .gifting-para {
            width: 90%;
        }
   
 }
 
 } 
 .trebuchet{
     span{
         color: #000;
         font-size: 16px;
         font-weight:700;
     }
 }
 .termsconditions{
    color: #000 !important;
    font-size: 16px;
    font-weight:700;
 }
 .onlyText {
margin:0 !important;
 }
 .delivery-returns .static_para p{
     padding-bottom:7px;
     margin:0 0 10px;
 }
 .delivery-returns .static_para p a {
    font-weight: 700 !important;
    color: #000 !important;
}
 .delivery-returns .static_para strong a {
    font-weight: 700 !important;
    color: #464646 !important;
}
.delivery-returns .static_para p b, .delivery-returns .static_para p strong  {
    font-weight: 700 !important;
    color: #260e03!important;
}
.delivery-returns .static_para  b {
    font-weight: 700 !important;
    color: #260e03!important;
}
.delivery-returns .static_para p strong a{
    font-weight: 700 !important;
    color: #000000!important;
    text-decoration: underline;
}
.delivery-returns .static_para strong a:link {
    color: #464646!important;
    font-weight: normal;
}

.delivery-returns .static_para p {
    font-weight: 700 !important;
}
    .delivery-returns .static_para p a:link {
        font-weight: 700 !important;
    color: #666666!important;
}
.mb_contentblock_landing.singleBlock span strong{
    font-weight: 700 !important;
    color:#000 !important;

}
.mb_contentblock_landing.singleBlock span {
    color:#000 !important;

}
.staticsubheader p strong a{
    color:#000 !important;
}
.mb_contentblock_landing.singleBlock static_para{
    color: #260e03!important;
}