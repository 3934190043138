 /* video css */
 .amp-dc-banner span.font-xsmall.uppercase.block {
  font-weight:600;
}
.century-gothic-stack {
 font-family: century-gothic, Verdana, sans-serif!important;
}
.times-new-roman {
font-family: 'Times New Roman', Times, serif !important;
}
@media screen and (max-width:480px){
  .desktop-video{
      display:none!important;
  }
  .desktop-video .amp-dc-video {
        display:none!important;
  }
}
@media screen and (max-width:768px){
  #card-list-slot1 .amp-dc-card-list-wrap {
  margin: auto!important;
}
  .desktop-video{
      display:none!important;
  }
  .desktop-video .amp-dc-video {
        display:none!important;
  }
   .amp-dc-video-wrap.mob-video {
       height:auto;
   }
}
@media screen and (min-width:769px){
  
  .landingpage_wrapper #bannerslot .amp-dc-banner .amp-dc-banner-info {
      padding:25px;
  }
  .mob-video{
    
      display:none!important;
  }
   .mob-video .amp-dc-video {
        display:none!important;
         
  }
}
.amp-dc-banner table#uk-timer-web-thumbcontainer {
  margin: auto;
}
#pickmix-amp-content .amp-dc-banner-header, #pickmix-amp-content .amp-dc-banner-subheader, #pickmix-amp-content .amp-dc-banner-subheader-2{
  font-style:italic;
  margin-top:0;
  
}
#pickmix-amp-content .amp-dc-banner-subheader{
width: max-content;
}
#pickmix-amp-content .amp-dc-banner-info{
  text-align:right;
}



  

.amp-dc-banner .scroll-container{
   color:#000;
width:100%;
margin:auto;
position: absolute!important;
  bottom: 0;
  height: 45px;
  display: block;
}
.amp-dc-banner .scroll-container {
  cursor:pointer;
}
.amp-dc-banner .scroll-container span{
   font-size:12px;
   color:#000;
}
@media screen and (max-width: 768px) {
  .amp-dc-banner .scroll-container{
      display:none;
  }
  
.amp-dc-banner .amp-dc-banner-info-wrap {
  width: 100%;
}

}
.amp-dc-banner .scroll-container .arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #000;
  margin: 0 auto;
}
/*video icon */
.amp-dc-banner .video-player-ft {
  display: block;
  width: 76px;
  height: 76px;
  line-height: 66px;
  border-radius: 50px;
  border-width: 7px;
  border-style: solid;
  margin: 0 auto;
  overflow: hidden;
   color:#fff;
}
.amp-dc-banner .video-player-ft .video-arrow {
  color:#fff;
  display: inline-block;
  margin: 12px 0 0 8px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left-style: solid;
  border-left-width: 33px;
  border-bottom: 20px solid transparent;
}
.amp-dc-banner .banner-video-icon{
   color:#fff;
    height: 100%;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
}

/* video icon */

/* Markdown banner */
.amp-dc-banner .amp-dc-banner-description p, .amp-dc-banner .amp-dc-banner-description a, .amp-dc-banner .amp-dc-banner-description em, .amp-dc-banner .amp-dc-banner-description strong {
  font-family:inherit;
}
.amp-dc-banner .amp-dc-banner-description h1, .amp-dc-banner .amp-dc-banner-description h2, .amp-dc-banner .amp-dc-banner-description h3, .amp-dc-banner .amp-dc-banner-description h4, .amp-dc-banner .amp-dc-banner-description h5, .amp-dc-banner .amp-dc-banner-description h6{
  font-family:inherit;
}

.amp-dc-banner .amp-dc-banner-description p a{
  font-family:inherit;
  text-decoration: underline;
  color:#000;
}
.amp-dc-banner .amp-dc-banner-description p em{
  font-family:inherit;
}
.amp-dc-banner .amp-dc-banner-description  em{
  font-family:inherit;
}
.amp-dc-banner .amp-dc-banner-description p strong{
  font-family:inherit;
}
.amp-dc-banner .amp-dc-banner-description h2{
  font-family:inherit;
  font-size:30px;
  font-weight:500;
}
.amp-dc-banner .amp-dc-banner-description h1, .amp-dc-banner .amp-dc-banner-description h2, .amp-dc-banner .amp-dc-banner-description h3, .amp-dc-banner .amp-dc-banner-description h4, .amp-dc-banner .amp-dc-banner-description h5, .amp-dc-banner .amp-dc-banner-description h6{
  font-weight:500;
}
.amp-dc-banner .amp-dc-banner-description h1{
  font-size:36px;
}
.amp-dc-banner .amp-dc-banner-description h2{
  font-size:30px;
}
.amp-dc-banner .amp-dc-banner-description h3{
  font-size:22px;
}
.amp-dc-banner .amp-dc-banner-description h4{
 font-size:18px; 
}
.amp-dc-banner .amp-dc-banner-description h5{
  font-size:16px;
}
.amp-dc-banner .amp-dc-banner-description h6{
  font-size:14px;
}

.amp-dc-banner .amp-dc-banner-description p{
   font-size:14px;
}
.amp-dc-banner .amp-dc-banner-description h2 em{
  font-family:inherit;
}
.amp-dc-banner .amp-dc-banner-description h3{
  font-family:inherit;
}

/* Markdown banner */

/* Need to keep heritage banner heading as h1 for seo reasons so changing the font size */
#heritageSlot .amp-dc-banner .amp-dc-banner-description h1{
  font-size:24px;
}

.custom-country-select{
position: relative;

}
.custom-country-select  select.form-control{
  margin:0;
}
/* blockquote */



/* blockquote*/

/* background image */
.amp-dc-banner.banner-background-image {
  height: calc(100vh - 95px);
  width:100%;
  background-repeat: no-repeat;
  background-position: bottom;
  color: #000;
  background-size: cover;
  text-align: center;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
.amp-dc-banner.banner-background-image .amp-dc-banner-info .amp-dc-banner-subheader{
  padding: 0px 56px 15px; 
  font-weight: 600;
}
.amp-dc-banner.banner-background-image .amp-dc-banner-info .amp-dc-banner-description{
  padding: 0px 80px 15px; 
}
}

/* background image */

.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-info .amp-dc-banner-description a{
  text-decoration:underline;
  color:#000;
}
.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-button {
font-weight: normal;
  margin: 0;
  background-color: transparent!important;
  color: #000!important;
  font-family: Times New Roman!important;
  font-style: italic;
  font-size: 18px;
}



.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-subheader{
      font-weight: 700;
  font-size: 18px;
  font-style: normal;

}
.amp-dc-banner.hp-heritage-880x175 .amp-dc-banner-button::after{
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-banner {
  position: relative;
text-align: center;
overflow: hidden;
white-space: nowrap;
}

.amp-dc-banner .amp-dc-banner-info .countdown-timer {
  margin: 0;
  margin: auto;
  margin-top: 12px;
}
.amp-dc-banner .amp-dc-banner-info .countdown-time table#uk-timer-web-thumbcontainer {
  margin: auto;
}
.amp-dc-banner * {
margin: 0;
padding: 0;
}

.amp-dc-banner .amp-dc-hide {
display: none;
}

.amp-dc-banner .amp-dc-banner-pic {
max-width: 100%;
display: block;
}

.amp-dc-banner .amp-dc-banner-pic img {
width: 100%;
}

.amp-dc-banner .amp-dc-banner-img {
width: 100%;
display: block;
}
.amp-dc-image img {
  width: 100%;
 
}

.amp-dc-banner .amp-dc-banner-info-wrap {
display:block;
width: 33.3%;
height: 100%;
position: absolute;
top: 0;
white-space: normal;
}
.amp-dc-banner .amp-dc-banner-info {
font-size: 22px;
text-align: center;
color: #000;
padding: 15px;
min-width: 200px;
}

.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom {
   vertical-align: top;
  position: relative!important;
  top: 0 !important;
  left: 0 !important;
  right: auto;
  display:inline-block;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0 15px 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.amp-dc-banner-info-wrap.amp-dc-info-bottom::before {
  height: auto;
}
.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom .amp-dc-banner-info {
  vertical-align: middle;
  display: inline-block;
}
.amp-dc-banner .amp-dc-banner-info-wrap[data-align='left'] {
left: 65px;
}

.amp-dc-banner .amp-dc-banner-info-wrap[data-align='right'] {
right: 65px;
}
@media screen and (min-width: 768px) {
 
.amp-dc-banner .amp-dc-banner-info-wrap:before {
content: '';
height:100%;
 display: inline-block;
vertical-align: middle;
margin-right: -1em;
/* Adjusts for spacing */
}

}

.amp-dc-banner .amp-dc-banner-info {
display: inline-block;
vertical-align: middle;
}


.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom  .amp-dc-banner-info {
   display: inline-block;
vertical-align: top;

}
.amp-dc-banner .amp-dc-banner-header {
  white-space:pre-wrap;
font-size: 30px;

}

.amp-dc-banner .amp-dc-banner-description{
  font-size: 14px;
margin-top: 10px;
}
.amp-dc-banner .amp-dc-banner-subheader {
  white-space:pre-wrap;
font-size: 20pxt;
margin-top: 10px;

}

.amp-dc-banner .amp-dc-banner-button {
font-size: 14px;
margin-top: 10px;
display: inline-block;
min-width: 40%;
   padding: 7px 25px 5px;

text-decoration: none;
font-weight: bold;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

.amp-dc-banner .amp-dc-banner-button.black {
background: #000;
color: #fff;
}

.amp-dc-banner .amp-dc-banner-button.white {
background: #fff;
color: #000;
}

@media screen and (max-width: 768px) {
.amp-dc-banner .amp-dc-banner-button {
  background-color: #000!important;
  color:#fff!important;
}

  .amp-dc-banner .amp-dc-banner-info .countdown-timer .uk-timer-web-imagecontainer {
      color:#000;
  }
  .amp-dc-banner .amp-dc-banner-header {
      line-height: 40px;
      font-size:30px!important;
      color:#000!important;
  }
  .amp-dc-banner .amp-dc-banner-subheader {
    font-size:20px!important;
      color:#000!important;
  }
   .amp-dc-banner .amp-dc-banner-description p{
      color:#000;
  }
  .amp-dc-banner .amp-dc-banner-info {
      background: transparent!important;
  }
.amp-dc-banner .amp-dc-banner-info-wrap {
  width: 40%;
}
.amp-dc-banner .amp-dc-banner-info-wrap[data-align='left'] {
  left: 35px;
}
.amp-dc-banner .amp-dc-banner-info-wrap[data-align='right'] {
  right: 35px;
}
.amp-dc-banner .amp-dc-banner-info-wrap{
     vertical-align: top;
  position: relative!important;
  top: 0 !important;
  left: 0 !important;
  right: auto;
 
  width: 100%;
  height:auto;
  text-align: center;
  padding: 0 15px 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom .amp-dc-banner-info {
  display: inline-block;
}
}

@media screen and (max-width: 480px) {
.amp-dc-banner .amp-dc-banner-info-wrap {
  width: 100%!important;
}
.amp-dc-banner .amp-dc-banner-info-wrap[data-align='left'] {
  left: 20px;
}
.amp-dc-banner .amp-dc-banner-info-wrap[data-align='right'] {
  right: 20px;
}
.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom {
  width: 100%;
}
.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom[data-align='left'] {
  left: 0;
}
.amp-dc-banner .amp-dc-banner-info-wrap.amp-dc-info-bottom[data-align='right'] {
  right: 0;
}
}


.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-header{
  font-size:20px
}

.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-subheader{
  font-size:16px;
  font-style:italic;
}
.amp-dc-banner.special-offers-964x257 .amp-dc-banner-info .amp-dc-banner-description p{
   font-style: initial;
} 
.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-description p{
   font-style: initial;
}  
.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-header{
  font-size:26px;
}
.amp-dc-banner.special-offers-964x372 .amp-dc-banner-info .amp-dc-banner-subheader{
  font-size:20px;
}
#heritageSlot  .amp-dc-banner .amp-dc-banner-info .amp-dc-banner-description h1{
  font-family:century-gothic,Verdana,sans-serif;
  font-size:18px;
  font-style: normal;
}

@media only screen and (max-width: 1600px){
  .container-column .blockquote-padding {
      padding: 35px 20px 0 62px;
  }
}
.amp-dc-banner.hp-delivery-banner .amp-dc-banner-header {font-weight: 700;}
.amp-dc-banner.hp-delivery-banner .amp-dc-banner-description a { text-decoration:underline;color:inherit;}

 

.amp-dc-banner.hp-delivery-banner .amp-dc-banner-button {border: 2px solid #000;font-size:16px;font-family: century-gothic,Verdana,sans-serif;font-weight:normal;}
.amp-dc-banner .amp-dc-banner-info-wrap .amp-dc-banner-info .amp-dc-banner-promolink {
    font-size:10px;
    margin-top:10px;
    display:block;
    text-decoration:none;
    color:#000;
}
@media screen and (max-width:768px){
    .amp-dc-banner.hp-delivery-banner .amp-dc-banner-header {font-size: 18px!important;line-height: 1.2;}
#card-list-slot1 .amp-dc-banner {
    padding-left: 15px;
    padding-right: 15px;
}
}
@media screen and (min-width:1024px){
    .amp-dc-banner.hp-delivery-banner .amp-dc-banner-button:hover {
        background-color: #000;
        color:#fff!important;
     }
}
@media screen and (min-width: 1024px){
  #card-list-slot1 .amp-dc-banner {
      width: 880px;
      margin: auto;
  }
}
