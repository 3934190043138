.amp-dc-image {
    
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;

 
}





.hotel-aminities-cardlist-card-image .amp-dc-image-pic{
  width: 220px;
  height: 202px;
}

@media screen and (max-width: 768px){
.hotel-aminities-right-banner-image-02 .amp-dc-image-pic{
  width:100%;
}
.hotel-aminities-right-logo-image .amp-dc-image-pic{
  width:100%;
}
.hotel-aminities-right-banner-image-03 .amp-dc-image-pic{
  width:100%;
}
.hotel-aminities-cardlist-card-image .amp-dc-image-pic{
  width:100%;
  height:auto;
}

}
img.amp-dc-image-pic.image-431x291 {
  width:431px;
  height:291px;
}

img.amp-dc-image-pic.image-934x500{
  
  width:934px;
  height:500px;
}

img.amp-dc-image-pic.image-417{
  width:417px;
  height:auto;
}

.image-964x372{
  width:964px;
  height:372px;
}
.image-964x400{
   width:964px;
  height:400px;
}

.image-964x400{
   width:964px;
  height:400px;
}
.image-880x175{
   width:880px;
   height:auto;
}
.image-729{
   width:729px;
   height:auto;
}
.image-574x385{
   width:574px;
  height:auto;
}
.image-430x290{
   width:430px;
  height:290px;
}
.image-widthx590px{
width:100%;
height:590px;
}
.image-445x181{
   width:445px;
  height:181px;
}
.image-428x514{
  width:428px;
  height:auto;
}
.image-413{
   width:413px;
   height:auto;
}
.image-414{
   width:414px;
   height:auto;
}   
.image-415{
   width:415px;height:auto;
}

.image-417{
     width:417px;height:auto;
}
.image-422{
     width:422px;height:auto;
}
      
.image-355x478{
   width:355px;
  height:478px;
}
.image-200x200{
   width:200px;
  height:200px;
}
.image-292x161{
   width:292px;
  height:161px;
}
.image-293x218{
   width:293px;
  height:218px;
}
.image-277x172{
   width:277px;
  height:172px;
}

.image-270x181{
   width:270px;
  height:181px;
}

.image-220x220{
   width:220px;
  height:220px;
}
.image-220x202{
   width:220px;
  height:220px;
}
.image-165x166{
   width:165px;
  height:166px;
}
.image-415x277{
  width:415px;
  height:277px;
}
.image-415x532{
  width:415px;
  height:532px;
}
.image-125x125{
   width:125px;
  height:125px;
}
.image-120x120{
   width:120px;
  height:120px;
}
.image-208x208{
   width:208px;
  height:208px;
}

@media screen and (max-width: 768px){
   
.image-445x181, .image-355x478, .image-292x161, .image-293x218, .image-270x181, .image-220x220, .image-165x166{
  width:100%;
  height:auto;
}
.image-277x172, .image-934x500, .image-430x290,  .image-964x400, .image-880x175, .image-574x385 , .image-430x290 , .image-270x181{
  width:100%;
  height: auto;
}
.image-125x125{
  width: 100%!important;
  height: auto!important;
   
}
.image-120x120{
  width: 100%!important;
  height: auto!important;
   
}

.image-413,.image-414 ,.image-415,.image-417,.image-416,.image-729px{
   width:100%;
   height:auto;
}

img.amp-dc-image-pic.image-417{
  width:100%;
  height:auto;
}
.image-415x532{
  width:100%;
height: auto;
}
.image-415x277{
width:100%;
  height: auto;
}
.image-200x200{
width:100%;
  height: auto;
}
.image-widthx590px{
  width:100%;
  height: auto;
}

}
@media screen and (max-width: 1024px){
img.amp-dc-image-pic.image-431x291 {
  width:100%;
  height:auto;
}
}
          
          
          








