.amp-dc-homepage .amp-dc-text {
  margin:0;
}
.amp-dc-splitBlock .amp-dc-size-10 .amp-dc-split-part-wrap{
  text-align:left;
  
}
.amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
  height:auto!important;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text h5{
  color: #000000;
  margin: 24px 0 0 0;
}
.amp-dc-split-part.amp-dc-size-90 .amp-dc-text {
  margin-left: 20px;
}
.splitblock-heading .title{
      padding: 45px 0 40px 35px;
      font-size:36px;
  }
.amp-dc-splitBlock .amp-dc-text{
   margin:auto;
}
.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {
      content: "\201C"; /*Unicode for Left Double Quote*/
      font-size: 189px;
      color: rgba(255, 255, 255, 0.2);
      font-family: Didot, Georgia;
      font-style: italic;
      position: absolute;
      left: -49px;
      top: -56px;
      z-index: 1;
  }
  .amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {
      color: rgba(0, 0, 0, 0.2);
      left: -17px;
  }
  .amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::after {
      content: "";
  }
  
  
.amp-dc-homepage {
  display: block!important;
}



/*
.amp-dc-splitBlock .amp-dc-split-part {
 width: 355px ;
}
*/

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote p{
  font-size:22px;
  font-style:italic;    
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text a{
color: #666;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text p, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text p, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text a, .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size960 .amp-dc-text a {
  font-size:13px;
}
/*
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text p{
  padding-top:8px;
  padding-bottom:8px;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text p{
  padding-bottom:8px;
}*/
@media screen and (min-width: 1024px) {
  
  
.amp-dc-splitBlock .container-column .amp-dc-split-part.amp-dc-size-50:nth-child(2) .amp-dc-split-part-wrap{
    width:288px;
}

 .splitblock-background{
  justify-content: center;
  align-items: center;
  padding: 54px 0 40px;  
  width: 720px;
 }
  
  .splitblock-heading .title{
      padding: 45px 0 40px 35px;
      font-size:36px;
  }
  .container-column
       {
      
         display:flex;
         justify-content:center;
         position: relative;
        align-items: center;
         width: 890px;
        margin: 0 auto;
        text-align: center;
        padding: 0;
   }
  
 .container-column .amp-dc-split-part-wrap:nth-child(2) {
  width: 288px;
 }
.amp-dc-splitBlock .amp-dc-split-part:first-child .amp-dc-split-part-wrap{
    float: right;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40:nth-child(2) .amp-dc-split-part-wrap{
    float:none;
}
.amp-dc-splitBlock .amp-dc-split-part:nth-child(2) .amp-dc-split-part-wrap{
    float:left;
}
.amp-dc-splitBlock .amp-dc-split-part:nth-child(1) .amp-dc-split-part-wrap{
    float:right;
}
  .amp-dc-splitBlock .amp-dc-split-part:first-child .amp-dc-split-part-wrap {
     
    padding-left: 0;
  padding-right: 20px;
}
 .amp-dc-splitBlock .amp-dc-split-part .amp-dc-split-part-wrap {
    padding-right: 0;
  padding-left: 20px;
}
.width-770 {
     width: 770px ;
   height: auto;
   
}
.width-964{
     width: 964px ;
   height: auto;
   
}
.width-878{
     width: 878px ;
   height: auto;
   
}
  
}	
@media screen and (max-width: 768px) {
 .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 .amp-dc-text {
  text-align: left;
   padding: 0; 
}
  .amp-dc-splitBlock{
      border:none!important;
  }
  #background{
          padding: 15px;
  }
  
  .amp-dc-splitBlock .container-column .splitblock-background{
      padding: 20px;
  }
  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-text{
   margin: 0 25px;
}
 
.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text blockquote::before {

          left: -37px;

          top: -78px;

      }
  .width-770 {
     width: 100% ;
   height: auto;
   
}
.width-964{
     width: 100% ;
   height: auto;
   
}
.width-878{
     width: 100% ;
   height: auto;
   
}
  .amp-dc-splitBlock .amp-dc-split-part {
   width: 100% ;
   height: auto;
   padding: 0 ;
}
.width-770 , .width-964{
     width: 100% ;
   height: auto;
   padding: 0 ;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
 
     height: auto;
     width: 80%;
     margin: auto;
  }
}

.amp-dc-splitBlock {
  text-align:center;
margin: 0;
padding: 0;
}

.amp-dc-splitBlock:after {
content: "";
display: table;
clear: both;
}

.amp-dc-splitBlock .amp-dc-split-part {
float: left;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-text {
min-width: 100px;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-video {
min-width: 300px;
}

.amp-dc-splitBlock .amp-dc-split-part .amp-dc-image {
 min-width: auto; 
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
width: 50%;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 {
width: 60%;
height:auto;
text-align:left;
}


.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
width: 70%;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-80 {
width: 80%;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
width: 80%;
height:auto;
text-align:left;
}

@media screen and (min-width: 768px){
  
  #featureFRQuote .amp-dc-splitBlock{
  display:flex;
      align-items: center;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
width: 80%;
height:auto;
}
  
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 {
width: 17%;
height:auto;
border:none;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 .amp-dc-split-part-wrap {
   padding-left: unset!important; 
  padding-right: unset!important; 
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 .amp-dc-split-part-wrap {
  padding-right:  unset!important;
  padding-left:  unset!important; 
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 {
width: 20%;
padding:10px;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote::before{
  left:-72px;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-30 {
width: 30%;
height:auto;
}

.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
width: 40%;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
width: 50%;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
width: 40%;
height:auto;
}

@media screen and (min-width: 1024px){
  .container-column{
    flex-direction: column;
  }
 
.amp-dc-splitBlock {
  height:auto;
}

 #background{
 height: 658px;
  align-items: center;   
  position: relative;
  z-index: 0;
  
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
 }   
  
  
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 .amp-dc-split-part-wrap{
   padding-left:0;
}
}





@media screen and (max-width: 768px) {
  .hotel-collections .amp-dc-splitBlock{
      border:none;
  }
  .hotelproducts-page .amp-dc-splitBlock{
      border:none;
  }
.amp-dc-splitBlock .amp-dc-split-part {
  float: none;
}  .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-90 {
  width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50 {
  width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-80 {
width: 100%;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
  width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60 {
   width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-70 {
   width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-40 {
   width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap { 
  height:auto;
}
.amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap .amp-dc-image{ 
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-30 {
  width: 100%;
  height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 {
width: 100%;
height:auto;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-10 {
width: 100%;
height:auto;
border: none
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
  border: none;
  height: auto;
  width: 80%;
  margin: auto;
}
.amp-dc-splitBlock .amp-dc-split-part:first-child {
  padding: 0 0 30px 0;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50{
    margin-bottom:15px;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote::before{
  left:-25px;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text{
    text-align:center;
}
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-20 .amp-dc-text blockquote{
   padding-left: 20px;
   padding-right: 20px;
   text-align:center;
}


}

@media screen and (min-width: 1024px){
.amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-60:first-child .amp-dc-split-part-wrap{
padding-left: 0;
padding-right: 0;
float:left;
padding: 15px;
}

}
