.journal-nav-bar ul.amp-dc-nav-wrap a:active {
    text-decoration:underline;
  }
  @media screen and (max-width: 768px){
  .journal-nav-bar ul.amp-dc-nav-wrap{
    display:flex!important;    
  }
  .journal-nav-bar ul.amp-dc-nav-wrap .navigation-link {
      font-size: 16px!important;
     
  }
  .journal-nav-bar ul.amp-dc-nav-wrap li{
      margin:auto;
  }
  
  }
  
  
  .journal-nav-bar ul.amp-dc-nav-wrap li{
       font-family:inherit;
  }
  .journal-nav-bar ul.amp-dc-nav-wrap .navigation-link {
      font-size: 18px;
      color:#000!important;
      font-family:inherit;
  }
   @media screen and (min-width: 1024px) {
    
    .top-navigation-header.journal-nav-bar {
      width: 581.59px;
      margin: auto;
    }
    
  }
   .top-navigation-header.journal-nav-bar .amp-dc-nav-wrap{
       width:auto;
   }
   .top-navigation-header.journal-nav-bar .amp-dc-nav-wrap li{
       font-style:italic;
   }
  .amp-dc-navigation{
      text-align:center;
  }
  .amp-dc-navigation .Title{
    
      height: auto;
      line-height: 42px;
      width: 320px;
      margin: 25px auto;
      border-bottom: 1px solid #000;
      font-size:62px;
  }

  ul.amp-dc-nav-wrap {
     margin: auto;
     
     display: flex;
     justify-content: space-around;
     height: 26px;
     align-items: center;
  }
  
  ul.amp-dc-nav-wrap li{
      
    font-size:18px;
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }
  
  ul.amp-dc-nav-wrap li a {
      color: black;
     text-decoration: none;
  }
  
  
  
  