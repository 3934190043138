div.sub-menu .amp-dc-card .amp-dc-card-link, .amp-dc-card.mm-208x208 .amp-dc-card-link {
    
   font-family: century-gothic, Verdana, sans-serif!important;
}

.amp-dc-card .century-gothic-stack {
font-family: century-gothic, Verdana, sans-serif!important;
}
.amp-dc-card .times-new-roman {
font-family: 'Times New Roman', Times, serif;
}

.amp-dc-card .amp-dc-card-subheader{
  font-size: 20px;
  color: #000;
  position: absolute;
  font-style: italic;
 font-family: 'Times New Roman', Times, serif;
}

.sub-menu .amp-dc-card .amp-dc-card-description {
   font-family: 'Times New Roman', Times, serif!important;
}
.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap .amp-dc-card-description p,  .amp-dc-card.journal-small-415 .amp-dc-card-text-wrap .amp-dc-card-description p,  .amp-dc-card.journal-small-416 .amp-dc-card-text-wrap .amp-dc-card-description p,  .amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description p{
margin-bottom:15px;
}
.amp-dc-card .amp-dc-card-description a, .amp-dc-card .amp-dc-card-description p, .amp-dc-card .amp-dc-card-description p a, .amp-dc-card .amp-dc-card-description p em, .amp-dc-card .amp-dc-card-description  em, .amp-dc-card .amp-dc-card-description p strong{
  font-family:inherit;
  color:inherit;
}
.amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description a{
   text-decoration:underline;
}
.amp-dc-card .amp-dc-card-description blockquote {
  font-family: 'Times New Roman', Times, serif!important;
  color:inherit;
}
.amp-dc-card .amp-dc-card-description blockquote h1, .amp-dc-card .amp-dc-card-description blockquote h2, .amp-dc-card .amp-dc-card-description blockquote h3, .amp-dc-card .amp-dc-card-description blockquote h4, .amp-dc-card .amp-dc-card-description blockquote h5, .amp-dc-card .amp-dc-card-description blockquote h6, .amp-dc-card .amp-dc-card-description blockquote p{
  font-family: 'Times New Roman', Times, serif!important;
  color:inherit;
}
.amp-dc-card .amp-dc-card-description strong, .amp-dc-card .amp-dc-card-description strong a, .amp-dc-card .amp-dc-card-description strong p{
  text-transform:none;
  font-weight:bold;
}
.amp-dc-card .amp-dc-card-description h1, .amp-dc-card .amp-dc-card-description h2, .amp-dc-card .amp-dc-card-description h3, .amp-dc-card .amp-dc-card-description h4, .amp-dc-card .amp-dc-card-description h5{
  font-family:inherit;
}
.amp-dc-card .amp-dc-card-description h2{
  font-family:inherit;
  font-size:30px;
  font-weight:500;
}
.amp-dc-card .amp-dc-card-description h1, .amp-dc-card .amp-dc-card-description h2, .amp-dc-card .amp-dc-card-description h3, .amp-dc-card .amp-dc-card-description h4, .amp-dc-card .amp-dc-card-description h5, .amp-dc-card .amp-dc-card-description h6{
  font-weight:500;
}
.amp-dc-card .amp-dc-card-description h1{
  font-size:36px;
}
.amp-dc-card .amp-dc-card-description h2{
  font-size:30px;
}
.amp-dc-card .amp-dc-card-description h3{
  font-size:22px;
}
.amp-dc-card .amp-dc-card-description h4{
 font-size:18px; 
}
.amp-dc-card .amp-dc-card-description h5{
  font-size:16px;
}
.amp-dc-card .amp-dc-card-description h6{
  font-size:14px;
}
.amp-dc-card .amp-dc-card-description em{
  font-family:inherit;
  font-size:inherit;
}

.amp-dc-card .amp-dc-card-description p{
   font-size:14px;
}
.amp-dc-card .amp-dc-card-description h2 em{
  font-family:inherit;
}
.amp-dc-card .amp-dc-card-description h3{
  font-family:inherit;
}
.amp-dc-card .video-player-ft {
  display: block;
  position: absolute!important;
  margin: 0 auto 0;
  top: 50%;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  z-index: 10;
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 35px;
  border: 7px solid rgba(255,255,255,0.8);
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.amp-dc-card .video-player-ft .animated.infinite {
  /* -webkit-animation-iteration-count: infinite; */
  animation-iteration-count: infinite;
}
.amp-dc-card .video-player-ft .zoomIn  {
  /* -webkit-animation-iteration-count: infinite; */
 webkit-animation-name: zoomIn;
}
.amp-dc-card .video-player-ft .animated {
  -webkit-animation-duration: 1.9s;
  animation-duration: 1.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes zoomIn {
      from {
          opacity: 1;
          transform: scale3d(.7, .7, .7);
      }

      50% {
          opacity: 1;
          transform: scale3d(.9, .9, .9);
      }
      100% {
          opacity: 1;
          transform: scale3d(.7, .7, .7);
      }
  }

  .amp-dc-card .video-player-ft .zoomIn {
      -webkit-animation-name: zoomIn;
      animation-name: zoomIn;
  }
.amp-dc-card .video-player-ft .video-arrow {
  display: inline-block;
  margin: 2px 0 0 6px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-left: 34px solid rgba(255,255,255,0.8);
  border-bottom: 18px solid transparent;
}




.time-duartion .time-circle {
   font-size:10px;
       text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;

  margin: auto;
 font-family: 'Times New Roman', Times, serif; 
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
span{
  display:block;
}
}
.time-circle span{
  font-family: 'Times New Roman', Times, serif; 
  font-size:14px;
}
.ImageOverlay {
  
     
 height: 24px;
  position: absolute!important;
  
  top: 0px;
  font-style: italic;
  width: 76.84px;
  color: #fff;
  background-color:rgba(0, 0, 0, 0.5);
  font-family: 'Times New Roman', Times, serif;
  text-align: center;

  justify-content: center;
  display: flex;
  vertical-align: middle;
  align-items: center;
}
.journal-main-574x385 .ImageOverlay{
  width: 106px;
  height:35px;
  font-size:14px;
}
.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}

.amp-dc-card .amp-dc-card-description blockquote p{
  font-style:italic;
}

.amp-dc-card .amp-dc-card-description blockquote::before {

      content: "\201C"; /*Unicode for Left Double Quote*/

      font-size: 189px;

      color: rgba(255, 255, 255, 0.2);

      font-family: 'Times New Roman', Times, serif; 

      font-style: italic;



      /*Positioning*/

      position: absolute;

      left: -49px;

      top: -38px;

      z-index: 1;

  }

.amp-dc-card .amp-dc-card-description blockquote::before {
color: rgba(0, 0, 0, 0.2);
left: -10px;
  top: -26px;
}


.amp-dc-card .amp-dc-card-description blockquote::after {
content: "";
}
blockquote{
   
  position: relative;
  padding: 0;
  border: none;
  font-size: 150%;
  margin-top: 24px;
  font-style: italic;
  text-align: left;
}


.amp-dc-card .time-duartion {
  padding-top: 6px;
}
.time-duartion .time-circle span{
  display:block;
}
.time-duartion .time-circle {
      padding-top: 4px;
   font-size:10px;
       text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;

  margin: auto;
 font-family: 'Times New Roman', Times, serif; 
  line-height: 13px;
  font-style: italic;
  font-size: 14px;

}


.time-duartion {
  margin-top: 15px;
  margin-bottom: 10px;
}
.amp-dc-card {
width: 100%;
display: inline-block;
vertical-align: top;
    position: relative;
}

.amp-dc-card * {
margin: auto;
padding: 0;
}

.amp-dc-card .amp-dc-hide {
display: none;
}

.amp-dc-card .amp-dc-card-text-wrap {
color: #000;

text-align: center;
background-color: #fff;
}

.amp-dc-card a.amp-dc-card-wrap {
 
display: block;
text-decoration: none;
}

.amp-dc-card a.amp-dc-card-wrap:hover .amp-dc-card-link {
text-decoration: underline;
}

.amp-dc-card .amp-dc-card-name {
  white-space:pre-wrap;
    font-size: 15px;
  line-height: 20px;
  color: #000;color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}

.amp-dc-card .amp-dc-card-description {

  
  font-size: 14px;
  line-height: 1.2;
  display: block;
  color: #000;
}

.amp-dc-card .amp-dc-card-link {
 
font-size: 14px;
  padding: 7px 25px 5px;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  color: #000;
  font-family:Times New Roman;
}

.amp-dc-external-block .test {
padding: 20px;
background: #efefef;
border-radius: 5px;
margin: 20px;
min-width: 360px;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

.amp-dc-image {
margin: 0;
padding: 0;
position: relative;
overflow: hidden;
white-space: nowrap;
width: 100%;
display: block;
}

.amp-dc-image .amp-dc-image-pic {
display: block;
}

.amp-dc-image img {
width: 100%;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.amp-dc-card .amp-dc-card-description p {
  font-family: inherit;
}
.amp-dc-card.hotel-right-292x161 {
  margin-bottom:15px;
}
/*special offers small banner */
.amp-dc-card.special-offers-308x330{
  margin:auto;
}
.amp-dc-card.special-offers-308x330 .amp-dc-card-text-wrap {
  display: flex;
      left: 0;
  right: 0;
  justify-content: center;
  height: 100%;
  top: 0;
  width: 100%;
  background: transparent;
  position: absolute!important;
  color: #000;
  margin:auto;
}
.amp-dc-card.special-offers-308x330 .amp-dc-card-link{
  left:28%;
  right:28%;
  bottom: 20px!important;
  margin: 0;
  color: #fff!important;
  background-color: #000;
  position: absolute;
  left: unset!important;
}
.amp-dc-card.special-offers-308x330 .amp-dc-card-name{
   position: absolute!important;
   left:0px;
   right:0px;
  margin-top:25px;
}
.amp-dc-card.special-offers-308x330 .amp-dc-card-subheader{
  left:0px;
   right:0px;
}
.amp-dc-card.special-offers-308x330 .amp-dc-card-description{
   margin-top: 65px;
   left:0px;
   right:0px;
  position: absolute;
      
}

.amp-dc-card.special-offers-308x330 .amp-dc-card-description p{
  font-size:16px;
      
}
/* homepage small banners */


.amp-dc-card.hp-small-431x291 .amp-dc-card-name, .amp-dc-card.hp-small-431x291-poi .amp-dc-card-name {
  font-size: 18px;
  font-weight: 700;
}
.amp-dc-card.hp-small-431x291 .amp-dc-card-description { font-style: italic; font-size: 14px; padding-bottom: 12px; line-height: 18px;}
.amp-dc-card.hp-small-431x291-poi .amp-dc-card-description { font-style: italic; font-size: 14px; padding-bottom: 12px;line-height: 18px;}
.amp-dc-card.hp-small-431x291-poi .amp-dc-card-link { font-family: century-gothic, Verdana, sans-serif!important;border: 2px solid #000; color: #000!important; background-color: #fff; font-size: 16px;  height: 36px;}
.amp-dc-card.hp-small-431x291 .amp-dc-card-link {
   font-family: century-gothic, Verdana, sans-serif!important;
  border: 2px solid #000;
  color: #000!important;
  background-color: #fff;
  font-size: 16px;
  height: 36px;
}
.amp-dc-card.hp-small-431x291 .amp-dc-card-link:hover {
background-color: #000!important;
}

.amp-dc-card.hp-small-431x291-poi .amp-dc-card-link:hover { background-color: #000!important;}
.journal-small-270x181 .ImageOverlay{
  font-size:10px;
}
.amp-dc-card.journal-small-270x181{
  padding-top:0;
}
.amp-dc-card.journal-small-270x181 .amp-dc-card-name{
   font-weight: normal;
  font-size:16px;
     line-height: 17px;
         text-transform: uppercase;
}
.amp-dc-card.journal-small-270x181 .amp-dc-card-description{
  font-style: italic;
   line-height: 20.94px;
  font-size:14px;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-description h3 { 
  line-height:1.2;
}
.amp-dc-card.journal-small-270x181 .amp-dc-card-link{
  font-size:16px;
  height:36px;
}
.amp-dc-card.journal-small-270x181 .amp-dc-card-description p::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: ' ';
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 7px;
  vertical-align: baseline;
  visibility: visible;
}
.amp-dc-card.journal-hp-product-img .amp-dc-card-link{
 white-space: initial;
  padding:0;
  
}
.amp-dc-card.journal-small-270x181 .amp-dc-card-description a{
  text-decoration:none;
}
.amp-dc-card.journal-hp-product-img .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
 margin-left: 7px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-description p::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: " ";
  display: inline-block;
  height: auto;
  width: auto;
  
  margin-left: 6px;
  vertical-align: baseline;
  visibility: visible;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-img-wrap{
  position:initial!important;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-description h3 { 
  line-height:1.2;
  padding: 7px 0 13px;
}

.amp-dc-card.journal-main-574x385{
  padding-top:0;
}

.amp-dc-card.perfumer-profile-shop-fragrances-img{
   width:291px!important;
   height:auto;
}
.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link{
  font-family:'Times New Roman', Times, serif;
  font-style:italic; 
}
.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

.amp-dc-card.journal-perfumers-img-291x291{
  width:291px!important;
  height:auto;
}
.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description h4::after{
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}
.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description p em {
  line-height: 2.1;
}
.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description p a{
  text-decoration:underline;
}
/* homepage small banners */


/* about us page small banner*/
.amp-dc-card.about-us-165x166 .amp-dc-card-name{
  display: none;
}
.about-us-165x166{
   width:165px;
   height:auto;
}
.amp-dc-card.about-us-165x166 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute!important;
  bottom: 0!important;
  right: 0;
  left: 0;
  background-color: #0000008a!important;
   text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.amp-dc-card.about-us-165x166 .amp-dc-card-description{
  display:none;
}
.amp-dc-card.about-us-165x166  .amp-dc-card-link {
  padding: 0;
  line-height: 15px;
  background-color: unset;
  color: #fff!important;
  font-size: 12px;
  white-space: nowrap;
}
.amp-dc-card.about-us-165x166  .amp-dc-card-link::after {
  color:#fff;
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}

/* megamenu cards */
.amp-dc-card.mm-208x208 .amp-dc-card-description, .amp-dc-card.mm-208x208 .amp-dc-card-description p, .amp-dc-card.mm-208x208 .amp-dc-card-description p em, .amp-dc-card.mm-208x208 .amp-dc-card-description em p{
  font-family:Times New Roman!important;
}
.image-208x208{
   width:208px;
   height:auto;
}
.amp-dc-card.mm-208x208 .amp-dc-card-name{
  font-size: 14px !important;
  font-weight: normal !important;
}
.amp-dc-card.mm-208x208 .amp-dc-card-link{
  background: unset;
  font-size: 14px;
  color: #000;
  text-decoration: underline;
}

/* hotel aminities cards */
.amp-dc-card.hotel-bottom-220x202 .amp-dc-card-wrap .amp-dc-card-text-wrap {
   padding:10px;
  position: absolute!important;
  bottom: 0!important;
  right: 0;
  left: 0;
   padding:8px!important;
  background-color: #0000008a!important;
}
.amp-dc-card.hotel-bottom-220x202 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif;
  padding:0;
  background-color: unset;
  color: #8f8f8f;
  font-size: 12px;
  white-space: nowrap;
}

.amp-dc-card.hotel-right-293x218 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  
  position: absolute!important;
  bottom: 0!important;
  right: 0;
  left: 0;
   padding:8px!important;
  background-color: #0000008a!important;
}
.amp-dc-card.hotel-right-293x218 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif!important;
      padding: 0;
  background-color: unset;
  color: #fff!important;
  font-size: 16px;
 
 
  white-space: nowrap;
}

/* journal category and homepage cards */
.amp-dc-card.journal-small-270x181 .amp-dc-card-wrap{
  display:block;
}

.amp-dc-card.journal-small-270x181 .amp-dc-card-img-wrap{
  position:initial!important;
}
.journal-small-270x181 .ImageOverlay{
  font-size:10px;
}

.amp-dc-card-img-wrap {
  position: relative;
} 


/* about us main banner */
.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name{
font-size:23px;
color:#fff!important;
padding: 0 0 14px 0;
}
.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description p{
  font-size:13px;
  color:#fff!important;
}


.amp-dc-card.about-us-main-355x478 .amp-dc-card-wrap .amp-dc-card-text-wrap{
  width: 268px!important;
  background-color: #2c2d2d;
  height: 370px;
  position: absolute;
  opacity: .7;
  
  text-align: left!important;
  top: 34px;
  padding: 10px;
  left: 20px;
     
}


/*journal page product image */
.amp-dc-card.journal-product-212{
   width: 212px;
  height: auto;
}
.amp-dc-card.journal-product-212 .amp-dc-card-link{
  background: transparent!important;
  color: #000!important;
}
.amp-dc-card.journal-product-212 .amp-dc-card-link{
  font-style:italic;
}
.amp-dc-card.journal-product-212 .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 3px;
  vertical-align: baseline;
  visibility: visible;
}
.amp-dc-card.journal-product-212 .amp-dc-card-description p a {
  color: #000;
  text-decoration: none;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-name{
    font-size:25px;
    line-height: 1.2;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-description  a {
  color: #000;
  text-decoration: none;
}

/* frag-hub */
.amp-dc-card.frag-hub-two-banners-620 {
  width: 620px;
  height: auto;
}
.amp-dc-card.frag-hub-two-banners-620 .amp-dc-card-name{
  padding-top:10px;
  font-size:20px;
}
.amp-dc-card.frag-hub-two-banners-620 .amp-dc-card-link {
  font-family: century-gothic, Verdana, sans-serif!important;
  border: 2px solid #000;
  color: #000!important;
  background-color: #fff;
  font-size: 16px;

}
.amp-dc-card.frag-hub-product-icons-img .amp-dc-card-link {
   font-family: century-gothic, Verdana, sans-serif!important;
  border: 2px solid #000;
  color: #000!important;
  background-color: #fff;
  font-size: 16px;

}

.amp-dc-card.frag-hub-product-icons-img{
  width:291px;
  height:auto;
}
/* more stories*/ 
.amp-dc-card.journal-more-stories-img{
  width:316px;
  height:auto;
}
.amp-dc-card.journal-more-stories-img .amp-dc-card-name{
  margin-bottom:0;
}
.amp-dc-card.journal-more-stories-img .amp-dc-card-description strong a::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.amp-dc-card.journal-more-stories-img .amp-dc-card-description p {
line-height:1.2;
  
}
.amp-dc-card.journal-more-stories-img .amp-dc-card-description em {
line-height:1.2;
  
}

/* journal cards */
@media screen and (min-width: 769px){
    
.amp-dc-card.journal-small-415 blockquote p{
  padding: 44px 58px 0 50px;
  line-height: 1.2;
}
.amp-dc-card.journal-small-415 blockquote p{
  padding: 44px 58px 0 50px;
  line-height: 1.2;
}
}
.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap .amp-dc-card-description,  .amp-dc-card.journal-small-415 .amp-dc-card-text-wrap .amp-dc-card-description,  .amp-dc-card.journal-small-416 .amp-dc-card-text-wrap .amp-dc-card-description,  .amp-dc-card.journal-small-417 .amp-dc-card-text-wrap .amp-dc-card-description{
line-height: 1.3!important;
}
.amp-dc-card.journal-small-414 .amp-dc-card-text-wrap {
   margin-top: 24px;
  padding: 0 16px;
  
}
.amp-dc-card.journal-small-415 .amp-dc-card-text-wrap {
   margin-top: 24px;
  padding: 0 16px;
}
.amp-dc-card.journal-small-416 .amp-dc-card-text-wrap {
   margin-top: 24px;
  padding: 0 16px;
}
.amp-dc-card.journal-small-416 .amp-dc-card-text-wrap {
   margin-top: 24px;
  padding: 0 16px;
}
.amp-dc-card.journal-small-417 .amp-dc-card-text-wrap {
   margin-top: 24px;
  padding: 0 16px;
}
.amp-dc-card.journal-small-414 .amp-dc-card-description{
   text-align:left;
}

.amp-dc-card.journal-small-415 .amp-dc-card-description{
   text-align:left;
}
.amp-dc-card.journal-small-416 .amp-dc-card-description{
   text-align:left;
}
.amp-dc-card.journal-small-417 .amp-dc-card-description{
   text-align:left;
}
.amp-dc-card.journal-small-422 .amp-dc-card-description{
   text-align:left;
}
/*journal cards*/

@media screen and (min-width: 769px){
  
  
.amp-dc-card.journal-main-574x385 .amp-dc-card-img-wrap{
   float: left;
   width:574px;
   
   height:386px;
}
.amp-dc-card.journal-main-574x385 .time-duartion {
  float: right;
  width: 304px;
}

.amp-dc-card.journal-main-574x385 .amp-dc-card-text-wrap {
    padding:10px;
    margin-top: 100px;
  width: 304px;
  float: right;
}

}





/* card sizes */
.amp-dc-card.special-offers-308x330 {
  width:308px;
  height:338px !important;
}

.amp-dc-card.hotel-collection-277x172 {
  width: 277px;
  height: auto;
}
.amp-dc-card.journal-img-729 {
  width: 729px;
  height: auto;
    
}
/*hotel product images*/
.amp-dc-card.hotel-product-120{
   width: 120px;
   border: 1px solid #d6d6d6;
  height: auto;
}
/*about us bottom images*/
.amp-dc-card.about-us-165x166{
  width: 165px;
  height: 166px;
}
/*about us main image */
.amp-dc-card.about-us-main-355x478{
   width: 355px;
  height: 478px;
}
/*mega menu images*/
.amp-dc-card.mm-208x208{
   width: 208px;
  height: 208px;
}
/*Hotel aminities bottom images*/
.amp-dc-card.hotel-bottom-220x202{
   width: 220px;
  height: auto;
}
/*Journal home,category page images*/
.amp-dc-card.journal-small-270x181{
   width: 270px;
   margin-bottom: 10px;
  height: auto;
}
.amp-dc-card.journal-hp-product-img{
   width: 138px;
  height: auto;
}
.amp-dc-card.journal-main-574x385{
   width: 100%;
  height: auto;
}
/*hotel aminities  images*/
.amp-dc-card.hotel-right-293x218{
   width: 293px;
  height: auto;
}
.amp-dc-card.hotel-right-292x161{
   width: 292px;
  height: auto;
 

}
/*about us main image */
.amp-dc-card.journal-product-212{
   width: 212px;
  height: auto;
}

/*journal listcials,editorial, listical title and numericals*/

.amp-dc-card.journal-small-413 {
   width: 413px;
  height: auto;
}
.amp-dc-card.journal-small-414 {
  width: 414px;
  height: auto;
}
.amp-dc-card.journal-small-417 {
   width: 417px;
  height: auto;
}
.amp-dc-card.journal-small-415 {
   width: 415px;
  height: auto;
}
.amp-dc-card.journal-small-422 {
   width: 422px;
  height: auto;
}
/* home page images */
.amp-dc-card.hp-small-445x181{
   width: 445px;
  height: auto;
}
.amp-dc-card.hp-small-431x291{
   width: 431px;
  height: auto;
}
.amp-dc-card.hp-small-431x291-poi{ width: 431px; height: auto;}
.amp-dc-card.hp-small-431x291-poi{   width: 431px;  height: auto;}
.amp-dc-card-list-wrap .amp-dc-external-block {
  width: 431px;
  display: inline-block;
  height: auto;
  position: relative;
  margin: 14px 29px 0;
}
.amp-dc-card-list-wrap .amp-dc-external-block video, .amp-dc-card-list-wrap .amp-dc-external-block img {
  width: 431px;
  height:auto;
}

@media screen and (max-width: 1024px) and (min-width:768px){
 .amp-dc-card.hp-small-431x291{
  width:50%;
  height:auto;
}
.amp-dc-card.hp-small-431x291-poi{ width:50%;height:auto; }

.amp-dc-card-list-wrap .amp-dc-external-block {
     width: 100%;
   height:auto;
   margin:auto;
  }
   .amp-dc-card-list-wrap .amp-dc-external-block {
  width: 50%;
  height: auto;
  margin: auto;
 }
}
@media screen and (max-width: 767px){ 
   .amp-dc-card.hp-small-431x291 .amp-dc-card-text-wrap {  width:100%;  }
   .amp-dc-card.hp-small-431x291-poi .amp-dc-card-text-wrap { width:100%;  }
     .amp-dc-card-list-wrap .amp-dc-external-block {
  width: 100%;
  height: auto;
  margin: auto;
 }
.amp-dc-card.hp-small-431x291{
  width:100%!important;
  height:auto;
 } 
}
@media screen and (max-width: 768px){
   .amp-dc-card-list-wrap .amp-dc-external-block {
     width: 100%;
   height:auto;
   margin:auto;
  }
   .amp-dc-card-list-wrap .amp-dc-external-block video, .amp-dc-card-list-wrap .amp-dc-external-block img {
     width: 100%;
   height:auto;
   margin:auto;
  }
 
   .amp-dc-card.hp-small-431x291 .amp-dc-card-link {    border: 2px solid #000;  color: #fff!important;  background-color: #000;  font-size: 16px;  height: 36px;  }
   
  .amp-dc-card.hp-small-431x291-poi .amp-dc-card-link {    border: 2px solid #000;  color: #fff!important;  background-color: #000;  font-size: 16px;  height: 36px;  }
  
   .amp-dc-card .amp-dc-card-description blockquote{
       top:10px;
   }
   .journal-small-270x181 .ImageOverlay, .journal-main-574x385 .ImageOverlay{
  font-size:16px;
 }
   
    
.amp-dc-card .amp-dc-card-description blockquote::before {

          left: -37px;

          top: -78px;

      }     
/*special offers */ 
.amp-dc-card.special-offers-308x330 {
  width:100%;
  height:auto!important;
}
 /*hotel collections*/
 .amp-dc-card.hotel-collection-277x172 {
  width: 100%;
  height: auto;
}
.amp-dc-card.journal-hp-product-img{
   width: 100%;
  height: auto;
}
 .amp-dc-card.journal-img-729 {
  width: 100%;
  height: auto;
}  
/*hotel products*/
.amp-dc-card.hotel-product-120{
   width: 120px;
  height: auto;
}
/*about us bottom banners*/
.amp-dc-card.about-us-165x166{
  width: 47%!important;
  height: auto;
}
/*about us main banner */
.amp-dc-card.about-us-main-355x478{
   width: 50%;
  height: auto;
  float:left;
}
/*Hotel aminities bottom banner*/
.amp-dc-card.hotel-bottom-220x202{
   width: 100% !important;
  height:auto !important;
}

/*Journal home,category page banner*/
.amp-dc-card.journal-small-270x181{
   width: 100%;
  height: auto;
}
 .amp-dc-card.journal-small-270x181 .amp-dc-card-description p{
  padding-left: 10%;
  padding-right: 10%;
}
.amp-dc-card.journal-main-574x385 .amp-dc-card-description p{
   padding-left: 12%;
  padding-right: 12%;
}

.amp-dc-card.journal-perfumers-img-291x291{
  width:100%;
  height:auto;
}


.amp-dc-card.journal-main-574x385{
   width: 100%;
  height: auto;
  padding-bottom:15px;
}
/*hotel aminities  banners*/
.amp-dc-card.hotel-right-293x218{
   width: 96%;
  height: auto;
}
.amp-dc-card.hotel-right-292x161{
   width: 96%;
  height: auto;
}
/*journal page prodcut banners */
.amp-dc-card.journal-product-212{
  width: 49%;
  height: 100%;
}
/*journal listcials,editorial, listical title and numericals*/

.amp-dc-card.journal-small-413 {
   width: 100%;
  height: auto;
}
.amp-dc-card.journal-small-414 {
  width: 100%;
  height: auto;
}
.amp-dc-card.journal-small-417 {
 width: 100%;
  height: auto;
}
.amp-dc-card.journal-small-415 {
 width: 100%;
  height: auto;
}
.amp-dc-card.journal-small-422 {
 width: 100%;
  height: auto;
}

/* More Stories*/
.amp-dc-card.journal-more-stories-img{
  width:92%;
  height:auto;
}
.amp-dc-card.frag-hub-two-banners-620 {
  width: 92%;
  height: auto;
}
.amp-dc-card.frag-hub-product-icons-img{
  width:auto;
  height:auto;
}
.amp-dc-card.perfumer-profile-shop-fragrances-img{
   width:49%!important;
   height:auto;
}

}

@media screen and (min-width: 1024px){
   .amp-dc-card.hp-small-445x181 {
  width: 431px;
  height: auto;
}
}
@media screen and (max-width: 1023px){
   .amp-dc-card.hp-small-445x181 {
  width: 100%;
  height: auto;
}
}
@media screen and (max-width: 480px){
  .amp-dc-card.about-us-main-355x478{
       width: 96%!important;
       height: auto;
       float: none;
  }
.amp-dc-card.about-us-165x166{
  width: 96%!important;
  height: auto;
}
}
@media screen and (min-width: 768px){
#cardListslot .amp-dc-splitBlock .amp-dc-split-part.amp-dc-size-50{
    width:50%;
    float:left;
    padding-left:10px;
    padding-right:10px;
}
#cardListslot .amp-dc-splitBlock {
  padding: 0px 6%;
  text-align: center;
  margin: 0;
  display: flex;
  padding: 0;
  justify-content: center;
  margin-bottom:20px;
}
  
}    

          
