.amp-dc-text {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    li {
      list-style-position: outside;
    }
  }


  //css
.Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.static_para {
   color:#260E03 !important;
  margin-top: 15px;
  font-size: 13px !important;
}
.static_para h1 {
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  .static_para {
    padding: 0 15px;
 }
}
 .onlyImage {
  margin: 0 !important;
}
 .onlyImage .staticHeader h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
  margin: 10px 0 0;
}
@media screen and (max-width: 768px) {
   .onlyText .staticHeader h1 {
    padding: 15px !important;
 }
}
.staticAside {
  width: 194px;
  height: 441px;
}
.staticAside ul .list-group-item {
  border: none !important;
  padding: 0px;
}
.leftColumn .medium-aside-menu li ul.search-menu-sub-cntnt li {
  line-height: 16px;
}
@media (max-width: 768px) {
  .leftColumn {
    margin-top: 0px !important;
 }
  .staticHeader.list-group-item {
    border: none !important;
    background-color: #f4f4f4 !important;
    margin-bottom: 10px;
 }
  aside {
    padding: 0 !important;
 }
  .browser-info-cntnt {
    margin-bottom: 10px;
 }
  .browser-info-cntnt h1 {
    font-size: 12px !important;
 }
  .medium-aside-menu li {
    padding: 10px 20px;
 }
}
.onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
  color: #000;
  font-size: 28px;
  font-weight: 100;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .onlyText .staticHeader h1, .onlyImage .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px;
    font-size: 15px !important;
    font-weight: bold !important;
    color: #fff !important;
    background: #90d2c5;
    margin: 0;
 }
}

/* About us*/




 .amp-dc-split-part-wrap .aboutus-card-01 .amp-dc-card-wrap {
  position: relative;
}



@media screen and (max-width: 768px) {
   .aboutus-text {
    margin: 0px !important;
 }
}
.aboutus-text {
  text-align: center;
}
@media screen and (max-width: 768px) {
   .aboutus-text {
    width: 300px;
    margin: 10px !important;
 }
}
.aboutus-text .rightContent ul li {
  list-style: none;
  line-height: 15px;
  font: 12px/15px Arial, Helvetica, sans-serif;
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .aboutus-text ul li {
    margin-left: 0px !important;
 }
}
.aboutus-text .rightContent ul li h4 {
  font-weight: bold;
  margin: 10px 0;
  font-size: 18px;
}
.aboutus-text .rightContent h3 {
  margin: 3.5em 0 2em;
  color: #4b2d16 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link {
  text-align: center !important;
  margin: 3.5em 25px 2em !important;
}
.aboutus .amp-dc-block-wrap .amp-dc-splitBlock #aboutusrightsection .amp-dc-block-wrap:last-child .amp-dc-link .amp-dc-link-value {
  color: #4b2d16 !important;
  font-weight: bold;
  text-decoration: underline;
}
.aboutus-text .amp-dc-link .amp-dc-link-value:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap {
    margin-left: 0px !important;
 }
}
.aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  width: 165px;
  height: 166px;
  position: relative !important;
}
@media screen and (max-width: 768px) {
  .aboutus .amp-dc-block-wrap:last-child .amp-dc-card-list .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
    margin: auto;
 }
}
.staticHeader{
       font-family: Trebuchet MS, Arial, sans-serif;
}
/* gift terms and conditions */
.giftcardterms-conditions .staticHeader .mb_contentblock_landing span a {
  color: #666 !important;
}
.giftcardterms-conditions .staticHeader .static_para p {
  margin-bottom: 15px;
}
/*Faqs*/
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
  font-family: Trebuchet MS, Arial, sans-serif;
  background: #fafafa;
  border: 1px solid #d6d6d6;
  padding: 20px;
  width: auto;
  float: left;
  margin: 23px 0 0 0;
}
@media screen and (max-width: 768px) {
    
   .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent {
   font-family: Trebuchet MS, Arial, sans-serif;
    border: 0px;
    background-color: #fff;
    padding: 20px 0;
    width: 100%;
 }
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
  background: #fff;
  border: 1px solid #d6d6d6;
  display: block !important;
  margin-top: 20px;
  padding: 10px;
  width: 710px;
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs a {
  color: #666;
}
@media screen and (max-width: 768px) {
   .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs {
    border: none;
    padding: 10px 15px;
    width: auto;
 }
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul {
  margin-left: 20px;
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs ul li {
  list-style: circle;
  line-height: 15px;
  font-size: 12px;
  color: #000;
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p {
  padding-bottom: 7px;
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 900;
  color: #666;
}
@media screen and (max-width: 768px) {
   .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs p .mb_FAQ_link_title {
    font-weight: normal;
    line-height: 30px;
    color: #333;
 }
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .title {
  font-weight: 900;
  font-size: 12px;
  color: #000;
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
  background-color: #d6d6d6;
  clear: both;
  display: block;
  height: 1px;
  margin: 10px 0;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
   .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent .mb_contentblock_FAQs .hr {
    display: none;
 }
}
 .deliveryfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .placingorderfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .onlineaccountfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .productfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .giftcardfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .companyfaqs .staticHeader .mb_contentblock_FAQs-myprofilecontent h1,  .faqsandhelp .staticHeader .mb_contentblock_FAQs-myprofilecontent h1 {
  font-size: 28px;
  color: #000;
  font-weight: 100;
}
 .faqsandhelp h1 {
  margin-top: 0;
}
 .placingorderfaqs h1 {
  margin-top: 0;
}
 .deliveryfaqs .staticHeader h1 {
  padding: 0 !important;
}
@media screen and (max-width: 768px) {
   .deliveryfaqs .staticHeader h1 {
    display: block;
    overflow: hidden;
    padding: 15px !important;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background: #90d2c5;
    margin: 0;
 }
}
.mb_back_to_top {
  float: right;
  color: #5d5d5d;
  font-size: 12px;
  text-decoration: underline;
}
.mb_back_to_top:before {
  border-left: 5px solid transparent;
  border-bottom: 8px solid;
  border-right: 5px solid transparent;
  content: "";
  display: inline-block;
  height: auto;
  width: 1px;
  margin-right: 5px;
  margin-top: -8px;
  vertical-align: baseline;
  visibility: visible;
}
@media screen and (max-width: 768px) {
  .mb_back_to_top:before {
    border-top: 4px solid transparent;
    border-left: 8px solid;
    border-bottom: 4px solid transparent;
 }
}
.mb_back_to_top:hover {
  color: #000;
}
#FAQ_links span.title {
  font-weight: 900;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  #FAQ_links span.title {
    font-size: 12px;
    color: #333;
    font-weight: normal;
    line-height: 25px;
    padding-bottom: 10px;
 }
}
/*terms& conditions */
.terms-conditions .staticHeader .static_para {
  margin-top: 0px;
}
.terms-conditions .staticHeader .static_para p {
  padding: 10px 0px;
}
/* Promotional Terms & Conditions */
.promotionalterms-conditions .staticHeader .mb_contentblock_landing div p {
  padding-bottom: 17px;
}
/* Placing an Order trade enquire */
.tradeenquiry .staticHeader .static_para p a {
  color: #666;
}
/* delivery and returns */
span p a {
  color: #666;
}
.staticsubheader {
  padding-bottom: 13px;
}
 .delivery-returns .staticHeader .static_para ul li {
  list-style: circle;
  margin-left: 15px;
  color: #000;
}
a:link, a {
  color: #666 ;
}
/*--affiliate -- */
.affiliate-enquiry-page .amp-dc-block-wrap:after {
  clear: both;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
  width: 964px;
  height: 372px;
  margin-bottom: 30px;
  position: relative;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap {
  height: 210px;
  width: 427px;
  background-color: rgba(255, 255, 255, 0.8);
  top: 20%;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-banner-info-wrap .amp-dc-banner-info {
  display: inline-block;
 /* vertical-align: middle;
  */
  width: 75%;
  margin: 24px 55px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner {
    width: 100%;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap {
  position: relative;
  height: auto;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin-top: 0px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  width: 964px;
  height: 372px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
    width: 100%;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  top: 25%;
  width: 427px;
  height: 210px;
  left: 3%;
  right: 3%;
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap {
    width: 94%;
    height: auto;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
  font-size: 28px;
  line-height: 35px;
  padding: 15px 65px;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif !important;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-name {
    padding: 15px 10px;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
  color: #1c1c1c;
  font-size: 20px;
  line-height: 25px;
  margin: 0px 0 25px 0;
  padding: 0px 65px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-banner .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-description {
    padding: 0px 10px;
    font-size: 14px;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
  width: 365px;
  height: 237px;
  border: 4px solid #4fb9c9;
  padding: 28px 10px 28px 60px;
  float: right;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 {
    width: 100%;
    margin: 0;
    height: auto;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 h2 i {
  font-family: Georgia, 'Times New Roman', Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .afiliate-enquiry-rectangle-1 ul li:first-child {
  font-weight: bold;
  margin-top: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
  background: #f9f9f9;
  width: 365px;
  height: 237px;
  padding: 32px 10px 28px 60px;
  float: left;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 {
    width: 100%;
    margin: 15px 0 0;
    height: auto;
 }
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 h2 i {
  font-family: Georgia, 'Times New Roman', Times, serif !important;
  font-size: 28px;
  color: #1c1c1c;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li {
  font-size: 12px;
  color: #000;
  margin-left: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .affiliate-enquiry-rectangle-02 ul li:first-child {
  margin-top: 15px;
}
.affiliate-enquiry-page .amp-dc-block-wrap .note {
  margin-top: 30px !important;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
  margin-top: 30px !important;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 {
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 {
    margin-left: 0 !important;
    margin-right: 0 !important;
 }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p {
  color: #1c1c1c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 17px;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .mail-link, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .mail-link {
  color: #1c1c1c !important;
  font-size: 14px;
  font-weight: bold;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
  background-color: #c74782;
  color: #fff !important;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  padding: 10px 20px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button {
    width: 100%;
    text-align: center;
 }
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 p .generic-button:after, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 p .generic-button:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.affiliate-enquiry-page .affiliate-enquiry-applynow-section-1 h2 i, .affiliate-enquiry-page .affiliate-enquiry-applynow-section-2 h2 i {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part {
  padding: 0px;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with {
  margin-top: 15px !important;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with h2 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 28px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #000;
  font-style: italic;
}
.affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
  margin-top: 15px !important;
  width: 346.8px;
  height: 46.53px;
}
@media screen and (max-width: 768px) {
  .affiliate-enquiry-page .amp-dc-splitBlock .amp-dc-split-part .we-work-with-image {
    width: 100%;
    height: auto;
 }
}
/* affiliate --End */
/* bespoke-hotel-amenitiespage */
.bespoke-hotel-amenitiespage {
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .bespoke-hotel-amenitiespage {
    padding-left: 0px;
 }
}
 .WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}
 .HotelS_heading, .bespoke-hotel-amenitiespage .WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-01 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: 10px auto;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap {
  position: relative;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap {
  width: 292px;
  height: 218px;
  margin: auto;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image {
  height: inherit;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-img-wrap .amp-dc-image img {
  height: inherit;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  width: 292px;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #000 8a !important;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff;
}
.bespoke-hotel-amenitiespage .bespoke-hotel-aminities-right-section .amp-dc-block-wrap .bespoke-right-card-02 .amp-dc-card-wrap .amp-dc-card-text-wrap .amp-dc-card-link::after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.mb_address {
  margin-top: 10px;
  padding-top: 7px;
}
.contactdetails {
  margin: 0px !important;
}
.contactdetails .HotelS_text-orange {
  color: #eb6909;
  margin-top: 32px;
  font-size: 13px !important;
}
.hotelproducts-page {
  width: 752px;
}
.hotelproducts-page .amp-dc-splitBlock {
  border: 1px solid #d6d6d6;
  margin-top: 20px;
  padding: 10px;
  width: 752px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-60 {
  width: 448px;
}
 .hotel-collections-description {
  width: 448px;
  margin: 0;
  padding: 0 0 0 12px;
}
 .hotel-collections-description .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}
 .hotel-collections-description .HotelS_text {
  margin: 10px 0 20px;
}
 .hotel-collections-description a {
  font-size: 13px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 {
  width: 277px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap {
  height: 172px;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap .amp-dc-image {
  height: inherit;
}
.hotelproducts-page .amp-dc-splitBlock .amp-dc-size-40 .amp-dc-split-part-wrap img {
  width: 100%;
  height: inherit;
}
@media screen and (max-width: 768px) {
  .hotelproducts-page {
    width: 100%;
 }
   .hotel-collections-description, .hotelproducts-page .amp-dc-size-60 {
    width: 100% !important;
 }
   .hotel-collections-description .amp-dc-size-40, .hotelproducts-page .amp-dc-size-60 .amp-dc-size-40 {
    margin: auto;
 }
}
/* hand care*/
.eledescription {
  margin-top: 20px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child {
  position: relative;
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap {
  padding: 0px;
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
  background-color: #941e7e;
  padding-top: 10px;
  color: #b5c437 !important;
  height: 161px;
  width: 410px;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-name {
    width: 100%;
 }
}
.hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
  position: absolute;
  bottom: 35px;
  right: 50px;
  color: #b5c437 !important;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .amp-dc-block-wrap:first-child .amp-dc-card-text-wrap .amp-dc-card-description {
    bottom: 10px;
 }
}
.hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
  margin: 0px;
  width: 408px;
  text-align: justify;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-leftsection .hotel-aminities-main-text {
    width: 100%;
 }
}
.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}
.hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  margin: auto;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
    width: 100%;
 }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 {
  position: relative;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
    height: auto;
 }
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}
.hotel-aminities-page .Hotel-aminities-rightsection .bespoke-right-card-02 .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap {
    flex-direction: column;
 }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
  width: 220px;
  height: 202px;
}
@media screen and (max-width: 480px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card {
    margin: 10px auto;
    width: 100%;
    height: auto;
 }
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap {
  position: relative;
  margin: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-wrap .amp-dc-card-img-wrap {
  margin: 0px;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link {
  color: #fff !important;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-text-wrap .amp-dc-card-link:after {
  border-top: 5px solid transparent;
  border-left: 5px solid;
  border-bottom: 5px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 5px;
  visibility: visible;
  width: 0;
}
.hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
  width: 220px;
  height: 202px;
}
@media screen and (max-width: 768px) {
  .hotel-aminities-page .amp-dc-block-wrap .amp-dc-prod-3-rows .amp-dc-card-list-wrap .amp-dc-card .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
 }
}
.hotel-collections {
  width: 770px;
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .hotel-collections {
    width: 100%;
    padding-left: 0px;
 }
}
.hotel-collections .amp-dc-splitBlock {
  padding: 10px;
  border: 1px solid #d6d6d6;
  margin-top: 20px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 {
  width: 125px;
  height: 125px;
  padding: 2px;
  border: 1px solid #d6d6d6;
  margin: auto;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-10 .amp-dc-image {
  height: 120px;
  width: 120px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description {
  margin-top: 10px;
  padding-bottom: 7px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p.title {
  margin-top: 10px;
  font-size: 14.1px;
  padding-bottom: 7px;
}
.hotel-collections .amp-dc-splitBlock .amp-dc-size-90 .description p {
  margin-top: 10px;
}
.hotel-collections.collections1973 .hotelproducts .staticHeader p {
  margin-top: 20px;
}
.whymoltonbrown {
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  .whymoltonbrown {
    padding-left: 0px;
 }
}
.WhyMoltonBrown-main-text {
  margin: 0px !important;
}
.WhyMoltonBrown-main-text .leftContent {
  margin: 20px 24px 0 0;
}
.WhyMoltonBrown-main-text .leftContent .HotelS_heading {
  font-size: 16px;
  color: #da007a;
  margin: 24px 0 0 0;
  font-weight: bold;
}
.WhyMoltonBrown-main-text .leftContent img {
  width: 130px;
  height: 78px;
}
aside {
  width: 194px;
  border: 0;
  text-align: left;
  padding: 4px 6px 20px 15px;
}
aside h3.facet_header {
  font-size: 14px;
  line-height: 30px;
  color: #666;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: left !important;
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  aside h3.facet_header {
    display: none !important;
 }
}
.whymoltonbrown-right-section .amp-dc-block-wrap {
  width: 292px;
  margin: auto;
}
.whymoltonbrown-right-section .amp-dc-block-wrap:first-child {
  margin-top: 20px;
}
.whymoltonbrown-right-section .amp-dc-block-wrap:first-child .amp-dc-card-img-wrap {
  width: 292px;
  height: 161px;
  margin: auto;
}
.whymoltonbrown-right-section .bespoke-right-card-02 {
  position: relative;
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
  width: 292px;
  height: 218px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-img-wrap .amp-dc-image img {
    width: 100%;
 }
}
.whymoltonbrown-right-section .bespoke-right-card-02 .amp-dc-card-text-wrap {
  position: absolute;
  bottom: 0px;
  background-color: #777674 d4;
  color: #fff !important;
  width: 100%;
}

.careers h1 {
  padding-left: 18px;
}
.careers .spaHotelsCareers3900026 {
  margin-top: 40px;
}
.careers .spaHotelsCareers3900026 p {
  margin-top: 20px;
  padding-bottom: 7px;
  padding-left: 18px;
}
.careers .spaHotelsCareers3900026 .one {
  margin-top: 20px;
}
.careers .spapage {
  display: flex;
  padding: 42px 18px 18px 18px;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .careers .spapage {
    flex-direction: column;
 }
  .careers .spapage div {
    width: 100% !important;
    height: auto !important;
 }
}
.careers .spapage p {
  padding-top: 20px;
}
.careers .spapage a {
  text-decoration: underline;
}
.careers .spapage div {
  width: 220px;
  height: 202px;
  padding: 5px;
  border: #d6d6d6 1px solid;
  margin-top: 10px;
}
.ourheritagepage .ourheritageheaderblock {
  margin: 0;
}
.ourheritagepage .ourheritageheaderblock .head-section {
  font-family: Georgia, Garamond, serif;
  color: #000;
  margin: auto;
  text-align: center;
  width: 425px !important;
}
@media screen and (max-width: 768px) {
  .ourheritagepage .ourheritageheaderblock .head-section {
    width: 100% !important;
 }
}
.ourheritagepage .ourheritageheaderblock .head-section .main-title {
  font-size: 25px;
  margin: 30px 0 3px;
  padding: 0;
  font-weight: 100;
  font-family: Georgia;
  text-transform: uppercase;
}
.ourheritagepage .ourheritageheaderblock .head-section .sub-title {
  font-size: 22px;
  font-style: italic;
  margin: 0 0 15px;
  font-weight: 100;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion {
  margin-bottom: 15px;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle {
  width: 42px;
  height: 42px;
  border-radius: 40px;
  border: 1px solid #000;
  padding-top: 3px;
  margin: auto;
  font-family: Georgia;
  line-height: 15px;
  font-style: italic;
  font-size: 14px;
}
.ourheritagepage .ourheritageheaderblock .head-section .time-duartion .time-circle span {
  display: block;
  font-size: 14px;
}
.ourheritagepage .ourheritageheaderblock .head-section .font14 {
  width: 425px;
  font-family: Georgia !important;
  font-size: 14px;
  margin: auto;
}
@media screen and (max-width: 425px) {
  .ourheritagepage .ourheritageheaderblock .head-section .font14 {
    width: 100% !important;
 }
}
.ourheritagepage .amp-dc-splitBlock .Ourheritage-text {
  color: #000;
  padding-bottom: 30px;
}
.Ourheritage-text h3 {
  font-style: italic;
  font-size: 22px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
 .Ourheritage-text p {
  margin-top: 16px;
}




.privacyPolicy .staticHeader .mb_contentblock_landing {
  counter-reset: h2;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list {
  margin: 0 0 13px;
  padding: 0 0 0 34px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .bullet-list li {
  line-height: 15px;
  font-size: 12px;
  margin-left: 15px;
  padding-bottom: 5px;
  color: #000;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .para-left-padding {
  padding: 0 0 13px 34px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h2 {
  font-weight: 700;
  counter-reset: h3;
  margin: 0;
  padding: 5px 0 13px 0;
  font-size: 13px;
  color: #000 !important;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h2::before {
  counter-increment: h2;
  content: counter(h2) ". ";
  padding-right: 18px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h3 {
  padding: 5px 0 13px;
  font-size: 13px;
  color: #000;
  font-weight: 700;
}
.privacyPolicy .staticHeader .mb_contentblock_landing h3::before {
  counter-increment: h3;
  content: counter(h2) "." counter(h3) ". ";
  padding-right: 8px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead {
  vertical-align: top;
  height: 30px;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc thead th {
  color: #000 !important;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .staticsubheader {
  font-weight: 700;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc p {
  font-family: Arial, Helvetica, sans-serif;
}
.privacyPolicy .staticHeader .mb_contentblock_landing .container-bcc .table-bcc {
  border: 0 transparent;
  margin: 5px 0 13px 34px;
  width: 100%;
  border-collapse: collapse;
}
.brochureblock {
  padding: 0 !important;
}
.mb_contentblock_landing.singleBlock td {
  border-top: 0 solid #d6d6d6;
  padding: 5px 5px 5px 0;
  vertical-align: top;
}


.corporate-gifting p {
   padding-top: 20px;
   font-size: 16px;
   width: 80%;
   margin: 0 auto;
   line-height: 18px;
}
.corporate-gifting a {
   color: #000;
   font-weight: bold;
}
.corporate-gifting a.download-brochure-link{
   background: #4d3938;
   font-style: italic;
   font-family: georgia;
   color: #fff;
   padding: 10px 30px 10px 30px;
   display: inline-block;
   margin: 30px 0 33px;
   font-size: 16px;
   cursor: pointer;
   font-weight: normal;
}
