
.time-duartion .time-circle {
  font-size:10px;
      text-align: center;
 width: 42px;
 height: 42px;
 border-radius: 40px;
 border: 1px solid #000;
 padding-top: 3px;
 margin: auto;
 font-family: Georgia;
 line-height: 15px;
 font-style: italic;
 font-size: 14px;
span{
 display:block;
}
}
.ImageOverlay {
 height: 24px;
 position: absolute;
 top: 0px;
 font-style:italic;
 width: 76.84px;
 font-family: "Times New Roman";
 text-align: center;
 border: 1px;
 border-style: solid;
}
.time-duartion {
 margin-top: 15px;
 margin-bottom: 10px;
}
.image-414 blockquote p{
 padding: 44px 58px 0 50px;
 line-height: 1.2;
}
.image-415 blockquote p{
 padding: 44px 58px 0 50px;
 line-height: 1.2;
}

blockquote p{

 font-size: 30px
}
blockquote{
 position: relative;
 padding: 0;
 border: none;
 font-size: 150%;
 margin-top: 24px;
 font-style: italic;
 text-align: left;
}
.time-duartion .time-circle span{
 display:block;
}
.time-duartion .time-circle {
  font-size:10px;
      text-align: center;
 width: 42px;
 height: 42px;
 border-radius: 40px;
 border: 1px solid #000;
 padding-top: 3px;
 margin: auto;
 font-family: Georgia;
 line-height: 15px;
 font-style: italic;
 font-size: 14px;

}
.image-270x181 .ImageOverlay{
 font-size:10px;
}
.ImageOverlay {
 height: 24px;
 position: absolute;
 top: 0px;
 font-style:italic;
 width: 76.84px;
 font-family: "Times New Roman";
 text-align: center;
 border: 1px;
 border-style: solid;
}
.time-duartion {
 margin-top: 15px;
 margin-bottom: 10px;
}
.amp-dc-card {
width: 100%;
display: inline-block;
vertical-align: top;
   position: relative;
}

.amp-dc-card * {
margin: auto;
padding: 0;
}

.amp-dc-card .amp-dc-hide {
display: none;
}

.amp-dc-card .amp-dc-card-text-wrap {
color: #000;
padding: 10px 0;
text-align: center;
background-color: #fff;
}

.amp-dc-card a.amp-dc-card-wrap {
display: block;
text-decoration: none;
}

.amp-dc-card a.amp-dc-card-wrap:hover .amp-dc-card-link {
text-decoration: underline;
}

.amp-dc-card .amp-dc-card-name {
   font-size: 15px;
 line-height: 20px;
 color: #000;color: #000;
 font-weight: 700;
 margin-bottom: 4px;
}

.amp-dc-card .amp-dc-card-description {
font-style: italic;
 font-family: Georgia;
 
 font-size: 14px;
 line-height: 1.2;
 display: block;
 color: #000;
}

.amp-dc-card .amp-dc-card-link {
font-size: 14px;
 padding: 7px 25px 5px;
 line-height: 20px;
 display: inline-block;
 text-decoration: none;
 color: #fff;
 background-color: #000;
 text-transform: uppercase;
}

.amp-dc-external-block .test {
padding: 20px;
background: #efefef;
border-radius: 5px;
margin: 20px;
min-width: 360px;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

.amp-dc-card-img-wrap {
 position: relative;
}
.amp-dc-image {
margin: 0;
padding: 0;
position: relative;
overflow: hidden;
white-space: nowrap;
width: 100%;
display: block;
}

.amp-dc-image .amp-dc-image-pic {
display: block;
}

.amp-dc-image img {
width: 100%;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.image-934x500{
 
 width:934px;

}

.image-964x372{
 width:964px;

}
.image-964x400{
  width:964px;
 
}

.image-964x400{
  width:964px;
 
}
.image-880x175{
  width:880px;
}

@media screen and (min-width: 769px){


.amp-dc-card.image-270x181 {
       width:270px;
       height:auto;
 
}
 .image-574x385{
  width:100%!important;
 }
.image-574x385 .amp-dc-card-img-wrap{
  float: left;
  width:574px;

  height:386px;
}
.image-574x385 .time-duartion {
 float: right;
 width: 304px;
}

.image-574x385 .amp-dc-card-text-wrap {
   margin-top: 100px;
 width: 304px;
 float: right;
}
}

.image-417{
 width:417px;
}
.image-415x277{
  width:415px;
height:auto;
}
.image-415x532{
  width:415px;
height:auto;
}
.image-430x290{
  width:430px;
}
.amp-dc-card.image-431x291{
  width:431.98px;
  
}
@media screen and (min-width: 1025px){
  
.amp-dc-card.image-431x291 a.amp-dc-card-wrap{
  width:431.98px;
}
}
.amp-dc-card.image-431x291 .amp-dc-card-name{
 font-size:18px;
}
.image-431x291 .amp-dc-banner-description{
 margin-bottom: 10px;
  line-height: 18px;
 font-size:14px;
}
.amp-dc-card.image-431x291 .amp-dc-card-link{
 border: 2px solid #000;
 color: #000;
 background-color: #fff;
 font-size:16px;
 height:36px;
}
.image-445x181{
  width:445px;
}
.image-355x478{
  width:355px;
}
.image-292x161{
  width:292px;
}
.image-293x218{
  width:293px;
}
.image-293x218 .amp-dc-card-text-wrap{
display: block;
 position: relative;
}
.image-293x218 .amp-dc-card-link{
 
 bottom:0px;
 padding: 10px 0;
 text-align: center;
    background-color:unset;
}
.image-277x172{
  width:277px;
}

.image-220x220{
  width:220px;
}
.image-270x181{
  width:270px;
}

.image-270x181.amp-dc-card-name{
 font-size:16px;
    line-height: 17px;
}
.image-270x181.amp-dc-card-description{
  line-height: 20.94px;
 font-size:14px;
}
.image-270x181 .amp-dc-card-link{
 font-size:16px;
 height:36px;
}

.image-165x166{
  width:165px;
  height:auto;
}
.image-165x166 .amp-dc-card-name{
 display: none;
}

.image-165x166 .amp-dc-card-wrap .amp-dc-card-text-wrap {
 position: absolute!important;
 bottom: 0!important;
 right: 0;
 left: 0;
 background-color: #0000008a!important;
}
.image-165x166 .amp-dc-card-description{
 display:none;
}
.image-165x166  .amp-dc-card-link {
     background-color: unset;

 color: #8f8f8f;
 font-size: 16px;
 white-space: nowrap;
}
.image-220x208 .amp-dc-card-wrap .amp-dc-card-text-wrap {
 position: absolute!important;
 bottom: 0!important;
 right: 0;
 left: 0;
 background-color: #0000008a!important;
}

.image-220x202 .amp-dc-card-link {
 background-color: unset;
 color: #8f8f8f;
 font-size: 16px;
 white-space: nowrap;
}
.image-220x202 {
 width:220px;
 height:208px;
}

.image-125x125{
  width:125px;
  height:auto;
}
.image-208x208{
  width:208px;
  height:auto;
}
.image-208x208 .amp-dc-card-name{
 font-size: 14px !important;
 font-weight: normal !important;
}
.image-208x208 .amp-dc-card-link{
 background: unset;
 font-size: 14px;
 color: #000;
 text-decoration: underline;
}
.image-208x208 .amp-dc-card-link::after {
 border-top: 5px solid transparent;
 border-left: 5px solid;
 border-bottom: 5px solid transparent;
 content: "";
 display: inline-block;
 height: 0;
 margin-left: 5px;
 visibility: visible;
 width: 0;
}

@media screen and (max-width: 768px){
  
.image-445x181, .image-355x478, .image-292x161, .image-293x218, .image-431x291,.image-270x181, .image-220x220, .image-165x166{
 width:100%;
 height:auto;
}
.image-277x172, .image-934x500, .image-430x290,  .image-964x400, .image-880x175, .image-574x385 , .image-430x290{
 width:100%;
 height: auto;
}
.image-125x125{
  width:50%;
  height:50%;
  
}
.image-415x532{
width:100%;
 height: auto;

}
.image-415x277{
width:100%;
 height: auto;

}

.image-417{
 width:100%;
 height:auto;
}
.image-430x290{
width:100%;
}
.image-270x181{
  width:100%;
}
.image-220x208 {
 width:100%;
 height:auto;
}

}
.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-link {
  background-color: #fff;
  color: #000;
  text-transform: none;
}
.amp-dc-card.journal-product-212 .amp-dc-card-link {
  background-color: #fff;
  color: #000;
  text-transform: none;
}
.amp-dc-card.perfumer-profile-shop-fragrances-img .amp-dc-card-description p , .amp-dc-card.journal-product-212 .amp-dc-card-description p {
  font-family: inherit!important;
  font-style: normal;
}
.amp-dc-card.journal-perfumers-img-291x291 .amp-dc-card-description {
  font-style: normal;
  }
  @media screen and (min-width: 1025px){
  .amp-dc-card.journal-perfumers-img-291x291 {  
  width: 291px!important;
    height: auto;
  }
}
@media screen and (max-width: 767px)
{
.amp-dc-card.hp-small-431x291-poi {  
  width: 100%!important;
  height: auto;
}
}
@media screen and (max-width: 1024px){
  .amp-dc-card.image-431x291{
 width:100%;
 height:auto;
}
}
         
